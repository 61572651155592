export default function (Availability, Step, $scope, $log, $state, availabilityLoggerService) {
    'ngInject';

    const vm = this;
    vm.onChange = function (channel) {
        availabilityLoggerService.log(
            {
                message: 'Availability step reception event emitted',
                channel,
                stepId: vm.step.stepId,
                menuId: vm.step.menuId,
            },
            vm.step.menuId,
        );
        $log.info('AVAILABILITY-STEP-EVENT', {
            stepId: vm.step.stepId,
        });
        Availability.updateStep({
            actionId: vm.step.stepId,
            availability: channel === 2 ? vm.step.webAvailability : vm.step.kioskAvailability,
            channel,
            menuId: vm.step.menuId,
        }).$promise.then(
            function (step) {
                availabilityLoggerService.log(
                    {
                        source: 'reception',
                        codeLocalizationLevel1: 'components/availability/step/step.controller.js',
                        codeLocalizationLevel2: 'vm.onChange(channel)',
                        codeLocalizationLevel3: 'Availability step reception event emitted',
                        channel,
                        stepId: vm.step.stepId,
                        menuId: vm.step.menuId,
                    },
                    vm.step.menuId,
                );
                vm.step.webAvailability = step.webAvailability;
                vm.step.kioskAvailability = step.kioskAvailability;
            },
            function (error) {
                availabilityLoggerService.log(
                    {
                        source: 'reception',
                        codeLocalizationLevel1: 'components/availability/step/step.controller.js',
                        codeLocalizationLevel2: 'vm.onChange(channel)',
                        codeLocalizationLevel3: 'Availability step reception event error',
                        error: JSON.stringify(error),
                        channel,
                        stepId: vm.step.stepId,
                        menuId: vm.step.menuId,
                    },
                    vm.step.menuId,
                );
                $state.go(
                    'home.availability',
                    {},
                    {
                        reload: 'home.availability',
                    },
                );
            },
        );
    };

    vm.select = function () {
        if (vm.selected === vm.step.signature) {
            vm.selected = null;
        } else {
            Step.get({
                menuId: vm.step.menuId,
                signature: vm.step.signature,
                should_fetch_ubereats_status: vm.shouldUseUbereats ? 1 : 0,
                should_fetch_deliveroo_status: vm.shouldUseDeliveroo ? 1 : 0,
            }).$promise.then(
                function (step) {
                    vm.selectedStep = null;
                    vm.products = step.stepProduct;
                    vm.selected = step.signature;
                },
                // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
                function (error) {
                    $state.go(
                        'home.availability',
                        {},
                        {
                            reload: 'home.availability',
                        },
                    );
                },
            );
        }
    };

    vm.onChildUpdate = function (channel) {
        const property = channel === 2 ? 'webAvailability' : 'kioskAvailability';
        const update = !vm.products
            .map(function (product) {
                return !product[property];
            })
            .every(function (availability) {
                return availability;
            });
        if (vm.step[property] !== update) {
            vm.step[property] = update;
            vm.onChange(channel);
        }
    };
}

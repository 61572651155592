import ordersHomeView from 'app/components/orders_home/orders_home.view.html';
import availabilityView from 'app/components/availability/availability.view.html';
import stuartView from 'app/components/stuart/stuart.view.html';
import parametersView from 'app/components/parameters/parameters.view.html';
import { get } from '@innovorder/angularcore/core/localStorageConverter';

const KIOSK_WEBORDERING_CHANNELS = [1, 2];

export default function ($stateProvider, $urlRouterProvider, $locationProvider) {
    'ngInject';

    $locationProvider.html5Mode(true);

    $stateProvider
        .state('home.orders_home', states.ordersHome())
        .state('home.availability', states.availability())
        .state('home.stuart', states.stuart())
        .state('home.parameters', states.parameters());
}

// eslint-disable-next-line vars-on-top, no-var
var states = {
    ordersHome() {
        return {
            url: '/orders_home',
            sticky: true,
            templateProvider: [
                '$templateCache',
                function ($templateCache) {
                    return $templateCache.get(ordersHomeView);
                },
            ],
            controller: 'OrdersHomeController',
            controllerAs: 'ordersHomeVm',
            abstract: true,
            resolve: {
                stuartActive: [
                    'Stuart',
                    'restaurant',
                    '$q',
                    function (Stuart, restaurant, $q) {
                        const deferred = $q.defer();
                        Stuart.getStuartByRestaurantId({
                            restaurantId: restaurant.restaurantId,
                        })
                            // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
                            .$promise.then(function (data) {
                                deferred.resolve(true);
                            })
                            // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
                            .catch(function (e) {
                                deferred.resolve(false);
                            });
                        return deferred.promise;
                    },
                ],
                stuartV2Active: [
                    'StuartV2',
                    'restaurant',
                    '$q',
                    function (StuartV2, restaurant, $q) {
                        const deferred = $q.defer();
                        StuartV2.getStuartByRestaurantId({
                            restaurantId: restaurant.restaurantId,
                        })
                            .$promise.then(function (data) {
                                if (data.isConnected) {
                                    deferred.resolve(true);
                                } else {
                                    deferred.resolve(false);
                                }
                            })
                            // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
                            .catch(function (e) {
                                deferred.resolve(false);
                            });
                        return deferred.promise;
                    },
                ],
                waitLongEnough: [
                    '$timeout',
                    '$q',
                    function ($timeout, $q) {
                        const deferred = $q.defer();

                        $timeout(function () {
                            deferred.resolve();
                        }, 1000); // 1s waiting

                        return deferred;
                    },
                ],
                openAt: [
                    '$q',
                    'RestaurantModule',
                    'restaurant',
                    'moment',
                    function ($q, RestaurantModule, restaurant, moment) {
                        const deferred = $q.defer();
                        const moduleOptions = {
                            restaurantId: restaurant.restaurantId,
                            moduleId: 2,
                            channelId: 2,
                        };
                        RestaurantModule.get(moduleOptions)
                            .$promise.then(function (module) {
                                deferred.resolve(moment(module.openAt));
                            })
                            .catch(function (err) {
                                deferred.reject(err);
                            });
                        return deferred.promise;
                    },
                ],
                colors(localStorageService) {
                    'ngInject';

                    try {
                        // TODO : When all reception are >= 2.0.3 replace by localStorageService.cookie.get()
                        const colors = get(localStorageService, 'cardColors');
                        return colors == null ? undefined : colors;
                    } catch (e) {
                        return undefined;
                    }
                },
            },
        };
    },
    availability() {
        return {
            url: '/availability',
            sticky: true,
            templateProvider: [
                '$templateCache',
                function ($templateCache) {
                    return $templateCache.get(availabilityView);
                },
            ],
            controller: 'AvailabilityController',
            controllerAs: 'availabilityVm',
            resolve: {
                menus($q, apiService, restaurant) {
                    'ngInject';

                    const deferred = $q.defer();

                    apiService.restaurant
                        .query({
                            restaurantId: restaurant.restaurantId,
                            action: 'menus',
                        })
                        .$promise.then(function (menus = []) {
                            // We only keep availabilities for webordering and kiosk channels
                            const filteredMenus = menus.filter((menu) =>
                                menu.channels.some((c) => KIOSK_WEBORDERING_CHANNELS.includes(c.channelId)),
                            );
                            filteredMenus.forEach((menu) => {
                                menu.channels = menu.channels.filter((c) =>
                                    KIOSK_WEBORDERING_CHANNELS.includes(c.channelId),
                                );
                            });
                            deferred.resolve(filteredMenus);
                        });

                    return deferred.promise;
                },
            },
        };
    },
    stuart() {
        return {
            abstract: true,
            views: {
                'stuart@': {
                    templateUrl: stuartView,
                    controller: 'StuartController',
                    controllerAs: 'stuartVm',
                },
            },
            params: {
                stuartOrder: null,
                statePromiseName: null,
            },
            resolve: {
                order($stateParams, $q) {
                    'ngInject';

                    const deferred = $q.defer();
                    if ($stateParams.stuartOrder) {
                        deferred.resolve($stateParams.stuartOrder);
                    } else {
                        deferred.reject();
                    }
                    return deferred.promise;
                },
            },
        };
    },
    parameters() {
        return {
            abstract: true,
            url: '/parameters',
            templateProvider: [
                '$templateCache',
                function ($templateCache) {
                    return $templateCache.get(parametersView);
                },
            ],
            controller: 'ParametersController',
            controllerAs: 'parametersVm',
        };
    },
};

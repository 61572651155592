"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.PRINTER_SUB_PADDING = exports.PRINTER_FIRST_PADDING = exports.DEFAULT_LINE_OPTIONS = exports.PRINTER_SCHEMA = exports.PRINTER_ALIGNMENT = exports.PRINTER_UNDERLINE = exports.PRINTER_FONT_SIZE = exports.PRINTER_EMPHASIS = exports.PRINTER_ACTIONS = exports.EMPTY_CHARACTER = exports.PRINTER_ATTRIBUTES = exports.PRINTER_LINE_LENGTH = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/defineProperty"));

var _PRINTER_SCHEMA;

var PRINTER_LINE_LENGTH = 48; // 48 for fontA, 64 for fontB

exports.PRINTER_LINE_LENGTH = PRINTER_LINE_LENGTH;
var PRINTER_ATTRIBUTES = {
  ALIGNMENT: 0,
  EMPHASIS: 1,
  UNDERLINE: 2,
  SIZE: 3
};
exports.PRINTER_ATTRIBUTES = PRINTER_ATTRIBUTES;
var EMPTY_CHARACTER = '&#8203;';
exports.EMPTY_CHARACTER = EMPTY_CHARACTER;
var PRINTER_ACTIONS = {
  LINE: 'line',
  FEEDLINE: 'feedline',
  BARCODE: 'barcode'
};
exports.PRINTER_ACTIONS = PRINTER_ACTIONS;
var PRINTER_EMPHASIS = {
  BOLD: '1',
  NORMAL: '0'
};
exports.PRINTER_EMPHASIS = PRINTER_EMPHASIS;
var PRINTER_FONT_SIZE = {
  NORMAL: '1',
  MEDIUM: '2',
  LARGE: '3'
};
exports.PRINTER_FONT_SIZE = PRINTER_FONT_SIZE;
var PRINTER_UNDERLINE = {
  UNDERLINED: '1',
  NORMAL: '0'
};
exports.PRINTER_UNDERLINE = PRINTER_UNDERLINE;
var PRINTER_ALIGNMENT = {
  CENTER: 'center',
  LEFT: 'left',
  RIGHT: 'right'
};
exports.PRINTER_ALIGNMENT = PRINTER_ALIGNMENT;
var PRINTER_SCHEMA = (_PRINTER_SCHEMA = {}, (0, _defineProperty2["default"])(_PRINTER_SCHEMA, PRINTER_ACTIONS.BARCODE, {}), (0, _defineProperty2["default"])(_PRINTER_SCHEMA, PRINTER_ACTIONS.FEEDLINE, {
  size: ['1', '2', '3', '4']
}), (0, _defineProperty2["default"])(_PRINTER_SCHEMA, PRINTER_ACTIONS.LINE, {
  alignment: [PRINTER_ALIGNMENT.LEFT, PRINTER_ALIGNMENT.CENTER, PRINTER_ALIGNMENT.RIGHT],
  emphasis: [PRINTER_EMPHASIS.NORMAL, PRINTER_EMPHASIS.BOLD],
  underline: [PRINTER_UNDERLINE.NORMAL, PRINTER_UNDERLINE.UNDERLINED],
  size: [PRINTER_FONT_SIZE.NORMAL, PRINTER_FONT_SIZE.MEDIUM, PRINTER_FONT_SIZE.LARGE, '4']
}), _PRINTER_SCHEMA);
exports.PRINTER_SCHEMA = PRINTER_SCHEMA;
var DEFAULT_LINE_OPTIONS = {
  align: PRINTER_ALIGNMENT.LEFT,
  emphasis: PRINTER_EMPHASIS.NORMAL,
  underline: PRINTER_UNDERLINE.NORMAL,
  size: PRINTER_FONT_SIZE.NORMAL
}; // TODO: Change paddings constants to be more useful

exports.DEFAULT_LINE_OPTIONS = DEFAULT_LINE_OPTIONS;
var PRINTER_FIRST_PADDING = {
  '0': '      ',
  '1': '         ',
  '2': '   ',
  '3': '  ',
  '4': ' '
};
exports.PRINTER_FIRST_PADDING = PRINTER_FIRST_PADDING;
var PRINTER_SUB_PADDING = {
  '0': '  ',
  '1': '  ',
  '2': ' ',
  '3': ' ',
  '4': ' '
};
exports.PRINTER_SUB_PADDING = PRINTER_SUB_PADDING;
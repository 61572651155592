"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = _default;

function _default() {
  'ngInject';

  return {
    getNameByValue: function getNameByValue(constants, value) {
      for (var key in constants) {
        if (constants.hasOwnProperty(key) && constants[key].VALUE == value) {
          return constants[key].NAME;
        }
      }

      return null;
    },
    getValueByName: function getValueByName(constants, name) {
      for (var key in constants) {
        if (constants.hasOwnProperty(key) && constants[key].NAME == name) {
          return constants[key].VALUE;
        }
      }

      return null;
    },
    getTitleByValue: function getTitleByValue(constants, value) {
      for (var key in constants) {
        if (constants.hasOwnProperty(key) && constants[key].VALUE == value) {
          return key;
        }
      }

      return null;
    }
  };
}
export default function (authInterceptorServiceProvider, $httpProvider) {
    'ngInject';

    authInterceptorServiceProvider.setCaseResponseError(
        401,
        function ($state, Api, authService) {
            authService.logout().then(function () {
                $state.go('login');
            });
        },
        'token_expired',
    );

    authInterceptorServiceProvider.setCaseResponseError(-1, function ($state, Api, authService, networkService) {
        networkService.testNetwork();
    });

    $httpProvider.interceptors.push('authInterceptorService');
}

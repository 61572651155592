"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

require("core-js/modules/es.array.find");

require("core-js/modules/es.function.name");

require("core-js/modules/es.object.to-string");

require("core-js/modules/es.promise");

require("core-js/modules/es.promise.finally");

var _splice = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/splice"));

var _indexOf = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/index-of"));

var _parseInt2 = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/parse-int"));

var _keys = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/keys"));

var _slice = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/slice"));

(function () {
  'use strict';

  var __slice = (0, _slice.default)([]);

  angular.module('indexedDB', []).provider('$indexedDB', function () {
    var IDBKeyRange, allTransactions, apiDirection, appendResultsToPromise, applyNeededUpgrades, cursorDirection, db, dbMode, dbName, dbPromise, dbVersion, defaultQueryOptions, errorMessageFor, indexedDB, readyState, upgradesByVersion;
    indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB, IDBKeyRange = window.IDBKeyRange || window.mozIDBKeyRange || window.webkitIDBKeyRange || window.msIDBKeyRange, dbMode = {
      readonly: 'readonly',
      readwrite: 'readwrite'
    }, readyState = {
      pending: 'pending'
    }, cursorDirection = {
      next: 'next',
      nextunique: 'nextunique',
      prev: 'prev',
      prevunique: 'prevunique'
    }, apiDirection = {
      ascending: cursorDirection.next,
      descending: cursorDirection.prev
    }, dbName = '', dbVersion = 1, db = null, upgradesByVersion = {}, dbPromise = null, allTransactions = [], defaultQueryOptions = {
      useIndex: void 0,
      keyRange: null,
      direction: cursorDirection.next
    }, applyNeededUpgrades = function applyNeededUpgrades(oldVersion, event, db, tx, $log) {
      var version;

      for (version in upgradesByVersion) {
        !upgradesByVersion.hasOwnProperty(version) || version <= oldVersion || ($log.log('$indexedDB: Running upgrade : ' + version + ' from ' + oldVersion), upgradesByVersion[version](event, db, tx));
      }
    }, errorMessageFor = function errorMessageFor(e) {
      return e.target.readyState === readyState.pending ? 'Error: Operation pending' : e.target.webkitErrorMessage || e.target.error.message || e.target.error.name || e.target.errorCode;
    }, appendResultsToPromise = function appendResultsToPromise(promise, results) {
      return void 0 !== results ? promise.then(function () {
        return results;
      }) : promise;
    }, this.connection = function (databaseName) {
      return dbName = databaseName, this;
    }, this.upgradeDatabase = function (newVersion, callback) {
      return upgradesByVersion[newVersion] = callback, dbVersion = Math.max.apply(null, (0, _keys.default)(upgradesByVersion)), this;
    }, this.$get = ['$q', '$rootScope', '$log', function ($q, $rootScope, $log) {
      var DbQ, ObjectStore, Query, Transaction, addTransaction, _closeDatabase, createDatabaseConnection, keyRangeForOptions, openDatabase, openTransaction, rejectWithError, validateStoreNames;

      return rejectWithError = function rejectWithError(deferred) {
        return function (error) {
          return $rootScope.$apply(function () {
            return deferred.reject(errorMessageFor(error));
          });
        };
      }, createDatabaseConnection = function createDatabaseConnection() {
        var dbReq, deferred;
        return deferred = $q.defer(), dbReq = indexedDB.open(dbName, (0, _parseInt2.default)(dbVersion) || 1), dbReq.onsuccess = function () {
          db = dbReq.result, $rootScope.$apply(function () {
            deferred.resolve(db);
          });
        }, dbReq.onblocked = dbReq.onerror = rejectWithError(deferred), dbReq.onupgradeneeded = function (event) {
          var oldVer, tx;
          oldVer = event.oldVersion, oldVer > Math.pow(2, 62) && (oldVer = 0), db = event.target.result, tx = event.target.transaction, $log.log("$indexedDB: Upgrading database '" + db.name + "' from version " + oldVer + ' to version ' + event.newVersion + ' ...'), applyNeededUpgrades(oldVer, event, db, tx, $log);
        }, deferred.promise;
      }, openDatabase = function openDatabase() {
        return dbPromise || (dbPromise = createDatabaseConnection());
      }, _closeDatabase = function closeDatabase() {
        return openDatabase().then(function () {
          return db.close(), db = null, dbPromise = null;
        });
      }, validateStoreNames = function validateStoreNames(storeNames) {
        var found, storeName, _i, _len;

        for (found = !0, _i = 0, _len = storeNames.length; _i < _len; _i++) {
          storeName = storeNames[_i], found &= db.objectStoreNames.contains(storeName);
        }

        return found;
      }, openTransaction = function openTransaction(storeNames, mode) {
        return null == mode && (mode = dbMode.readonly), openDatabase().then(function () {
          return validateStoreNames(storeNames) ? new Transaction(storeNames, mode) : $q.reject('Object stores ' + storeNames + ' do not exist.');
        });
      }, keyRangeForOptions = function keyRangeForOptions(options) {
        if (options.beginKey && options.endKey) return IDBKeyRange.bound(options.beginKey, options.endKey);
      }, addTransaction = function addTransaction(transaction) {
        return allTransactions.push(transaction.promise), transaction.promise.finally(function () {
          var index;
          if (index = (0, _indexOf.default)(allTransactions).call(allTransactions, transaction.promise), index > -1) return (0, _splice.default)(allTransactions).call(allTransactions, index, 1);
        });
      }, Transaction = function () {
        function Transaction(storeNames, mode) {
          null == mode && (mode = dbMode.readonly), this.transaction = db.transaction(storeNames, mode), this.defer = $q.defer(), this.promise = this.defer.promise, this.setupCallbacks();
        }

        return Transaction.prototype.setupCallbacks = function () {
          return this.transaction.oncomplete = function (_this) {
            return function () {
              return $rootScope.$apply(function () {
                return _this.defer.resolve('Transaction Completed');
              });
            };
          }(this), this.transaction.onabort = function (_this) {
            return function (error) {
              return $rootScope.$apply(function () {
                return _this.defer.reject({
                  title: 'Transaction Aborted',
                  error: error
                });
              });
            };
          }(this), this.transaction.onerror = function (_this) {
            return function (error) {
              return $rootScope.$apply(function () {
                return _this.defer.reject({
                  title: 'Transaction Error',
                  error: error
                });
              });
            };
          }(this), addTransaction(this);
        }, Transaction.prototype.objectStore = function (storeName) {
          return this.transaction.objectStore(storeName);
        }, Transaction.prototype.abort = function () {
          return this.transaction.abort();
        }, Transaction;
      }(), DbQ = function () {
        function DbQ() {
          this.q = $q.defer(), this.promise = this.q.promise;
        }

        return DbQ.prototype.reject = function () {
          var args;
          return args = 1 <= arguments.length ? __slice.call(arguments, 0) : [], $rootScope.$apply(function (_this) {
            return function () {
              var _ref;

              return (_ref = _this.q).reject.apply(_ref, args);
            };
          }(this));
        }, DbQ.prototype.rejectWith = function (req) {
          return req.onerror = req.onblocked = function (_this) {
            return function (e) {
              return _this.reject(errorMessageFor(e));
            };
          }(this);
        }, DbQ.prototype.resolve = function () {
          var args;
          return args = 1 <= arguments.length ? __slice.call(arguments, 0) : [], $rootScope.$apply(function (_this) {
            return function () {
              var _ref;

              return (_ref = _this.q).resolve.apply(_ref, args);
            };
          }(this));
        }, DbQ.prototype.notify = function () {
          var args;
          return args = 1 <= arguments.length ? __slice.call(arguments, 0) : [], $rootScope.$apply(function (_this) {
            return function () {
              var _ref;

              return (_ref = _this.q).notify.apply(_ref, args);
            };
          }(this));
        }, DbQ.prototype.dbErrorFunction = function () {
          return function (_this) {
            return function (error) {
              return $rootScope.$apply(function () {
                return _this.q.reject(errorMessageFor(error));
              });
            };
          }(this);
        }, DbQ.prototype.resolveWith = function (req) {
          return this.rejectWith(req), req.onsuccess = function (_this) {
            return function (e) {
              return _this.resolve(e.target.result);
            };
          }(this);
        }, DbQ;
      }(), ObjectStore = function () {
        function ObjectStore(storeName, transaction) {
          this.storeName = storeName, this.store = transaction.objectStore(storeName), this.transaction = transaction;
        }

        return ObjectStore.prototype.defer = function () {
          return new DbQ();
        }, ObjectStore.prototype._mapCursor = function (defer, mapFunc, req) {
          var results;
          return null == req && (req = this.store.openCursor()), results = [], defer.rejectWith(req), req.onsuccess = function (e) {
            var cursor;
            return (cursor = e.target.result) ? (results.push(mapFunc(cursor)), defer.notify(mapFunc(cursor)), cursor.continue()) : defer.resolve(results);
          };
        }, ObjectStore.prototype._arrayOperation = function (data, mapFunc) {
          var defer, item, req, results, _i, _len;

          for (defer = this.defer(), angular.isArray(data) || (data = [data]), _i = 0, _len = data.length; _i < _len; _i++) {
            item = data[_i], req = mapFunc(item), results = [], defer.rejectWith(req), req.onsuccess = function (e) {
              if (results.push(e.target.result), defer.notify(e.target.result), results.length >= data.length) return defer.resolve(results);
            };
          }

          return 0 === data.length ? $q.when([]) : defer.promise;
        }, ObjectStore.prototype.getAllKeys = function () {
          var defer, req;
          return defer = this.defer(), this.store.getAllKeys ? (req = this.store.getAllKeys(), defer.resolveWith(req)) : this._mapCursor(defer, function (cursor) {
            return cursor.key;
          }), defer.promise;
        }, ObjectStore.prototype.clear = function () {
          var defer, req;
          return defer = this.defer(), req = this.store.clear(), defer.resolveWith(req), defer.promise;
        }, ObjectStore.prototype.delete = function (key) {
          var defer;
          return defer = this.defer(), defer.resolveWith(this.store.delete(key)), defer.promise;
        }, ObjectStore.prototype.upsert = function (data) {
          return this._arrayOperation(data, function (_this) {
            return function (item) {
              return _this.store.put(item);
            };
          }(this));
        }, ObjectStore.prototype.insert = function (data) {
          return this._arrayOperation(data, function (_this) {
            return function (item) {
              return _this.store.add(item);
            };
          }(this));
        }, ObjectStore.prototype.getAll = function () {
          var defer;
          return defer = this.defer(), this.store.getAll ? defer.resolveWith(this.store.getAll()) : this._mapCursor(defer, function (cursor) {
            return cursor.value;
          }), defer.promise;
        }, ObjectStore.prototype.eachWhere = function (query) {
          var defer, direction, indexName, keyRange, req;
          return defer = this.defer(), indexName = query.indexName, keyRange = query.keyRange, direction = query.direction, req = indexName ? this.store.index(indexName).openCursor(keyRange, direction) : this.store.openCursor(keyRange, direction), this._mapCursor(defer, function (cursor) {
            return cursor.value;
          }, req), defer.promise;
        }, ObjectStore.prototype.findWhere = function (query) {
          return this.eachWhere(query);
        }, ObjectStore.prototype.each = function (options) {
          return null == options && (options = {}), this.eachBy(void 0, options);
        }, ObjectStore.prototype.eachBy = function (indexName, options) {
          var q;
          return null == indexName && (indexName = void 0), null == options && (options = {}), q = new Query(), q.indexName = indexName, q.keyRange = keyRangeForOptions(options), q.direction = options.direction || defaultQueryOptions.direction, this.eachWhere(q);
        }, ObjectStore.prototype.count = function () {
          var defer;
          return defer = this.defer(), defer.resolveWith(this.store.count()), defer.promise;
        }, ObjectStore.prototype.find = function (key) {
          var defer, req;
          return defer = this.defer(), req = this.store.get(key), defer.rejectWith(req), req.onsuccess = function (_this) {
            return function (e) {
              return e.target.result ? defer.resolve(e.target.result) : defer.reject('' + _this.storeName + ':' + key + ' not found.');
            };
          }(this), defer.promise;
        }, ObjectStore.prototype.findBy = function (index, key) {
          var defer;
          return defer = this.defer(), defer.resolveWith(this.store.index(index).get(key)), defer.promise;
        }, ObjectStore.prototype.query = function () {
          return new Query();
        }, ObjectStore;
      }(), Query = function () {
        function Query() {
          this.indexName = void 0, this.keyRange = void 0, this.direction = cursorDirection.next;
        }

        return Query.prototype.$lt = function (value) {
          return this.keyRange = IDBKeyRange.upperBound(value, !0), this;
        }, Query.prototype.$gt = function (value) {
          return this.keyRange = IDBKeyRange.lowerBound(value, !0), this;
        }, Query.prototype.$lte = function (value) {
          return this.keyRange = IDBKeyRange.upperBound(value), this;
        }, Query.prototype.$gte = function (value) {
          return this.keyRange = IDBKeyRange.lowerBound(value), this;
        }, Query.prototype.$eq = function (value) {
          return this.keyRange = IDBKeyRange.only(value), this;
        }, Query.prototype.$between = function (low, hi, exLow, exHi) {
          return null == exLow && (exLow = !1), null == exHi && (exHi = !1), this.keyRange = IDBKeyRange.bound(low, hi, exLow, exHi), this;
        }, Query.prototype.$desc = function (unique) {
          return this.direction = unique ? cursorDirection.prevunique : cursorDirection.prev, this;
        }, Query.prototype.$asc = function (unique) {
          return this.direction = unique ? cursorDirection.nextunique : cursorDirection.next, this;
        }, Query.prototype.$index = function (indexName) {
          return this.indexName = indexName, this;
        }, Query;
      }(), {
        openStore: function openStore(storeName, callBack, mode) {
          return null == mode && (mode = dbMode.readwrite), openTransaction([storeName], mode).then(function (transaction) {
            var results;
            return results = callBack(new ObjectStore(storeName, transaction)), appendResultsToPromise(transaction.promise, results);
          });
        },
        openStores: function openStores(storeNames, callback, mode) {
          return null == mode && (mode = dbMode.readwrite), openTransaction(storeNames, mode).then(function (transaction) {
            var objectStores, results, storeName;
            return objectStores = function () {
              var _i, _len, _results;

              for (_results = [], _i = 0, _len = storeNames.length; _i < _len; _i++) {
                storeName = storeNames[_i], _results.push(new ObjectStore(storeName, transaction));
              }

              return _results;
            }(), results = callback.apply(null, objectStores), appendResultsToPromise(transaction.promise, results);
          });
        },
        openAllStores: function openAllStores(callback, mode) {
          return null == mode && (mode = dbMode.readwrite), openDatabase().then(function (_this) {
            return function () {
              var objectStores, results, storeName, storeNames, transaction;
              return storeNames = (0, _slice.default)(Array.prototype).apply(db.objectStoreNames), transaction = new Transaction(storeNames, mode), objectStores = function () {
                var _i, _len, _results;

                for (_results = [], _i = 0, _len = storeNames.length; _i < _len; _i++) {
                  storeName = storeNames[_i], _results.push(new ObjectStore(storeName, transaction));
                }

                return _results;
              }(), results = callback.apply(null, objectStores), appendResultsToPromise(transaction.promise, results);
            };
          }(this));
        },
        closeDatabase: function closeDatabase() {
          return _closeDatabase();
        },
        deleteDatabase: function deleteDatabase() {
          return _closeDatabase().then(function () {
            var defer;
            return defer = new DbQ(), defer.resolveWith(indexedDB.deleteDatabase(dbName)), defer.promise;
          }).finally(function () {
            return $log.log('$indexedDB: ' + dbName + ' database deleted.');
          });
        },
        queryDirection: apiDirection,
        flush: function flush() {
          return allTransactions.length > 0 ? $q.all(allTransactions) : $q.when([]);
        },
        databaseInfo: function databaseInfo() {
          return openDatabase().then(function () {
            var storeNames, transaction;
            return transaction = null, storeNames = (0, _slice.default)(Array.prototype).apply(db.objectStoreNames), openTransaction(storeNames, dbMode.readonly).then(function (transaction) {
              var store, storeName, stores;
              return stores = function () {
                var _i, _len, _results;

                for (_results = [], _i = 0, _len = storeNames.length; _i < _len; _i++) {
                  storeName = storeNames[_i], store = transaction.objectStore(storeName), _results.push({
                    name: storeName,
                    keyPath: store.keyPath,
                    autoIncrement: store.autoIncrement,
                    indices: (0, _slice.default)(Array.prototype).apply(store.indexNames)
                  });
                }

                return _results;
              }(), transaction.promise.then(function () {
                return {
                  name: db.name,
                  version: db.version,
                  objectStores: stores
                };
              });
            });
          });
        }
      };
    }];
  });
}).call(void 0);
"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty2 = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty2(exports, "__esModule", {
  value: true
});

exports.default = _ngInjectExport;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/define-property"));

var _defineProperties = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/define-properties"));

var _getOwnPropertyDescriptors = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors"));

var _forEach = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/for-each"));

var _getOwnPropertyDescriptor = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor"));

var _filter = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/filter"));

var _getOwnPropertySymbols = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols"));

var _keys = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/keys"));

var _stringify = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/json/stringify"));

var _includes = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/includes"));

var _defineProperty3 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/defineProperty"));

var _uuid = require("uuid");

var _ticketSerializer = _interopRequireDefault(require("@innovorder/ticket-serializer"));

var _web_bridge = _interopRequireDefault(require("../web_bridge/web_bridge.constants"));

var _order_ticket = require("./order_ticket.constants");

function ownKeys(object, enumerableOnly) { var keys = (0, _keys.default)(object); if (_getOwnPropertySymbols.default) { var symbols = (0, _getOwnPropertySymbols.default)(object); if (enumerableOnly) symbols = (0, _filter.default)(symbols).call(symbols, function (sym) { return (0, _getOwnPropertyDescriptor.default)(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { var _context; (0, _forEach.default)(_context = ownKeys(Object(source), true)).call(_context, function (key) { (0, _defineProperty3.default)(target, key, source[key]); }); } else if (_getOwnPropertyDescriptors.default) { (0, _defineProperties.default)(target, (0, _getOwnPropertyDescriptors.default)(source)); } else { var _context2; (0, _forEach.default)(_context2 = ownKeys(Object(source))).call(_context2, function (key) { (0, _defineProperty2.default)(target, key, (0, _getOwnPropertyDescriptor.default)(source, key)); }); } } return target; }

_ngInjectExport.$inject = ["$rootScope", "apiService", "webBridge"];

function _ngInjectExport($rootScope, apiService, webBridge) {
  'ngInject';

  var orderTicketService = this;

  orderTicketService.init = function (_ref) {
    var source = _ref.source,
        brandId = _ref.brandId,
        restaurantId = _ref.restaurantId,
        userId = _ref.userId;
    orderTicketService.userId = userId;
    orderTicketService.brandId = brandId;
    orderTicketService.restaurantId = restaurantId;
    orderTicketService.source = source;
  };

  orderTicketService.eventListener = function () {
    $rootScope.$on(_web_bridge.default.ORDER_PRINT_STARTING, function (event, orderTicket) {
      var _orderTicket$isKitche, _orderTicket$printerD, _orderTicket$printers, _orderTicket$printers2, _orderTicket$printers3, _orderTicket$printers4;

      if (orderTicket.isTest) {
        webBridge.emitMessage(_web_bridge.default.PRINT_TICKET, _objectSpread({}, orderTicket));
        return;
      } // TODO : remove "add isKitchenPrinter to printers objects"


      orderTicket.isKitchenPrinter = (_orderTicket$isKitche = orderTicket.isKitchenPrinter) !== null && _orderTicket$isKitche !== void 0 ? _orderTicket$isKitche : true;
      var printedTicket = (0, _ticketSerializer.default)(orderTicket.xmlTicket, orderTicket.orderData, !orderTicket.isKitchenPrinter && orderTicket.isEcoFriendlyTicket);

      if (!printedTicket.length) {
        return;
      }

      var target = (orderTicket === null || orderTicket === void 0 ? void 0 : (_orderTicket$printerD = orderTicket.printerData) === null || _orderTicket$printerD === void 0 ? void 0 : _orderTicket$printerD.target) || null;
      var printerType = target ? (0, _includes.default)(target).call(target, 'TCP') ? 'TCP' : 'USB' : null;
      var bodyOrderTicket = {
        orderTicketUuid: (0, _uuid.v4)(),
        ticketId: orderTicket.ticket.ticketId,
        orderId: orderTicket.orderData.orderId,
        status: _order_ticket.PRINT_STATUS.PENDING,
        userId: orderTicketService.userId,
        printedTicket: (0, _stringify.default)(printedTicket),
        printersInformation: (0, _stringify.default)(orderTicket.printersStatus),
        target: target,
        printerType: printerType,
        source: orderTicketService.source,
        brandId: orderTicketService.brandId,
        isEcoFriendlyTicket: orderTicket.isEcoFriendlyTicket,
        isKitchenPrinter: orderTicket.isKitchenPrinter,
        restaurantId: orderTicketService.restaurantId
      };
      var statusPaper = orderTicket === null || orderTicket === void 0 ? void 0 : (_orderTicket$printers = orderTicket.printersStatus) === null || _orderTicket$printers === void 0 ? void 0 : (_orderTicket$printers2 = _orderTicket$printers[target]) === null || _orderTicket$printers2 === void 0 ? void 0 : _orderTicket$printers2.statusPaper;
      var statusCoverOpen = orderTicket === null || orderTicket === void 0 ? void 0 : (_orderTicket$printers3 = orderTicket.printersStatus) === null || _orderTicket$printers3 === void 0 ? void 0 : (_orderTicket$printers4 = _orderTicket$printers3[target]) === null || _orderTicket$printers4 === void 0 ? void 0 : _orderTicket$printers4.statusCoverOpen;

      if (statusPaper === 'PAPER_EMPTY' || statusCoverOpen === 'TRUE') {
        var bodyOrderTicketError = _objectSpread({}, bodyOrderTicket, {
          status: _order_ticket.PRINT_STATUS.ERROR
        });

        apiService.orderTicket.post(bodyOrderTicketError).$promise.then();
        return;
      }

      apiService.orderTicket.post(bodyOrderTicket).$promise.then();
      webBridge.emitMessage(_web_bridge.default.PRINT_TICKET, _objectSpread({}, orderTicket, {
        orderTicketUuid: bodyOrderTicket.orderTicketUuid
      }));
    });
    $rootScope.$on(_web_bridge.default.ORDER_PRINT_ERROR, function (event, _ref2) {
      var orderTicket = _ref2.element;

      /**
       * This is a temporary fix to avoid flooding the API when a printer is errored
       * The real fix will be made on android-web-app-bridge but it will takes some time to deploy all the apps
       */
      var maxRetryAsDefinedInAndroidWebAppBridge = 40;

      if (orderTicket.retries > maxRetryAsDefinedInAndroidWebAppBridge) {
        return;
      }

      var bodyErrorOrderTicket = {
        orderTicketUuid: orderTicket.orderTicketUuid,
        status: _order_ticket.PRINT_STATUS.ERROR,
        orderId: orderTicket.orderId,
        retries: orderTicket.retries,
        userId: orderTicketService.userId,
        brandId: orderTicketService.brandId,
        restaurantId: orderTicketService.restaurantId
      };
      apiService.orderTicket.put(bodyErrorOrderTicket).$promise.then();
    });
    $rootScope.$on(_web_bridge.default.ORDER_PRINT_SUCCEEDED, function (event, orderTicket) {
      var bodyOrderTicket = {
        orderTicketUuid: orderTicket.orderTicketUuid,
        status: _order_ticket.PRINT_STATUS.PRINTED,
        orderId: orderTicket.orderId,
        retries: orderTicket.retries,
        userId: orderTicketService.userId,
        brandId: orderTicketService.brandId,
        restaurantId: orderTicketService.restaurantId
      };
      apiService.orderTicket.put(bodyOrderTicket).$promise.then();
    });
  };
}
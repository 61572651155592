import fioDatepickerView from './fio_datepicker.view.html';
export default function($templateCache, $rootScope) {
  'ng-inject';

  function link(scope, element, attrs) {
    scope.UID = Math.random()
      .toString(36)
      .substring(20);

    setTimeout(function() {
      console.log('event broadcasted to datepicker');
      $rootScope.$broadcast('pickerUpdate', 'datepicker', {
        minView: 'date',
      });
    }, 1000);
  }

  return {
    link: link,
    restrict: 'E',
    transclude: true,
    scope: {},
    template: $templateCache.get(fioDatepickerView),
  };
}

import orderView from 'app/components/orders_home/order/order.view.html';

export default {
    templateUrl: orderView,
    controller: 'OrderController',
    controllerAs: 'orderVm',
    bindings: {
        order: '<',
        selected: '<',
        currency: '<',
        nextStatusGroup: '<',
        onError: '<',
        select: '<',
        cancel: '<',
        colors: '<',
    },
};

export default function (statePromise, $state, printService, orderTickets, $sce) {
    ('ngInject');

    const modalTicketPreviewVm = this;
    modalTicketPreviewVm.$onInit = () => {
        modalTicketPreviewVm.tickets = addPrintStatusToTicket(printService.tickets, orderTickets);
        if (modalTicketPreviewVm.tickets.length === 0) {
            return;
        }
        const defaultSelectedTicket = modalTicketPreviewVm.tickets[0];
        modalTicketPreviewVm.selectedTicket = defaultSelectedTicket;
    };

    modalTicketPreviewVm.close = () => statePromise.reject('ticketPreview');

    modalTicketPreviewVm.onChangeTicket = (ticket) => {
        modalTicketPreviewVm.selectedTicket = ticket;
    };

    modalTicketPreviewVm.displayTicketPreview = () => {
        const generatedPreview = printService.previewTicket(
            modalTicketPreviewVm.selectedTicket.config,
            $state.params.order,
        );
        modalTicketPreviewVm.ticketPreview = $sce.trustAsHtml(generatedPreview);
        return modalTicketPreviewVm.ticketPreview;
    };

    // eslint-disable-next-line no-shadow
    function addPrintStatusToTicket(tickets, orderTickets) {
        return tickets.map((ticket) => {
            const [lastPrintedInformation] = orderTickets
                .filter((orderTicket) => orderTicket.ticketId === ticket.ticketId)
                .sort((a, b) => (a.updated_at < b.updated_at ? 1 : -1));
            return {
                ...ticket,
                status: lastPrintedInformation ? lastPrintedInformation.status : null,
            };
        });
    }
    modalTicketPreviewVm.getTicketButtonClass = (ticket) => {
        const selectedClass = ticket.name === modalTicketPreviewVm.selectedTicket.name ? 'selected' : '';
        return `${ticket.status} ${selectedClass}`;
    };
}

import inputView from './input.view.html';

export default {
  templateUrl: inputView,
  require: {
    form: '^form',
  },
  scope: true,
  controller($element, $compile, $scope) {
    'ngInject';

    const vm = this;
    let input =
      '<input ng-focus="inputVm.setFocus(true, inputVm.name)" ng-blur="inputVm.setFocus(false, inputVm.name)"' +
      'type="{{inputVm.type}}" placeholder="{{inputVm.placeholder}}" name="{{inputVm.name}}" ng-model="inputVm.model" ';
    vm.disabled = false;
    vm.isFocused = false;
    vm.setFocus = function(value, fieldName) {
      vm.form[vm.name].$setValidity('invalid', true);
      vm.isFocused = value;

      if (value && vm.focusCallback) {
        vm.focusCallback(vm.model, fieldName);
      }

      if (!value && vm.blurCallback) {
        vm.blurCallbackArgument
          ? vm.blurCallback(vm.blurCallbackArgument)
          : vm.blurCallback();
      } else if (!value && vm.onBlurCallback) {
        vm.onBlurCallback({ parentEvent: $event });
      }
    };

    vm.enterTriggered = false;
    // #fffaeb
    vm.isValid = function() {
      return (
        !vm.skipErrorDisplay &&
        vm.form[vm.name] &&
        !vm.form[vm.name].search &&
        (vm.enterTriggered ||
          (!vm.isFocused && vm.form[vm.name].$modelValue)) &&
        vm.form[vm.name].$valid &&
        (vm.form.$pristine || vm.form.$dirty)
      );
    };
    vm.isInvalid = function() {
      return (
        !vm.skipErrorDisplay &&
        vm.form[vm.name] &&
        !vm.form[vm.name].search &&
        (vm.enterTriggered ||
          vm.form.$submitted ||
          (!vm.isFocused &&
            (vm.form[vm.name].$modelValue || vm.form[vm.name].$touched))) &&
        vm.form[vm.name].$invalid &&
        !vm.form.$pristine &&
        vm.form.$dirty
      );
    };
    vm.onEnter = function(keyEvent) {
      if (keyEvent.which === 13) {
        if (
          vm.searchEnabled &&
          vm.form[vm.name] &&
          !vm.form[vm.name].shouldNotSearch
        ) {
          vm.form[vm.name].search = true;
        }
        if (vm.form[vm.name]) {
          vm.form[vm.name].shouldNotSearch = false;
        }
        vm.enterTriggered = true;
        keyEvent.stopPropagation();
        keyEvent.preventDefault();
      }
    };
    vm.onChange = function() {
      vm.enterTriggered = false;
      vm.form.$submitted = false;
    };

    vm.$onInit = function() {
      const e = angular.element($element.find('div'));
      if (!vm.validateFormEnter) {
        input += ' ng-keypress="inputVm.onEnter($event);"';
      }
      if (vm.type == 'phone') {
        input += ' input-phone';
      }
      if (vm.dateOptions) {
        input += ' date-time';
        for (const option in vm.dateOptions) {
          input += ` ${option
            .replace(/([a-z](?=[A-Z]))/g, '$1-')
            .toLowerCase()}="{{inputVm.dateOptions.${option}}}"`;
        }
      }
      if (vm.ngDisabled !== undefined) {
        input += ' ng-disabled="inputVm.ngDisabled"';
      }
      if (vm.fioChange) {
        input += ' ng-change="inputVm.onChange();inputVm.fioChange();"';
      } else {
        input += ' ng-change="inputVm.onChange();"';
      }
      if (vm.onAddressChange) {
        input +=
          ' on-place-changed="inputVm.onAddressChange()" types="[\'address\']" mapbox-auto-complete';
      }
      if (vm.step) {
        input += ' step="{{inputVm.step}}"';
      }
      if (vm.fioCommaFormatter) {
        input += ' comma-formatter';
      }
      if (vm.fioInputBigDecimal) {
        input += ' input-big-decimal';
        input += ` fraction-digits="${vm.fractionDigits}"`;
      }
      if (vm.validators) {
        for (const i in vm.validators) {
          switch (i) {
            case 'required':
              if (vm.validators.required) {
                input += ' required="{{inputVm.validators.required}}"';
              }
              vm.isRequired = vm.validators.required;
              break;
            case 'mask':
              input += ` mask="${vm.validators.mask}" restrict="reject"`;
              break;
            case 'maskClean':
              input += ' clean="true"';
              break;
            case 'pattern':
              input += ' fio-pattern="{{inputVm.validators.pattern}}"';
              break;
            case 'min':
              input += ' fio-min="{{inputVm.validators.min}}"'; // min="{{inputVm.validators.min}}"';
              break;
            case 'max':
              input += ' fio-max="{{inputVm.validators.max}}"'; // max="{{inputVm.validators.max}}"';
              break;
            case 'minLength':
              input +=
                ' ng-minlength="{{inputVm.validators.minLength}}" fio-minlength="{{inputVm.validators.minLength}}"';
              break;
            case 'maxLength':
              input +=
                ' ng-maxlength="{{inputVm.validators.maxLength}}" fio-maxlength="{{inputVm.validators.maxLength}}"';
              break;
            case 'compareTo':
              input += ' compare-to="inputVm.validators.compareTo"';
              break;
            case 'phone':
              input += ' fio-valid-phone';
              break;
            case 'checkEmail':
              input += ' check-email';
              input += ' role="inputVm.validators.checkEmail.role"';
              break;
          }
        }
        if (vm.validators.checkEmail) {
          input +=
            ' ng-model-options="{ updateOn: \'blur\', allowInvalid: true }"';
        } else {
          input += ' ng-model-options="{ allowInvalid: true }"';
        }
      }
      input += '>';
      $compile(input)($scope, function(newElement) {
        e.append(newElement);
      });
    };
  },
  bindings: {
    name: '@',
    label: '@',
    placeholder: '@',
    type: '@',
    validators: '=',
    ngDisabled: '=',
    onAddressChange: '=',
    fioChange: '=',
    model: '=',
    blurCallback: '=',
    blurCallbackArgument: '<',
    focusCallback: '=',
    searchEnabled: '=',
    dateOptions: '=',
    componentRestrictions: '=',
    step: '@',
    fioCommaFormatter: '=',
    fioInputBigDecimal: '<',
    fractionDigits: '<',
    skipErrorDisplay: '=',
    validateFormEnter: '=',
    unit: '@',
  },
  controllerAs: 'inputVm',
};

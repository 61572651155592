import angular from 'angular';
// eslint-disable-next-line import/extensions
import digicodeController from './digicode.controller.js';

const dependencies = [];

angular
    .module('app.home.orders_home.detail.modal_refund.digicode', dependencies)
    .controller('DigicodeRefundController', digicodeController);
export default angular.module('app.home.orders_home.detail.modal_refund.digicode').name;

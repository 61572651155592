import angularUiRouter from 'angular-ui-router';
import statePromiseModule from '@innovorder/angularcore/core/state_promise/state_promise.module';
import angular from 'angular';
import addPrinterModule from './add_printer/add_printer.module';
import confirmDeletePrinterModule from './confirm_delete_printer/confirm_delete_printer.module';
import printRoutes from './print.routes';
import printController from './print.controller';

const dependencies = [angularUiRouter, addPrinterModule, confirmDeletePrinterModule, statePromiseModule];

angular
    .module('app.home.parameters.print', dependencies)
    .config(printRoutes)
    .controller('ParametersPrintController', printController);
export default angular.module('app.home.parameters.print').name;

/**
 *  Table directive
 *  ---------------------------------------------------
 *
 * This replaces all TR elements, which is necessary to make the API as non-
 * intrusive as possible. It looks for an `ngRepeat` attribute, then adds
 * sorting and pagination.
 *
 * Credits : si-table by simplicitylabs
 *           https://github.com/simplicitylabs/si-table
 *
 */
export default function($timeout) {
  'ngInject';

  function compile(tElement, tAttrs) {
    var asClause;
    var asPos = tAttrs.ngRepeat.indexOf(' as ');

    if (asPos >= 0) {
      asClause = tAttrs.ngRepeat.substr(asPos);
      tAttrs.ngRepeat = tAttrs.ngRepeat.slice(0, asPos);
    }

    tAttrs.ngRepeat += ' | orderBy:sortingParams.sortArray';
    if (tAttrs.fioLimitTo) {
      tAttrs.ngRepeat += ' | limitTo:' + tAttrs.fioLimitTo;
    }
    tAttrs.ngRepeat += ' | siPagination:paginationParams';

    // if (asClause) {
    // 	tAttrs.ngRepeat += asClause;
    // }

    tAttrs.ngRepeat += ' as currentList';

    return function link(scope, element, attrs, controller) {
      if (!controller) {
        return;
      }

      scope.$watch(
        function() {
          return scope.currentList.length;
        },
        function(value) {
          controller.currentPage = scope.currentList;
        },
      );

      scope.paginationParams = controller.paginationParams;

      // Commented by @DORIAN to avoid reset table view when changing page
      // if (attrs.ngRepeat) {
      // 	var matches = attrs.ngRepeat.match(/^\s*([\s\S]+?)\s+in\s+([\s\S]+?)(?:\s+track\s+by\s+([\s\S]+?))?\s*$/);
      // 	var collection = matches[2].split('|')[0].trim();
      //
      // 	scope.$watchCollection(collection, function() {
      // 		scope.paginationParams.offset = 0;
      // 	});
      // }
      scope.$watch('paginationParams.remote', function(remote) {
        if (remote) {
          scope.sortingParams = {
            single: false,
          };
        } else {
          scope.sortingParams = controller.sortingParams;
        }

        if (attrs.siSortable === 'single') {
          scope.sortingParams.single = true;
        }
      });
    };
  }

  return {
    restrict: 'A',
    priority: 1001,
    require: '?^fioTable',
    scope: true,
    compile: compile,
  };
}

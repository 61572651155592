export default function($fioHelper) {
  'ngInject';

  function link(scope, element, attrs, fioTableCtrl) {
    scope.$watch(
      function() {
        return scope.object.selected;
      },
      function(value) {
        if (value) {
          fioTableCtrl.addSelectedItem(scope.object);
        } else {
          fioTableCtrl.removeSelectedItem(scope.object);
        }
      },
    );

    scope.$watch(
      function() {
        return fioTableCtrl.triggerSelectAll;
      },
      function(newValue) {
        if (newValue == true) {
          scope.object.selected = true;
        } else if (newValue === false) {
          scope.object.selected = false;
        }
      },
    );

    // let's try that
    var tds = angular.element(element[0].querySelectorAll('td'));
    for (var i = 0; i < tds.length; i++) {
      angular.element(tds[i]).attr('width', $fioHelper.outerWidth(tds[i]));
    }
  }

  return {
    restrict: 'A',
    scope: {
      object: '=',
    },
    require: '^fioTable',
    link: link,
  };
}

import angular from 'angular';
// eslint-disable-next-line import/extensions
import confirmController from './confirm.controller.js';

const dependencies = [];

angular
    .module('app.home.orders_home.detail.modal_refund.confirm', dependencies)
    .controller('ConfirmRefundController', confirmController);
export default angular.module('app.home.orders_home.detail.modal_refund.confirm').name;

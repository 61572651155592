"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

require("core-js/modules/es.function.name");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = void 0;

var _consumption_modes_optionsFlagsConstant = _interopRequireDefault(require("./consumption_modes_options.flags.constant.json"));

var _consumption_modesFlagsConstant = _interopRequireDefault(require("./consumption_modes.flags.constant.json"));

var _flagsFactory = _interopRequireDefault(require("./flags.factory.js"));

var _angular = _interopRequireDefault(require("angular"));

var dependencies = [];

_angular.default.module('core.flags', dependencies).constant('CONSUMPTION_MODE_OPTIONS_FLAGS', _consumption_modes_optionsFlagsConstant.default).constant('CONSUMPTION_MODE_FLAGS', _consumption_modesFlagsConstant.default).factory('FlagsManager', _flagsFactory.default);

var _default = _angular.default.module('core.flags').name;

exports.default = _default;
import angular from 'angular';
import ordersController from './orders.controller';
import ordersComponent from './orders.component';

const dependencies = [];

angular
    .module('app.home.orders_home.orders', dependencies)
    .controller('OrdersController', ordersController)
    .component('orders', ordersComponent);
export default angular.module('app.home.orders_home.orders').name;

import moment from 'moment';
import numeral from 'numeral';

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
export default function (StuartV2, $state, ENV_VARS, statePromise, $stateParams, $rootScope) {
    'ngInject';

    const vm = this;
    vm.askingForQuote = false;
    vm.selectedTransport = null;
    vm.quote = null;
    vm.searchLaunched = false;
    vm.eta = null;
    const { stuartV2Order } = $stateParams;
    vm.formattedTicketNumber = stuartV2Order.formatTicket();

    vm.getQuote = function (transport) {
        // eslint-disable-next-line eqeqeq
        if (transport != vm.selectedTransport) {
            vm.quote = null;
            vm.error = null;
            vm.selectedTransport = transport;
            vm.askingForQuote = true;
            StuartV2.getQuote({
                orderId: stuartV2Order.orderId,
                transportType: transport,
            })
                .$promise.then(function (data) {
                    vm.quote = data;
                    if (vm.quote && vm.quote.amountVATIncluded) {
                        vm.quote.amountVATIncluded = numeral(vm.quote.amountVATIncluded).format('0.00');
                    }
                    if (vm.quote && vm.quote.amountVATExcluded) {
                        vm.quote.amountVATExcluded = numeral(vm.quote.amountVATExcluded).format('0.00');
                    }
                    if (vm.quote && vm.quote.etaInSeconds) {
                        const timeStampUnix = moment.duration(vm.quote.etaInSeconds, 's');
                        vm.eta = {
                            minutes: Math.ceil(timeStampUnix.asMinutes()),
                        };
                    }
                    if (vm.quote && vm.quote.errors) {
                        vm.error = vm.quote.errors.message;
                        vm.quote = null;
                    }
                    vm.askingForQuote = false;
                })
                .catch(function (e) {
                    vm.error = e.data.message;
                });
        }
    };

    vm.searchStuartV2 = function () {
        if (vm.quote && vm.selectedTransport && !vm.searchLaunched) {
            vm.searchLaunched = true;
            statePromise
                .go(
                    'home.orders_home.stuart_v2.search_v2',
                    {
                        orderId: stuartV2Order.orderId,
                        transport: vm.selectedTransport,
                    },
                    { location: false },
                    'stuartV2Search',
                )
                .then(function (data) {
                    stuartV2Order.stuartJob = data;
                    statePromise.resolve(data, 'stuartV2');
                });
        }
    };
}

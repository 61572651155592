"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.get = void 0;

// this is a top priory fix for migrating data for localstorage to cookie.
// this fix is temporary, if all receptions version are > 2.0.3 we can remove it.
var get = function get(localStorageService, key) {
  var savedValue = localStorageService.cookie.get(key);

  if (!savedValue) {
    var localStorageSavedValue = localStorageService.get(key);
    if (localStorageSavedValue) localStorageService.cookie.set(key, localStorageSavedValue);
    savedValue = localStorageSavedValue;
  }

  return savedValue ? savedValue : false;
};

exports.get = get;
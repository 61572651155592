import angular from 'angular';
import modalFiltersController from './modal_filters.controller';

const dependencies = [];

// eslint-disable-next-line angular/controller-name
angular
    .module('app.home.availability.modal_filters', dependencies)
    .controller('modalFiltersController', modalFiltersController);
export default angular.module('app.home.availability.modal_filters').name;

import angular from 'angular';
import digicodeModule from './digicode/digicode.module';
import reasonModule from './reason/reason.module';
import pendingModule from './pending/pending.module';
import confirmModule from './confirm/confirm.module';
import modalRefundRoutes from './modal_refund.routes';
import modalRefundController from './modal_refund.controller';

const dependencies = [digicodeModule, reasonModule, pendingModule, confirmModule];

angular
    .module('app.home.orders_home.detail.modal_refund', dependencies)
    .config(modalRefundRoutes)
    .controller('ModalRefundController', modalRefundController);
export default angular.module('app.home.orders_home.detail.modal_refund').name;

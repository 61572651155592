export default function (printService, orderService, Order, $state) {
    'ngInject';

    orderService.driveOrders.onAdded(function (order) {
        driveVm.selected = order;
        if (printService.printDriveNotificationTicket) {
            printService.printDriveNotification(order);
        }
    });
    // eslint-disable-next-line vars-on-top, no-var
    var driveVm = this;
    driveVm.orderList = orderService.driveOrders.list;
    driveVm.close = function () {
        driveVm.selected = null;
    };
    driveVm.validate = function () {
        if (driveVm.selected) {
            Order.notifyPresence({
                orderId: driveVm.selected.orderId,
                order_drive_status: 'RECEPTION_HAS_SEEN',
            }).$promise.then(function () {
                orderService.driveOrders.remove(driveVm.selected.orderId);
                driveVm.selected = null;
            });
        }
    };

    driveVm.seeDetail = function () {
        // eslint-disable-next-line no-var, block-scoped-var
        var stateToGo = 'home.orders_home.placed.detail';
        if (orderService.orders.IN_PROGRESS.list.indexOf(driveVm.selected) > -1) {
            // eslint-disable-next-line vars-on-top, no-var, no-redeclare, block-scoped-var
            var stateToGo = 'home.orders_home.in_progress.detail';
        } else if (orderService.orders.DONE.list.indexOf(driveVm.selected) > -1) {
            // eslint-disable-next-line vars-on-top, no-var, no-redeclare, block-scoped-var
            var stateToGo = 'home.orders_home.done.detail';
        }
        $state.go(
            // eslint-disable-next-line block-scoped-var
            stateToGo,
            {
                order: driveVm.selected,
            },
            {
                // eslint-disable-next-line block-scoped-var
                reload: stateToGo,
            },
        );
        driveVm.selected = null;
    };
    driveVm.hasOrders = function () {
        return Object.keys(driveVm.orderList).length;
    };
}

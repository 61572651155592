"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

require("core-js/modules/es.object.to-string");

require("core-js/modules/es.regexp.exec");

require("core-js/modules/es.regexp.to-string");

require("core-js/modules/es.string.split");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = _default;

var _keys = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/keys"));

function _default() {
  'ngInject';

  return function (flagsConstants, value) {
    var self = this;
    self.value = value;
    self.flagCount = value.toString(2).split('1').length - 1;
    self.flagsList = {};
    self.CONSTANTS = flagsConstants;

    self.enable = function (flag) {
      self.value = self.value | self.CONSTANTS[flag];
      self.flagsList = self.toObject();
      self.flagCount = self.value.toString(2).split('1').length - 1;
    };

    self.disable = function (flag) {
      self.value = (self.value & self.CONSTANTS[flag]) > 0 ? self.value ^ self.CONSTANTS[flag] : self.value;
      self.flagsList = self.toObject();
      self.flagCount = self.value.toString(2).split('1').length - 1;
    };

    self.toggle = function (flag) {
      self.hasFlag(flag) ? self.disable(flag) : self.enable(flag);
    };

    self.toObject = function () {
      var flagsObject = {};
      var flagNameList = (0, _keys.default)(self.CONSTANTS);

      for (var i = flagNameList.length - 1; i >= 0; i--) {
        flagsObject[flagNameList[i]] = self.hasFlag(flagNameList[i]);
      }

      return flagsObject;
    };

    self.hasFlag = function (flag) {
      return (self.value & self.CONSTANTS[flag]) > 0;
    };

    self.flagsList = self.toObject();
  };
}
import statePromiseModule from '@innovorder/angularcore/core/state_promise/state_promise.module';
import apiModule from '@innovorder/angularcore/core/api/api.module';

import angular from 'angular';
import modalTicketPreviewController from './modal_ticket_preview.controller';

const dependencies = [statePromiseModule, apiModule];

angular
    .module('app.home.orders_home.detail.modal_ticket_preview', dependencies)
    .controller('ModalTicketPreviewController', modalTicketPreviewController);
export default angular.module('app.home.orders_home.detail.modal_ticket_preview').name;

import angular from 'angular';
import quoteV2Module from './quote_v2/quote_v2.module';
import stuartV2Routes from './stuart_v2.routes';
import stuartV2Controller from './stuart_v2.controller';
import infosV2Module from './infos_v2/infos_v2.module';
import searchV2Module from './search_v2/search_v2.module';
import cancelModule from './cancel/cancel.module';

const dependencies = [quoteV2Module, infosV2Module, searchV2Module, cancelModule];

angular
    .module('home.orders_home.stuart_v2', dependencies)
    .config(stuartV2Routes)
    .controller('StuartV2Controller', stuartV2Controller);
export default angular.module('home.orders_home.stuart_v2').name;

export default function($element, $scope) {
  'ngInject';

  const vm = this;

  vm.$onInit = () => {
    vm.haveBackground = $scope.backgroundColor;
    vm.haveSubtitle = $scope.subtitle;
    if ($scope.openOnInit) {
      vm.toggleAccordion();
    }
  };

  vm.toggleLocked = false;

  vm.toggleAccordion = () => {
    if (!vm.toggleLocked) {
      vm.toggleLocked = true;
      const element = $element.children().children()[1];
      element.style.height = `${element.scrollHeight}px`;
      let duration = 500;
      let newHeight = 'auto';
      if ($element.children().hasClass('open')) {
        duration = 300;
        newHeight = '0px';
      }
      setTimeout(() => {
        element.style.height = newHeight;
        vm.toggleLocked = false;
      }, duration);
    }

    $element.children().toggleClass('open');
  };
}

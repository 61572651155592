"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

require("core-js/modules/es.function.name");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = void 0;

var _web_bridge = _interopRequireDefault(require("./web_bridge.service"));

var _web_bridge2 = _interopRequireDefault(require("./web_bridge.constants"));

var _angular = _interopRequireDefault(require("angular"));

var dependencies = [];

_angular.default.module('core.webBridge', dependencies).service('webBridge', _web_bridge.default).constant('BRIDGE_MESSAGE', _web_bridge2.default);

var _default = _angular.default.module('core.webBridge').name;

exports.default = _default;
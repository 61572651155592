import sidenavView from './sidenav.view.html';

export default function($templateCache, $fioHelper) {
  'ngInject';

  function link(scope, element, attrs) {
    scope.triggerRetract = function() {
      element.toggleClass('retracted');
    };
  }

  function controller($scope, $element) {
    'ngInject';

    $scope.displaySub = false;

    this.hideSubs = function() {
      if ($scope.displaySub) {
        angular.element($element).removeClass('display-sub');
        angular.forEach(
          angular.element($element).find('fio-sidenav-item'),
          function(i, k) {
            angular.element(i).removeClass('display-sub');
          },
        );
        $scope.displaySub = !$scope.displaySub;
      }
    };

    this.toggleSub = function() {
      $element.removeClass('retracted');
      $scope.displaySub = !$scope.displaySub;
      angular.element($element).toggleClass('display-sub');
    };
  }

  return {
    scope: {},
    link: link,
    controller: controller,
    restrict: 'E',
    transclude: true,
    template: $templateCache.get(sidenavView),
  };
}

export default function (
    orders,
    waitLongEnough,
    openAt,
    moment,
    printService,
    $state,
    orderService,
    $interval,
    StuartV2,
    restaurant,
) {
    'ngInject';

    const ordersHomeVm = this;

    ordersHomeVm.isLoading = true;
    ordersHomeVm.orderService = orderService;
    ordersHomeVm.orders = orders;

    ordersHomeVm.alert = function () {
        if (openAt.isAfter(moment())) {
            return 'ORDER_HOME.RESTAURANT_CLOSED';
        }
        if (!printService.canPrint()) {
            return 'ORDER_HOME.NO_PRINTER';
        }
        return false;
    };

    ordersHomeVm.goAlert = function () {
        const alertToGo = ordersHomeVm.alert();
        if (alertToGo) {
            // eslint-disable-next-line eqeqeq
            if (alertToGo == 'ORDER_HOME.RESTAURANT_CLOSED') {
                $state.go('home.parameters.close');
                // eslint-disable-next-line eqeqeq
            } else if (alertToGo == 'ORDER_HOME.NO_PRINTER') {
                $state.go('home.parameters.print');
            }
        }
    };

    ordersHomeVm.getTotalPlaced = function () {
        return ordersHomeVm.orders.VALIDATED.count + ordersHomeVm.orders.PLACED.count;
    };

    ordersHomeVm.getTotalInProgress = function () {
        return ordersHomeVm.orders.IN_PROGRESS.count;
    };

    ordersHomeVm.getTotalDone = function () {
        return ordersHomeVm.orders.DONE.count;
    };

    waitLongEnough.promise.then(function () {
        ordersHomeVm.isLoading = false;
    });
    ordersHomeVm.isStuartV2OrderOnGoing = false;
    ordersHomeVm.actualizeIsStuartV2OrderOnGoing = function () {
        let shouldDoCallsToApi = false;
        // eslint-disable-next-line no-restricted-syntax, vars-on-top, no-var, block-scoped-var
        for (var order of orders.VALIDATED.list) {
            // eslint-disable-next-line block-scoped-var
            if (order.deliveryStatus && order.deliveryStatus !== 2 && order.deliveryType === 'STUART_V2') {
                shouldDoCallsToApi = true;
            }
        }
        // eslint-disable-next-line no-restricted-syntax, vars-on-top, no-var, no-redeclare, block-scoped-var
        for (var order of orders.IN_PROGRESS.list) {
            // eslint-disable-next-line block-scoped-var
            if (order.deliveryStatus && order.deliveryStatus !== 2 && order.deliveryType === 'STUART_V2') {
                shouldDoCallsToApi = true;
            }
        }
        ordersHomeVm.isStuartV2OrderOnGoing = shouldDoCallsToApi;
    };
    $interval(ordersHomeVm.actualizeIsStuartV2OrderOnGoing, 20000);
    $interval(function () {
        if (ordersHomeVm.isStuartV2OrderOnGoing) {
            StuartV2.getJobList({
                restaurantId: restaurant.restaurantId,
            }).$promise.then(function () {
                orders.PLACED.forceUpdate();
                orders.VALIDATED.forceUpdate();
                orders.IN_PROGRESS.forceUpdate();
                orders.DONE.forceUpdate();
            });
        }
    }, 20000);

    ordersHomeVm.isOrderEmpty = function () {
        if ($state.includes('home.orders_home.placed')) {
            // eslint-disable-next-line eqeqeq
            return ordersHomeVm.orders.VALIDATED.count + ordersHomeVm.orders.PLACED.count == 0;
        }
        if ($state.includes('home.orders_home.in_progress')) {
            // eslint-disable-next-line eqeqeq
            return ordersHomeVm.orders.IN_PROGRESS.count == 0;
        }
        if ($state.includes('home.orders_home.done')) {
            // eslint-disable-next-line eqeqeq
            return ordersHomeVm.orders.DONE.count == 0;
        }
        return true;
    };
}

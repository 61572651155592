import angular from 'angular';
import closeTabsController from './close_tabs.controller';
import closeTabsComponent from './close_tabs.component';

const dependencies = [];

angular
    .module('app.home.parameters.close.close_tabs', dependencies)
    .controller('CloseTabsController', closeTabsController)
    .component('closeTabs', closeTabsComponent);
export default angular.module('app.home.parameters.close.close_tabs').name;

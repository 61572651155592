export default function (statePromise, availabilityService) {
    'ngInject';

    const vm = this;

    vm.consumptionModes = availabilityService;
    vm.consumptionModes.filters = availabilityService.selectedFilters;

    vm.showOnlyDisabledProducts = availabilityService.showOnlyDisabledProducts;

    const filtersOnInit = angular.copy(availabilityService.selectedFilters);

    vm.close = function () {
        availabilityService.selectedFilters = filtersOnInit;
        availabilityService.showOnlyDisabledProducts = false;

        statePromise.reject('dataFromModal');
    };

    vm.confirm = function () {
        vm.consumptionModes.counterFilter = Object.keys(vm.consumptionModes.selectedFilters).filter(
            (key) => vm.consumptionModes.selectedFilters[key],
        ).length;

        vm.consumptionModes.refreshTotalFilterCount();

        statePromise.resolve(vm.consumptionModes, 'dataFromModal');
    };

    vm.toggle = function (mode) {
        vm.consumptionModes.filters[mode] = !vm.consumptionModes.filters[mode];
    };

    vm.toggleDisabledProductsOnly = function () {
        vm.showOnlyDisabledProducts = !vm.showOnlyDisabledProducts;
        availabilityService.showOnlyDisabledProducts = vm.showOnlyDisabledProducts;
    };
}

"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

require("core-js/modules/es.regexp.exec");

require("core-js/modules/es.string.split");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = _ngInjectExport;
exports.formatTicketNumber = void 0;

var _parseInt2 = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/parse-int"));

var formatTicketNumber = function formatTicketNumber(ticketNumber) {
  if (ticketNumber) {
    var number = ticketNumber.split('_').pop();

    if (isNaN((0, _parseInt2.default)(number))) {
      return 'N° ' + number;
    }

    var str = '' + (0, _parseInt2.default)(number);
    var pad = 'N° #000';
    var lengthToRemove = str.length;

    if (lengthToRemove > 3) {
      lengthToRemove = 3;
    }

    return pad.substring(0, pad.length - lengthToRemove) + str;
  }

  return 'N° #000';
};

exports.formatTicketNumber = formatTicketNumber;
_ngInjectExport.$inject = ["$resource", "$log", "RESOURCES_VARS", "ENV_VARS"];

function _ngInjectExport($resource, $log, RESOURCES_VARS, ENV_VARS) {
  'ngInject'; // resource rules
  // =============================================================================

  function parseData(response) {
    try {
      var body = angular.fromJson(response);

      if (body && body.data) {
        var data = body.data;
        data.message = body.message;
        data.code = body.code;
        return data;
      }

      return body;
    } catch (e) {
      $log.error(e);
      return e;
    }
  }

  var order = $resource(ENV_VARS.API_URL + '/orders/:orderId/:action', {
    orderId: '@orderId',
    action: '@action'
  }, {
    getAll: {
      method: RESOURCES_VARS.API_METHODS.GET,
      isArray: true,
      transformResponse: parseData
    },
    countAll: {
      params: {
        action: 'count'
      },
      method: RESOURCES_VARS.API_METHODS.GET,
      transformResponse: parseData
    },
    getOne: {
      method: RESOURCES_VARS.API_METHODS.GET,
      transformResponse: parseData
    },
    getOneV1: {
      url: "".concat(ENV_VARS.API_URL, "/v1/orders/:orderId/:action"),
      method: RESOURCES_VARS.API_METHODS.GET,
      transformResponse: parseData
    },
    publicGetOne: {
      url: ENV_VARS.API_URL + '/orders/public/:orderId',
      method: RESOURCES_VARS.API_METHODS.GET,
      transformResponse: parseData
    },
    getStatusGroup: {
      url: ENV_VARS.API_URL + '/orders/statusgroups/:statusGroup',
      method: RESOURCES_VARS.API_METHODS.GET,
      isArray: true,
      transformResponse: parseData,
      params: {
        statusGroup: '@statusGroup'
      }
    },
    countStatusGroup: {
      url: ENV_VARS.API_URL + '/orders/statusgroups/count',
      method: RESOURCES_VARS.API_METHODS.GET,
      transformResponse: parseData
    },
    refresh: {
      method: RESOURCES_VARS.API_METHODS.GET,
      transformResponse: parseData
    },
    verify: {
      params: {
        action: 'verify'
      },
      method: RESOURCES_VARS.API_METHODS.POST,
      transformResponse: parseData
    },
    verifyCart: {
      params: {
        action: 'verify_cart'
      },
      method: RESOURCES_VARS.API_METHODS.POST,
      transformResponse: parseData
    },
    reOrder: {
      params: {
        action: 'reorder'
      },
      method: RESOURCES_VARS.API_METHODS.GET,
      transformResponse: parseData
    },
    receipt: {
      params: {
        action: 'receipt'
      },
      method: RESOURCES_VARS.API_METHODS.GET,
      transformResponse: parseData
    },
    updateStatusGroup: {
      params: {
        action: 'status'
      },
      method: RESOURCES_VARS.API_METHODS.PUT,
      transformResponse: parseData
    },
    cancel: {
      params: {
        action: 'cancel'
      },
      method: RESOURCES_VARS.API_METHODS.PUT,
      transformResponse: parseData
    },
    refund: {
      params: {
        action: 'refund'
      },
      method: RESOURCES_VARS.API_METHODS.PUT,
      transformResponse: parseData
    },
    create: {
      method: RESOURCES_VARS.API_METHODS.POST,
      transformResponse: parseData
    },
    createV2: {
      url: "".concat(ENV_VARS.API_URL, "/orders/v2"),
      method: RESOURCES_VARS.API_METHODS.POST,
      transformResponse: parseData
    },
    adyenConfirm: {
      url: "".concat(ENV_VARS.API_URL, "/orders/confirm/adyen"),
      method: RESOURCES_VARS.API_METHODS.POST,
      transformResponse: parseData
    },
    stripeConfirm: {
      url: "".concat(ENV_VARS.API_URL, "/orders/confirm"),
      method: RESOURCES_VARS.API_METHODS.POST,
      transformResponse: parseData
    },
    notifyPresence: {
      method: RESOURCES_VARS.API_METHODS.PUT,
      params: {
        action: 'drive_status'
      },
      transformResponse: parseData
    },
    publicNotifyPresence: {
      url: ENV_VARS.API_URL + '/orders/public/:orderId/drive_status',
      method: RESOURCES_VARS.API_METHODS.PUT,
      transformResponse: parseData
    },
    createKiosk: {
      method: RESOURCES_VARS.API_METHODS.POST,
      transformResponse: parseData,
      params: {
        action: 'kiosk'
      }
    },
    countBySchedules: {
      params: {
        action: 'count_by_schedules'
      },
      isArray: true,
      method: RESOURCES_VARS.API_METHODS.GET,
      transformResponse: parseData
    }
  });

  order.prototype.formatTicket = function () {
    return formatTicketNumber(this.ticketNumber);
  };

  return order;
}
import tabView from './tab.view.html';
export default function($templateCache) {
  'ngInject';

  function link(scope, element, attrs, fioTabsCtrl) {
    fioTabsCtrl.insertTab(attrs.label, element.html());
  }

  return {
    link: link,
    restrict: 'E',
    require: '^fioTabs',
    transclude: true,
    scope: true,
    template: $templateCache.get(tabView),
  };
}

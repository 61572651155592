"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

require("core-js/modules/es.function.name");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = void 0;

var _locale = _interopRequireDefault(require("./locale.config"));

var _localeConstant = _interopRequireDefault(require("./locale.constant.json"));

var _locale_interceptor = _interopRequireDefault(require("./locale_interceptor.provider"));

var _angular = _interopRequireDefault(require("angular"));

var dependencies = [];

_angular.default.module('core.locale', dependencies).config(_locale.default).constant('IO_LOCAL', _localeConstant.default).provider('localeInterceptor', _locale_interceptor.default);

var _default = _angular.default.module('core.locale').name;

exports.default = _default;
export default function (cancellation, $timeout, $state, $q, isCancel) {
    'ngInject';

    const pendingVm = this;
    pendingVm.waitLongEnough = waitLongEnough();

    cancellation.$promise
        // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
        .then(function (order) {
            pendingVm.waitLongEnough.then(function () {
                $state.go(
                    '^.confirm',
                    {
                        isCancel,
                    },
                    {
                        reload: false,
                        location: false,
                    },
                );
            });
        })
        .catch(function (data) {
            pendingVm.waitLongEnough.then(function () {
                let errorMessage = '';
                if (data && data.data && data.data.message) {
                    errorMessage = data.data.message;
                }
                $state.go(
                    '^.digicode',
                    {
                        message: errorMessage,
                    },
                    {
                        reload: false,
                        location: false,
                    },
                );
            });
        });

    function waitLongEnough() {
        const deferred = $q.defer();

        $timeout(function () {
            deferred.resolve();
        }, 1000); // 1s waiting

        return deferred.promise;
    }
}

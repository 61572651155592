import selectOptionView from 'js/directives/dashboard/selectable_list/selectable_option.view.html';

export default function ($templateCache, $timeout) {
  'ngInject';

  function link(scope, element, attrs, listCtrl) {
    
    function selectOption(mustUpdateParentCtrl = true, labelToAdd) {
      
      if (mustUpdateParentCtrl) {
        listCtrl.addOption(scope.value);
      }

      listCtrl.addLabel(labelToAdd);
      scope.isSelected = true;
      angular.element(element).addClass('selected');
    }

    function unselectOption(mustUpdateParentCtrl = true, labelToRemove) {
      
      if (mustUpdateParentCtrl) {
        listCtrl.removeOption(scope.value);
      }

      listCtrl.removeLabel(labelToRemove);
      scope.isSelected = false;
      angular.element(element).removeClass('selected');
    }

    function deferredInitialSelection() {
      const correctLabel = element[0].innerText.trim();
      return selectOption(false, correctLabel);
    }

    scope.$watch(
      function () {
        return listCtrl.scope.ngModel.$viewValue;
      },
      function (newSelectedOptions = []) {
        
        const isOptionSelected = newSelectedOptions.includes(scope.value);
        const optionShouldBeSelected = isOptionSelected && !scope.isSelected;
        const optionShouldBeRemoved = !isOptionSelected && scope.isSelected;
        const optionLabel = element[0].innerText.trim();

        const htmlNotCompiledYet = optionLabel.includes('{{');
        const requiredToDeferInitialSelection =
          htmlNotCompiledYet && optionShouldBeSelected; 
        
        if (requiredToDeferInitialSelection) {
          return $timeout(deferredInitialSelection, 0);
        }

        if (optionShouldBeSelected) {
          return selectOption(false, optionLabel);
        }

        if (optionShouldBeRemoved) {
          return unselectOption(false, optionLabel);
        }

        return null;
      },
    );

    angular.element(element).on('click', function () {
      const optionLabel = element[0].innerText.trim();
      const toggleOptionFn = !scope.isSelected ? selectOption : unselectOption;
      return toggleOptionFn(true, optionLabel);
    });
  }

  return {
    link,
    restrict: 'E',
    require: '^fioSelectableList',
    transclude: true,
    scope: {
      value: '=',
      name: '=',
    },
    template: $templateCache.get(selectOptionView),
  };
}

import quoteView from 'app/components/stuart/quote/quote.view.html';
import errorView from 'app/components/stuart/error/error.view.html';
import searchView from 'app/components/stuart/search/search.view.html';
import infosView from 'app/components/stuart/infos/infos.view.html';

export default function ($stateProvider, $urlRouterProvider, $locationProvider) {
    'ngInject';

    $locationProvider.html5Mode(true);

    $stateProvider
        .state('home.stuart.quote', states.quote())
        .state('home.stuart.error', states.error())
        .state('home.stuart.search', states.search())
        .state('home.stuart.infos', states.infos());
}

// eslint-disable-next-line vars-on-top, no-var
var states = {
    quote() {
        return {
            templateProvider: [
                '$templateCache',
                function ($templateCache) {
                    return $templateCache.get(quoteView);
                },
            ],
            controller: 'StuartQuoteController',
            controllerAs: 'stuartQuoteVm',
        };
    },
    error() {
        return {
            params: {
                error: null,
                transport: null,
            },
            templateProvider: [
                '$templateCache',
                function ($templateCache) {
                    return $templateCache.get(errorView);
                },
            ],
            controller: 'StuartErrorController',
            controllerAs: 'stuartErrorVm',
            resolve: {
                error: [
                    '$stateParams',
                    '$q',
                    function ($stateParams, $q) {
                        if ($stateParams.error) {
                            return $q.resolve($stateParams.error);
                        }
                        return $q.reject();
                    },
                ],
            },
        };
    },
    search() {
        return {
            params: {
                quote: null,
                transport: null,
            },
            templateProvider: [
                '$templateCache',
                function ($templateCache) {
                    return $templateCache.get(searchView);
                },
            ],
            controller: 'StuartSearchController',
            controllerAs: 'stuartSearchVm',
            resolve: {
                job: [
                    '$stateParams',
                    'Stuart',
                    'order',
                    '$q',
                    '$state',
                    function ($stateParams, Stuart, order, $q, $state) {
                        const deferred = $q.defer();
                        Stuart.createJob({ jobQuoteId: $stateParams.quote.id, orderId: order.orderId })
                            .$promise.then(function (data) {
                                deferred.resolve(data);
                            })
                            .catch(function (e) {
                                $state.go('home.stuart.error', { error: e, transport: $stateParams.transport });
                            });
                        return deferred.promise;
                    },
                ],
            },
        };
    },
    infos() {
        return {
            params: {
                job: null,
            },
            templateProvider: [
                '$templateCache',
                function ($templateCache) {
                    return $templateCache.get(infosView);
                },
            ],
            controller: 'StuartInfosController',
            controllerAs: 'stuartInfosVm',
            resolve: {
                job: [
                    '$stateParams',
                    function ($stateParams) {
                        return $stateParams.job;
                    },
                ],
            },
        };
    },
};

"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

require("core-js/modules/es.function.name");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = void 0;

var _angularLocalStorage = _interopRequireDefault(require("angular-local-storage"));

var _print = _interopRequireDefault(require("./print.service"));

require("iso-currency");

var _web_bridge = _interopRequireDefault(require("./../web_bridge/web_bridge.module"));

var _moneyline = _interopRequireDefault(require("./../moneyline/moneyline.module"));

var _simpleLog = _interopRequireDefault(require("./../simpleLog/simpleLog.module"));

var _angular = _interopRequireDefault(require("angular"));

var dependencies = [_angularLocalStorage.default, _web_bridge.default, _moneyline.default, 'isoCurrency', _simpleLog.default];

_angular.default.module('core.print', dependencies).service('printService', _print.default);

var _default = _angular.default.module('core.print').name;

exports.default = _default;
"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

require("core-js/modules/es.function.name");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = void 0;

var _angularResource = _interopRequireDefault(require("angular-resource"));

var _log = _interopRequireDefault(require("./../../log/log.module"));

var _area = _interopRequireDefault(require("./area.factory"));

var _angular = _interopRequireDefault(require("angular"));

var modules = [_angularResource.default, _log.default];

_angular.default.module('core.resources.area', modules).config(['$httpProvider', function ($httpProvider) {
  $httpProvider.defaults.useXDomain = true;
}]).factory('Area', _area.default);

var _default = _angular.default.module('core.resources.area').name;

exports.default = _default;
import { v4 as uuidv4 } from 'uuid';

export default function ($http, $compile, ENV_VARS) {
  'ngInject';

  function debounce(fn, delay) {
    var timer = null;
    return function () {
      var self = this,
        args = arguments;
      clearTimeout(timer);
      timer = setTimeout(function () {
        fn.apply(self, args);
      }, delay);
    };
  }

  function link(scope, element, attrs) {
    scope.suggestions = [];
    let sessionToken = uuidv4();
    const apiUrl = ENV_VARS.API_URL;

    angular.extend(scope, {
      displayProperty: 'place_name',
    });

    const updateList = (event) => {
      const { value } = event.target;
      if (!value) {
        scope.suggestions = [];
        return;
      }

      $http
        .get(`${apiUrl}/location/autocomplete?searchText=${value}&sessiontoken=${sessionToken}`)
        .success(({ data: { suggestions } }) => {
          scope.suggestions = suggestions;
        })
        .error(() => {
          scope.suggestions = [];
        });
    };

    const chooseLocation = (place) => {
      scope.selectedLocation = place;
      scope.selectedLoc = place.name;
      scope.searchText = '';
      scope.suggestions = [];
      scope.inputVm.onAddressChange(place);
      sessionToken = uuidv4();
    };

    scope.useSelectedLocation = function (index) {
      const suggestion = scope.suggestions[index];

      if (suggestion.provider === 'GOOGLE_MAPS') {
        $http
          .get(
            `${apiUrl}/location/place_details?placeId=${suggestion.providerId}&sessiontoken=${sessionToken}`,
          )
          .success(({ data: { place } }) => {
            chooseLocation(place);
          });
      } else {
        chooseLocation(suggestion);
      }
    };

    const listHtml = `
    <fio-list id="suggestions-list">
      <fio-list-item ng-repeat="suggestion in suggestions" ng-click="useSelectedLocation($index)"><span>{{suggestion['name'] ? suggestion['name'] : emptyPropertyText}}</span></fio-list-item>
    </fio-list>
    `;

    element.after($compile(listHtml)(scope));

    var updateListWithDebounce = debounce(updateList, 300);
    element[0].oninput = updateListWithDebounce;
  }

  return {
    restrict: 'AE',
    scope: false,
    link,
  };
}

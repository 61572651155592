import routerTabsTemplateView from './router_tabs_template.view.html';
export default function($templateCache, $fioHelper) {
  'ngInject';

  function link(scope, element, attrs) {}

  function controller($scope, $element) {
    'ngInject';

  }

  return {
    link: link,
    controller: controller,
    restrict: 'E',
    scope: {},
    transclude: true,
    template: $templateCache.get(routerTabsTemplateView),
  };
}

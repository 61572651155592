"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.LOCALE_STRING_MAP = void 0;
var LOCALE_STRING_MAP = {
  en: {
    TITLE_LEFT_LIST_PRODUCT: 'Recapitulatif',
    TITLE_RIGHT_LIST_PRODUCT: 'prix TTC',
    DELIVERY_FEE: 'Delivery fee',
    SERVICE_FEE: 'Service fee',
    ENTRANCE_FEE: 'Entrance fee',
    GRANT: 'Grant',
    ONLINE_PAYMENT_DISCOUNT: 'Online payment discount',
    TAKE_AWAY_REDUCTION: 'Take away discount',
    ROYALTY_REDUCTION: 'Royalty',
    TOTAL_TTC: 'Total TTC',
    VATS: 'Total VAT',
    PAID: 'Paid',
    UNPAID: 'Unpaid',
    PROMOCODE: 'Promocode',
    SUMMARY: 'Summary',
    MODE_OF_CONSUMPTION: {
      '1': 'Delivery',
      '2': 'Take away',
      '3': 'Eat In'
    },
    CHANNELS: {
      '1': 'Kiosk',
      '2': 'Web'
    },
    ORDER_ERROR: {
      TITLE: '/!\\ Warning /!\\',
      LINE_1: 'Due to an incident,',
      LINE_2: "we couldn't proceed the order.",
      LINE_3: 'Come to the desk, we will send your order to production'
    },
    APPARTMENT: 'Appartment',
    DIGICODE: 'Digicode',
    WARNING_MESSAGE: 'This order cannot be printed with this format.',
    STRIPE: 'Credit Card',
    PAYGREEN: 'Luncheon Vouchers Card'
  },
  fr: {
    TITLE_LEFT_LIST_PRODUCT: 'Récapitulatif',
    TITLE_RIGHT_LIST_PRODUCT: 'prix TTC',
    DELIVERY_FEE: 'Frais de livraison',
    SERVICE_FEE: 'Frais de service',
    ENTRANCE_FEE: 'Admission',
    GRANT: 'Subvention',
    TAKE_AWAY_REDUCTION: 'Réduction à emporter',
    ONLINE_PAYMENT_DISCOUNT: 'Réduction paiement en ligne',
    ROYALTY_REDUCTION: 'Cagnotte',
    TOTAL_TTC: 'Total TTC',
    VATS: 'Total TVA',
    PAID: 'Payée',
    UNPAID: 'Non payée',
    PROMOCODE: 'Code promo',
    SUMMARY: 'Récapitulatif',
    MODE_OF_CONSUMPTION: {
      '1': 'En Livraison',
      '2': 'A Emporter',
      '3': 'Sur Place'
    },
    CHANNELS: {
      '1': 'Borne',
      '2': 'Web'
    },
    ORDER_ERROR: {
      TITLE: '/!\\ Attention /!\\',
      LINE_1: 'Suite à un incident,',
      LINE_2: "la commande n'a pas été reçue.",
      LINE_3: 'Rapprochez-vous du comptoir pour que la commande soit envoyée en production.'
    },
    APPARTMENT: 'Appartement',
    DIGICODE: 'Digicode',
    WARNING_MESSAGE: 'Cette commande ne peut pas être imprimée sous ce format.',
    STRIPE: 'Carte Bancaire',
    PAYGREEN: 'Carte Titres Restaurant'
  }
};
exports.LOCALE_STRING_MAP = LOCALE_STRING_MAP;
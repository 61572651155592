import tableView from './_table.view.html';
/**
 *  Table directive
 *  ---------------------------------------------------
 *
 * Credits : si-table by simplicitylabs
 *           https://github.com/simplicitylabs/si-table
 *
 */
export default function($templateCache) {
  'ngInject';

  function link(scope, element, attrs) {}

  function controller($scope) {
    'ngInject';
    var self = this;
    self.allPageSelected = false;
    self.triggerSelectAll;

    $scope.selectedItems = [];

    self.paginationParams = {
      offset: 0,
      limit: Infinity,
      remote: false,
      firstText: 'First',
      lastText: 'Last',
      previousText: 'Previous',
      nextText: 'Next',
    };

    self.sortingParams = {
      sortArray: [],
      single: true,
    };

    // Copy sortArray to scope binding
    $scope.$watch(
      function() {
        return self.sortingParams.sortArray;
      },
      function(sortArray) {
        if (!angular.isArray($scope.sorting)) {
          $scope.sorting = [];
        }
        $scope.sorting.splice(0, $scope.sorting.length);
        for (var i = 0; i < sortArray.length; i++) {
          $scope.sorting.push(sortArray[i]);
        }
        if ($scope.sortModel) {
          $scope.sortModel.model = self.sortingParams.sortModel;
        }
      },
      true,
    );

    self.addSelectedItem = function(item) {
      //$scope.$apply(function() {

      if ($scope.selectedItems == undefined) {
        $scope.selectedItems = [];
      }

      if ($scope.selectedItems.indexOf(item) < 0) {
        $scope.selectedItems.push(item);
      }
      if (
        self.currentPage != undefined &&
        self.currentPage.length == $scope.selectedItems.length
      ) {
        self.triggerSelectAll = true;
      }
      //});
    };

    self.removeSelectedItem = function(item) {
      //$scope.$apply(function() {
      $scope.selectedItems.splice($scope.selectedItems.indexOf(item), 1);
      if ($scope.selectedItems.length == 0) {
        self.triggerSelectAll = false;
      }
      //});
    };

    self.selectAll = function() {
      if (!$scope.$$phase) {
        $scope.$apply(function() {
          self.triggerSelectAll = true;
        });
      }
    };

    self.unselectAll = function() {
      if (!$scope.$$phase) {
        $scope.$apply(function() {
          self.triggerSelectAll = false;
        });
      }
    };

    self.getSelectedItemsCount = function() {
      return $scope.selectedItems.length;
    };
    $scope.getSelectedItemsCount = self.getSelectedItemsCount;

    self.getNumberItemsInPage = function() {
      if (self.currentPage != undefined) {
        return self.currentPage.length;
      }
    };

    $scope.$watch(
      function() {
        return $scope.selectedItems.length;
      },
      function(value) {
        $scope.selectedCount = value;

        if (self.getNumberItemsInPage() == value && !self.allPageSelected) {
          self.allPageSelected = true;
        }
      },
    );

    /* bulk actions */
    $scope.applyAction = function(fn, promise, callback) {
      if (promise != undefined) {
        promise().then(function(data) {
          // if promise success
          $scope.selectedItems.forEach(function(object, index) {
            var promise = fn(object, index, data);
            if (
              promise &&
              index === $scope.selectedItems.length - 1 &&
              callback &&
              typeof callback === 'function'
            ) {
              promise.then(callback);
            }
          });
        });
      } else {
        $scope.selectedItems.forEach(function(object, index) {
          var promise = fn(object, index);
          if (
            promise &&
            index === $scope.selectedItems.length - 1 &&
            callback &&
            typeof callback === 'function'
          ) {
            promise.then(callback);
          }
        });
      }
    };
  }

  return {
    link: link,
    restrict: 'A',
    scope: {
      tableActions: '=?',
      sorting: '=?',
      sortModel: '=?',
      selectedItems: '=?',
    },
    controller: controller,
    transclude: true,
    template: $templateCache.get(tableView),
  };
}

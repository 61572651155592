import checkboxView from './checkbox.view.html';
export default function($templateCache) {
  'ngInject';

  var true_value = true;
  var false_value = false;

  function link(scope, element, attrs, ngModel) {
    angular.element(element[0]).on('click', function() {
      angular.element(element[0]).toggleClass('checked');
      scope.toggleCheckox();
    });

    scope.toggleCheckox = function() {
      scope.$apply(function() {
        if (Number.isInteger(ngModel.$viewValue)) {
          ngModel.$setViewValue(true_value);
        } else {
          switch (ngModel.$viewValue) {
            case true_value:
              ngModel.$setViewValue(false_value);
              break;
            case false_value:
              ngModel.$setViewValue(true_value);
              break;
            default:
              ngModel.$setViewValue(true_value);
              break;
          }
        }
      });
    };

    scope.$watch(
      function() {
        return ngModel.$viewValue;
      },
      function(newVal, oldVal) {
        if (Number.isInteger(newVal) && newVal > 0) {
          angular.element(element[0]).addClass('multiple');
        } else {
          angular.element(element[0]).removeClass('multiple');
          if (newVal == true_value) {
            angular.element(element[0]).addClass('checked');
          } else {
            angular.element(element[0]).removeClass('checked');
          }
        }
      },
    );

    scope.isChecked = function() {
      return ngModel.$viewValue == true_value;
    };
  }

  return {
    link: link,
    restrict: 'E',
    transclude: true,
    require: 'ngModel',
    scope: {},
    template: $templateCache.get(checkboxView),
  };
}

import menuView from 'app/components/availability/menu/menu.view.html';
import productQuantityView from 'app/components/availability/product_quantity/product_quantity.view.html';
import modalFiltersView from 'app/components/availability/modal_filters/modal_filters.view.html';
import modalReloadKiosksView from 'app/components/availability/modal_reload_kiosks/modal_reload_kiosks.view.html';
import modalSkuWarningView from 'app/components/availability/modal_sku_warning/modal_sku_warning.view.html';
import { EXTERNAL_CHANNELS_SETTINGS } from '../../constants/external_channels_settings';

export default function ($stateProvider, $urlRouterProvider, $locationProvider) {
    'ngInject';

    $locationProvider.html5Mode(true);
    $stateProvider
        .state('home.availability.menu', states.menu())
        .state('home.availability.product_quantity', states.quantityModal())
        .state('home.availability.modal_filters', states.modalFilters())
        .state('home.availability.modal_reload_kiosks', states.modalReloadKiosks())
        .state('home.availability.modal_sku_warning', states.modalSkuWarning());
}

// eslint-disable-next-line vars-on-top, no-var
var states = {
    menu() {
        return {
            url: '/menu/:menuId',
            templateUrl: menuView,
            controller: 'MenuController',
            controllerAs: 'menuVm',
            sticky: true,
            params: {
                menus: null,
                menuId: null,
            },
            resolve: {
                menu(menus, $stateParams, $q) {
                    'ngInject';

                    if ($stateParams.menuId) {
                        // eslint-disable-next-line vars-on-top, no-var
                        for (var i = 0; i < menus.length; i++) {
                            // eslint-disable-next-line eqeqeq
                            if (menus[i].menuId == $stateParams.menuId) {
                                // eslint-disable-next-line no-loop-func
                                menus[i].categories = menus[i].categories.sort((a, b) => {
                                    let aName;
                                    let bName;
                                    // eslint-disable-next-line prefer-const
                                    aName = a.parentCategoryId
                                        ? `${getParentName(a.parentCategoryId, menus[i].categories)} ${a.name}`
                                        : a.name;
                                    // eslint-disable-next-line prefer-const
                                    bName = b.parentCategoryId
                                        ? `${getParentName(b.parentCategoryId, menus[i].categories)} ${b.name}`
                                        : b.name;
                                    return aName > bName ? 1 : -1;
                                });
                                return $q.resolve(menus[i]);
                            }
                        }
                        return $q.reject();
                    }
                    const error = new Error('$stateParams.menuId not in the list');
                    error.redirect = 'home.availability';
                    return $q.reject(error);
                },
                channels(menu, shouldUseUberEatsAvailability, shouldUseDeliverooAvailability, $q, localStorageService) {
                    'ngInject';

                    if (localStorageService.cookie.get(EXTERNAL_CHANNELS_SETTINGS) === null) {
                        localStorageService.cookie.set(EXTERNAL_CHANNELS_SETTINGS, { ubereats: true, deliveroo: true });
                    }

                    const channels = [];
                    for (let i = 0; i < menu.channels.length; i++) {
                        channels.push({
                            channelAvailabilityName: channelAvailabilityName[menu.channels[i].channelId],
                            channelId: menu.channels[i].channelId,
                            name: menu.channels[i].name,
                        });
                    }
                    if (
                        shouldUseUberEatsAvailability &&
                        localStorageService.cookie.get(EXTERNAL_CHANNELS_SETTINGS).ubereats
                    ) {
                        channels.push({
                            channelAvailabilityName: 'uberEatsAvailability',
                            channelId: 9,
                            name: 'ubereats',
                        });
                    }
                    if (
                        shouldUseDeliverooAvailability &&
                        localStorageService.cookie.get(EXTERNAL_CHANNELS_SETTINGS).deliveroo
                    ) {
                        channels.push({
                            channelAvailabilityName: 'deliverooAvailability',
                            channelId: 10,
                            name: 'deliveroo',
                        });
                    }

                    return $q.resolve(channels);
                },
            },
        };
    },
    quantityModal() {
        return {
            params: {
                product: null,
            },
            views: {
                'modal@': {
                    templateUrl: productQuantityView,
                    controller: 'ProductQuantityController',
                    controllerAs: 'productQuantityVm',
                },
            },
            resolve: {
                product($stateParams) {
                    'ngInject';

                    return $stateParams.product;
                },
            },
        };
    },
    modalFilters() {
        return {
            views: {
                'modal@': {
                    templateUrl: modalFiltersView,
                    controller: 'modalFiltersController',
                    controllerAs: 'modalFiltersVm',
                },
            },
        };
    },
    modalReloadKiosks() {
        return {
            views: {
                'modal@': {
                    templateUrl: modalReloadKiosksView,
                    controller: 'modalReloadKiosksController',
                    controllerAs: 'modalReloadKiosksVm',
                },
            },
        };
    },
    modalSkuWarning() {
        return {
            params: {
                skuWarningData: null,
                shouldUseUberEatsAvailability: false,
                shouldUseDeliverooAvailability: false,
            },
            views: {
                'modal@': {
                    templateUrl: modalSkuWarningView,
                    controller: 'modalSkuWarningController',
                    controllerAs: 'modalSkuWarningVm',
                },
            },
        };
    },
};

// eslint-disable-next-line vars-on-top, no-var, consistent-return
var getParentName = function (parentCategoryId, categories) {
    for (let i = categories.length; i >= 0; i--) {
        // eslint-disable-next-line eqeqeq
        if (categories[i] != undefined && categories[i].categoryId === parentCategoryId) {
            return categories[i].name;
        }
    }
};

// eslint-disable-next-line vars-on-top, no-var
var channelAvailabilityName = [null, 'kioskAvailability', 'webAvailability'];

export default function (statePromise) {
    'ngInject';

    const vm = this;
    vm.close = function () {
        statePromise.reject('confirmDeletePrinter');
    };
    vm.confirm = function () {
        statePromise.resolve({}, 'confirmDeletePrinter');
    };
}

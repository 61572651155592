export default function () {
    'ngInject';

    const availabilityService = this;

    availabilityService.selectedFilters = {
        FLAG_MODE_DRIVE: false,
        FLAG_MODE_SIT_IN: false,
        FLAG_MODE_TAKE_AWAY: false,
        FLAG_MODE_DELIVERY: false,
    };

    availabilityService.showOnlyDisabledProducts = false;

    availabilityService.counterFilter = 0;
    availabilityService.totalFilterCount = 0;

    availabilityService.searchFilter = '';

    availabilityService.refreshTotalFilterCount = function () {
        availabilityService.totalFilterCount =
            availabilityService.counterFilter + (availabilityService.showOnlyDisabledProducts ? 1 : 0);
    };
}

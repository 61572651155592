export default function ($state, statePromise, ENV_VARS) {
    'ngInject';

    const vm = this;

    vm.sitIn = ENV_VARS.CONSOMMATION_MODES.SIT_IN;
    vm.takeAway = ENV_VARS.CONSOMMATION_MODES.TAKE_AWAY;
    vm.delivery = ENV_VARS.CONSOMMATION_MODES.DELIVERY;
    vm.mode = null;

    vm.$onInit = function () {
        vm.previousStatusGroup = vm.orders.previous;
        switch (vm.status) {
            case 'placed':
                vm.importantOrders = vm.orders[ENV_VARS.ORDERS_SERVICES.PLACED.NAME];
                vm.orders = vm.orders[ENV_VARS.ORDERS_SERVICES.VALIDATED.NAME];

                break;
            case 'in_progress':
                vm.importantOrders = null;
                vm.orders = vm.orders[ENV_VARS.ORDERS_SERVICES.IN_PROGRESS.NAME];
                break;

            case 'done':
                vm.importantOrders = null;
                vm.orders = vm.orders[ENV_VARS.ORDERS_SERVICES.DONE.NAME];
                break;
            default:
                throw Error('Unexpected status');
        }
    };

    vm.getFilteredImportantOrders = () => {
        if (!vm.importantOrders) return [];
        if (!vm.mode) return vm.importantOrders.list;
        return vm.importantOrders.list.filter((o) => o.consommationMode === vm.mode);
    };

    vm.getFilteredOrders = () => {
        if (!vm.orders) return [];
        if (!vm.mode) return vm.orders.list;
        return vm.orders.list.filter((o) => o.consommationMode === vm.mode);
    };

    vm.nextStatusGroup = function (orderId) {
        // eslint-disable-next-line eqeqeq
        if ($state.params.orderId == orderId) {
            $state.go('^');
        }
        return vm.orders.next(orderId);
    };

    vm.getTotalByMode = function (mode) {
        if (vm.status === 'placed') {
            return (
                vm.importantOrders.list.filter((o) => o.consommationMode === mode).length +
                vm.orders.list.filter((o) => o.consommationMode === mode).length
            );
        }

        if (vm.status === 'in_progress') {
            return vm.orders.list.filter((o) => o.consommationMode === mode).length;
        }

        if (vm.status === 'done') {
            return vm.orders.list.filter((o) => o.consommationMode === mode).length;
        }

        return 0;
    };

    vm.getCurrentMode = function () {
        return vm.mode;
    };

    vm.switchMode = function (mode) {
        if (vm.mode === mode) {
            vm.mode = null;
            return;
        }

        vm.mode = mode;
    };

    vm.selected = function () {
        if ($state.params.order) {
            return $state.params.order.orderId;
        }
        return null;
    };

    vm.select = function select(order) {
        // eslint-disable-next-line eqeqeq
        if ($state.params.order && $state.params.order.orderId == order.orderId) {
            $state.go('^');
        } else if (!$state.params.order) {
            $state.go('.detail', {
                order,
            });
        } else {
            $state.go('^.detail', {
                order,
            });
        }
    };

    vm.cancel = function (order) {
        if (order.mainStatus !== 0) {
            let nextState = '.modal_refund.digicode';
            if ($state.current.name.indexOf('detail') === -1) {
                nextState = '.detail.modal_refund.digicode';
            }
            statePromise
                .go(
                    nextState,
                    { orderId: order.orderId },
                    {
                        location: false,
                    },
                    'refundOrder',
                )
                .then(function () {
                    // eslint-disable-next-line no-undef
                    orderService.loop.forceUpdateAll();
                });
        } else {
            vm.previousStatusGroup(order.orderId);
        }
    };

    vm.onError = function (error) {
        if (error.data && error.data.message) {
            statePromise.go(
                'home.orders_home.notification',
                { message: error.data.message },
                { location: false },
                'notification',
            );
        }
    };
}

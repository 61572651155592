"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

require("core-js/modules/es.function.name");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = void 0;

var _angular = _interopRequireDefault(require("angular"));

var _web_bridge = _interopRequireDefault(require("./../web_bridge/web_bridge.module"));

var _simpleLog = _interopRequireDefault(require("./simpleLog.factory"));

var dependencies = [_web_bridge.default];

_angular.default.module('core.simpleLog', dependencies).factory('simpleLogService', _simpleLog.default);

var _default = _angular.default.module('core.simpleLog').name;

exports.default = _default;
"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

require("core-js/modules/es.array.join");

require("core-js/modules/es.regexp.exec");

require("core-js/modules/es.string.replace");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports["default"] = void 0;

var _parseInt2 = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/parse-int"));

var _map = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/map"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/defineProperty"));

var _concat = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/concat"));

var _fill = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/fill"));

var _get = _interopRequireDefault(require("lodash/get"));

var _serialize = _interopRequireDefault(require("../serialize"));

var _constants = require("../constants");

var _LOCALE_STRING_MAP = require("../LOCALE_STRING_MAP");

var _actions, _getStyleFromAttribut;

var actions = (_actions = {}, (0, _defineProperty2["default"])(_actions, _constants.PRINTER_ACTIONS.FEEDLINE, function (_ref) {
  var _context;

  var attributes = _ref.attributes;
  var lineNumberToSkip = attributes[_constants.PRINTER_ATTRIBUTES.ALIGNMENT];
  return (0, _fill["default"])(_context = Array(lineNumberToSkip)).call(_context, "<div>".concat(_constants.EMPTY_CHARACTER, "</div>")).join('\n');
}), (0, _defineProperty2["default"])(_actions, _constants.PRINTER_ACTIONS.LINE, function (_ref2) {
  var _context2;

  var attributes = _ref2.attributes,
      value = _ref2.value;
  var style = getStyle(attributes);

  if (!value) {
    return "<div>".concat(_constants.EMPTY_CHARACTER, "</div>");
  }

  return (0, _concat["default"])(_context2 = "<div style=\"".concat(style, "\">")).call(_context2, value.replace(/ /g, '&nbsp;'), "</div>");
}), (0, _defineProperty2["default"])(_actions, _constants.PRINTER_ACTIONS.BARCODE, function () {
  return '<div></div>'; // To be implemented ?;
}), _actions);
var getStyleFromAttributes = (_getStyleFromAttribut = {}, (0, _defineProperty2["default"])(_getStyleFromAttribut, _constants.PRINTER_ATTRIBUTES.ALIGNMENT, function (attribute) {
  return "text-align:".concat(attribute, ";");
}), (0, _defineProperty2["default"])(_getStyleFromAttribut, _constants.PRINTER_ATTRIBUTES.EMPHASIS, function (attribute) {
  return attribute ? 'font-weight:bold;' : '';
}), (0, _defineProperty2["default"])(_getStyleFromAttribut, _constants.PRINTER_ATTRIBUTES.UNDERLINE, function (attribute) {
  return attribute ? 'text-decoration:underline;' : '';
}), (0, _defineProperty2["default"])(_getStyleFromAttribut, _constants.PRINTER_ATTRIBUTES.SIZE, function (attribute) {
  var _FONT_SIZES;

  var FONT_SIZES = (_FONT_SIZES = {}, (0, _defineProperty2["default"])(_FONT_SIZES, _constants.PRINTER_FONT_SIZE.NORMAL, 12), (0, _defineProperty2["default"])(_FONT_SIZES, _constants.PRINTER_FONT_SIZE.MEDIUM, 24), (0, _defineProperty2["default"])(_FONT_SIZES, _constants.PRINTER_FONT_SIZE.LARGE, 30), _FONT_SIZES);
  return "font-size:".concat(FONT_SIZES[attribute], "px;line-height:110%");
}), _getStyleFromAttribut);

var getStyle = function getStyle(attributes) {
  var style = (0, _map["default"])(attributes).call(attributes, function (attribute, attributeKey) {
    var getStyleFromAttribute = getStyleFromAttributes[attributeKey];
    var formattedAttribute = attributeKey ? (0, _parseInt2["default"])(attribute, 10) : attribute;
    return getStyleFromAttribute(formattedAttribute);
  });
  return style.join('');
};

var wrapPreview = function wrapPreview(body) {
  var _context3;

  var style = ['width:360px;', 'font-family: monospace;'];
  return (0, _concat["default"])(_context3 = "<div style=\"".concat(style.join(''), "\">")).call(_context3, body, "</div>");
};

var formatHtmlTicket = function formatHtmlTicket(ticket) {
  var warningMessage = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';

  if (ticket.length === 0) {
    return wrapPreview(warningMessage);
  }

  var htmlTicket = (0, _map["default"])(ticket).call(ticket, function (raw) {
    return actions[raw.action](raw);
  });
  return wrapPreview(htmlTicket.join('\n'));
};

var getTicketPreview = function getTicketPreview(xmlTicket, orderData) {
  var locale = (0, _get["default"])(orderData, 'restaurant.locale') || 'fr';
  var warningMessage = _LOCALE_STRING_MAP.LOCALE_STRING_MAP[locale].WARNING_MESSAGE; // This function is only called in the reception : where don't want ecoFriendly tickets

  var ticket = (0, _serialize["default"])(xmlTicket, orderData, false);
  return formatHtmlTicket(ticket, warningMessage);
};

var _default = getTicketPreview;
exports["default"] = _default;
export default function($templateCache, $backDrop) {
  'ngInject';
  function controller($scope) {
    'ngInject';
    var vm = this;

    $scope.steps = [];

    vm.addStep = function(element) {
      $scope.steps.push(element);
    };

    vm.computeClasses = function(element) {
      var currentFlag = false;
      var currentIndex = $scope.steps.indexOf(element);

      angular.forEach($scope.steps, function(step, index) {
        angular.element(step).removeClass('current');
        angular.element(step).removeClass('past');
        angular.element(step).removeClass('future');

        if (index < currentIndex) {
          angular.element(step).addClass('past');
        } else if (index == currentIndex) {
          angular.element(step).addClass('current');
        } else {
          angular.element(step).addClass('future');
        }
      });
    };
  }

  return {
    controller: controller,
    scope: true,
    restrict: 'E',
  };
}

export default function (statePromise, $stateParams) {
    'ngInject';

    const confirmVm = this;

    confirmVm.back = back;
    confirmVm.isCancel = $stateParams.isCancel;

    function back() {
        statePromise.resolve({}, 'refundOrder');
    }
}

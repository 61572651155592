"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.isTokenError = void 0;

var _includes = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/includes"));

var token_codes = ['login_failed', 'permission_denied', 'token_expired', 'password_expired', 'user_not_found'];

var isTokenError = function isTokenError(error) {
  var data = error.data;
  return !!data && !!data.code && (0, _includes.default)(token_codes).call(token_codes, data.code);
};

exports.isTokenError = isTokenError;
export default function() {
  'ngInject';

  return {
    require: 'ngModel',
    restrict: 'A',
    link: function(scope, element, attributes, ngModel) {
      ngModel.$validators.maxlength = function(modelValue) {
        return modelValue ? modelValue.length <= attributes.fioMaxlength : true;
      };
    },
  };
}

"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty2 = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

require("core-js/modules/es.array.join");

require("core-js/modules/es.date.to-string");

require("core-js/modules/es.function.name");

require("core-js/modules/es.number.constructor");

require("core-js/modules/es.object.to-string");

require("core-js/modules/es.regexp.exec");

require("core-js/modules/es.regexp.to-string");

require("core-js/modules/es.string.split");

_Object$defineProperty2(exports, "__esModule", {
  value: true
});

exports.formatTicket = formatTicket;
exports.getXMLTicket = getXMLTicket;
exports.convertBasicPrinterInstruction = convertBasicPrinterInstruction;
exports.alignText = alignText;
exports.addActionToPrint = addActionToPrint;
exports.addLine = addLine;
exports.addLineBreaks = addLineBreaks;
exports.getPhone = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/define-property"));

var _defineProperties = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/define-properties"));

var _getOwnPropertyDescriptors = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors"));

var _forEach = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/for-each"));

var _getOwnPropertyDescriptor = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor"));

var _filter = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/filter"));

var _getOwnPropertySymbols = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols"));

var _defineProperty3 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/defineProperty"));

var _keys = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/keys"));

var _trim = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/trim"));

var _map = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/map"));

var _indexOf = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/index-of"));

var _concat = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/concat"));

var _isNan = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/number/is-nan"));

var _slice = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/slice"));

var _moment = _interopRequireDefault(require("moment"));

var _mustache = _interopRequireDefault(require("mustache"));

var _reactXmlParser = _interopRequireDefault(require("react-xml-parser"));

var _get = _interopRequireDefault(require("lodash/get"));

var _constants = require("./constants");

function ownKeys(object, enumerableOnly) { var keys = (0, _keys["default"])(object); if (_getOwnPropertySymbols["default"]) { var symbols = (0, _getOwnPropertySymbols["default"])(object); if (enumerableOnly) symbols = (0, _filter["default"])(symbols).call(symbols, function (sym) { return (0, _getOwnPropertyDescriptor["default"])(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { var _context16; (0, _forEach["default"])(_context16 = ownKeys(Object(source), true)).call(_context16, function (key) { (0, _defineProperty3["default"])(target, key, source[key]); }); } else if (_getOwnPropertyDescriptors["default"]) { (0, _defineProperties["default"])(target, (0, _getOwnPropertyDescriptors["default"])(source)); } else { var _context17; (0, _forEach["default"])(_context17 = ownKeys(Object(source))).call(_context17, function (key) { (0, _defineProperty2["default"])(target, key, (0, _getOwnPropertyDescriptor["default"])(source, key)); }); } } return target; }

function pad(pad_, str, padLeft) {
  if (typeof str === 'undefined') return pad_;

  if (padLeft) {
    var _context;

    return (0, _slice["default"])(_context = pad_ + str).call(_context, -pad_.length);
  }

  return (str + pad_).substring(0, pad_.length);
}

function formatTicket(ticketNumber) {
  if (ticketNumber) {
    var parsedNumber = ticketNumber.split('_').pop();

    if ((0, _isNan["default"])(Number(parsedNumber))) {
      return "N\xB0 ".concat(parsedNumber);
    }

    return "N\xB0 #".concat(Number(parsedNumber));
  }

  return 'N° #000';
}

function formatAddress(address) {
  var _context2, _context3, _context4;

  return (0, _concat["default"])(_context2 = (0, _concat["default"])(_context3 = (0, _concat["default"])(_context4 = "".concat(address.streetNumber, " ")).call(_context4, address.route, ", ")).call(_context3, address.postalCode, " ")).call(_context2, address.locality);
}

function formatApartmentNumber(address, localeStringMapInstance) {
  var _context5;

  return (0, _concat["default"])(_context5 = "".concat(localeStringMapInstance.APPARTMENT, " : ")).call(_context5, address.apartmentNumber);
}

function formatUserDigiCode(address, localeStringMapInstance) {
  var _context6;

  return (0, _concat["default"])(_context6 = "".concat(localeStringMapInstance.DIGICODE, " : ")).call(_context6, address.digiCode);
}

function formatPaymentMean(paymentMean, localeStringMapInstance) {
  return localeStringMapInstance[paymentMean] || paymentMean;
}

var getPhone = function getPhone(order) {
  if (!order.user || order.userName) {
    return undefined;
  }

  return order.address ? order.address.phone : order.user.phone;
};

exports.getPhone = getPhone;

function getXMLTicket(orderData, localeStringMapInstance, xmlTicket) {
  var _context7, _context8, _context11;

  var mustacheMapping = {
    restaurantName: orderData.restaurant.name,
    orderTicketNumberDescription: (0, _concat["default"])(_context7 = "".concat(localeStringMapInstance.CHANNELS[orderData.channelId], " ")).call(_context7, formatTicket(orderData.ticketNumber)),
    paymentMean: formatPaymentMean(orderData.paymentMean, localeStringMapInstance),
    modeOfConsumption: localeStringMapInstance.MODE_OF_CONSUMPTION[orderData.consommationMode],
    expectedDate: (0, _moment["default"])(orderData.expectedDate).format('HH:mm'),
    expectedTime: (0, _moment["default"])(orderData.expectedDate).format('HH:mm'),
    expectedFullDate: (0, _moment["default"])(orderData.expectedDate).format('DD/MM/YYYY HH:mm'),
    userFistName: orderData.user ? orderData.user.firstName : '',
    userLastName: orderData.user ? orderData.user.lastName : '',
    userEmail: orderData.user ? orderData.user.email : '',
    userClass: (0, _get["default"])(orderData, ['user', 'student', 'class'], ''),
    userStudentNumber: (0, _get["default"])(orderData, ['user', 'student', 'studentNumber'], ''),
    userStudentId: (0, _get["default"])(orderData, ['user', 'student', 'studentId'], ''),
    userSection: (0, _get["default"])(orderData, ['user', 'student', 'section'], ''),
    userSubSection: (0, _get["default"])(orderData, ['user', 'student', 'subSection'], ''),
    userTableNumber: orderData.tableName,
    userBadgeNumber: orderData.user ? orderData.user.badgeNumber : '',
    ewalletBalanceBeforeOrder: orderData.ewalletBalanceBeforeOrder,
    ewalletBalanceAfterOrder: orderData.ewalletBalanceAfterOrder,
    userAddressPhone: getPhone(orderData),
    userDigiCode: orderData.address && orderData.address.digiCode ? formatUserDigiCode(orderData.address, localeStringMapInstance) : '',
    userApartmentNumber: orderData.address && orderData.address.apartmentNumber ? formatApartmentNumber(orderData.address, localeStringMapInstance) : '',
    userAddressCompiled: orderData.address ? formatAddress(orderData.address, localeStringMapInstance) : '',
    userAdditionalInfo: orderData.address ? orderData.address.additionalInfo : '',
    userComment: orderData.comment ? orderData.comment : '',
    orderCreatedAt: (0, _moment["default"])(orderData.created_at).format('DD/MM/YYYY HH:mm')
  };

  if (orderData.userName) {
    mustacheMapping.userFistName = orderData.userName;
    mustacheMapping.userLastName = '';
  }

  if (orderData.expectedDateEnd) {
    mustacheMapping.expectedDateEnd = (0, _moment["default"])(orderData.expectedDateEnd).format('HH:mm');
  }

  if (orderData.servingCount) {
    mustacheMapping.servingCount = orderData.servingCount;
  }

  if (Number(orderData.paymentStatus) === 1) {
    mustacheMapping.paymentStatus = localeStringMapInstance.PAID;
  } else {
    mustacheMapping.paymentStatus = localeStringMapInstance.UNPAID;
  } // This statement check if there is multiple lines


  if (mustacheMapping.userComment && (0, _indexOf["default"])(_context8 = mustacheMapping.userComment).call(_context8, '\n') !== -1) {
    var _context9;

    mustacheMapping.userComment = (0, _map["default"])(_context9 = mustacheMapping.userComment.split('\n')).call(_context9, function (txt) {
      var _context10;

      return (0, _trim["default"])(_context10 = String(txt)).call(_context10);
    }).join(' ');
  }

  if (mustacheMapping.userAdditionalInfo && (0, _indexOf["default"])(_context11 = mustacheMapping.userAdditionalInfo).call(_context11, '\n') !== -1) {
    var _context12;

    mustacheMapping.userAdditionalInfo = (0, _map["default"])(_context12 = mustacheMapping.userAdditionalInfo.split('\n')).call(_context12, function (txt) {
      var _context13;

      return (0, _trim["default"])(_context13 = String(txt)).call(_context13);
    }).join(' ');
  }

  if (orderData.metadata) {
    mustacheMapping.metadata = JSON.parse(orderData.metadata);
  }

  xmlTicket = _mustache["default"].render(xmlTicket, mustacheMapping);
  var ticketParsedXml = new _reactXmlParser["default"]().parseFromString(xmlTicket);
  return ticketParsedXml;
}

function convertBasicPrinterInstruction(child) {
  var _context14;

  var baseChildScema = _constants.PRINTER_SCHEMA[child.name]; // Attribute matching with a default value

  var attributes = (0, _map["default"])(_context14 = (0, _keys["default"])(baseChildScema)).call(_context14, function (attribute) {
    var _context15;

    if (child.attributes[attribute] && (0, _indexOf["default"])(_context15 = baseChildScema[attribute]).call(_context15, child.attributes[attribute]) !== -1) {
      return child.attributes[attribute];
    } // default element is array[0]


    return baseChildScema[attribute][0];
  });
  return {
    action: child.name,
    attributes: attributes,
    value: child.value
  };
}

function alignText(leftString, rightString, fillerString, textLength) {
  textLength = textLength ? Number(textLength) : _constants.PRINTER_LINE_LENGTH;
  var paddingRightTextLength = textLength - leftString.length - rightString.length + 1; // when the string we want to print is too long, padding length is negativ, so we add this check to shorten the text

  if (paddingRightTextLength < 0) {
    // We want to cut the text to have text + '...' (3 charts) + 2 spaces, so 3 + 2 = 5
    if (leftString && leftString.length > Math.abs(paddingRightTextLength - 5)) {
      leftString = "".concat(leftString.substring(0, leftString.length + paddingRightTextLength - 5), "...");
    }

    paddingRightTextLength = 2;
  }

  var paddingRight = Array(paddingRightTextLength).join(fillerString);
  var paddingLeft = Array(rightString.length + 1).join(fillerString);
  var padding = paddingLeft + leftString + paddingRight;
  return pad(padding, rightString, true);
}

function addActionToPrint(_ref) {
  var output = _ref.output,
      action = _ref.action,
      align = _ref.align,
      emphasis = _ref.emphasis,
      underline = _ref.underline,
      size = _ref.size,
      value = _ref.value,
      lineBreaks = _ref.lineBreaks;
  var actionToPrint = {
    action: action,
    attributes: action === _constants.PRINTER_ACTIONS.LINE ? [align, emphasis, underline, size] : [lineBreaks],
    value: value
  };
  output.push(actionToPrint);
}

function addLine(output, value, options) {
  var lineOptions = _objectSpread(_objectSpread({}, _constants.DEFAULT_LINE_OPTIONS), options);

  addActionToPrint(_objectSpread({
    output: output,
    action: _constants.PRINTER_ACTIONS.LINE,
    value: value
  }, lineOptions));
}

function addLineBreaks(output, quantity) {
  addActionToPrint({
    output: output,
    action: _constants.PRINTER_ACTIONS.FEEDLINE,
    lineBreaks: quantity.toString(),
    value: ''
  });
}
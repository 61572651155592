import angular from 'angular';
import contactModule from './contact/contact.module';
import aboutRoutes from './about.routes';
import aboutController from './about.controller';

const dependencies = [contactModule];

angular
    .module('app.home.parameters.about', dependencies)
    .config(aboutRoutes)
    .controller('ParametersAboutController', aboutController);
export default angular.module('app.home.parameters.about').name;

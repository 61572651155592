import addPrinterView from 'app/components/parameters/print/add_printer/add_printer.view.html';
import confirmDeletePrinterView from 'app/components/parameters/print/confirm_delete_printer/confirm_delete_printer.view.html';

export default function ($stateProvider, $urlRouterProvider, $locationProvider) {
    'ngInject';

    $locationProvider.html5Mode(true);
    $stateProvider
        .state('home.parameters.print.add', states.addPrinter())
        .state('home.parameters.print.confirm_delete_printer', states.confirmDelete());
}

// eslint-disable-next-line vars-on-top, no-var
var states = {
    addPrinter() {
        return {
            abstract: true,
            views: {
                'modal@': {
                    templateUrl: addPrinterView,
                    controller: 'ParametersPrinterAddController',
                    controllerAs: 'parametersPrinterAddVm',
                },
            },
            resolve: {
                startPrintSearch: [
                    'printService',
                    function (printService) {
                        printService.search();
                    },
                ],
            },
        };
    },
    confirmDelete() {
        return {
            views: {
                'modal@': {
                    templateUrl: confirmDeletePrinterView,
                    controller: 'ParametersPrinterConfirmDeleteController',
                    controllerAs: 'parametersPrinterConfirmDeleteVm',
                },
            },
        };
    },
};

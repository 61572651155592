"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

require("core-js/modules/es.function.name");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = void 0;

var _filter = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/filter"));

var _filter_big_decimal = _interopRequireDefault(require("./filter_big_decimal.filter"));

var _angular = _interopRequireDefault(require("angular"));

var _context;

var modules = [];
(0, _filter.default)(_context = _angular.default.module('core.filter_big_decimal', modules)).call(_context, 'bigDecimal', _filter_big_decimal.default);

var _default = _angular.default.module('core.filter_big_decimal').name;

exports.default = _default;
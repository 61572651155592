"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = _ngInjectExport;
_ngInjectExport.$inject = ["$injector", "IO_LOCAL"];

function _ngInjectExport($injector, IO_LOCAL) {
  'ngInject';

  var interceptor = {};
  var translateService;

  interceptor.request = function (config) {
    if (!translateService) {
      translateService = $injector.get('$translate');
    }

    var ioLocal = translateService.use();
    config.headers['IO-Locale'] = IO_LOCAL[ioLocal] || IO_LOCAL.fr;
    return config;
  };

  return interceptor;
}
import quoteV2View from './quote_v2/quote_v2.view.html';
import infosV2View from './infos_v2/infos_v2.view.html';
import searchV2View from './search_v2/search_v2.view.html';
import cancelView from './cancel/cancel.view.html';

export default function ($stateProvider, $urlRouterProvider, $locationProvider) {
    'ngInject';

    $locationProvider.html5Mode(false);

    $stateProvider
        .state('home.orders_home.stuart_v2.quote_v2', states.quote_v2())
        .state('home.orders_home.stuart_v2.infos_v2', states.infos_v2())
        .state('home.orders_home.stuart_v2.search_v2', states.search_v2())
        .state('home.orders_home.stuart_v2.cancel', states.cancel());
}

// eslint-disable-next-line vars-on-top, no-var
var states = {
    quote_v2() {
        return {
            params: {
                stuartV2Order: null,
            },
            templateProvider: [
                '$templateCache',
                function ($templateCache) {
                    return $templateCache.get(quoteV2View);
                },
            ],
            controller: 'StuartV2QuoteController',
            controllerAs: 'stuartV2QuoteVm',
        };
    },
    infos_v2() {
        return {
            sticky: true,
            params: {
                stuartV2Order: null,
            },
            templateProvider: [
                '$templateCache',
                function ($templateCache) {
                    return $templateCache.get(infosV2View);
                },
            ],
            controller: 'StuartV2InfosController',
            controllerAs: 'stuartV2InfosVm',
            resolve: {
                job(StuartV2, $stateParams) {
                    'ngInject';

                    return StuartV2.getJob({ orderId: $stateParams.stuartV2Order.orderId }).$promise;
                },
            },
        };
    },
    search_v2() {
        return {
            templateUrl: searchV2View,
            controller: 'StuartV2SearchController',
            controllerAs: 'StuartV2SearchVm',
            params: {
                orderId: null,
                transport: null,
            },
        };
    },
    cancel() {
        return {
            params: {
                orderId: null,
            },
            views: {
                'modal@': {
                    templateUrl: cancelView,
                    controller: 'StuartV2InfosCancelController',
                    controllerAs: 'stuartInfosCancelVm',
                },
            },
        };
    },
};

/**
 *  Backdrop service
 *  ---------------------------------------------------
 */
export default function($rootScope, $timeout, $animate) {
  'ngInject';
  var backdropQueue = [],
    opaqueUid = null,
    scope;

  function inject(scpe, callback, elt, force) {
    scope = scpe;
    return injector(callback, false, elt, force);
  }

  function injectOpaque(scpe, callback, elt, force) {
    scope = scpe;
    return injector(callback, true, elt, force);
  }

  function injector(callback, opaque, sibling, force_inject) {
    // creating element
    var uid = new_UID();
    var elt = angular.element(
      '<fio-backdrop id="backdrop_' + uid + '"></fio-backdrop>',
    );

    var search = force_inject === undefined ? true : false;

    // registering in queue
    backdropQueue[uid] = {
      element: elt[0],
      callback: callback,
    };

    // attributing callback
    elt.on('click', function() {
      if (callback !== undefined) {
        callback();
        destroy(uid);
      }
    });

    // special case for opaque backdrop
    if (opaque == true) {
      elt.addClass('opaque');
      opaqueUid = uid;
    }

    // injecting dom element
    if (typeof sibling == 'undefined') {
      if (backdropQueue.length == 0) {
        $animate.enter(elt, document.body);
      } else {
        $animate.enter(
          elt,
          document.body,
          document.querySelectorAll('fio-backdrop')[
            document.querySelectorAll('fio-backdrop').length - 1
          ],
        );
      }
    } else {
      var tempElt = sibling;
      do {
        tempElt = angular.element(tempElt).parent();
      } while (
        search &&
        window
          .getComputedStyle(tempElt[0], null)
          .getPropertyValue('position') != 'static'
      );

      $animate.enter(elt, tempElt);
    }

    return uid;
  }

  function destroy(uid) {
    var backdropObj = backdropQueue[uid];

    if (!$rootScope.$$phase && !scope.$$phase) {
      scope.$apply(function() {
        $animate.leave(backdropObj.element);
      });
    } else {
      $animate.leave(backdropObj.element);
    }
    backdropQueue[uid] = null;
  }

  function randomMinMax(min, max) {
    return Math.floor(Math.random() * (max - min) + min);
  }

  function new_UID() {
    var x1 = randomMinMax(0, 10000);
    var x2 = randomMinMax(0, 10000);
    var x3 = randomMinMax(0, 10000);
    var x4 = randomMinMax(0, 10000);
    var x5 = randomMinMax(0, 10000);
    return [x1, x2, x3, x4, x5, Date.now()].join('-');
  }

  return {
    inject: inject,
    injectOpaque: injectOpaque,
    destroy: destroy,
  };
}

export default function (order, Stuart, $state, ENV_VARS, statePromise, $stateParams) {
    'ngInject';

    const vm = this;
    vm.askingForQuote = false;
    vm.selectedTransport = null;
    vm.quote = null;
    vm.searchLaunched = false;

    vm.formattedTicketNumber = order.formatTicket();

    vm.getQuote = function (transport) {
        // eslint-disable-next-line eqeqeq
        if (transport != vm.selectedTransport) {
            vm.quote = null;
            vm.error = null;
            vm.selectedTransport = transport;
            vm.askingForQuote = true;
            Stuart.getQuote({ orderId: order.orderId, transportNames: transport })
                .$promise.then(function (data) {
                    vm.quote = data.stuartQuote[ENV_VARS.STUART_TRANSPORTS[transport]];
                    if (vm.quote && vm.quote.errors) {
                        vm.error = vm.quote.errors.message;
                        vm.quote = null;
                    }
                    vm.askingForQuote = false;
                })
                .catch(function (e) {
                    vm.error = e.data.message;
                });
        }
    };

    vm.searchStuart = function () {
        if (vm.quote && vm.selectedTransport && !vm.searchLaunched) {
            vm.searchLaunched = true;
            $state.go('^.search', { quote: vm.quote, transport: vm.selectedTransport });
        }
    };

    vm.close = function () {
        statePromise.reject($stateParams.statePromiseName);
    };
}

import optionView from './option.view.html';
export default function($templateCache, $compile) {
  'ngInject';

  function link(scope, element, attrs, selectCtrl) {
    if (attrs.default != undefined) {
      setTimeout(function() {
        selectCtrl.selectElement(scope.name, scope.value);
      }, 0);
    }

    selectCtrl.addOption(scope.name, scope.value);

    angular.element(element).on('click', function() {
      selectCtrl.selectElement(scope.name, scope.value);
    });
  }

  return {
    link: link,
    restrict: 'E',
    require: '^fioSelect',
    scope: {
      value: '=',
      name: '=',
    },
    template: $templateCache.get(optionView),
  };
}

"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

require("core-js/modules/es.function.name");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = _ngInjectExport;

var _stringify = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/json/stringify"));

var _keys = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/keys"));

_ngInjectExport.$inject = ["$q", "$rootScope", "BRIDGE_MESSAGE"];

function _ngInjectExport($q, $rootScope, BRIDGE_MESSAGE) {
  'ngInject';

  var self = this;
  var subscribers = {};

  self.init = function () {
    var deferred = $q.defer();

    if (typeof WebViewBridge !== 'undefined') {
      if (self.ready) {
        deferred.resolve(self);
      } else {
        WebViewBridge.onMessage = function (serializedMessage) {
          try {
            var messageObject = unserializeMessage(serializedMessage);

            if (subscribers[messageObject.eventName]) {
              var keys = (0, _keys.default)(subscribers[messageObject.eventName]);

              for (var i = keys.length - 1; i >= 0; i--) {
                subscribers[messageObject.eventName][keys[i]](messageObject.eventData);
              }
            }

            if (messageObject.eventName === BRIDGE_MESSAGE.PONG) {
              if (!self.ready) {
                self.ready = true;
                deferred.resolve(self);
              }

              pong();
            }
          } catch (error) {
            emitMessage(BRIDGE_MESSAGE.ERROR_CATCH, error);
          }
        };

        var eventNames = (0, _keys.default)(BRIDGE_MESSAGE);

        for (var i = eventNames.length - 1; i >= 0; i--) {
          subscribers[eventNames[i]] = {};
        }

        ping();
      }
    } else {
      self.ready = false;
      deferred.resolve(self);
    }

    return deferred.promise;
  };

  self.emitMessage = emitMessage;

  self.on = function (eventName, key, callBack) {
    if (subscribers[eventName] == null) {
      throw new Error("Cannot find subscriber for event name ".concat(eventName));
    }

    subscribers[eventName][key] = function () {
      try {
        callBack.apply(void 0, arguments);
      } catch (error) {
        console.error(error);
        throw error;
      }
    };
  };

  self.off = function (eventName, key) {
    if (subscribers[eventName] == null) {
      throw new Error("Cannot find subscriber for event name ".concat(eventName));
    }

    subscribers[eventName][key] = undefined;
  };

  self.shouldShutdown = false;

  self.shutdown = function () {
    emitMessage(BRIDGE_MESSAGE.SHUTDOWN, null, true);
  };

  function ping() {
    emitMessage(BRIDGE_MESSAGE.PING, null, true);
  }

  function pong() {
    emitMessage(BRIDGE_MESSAGE.PONG, null, true);
  }

  function emitMessage(eventName, eventData, blindCheck) {
    if (BRIDGE_MESSAGE[eventName]) {
      emit(serializeMessage(BRIDGE_MESSAGE[eventName], eventData), blindCheck);
    } else {
      emit(serializeMessage(BRIDGE_MESSAGE.ERROR_BRIDGE_MESSAGE_NOT_FOUND, new Error('bridge_message_not_found')));
    }
  }

  function emit(serializedMessage, blindCheck) {
    if (blindCheck || self.ready) {
      WebViewBridge.send(serializedMessage);
    }
  }

  function serializeMessage(eventName, eventData) {
    if (eventData && eventData.message && eventData.name && eventData.stack) {
      eventData = {
        message: eventData.message,
        name: eventData.name,
        stack: eventData.stack
      };
    }

    return (0, _stringify.default)({
      eventName: eventName,
      eventData: eventData,
      eventTimestamp: new Date()
    });
  }

  function unserializeMessage(serializedMessage) {
    try {
      var messageObject = JSON.parse(serializedMessage);

      if (messageObject.eventName && BRIDGE_MESSAGE[messageObject.eventName]) {
        if (messageObject.eventData !== undefined && messageObject.eventTimestamp) {
          var a = {
            eventName: messageObject.eventName,
            eventData: messageObject.eventData,
            eventTimestamp: messageObject.eventTimestamp
          };
          return a;
        } else {
          throw new Error('bridge_message_incorrect_format');
        }
      } else {
        throw new Error('bridge_message_not_found');
      }
    } catch (e) {
      throw e;
    }
  }
}
export default function translateConfig($translateProvider) {
    'ngInject';

    $translateProvider
        .useStaticFilesLoader({
            prefix: 'i18n/',
            suffix: `.json?date=${Date.now()}`,
        })
        .use('fr')
        .preferredLanguage('fr')
        .fallbackLanguage('fr')
        .registerAvailableLanguageKeys(['fr'], {
            'fr-*': 'fr',
        })
        .determinePreferredLanguage()
        .useStorage('languageStorage')
        .useSanitizeValueStrategy('escape');
}

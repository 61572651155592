export default function (moment, RestaurantModule, restaurant, $timeout, $q, shouldUseUberEatsAvailability) {
    'ngInject';

    const vm = this;
    let openAt;

    vm.isOpen = false;
    vm.action = '';
    vm.shouldUseUberEatsAvailability = shouldUseUberEatsAvailability;

    vm.save = function () {
        let { action } = vm;
        if (!vm.isOpen) {
            action = 'open';
        }

        RestaurantModule.post({
            restaurantId: restaurant.restaurantId,
            moduleId: 2,
            channelId: 2,
            module: {
                closeRestaurant: action,
            },
        }).$promise.then(function (data) {
            openAt = moment(data.openAt);
            vm.isOpen = openAt.isBefore(moment());
        });
    };

    function loadState() {
        const deferred = $q.defer();
        const moduleOptions = {
            restaurantId: restaurant.restaurantId,
            moduleId: 2,
            channelId: 2,
        };
        RestaurantModule.get(moduleOptions)
            .$promise.then(function (module) {
                openAt = moment(module.openAt);
                vm.isOpen = openAt.isBefore(moment());
                deferred.resolve();
            })
            // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
            .catch(function (err) {
                deferred.reject();
            });
        return deferred.promise;
    }

    function initState(i) {
        loadState().then(
            function () {
                vm.isLoading = false;
                vm.networkError = false;
            },
            // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
            function (error) {
                vm.isLoading = true;
                vm.networkError = true;
                // eslint-disable-next-line no-restricted-properties
                $timeout(initState, Math.pow(2, (i + 1) % 5) * 1000, true, i + 1);
            },
        );
    }

    vm.isLoading = true;
    vm.networkError = false;
    initState(0);
}

export default function (orderService, RestaurantModule, restaurant) {
    'ngInject';

    const parametersAlertsVm = this;

    parametersAlertsVm.ringAlert = orderService.configReception.ringAlert;
    parametersAlertsVm.autoValidation = orderService.configReception.autoValidation;
    parametersAlertsVm.preorderAutoValidation = orderService.configReception.preorderAutoValidation;
    parametersAlertsVm.save = save;

    function save() {
        RestaurantModule.post({
            restaurantId: restaurant.restaurantId,
            moduleId: 12,
            channelId: 4,
            module: {
                ringAlert: parametersAlertsVm.ringAlert,
                autoValidation: parametersAlertsVm.autoValidation,
                preorderAutoValidation: parametersAlertsVm.preorderAutoValidation && parametersAlertsVm.autoValidation,
            },
        })
            .$promise.then(function (data) {
                parametersAlertsVm.ringAlert = data.ringAlert;
                parametersAlertsVm.autoValidation = data.autoValidation;
                parametersAlertsVm.preorderAutoValidation = data.preorderAutoValidation;
                orderService.configReception = data;
            })
            // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
            .catch(function (error) {
                parametersAlertsVm.ringAlert = orderService.configReception.ringAlert;
                parametersAlertsVm.autoValidation = orderService.configReception.autoValidation;
                parametersAlertsVm.preorderAutoValidation = orderService.configReception.preorderAutoValidation;
            });
    }
}

import recapModule from '@innovorder/angularcore/core/resources/recap/recap.module';

import angular from 'angular';
import recapWebController from './recap_web.controller';

const dependencies = [recapModule];
angular
    .module('app.home.parameters.recap_web', dependencies)
    .controller('ParametersRecapWebController', recapWebController);
export default angular.module('app.home.parameters.recap_web').name;

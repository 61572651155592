"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = void 0;
var _default = {
  template: '<div>' + '<order-product ng-repeat="product in orderCartVm.products" product="product" currency="orderCartVm.currency">' + '</div>',
  controller: 'OrderCartController',
  controllerAs: 'orderCartVm',
  bindings: {
    cart: '=',
    currency: '<'
  }
};
exports.default = _default;
import angular from 'angular';
import reasonController from './reason.controller';

const dependencies = [];

// eslint-disable-next-line angular/controller-name
angular
    .module('app.home.orders_home.detail.modal_refund.reason', dependencies)
    .controller('reasonController', reasonController);
export default angular.module('app.home.orders_home.detail.modal_refund.reason').name;

export default function (RestaurantModule, restaurant, $q) {
    'ngInject';

    const vm = this;
    vm.minutesSlider = 0;

    function loadState() {
        const deferred = $q.defer();
        const moduleOptions = { restaurantId: restaurant.restaurantId, moduleId: 2, channelId: 2 };

        RestaurantModule.get(moduleOptions)
            .$promise.then(function (module) {
                if (!module.rushDelay) {
                    module.rushDelay = 0;
                }
                vm.minutesSlider = module.rushDelay;
                deferred.resolve();
            })
            // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
            .catch(function (err) {
                deferred.reject();
            });
        return deferred.promise;
    }

    function initState(i) {
        loadState().then(
            function () {
                vm.isLoading = false;
                vm.networkError = false;
            },
            // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
            function (error) {
                vm.isLoading = true;
                vm.networkError = true;
                // eslint-disable-next-line no-undef, no-restricted-properties
                $timeout(initState, Math.pow(2, (i + 1) % 5) * 1000, true, i + 1);
            },
        );
    }

    vm.isLoading = true;
    vm.networkError = false;
    initState(0);

    vm.sliderOptions = {
        ceil: 60,
        onEnd() {
            RestaurantModule.post({
                restaurantId: restaurant.restaurantId,
                moduleId: 2,
                channelId: 2,
                module: {
                    rushDelay: vm.minutesSlider,
                },
            }).$promise.then(function (data) {
                vm.minutesSlider = data.rushDelay;
            });
        },
    };
}

"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = orderCartController;
exports.CartVersion = void 0;
orderCartController.$inject = ["$scope"];
var CartVersion = {
  V1: 'enriched-cart-1'
};
exports.CartVersion = CartVersion;

function orderCartController($scope) {
  'ngInject';

  var vm = this;
  vm.products = [];
  $scope.$watch('cart', function () {
    if (vm.cart === undefined) {
      return;
    }

    switch (vm.cart.version) {
      case CartVersion.V1:
        vm.products = vm.cart.enrichedCart;
        break;

      default:
        vm.products = vm.cart.products;
    }
  });
}
import { get } from '@innovorder/angularcore/core/localStorageConverter';

export default function (localStorageService) {
    'ngInject';

    const vm = this;
    // TODO : When all reception are >= 2.0.3 replace by localStorageService.cookie.get()
    vm.savedColors = get(localStorageService, 'cardColors');
    vm._savedColors = vm.savedColors;

    if (vm.savedColors) {
        vm.cardColors = vm.savedColors;
    } else {
        vm.cardColors = {
            delivery: '#75C2A2',
            take_away: '#F6C76E',
            here: '#FF7878',
            drive: '#A883D8',
        };
    }

    vm.save = function () {
        vm._savedColors = vm.cardColors;
        vm.savedColors = vm.cardColors;
        localStorageService.cookie.set('cardColors', vm.savedColors);
    };
}

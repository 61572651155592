import angular from 'angular';
import cancelModule from './cancel/cancel.module';
import infosRoutes from './infos.routes';
import infosController from './infos.controller';

const dependencies = [cancelModule];

angular
    .module('app.home.stuart.infos', dependencies)
    .config(infosRoutes)
    .controller('StuartInfosController', infosController);
export default angular.module('app.home.stuart.infos').name;

import colorPickerView from './color_picker.view.html';
export default function($timeout, $templateCache, $backDrop) {
  'ngInject';

  function link(scope, element, attrs, ngModel, $backdrop) {
    scope.popoverVisible = false;
    var backdrop;

    scope.triggerPicker = function() {
      scope.popoverVisible = true;

      backdrop = $backDrop.inject(
        scope,
        function() {
          scope.$apply(function() {
            scope.popoverVisible = !scope.popoverVisible;
          });
        },
        element,
      );
    };

    $timeout(function() {
      scope.$apply(function() {
        scope.innerColorModel = ngModel.$viewValue;
      });
    }, 0);

    scope.$watch(
      function() {
        return ngModel.$viewValue;
      },
      function(value) {
        if (value != undefined) {
          scope.innerColorModel = ngModel.$viewValue;
        }
      },
    );

    scope.$watch(
      function() {
        return scope.innerColorModel;
      },
      function(value) {
        if (value != undefined) {
          ngModel.$setViewValue(scope.innerColorModel);
        }
      },
    );
  }

  return {
    link: link,
    restrict: 'E',
    transclude: true,
    require: 'ngModel',
    scope: {},
    template: $templateCache.get(colorPickerView),
  };
}

import logoPickerView from './logo_picker.view.html';

export default function($templateCache) {
  'ngInject';
  function link(scope, element, attrs) {}

  return {
    link: link,
    restrict: 'E',
    transclude: true,
    scope: {},
    template: $templateCache.get(logoPickerView),
  };
}

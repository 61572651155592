import moment from 'moment-timezone';
import { OrderDeliveryStatus } from '../../../constants/order_delivery';
import { HOUR_FOR_END_OF_ORDERING_DAY } from '../../../constants/end_of_ordering_day';

export default function (CHANNELS, ENV_VARS, StuartV2) {
    'ngInject';

    const vm = this;
    vm.CHANNELS = CHANNELS;
    vm.job = {};
    vm.jobCreationError = null;

    vm.$onInit = function () {
        const { timeZoneId } = vm.order.restaurant.address;

        // The boolean isPreOrder returns false if the expectedDate for the order is today or between midnight and 3:00am tomorrow, and it returns returns true if the expectedDate for the order is later than 3:00am tomorrow
        const isPreOrder = moment
            .tz(vm.order.expectedDate, timeZoneId)
            .subtract({ hours: HOUR_FOR_END_OF_ORDERING_DAY })
            .isAfter(moment.tz(timeZoneId).subtract({ hours: HOUR_FOR_END_OF_ORDERING_DAY }), 'day');

        vm.isPreorder = isPreOrder;
        // eslint-disable-next-line default-case
        switch (vm.order.consommationMode) {
            case 1:
                vm.backgroundColor = vm.colors === undefined ? '#75C2A2' : vm.colors.delivery;
                break;
            case 2:
                vm.backgroundColor = vm.colors === undefined ? '#F6C76E' : vm.colors.take_away;
                break;
            case 3:
                vm.backgroundColor = vm.colors === undefined ? '#FF7878' : vm.colors.here;
                break;
            case 4:
                vm.backgroundColor = vm.colors === undefined ? '#A883D8' : vm.colors.drive;
                break;
        }

        const isStuartV2Job =
            vm.order.consommationMode === ENV_VARS.CONSOMMATION_MODES.DELIVERY && vm.order.deliveryType === 'STUART_V2';

        /* Fetch job details if it is scheduled in order to display the pickup_at datetime */
        if (isStuartV2Job && vm.order.deliveryStatus === OrderDeliveryStatus.DELIVERY_SCHEDULED) {
            StuartV2.getJob({ orderId: vm.order.orderId }).$promise.then(({ job } = {}) => {
                vm.job = job;
            });
        }

        /* Fetch job creation errors to display an icon */
        if (isStuartV2Job) {
            StuartV2.getLatestJobCreationError({ orderId: vm.order.orderId }).$promise.then(({ jobCreationError }) => {
                vm.jobCreationError = jobCreationError;
            });
        }
    };

    vm.cancelSwipe = function (event) {
        const cancelButton = event.currentTarget;
        const messageElt = angular.element(angular.element(angular.element(cancelButton).parent()[0]).parent()[0]);
        const orderElt = angular.element(messageElt.parent()[0].querySelectorAll('[fio-order]')[0]);
        orderElt.css('transform', 'translateX(0px)');
        orderElt.css('opacity', 1);
        messageElt.css('opacity', 0);
    };

    vm.cancelOrder = function (event, order) {
        vm.cancelSwipe(event);
        vm.cancel(order);
    };
}

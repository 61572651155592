// style-loader: Adds some css to the DOM by adding a <style> tag

// load the styles
var content = require("!!/home/runner/work/monorepo/monorepo/services/reception/node_modules/css-loader/index.js!/home/runner/work/monorepo/monorepo/services/reception/node_modules/sass-loader/dist/cjs.js??ref--4-2!/home/runner/work/monorepo/monorepo/services/reception/node_modules/replace-loader/index.js??ref--4-3!/home/runner/work/monorepo/monorepo/services/reception/assets/style.scss");
if(typeof content === 'string') content = [[module.id, content, '']];
// add the styles to the DOM
var update = require("!/home/runner/work/monorepo/monorepo/services/reception/node_modules/style-loader/addStyles.js")(content, {});
// Hot Module Replacement
if(module.hot) {
	// When the styles change, update the <style> tags
	module.hot.accept("!!/home/runner/work/monorepo/monorepo/services/reception/node_modules/css-loader/index.js!/home/runner/work/monorepo/monorepo/services/reception/node_modules/sass-loader/dist/cjs.js??ref--4-2!/home/runner/work/monorepo/monorepo/services/reception/node_modules/replace-loader/index.js??ref--4-3!/home/runner/work/monorepo/monorepo/services/reception/assets/style.scss", function() {
		var newContent = require("!!/home/runner/work/monorepo/monorepo/services/reception/node_modules/css-loader/index.js!/home/runner/work/monorepo/monorepo/services/reception/node_modules/sass-loader/dist/cjs.js??ref--4-2!/home/runner/work/monorepo/monorepo/services/reception/node_modules/replace-loader/index.js??ref--4-3!/home/runner/work/monorepo/monorepo/services/reception/assets/style.scss");
		if(typeof newContent === 'string') newContent = [[module.id, newContent, '']];
		update(newContent);
	});
	// When the module is disposed, remove the <style> tags
	module.hot.dispose(function() { update(); });
}
"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = _ngInjectExport;
_ngInjectExport.$inject = ["$resource", "$log", "RESOURCES_VARS", "ENV_VARS"];

function _ngInjectExport($resource, $log, RESOURCES_VARS, ENV_VARS) {
  'ngInject'; // resource rules
  // =============================================================================

  function parseData(response) {
    try {
      var body = angular.fromJson(response);

      if (body && body.data) {
        return body.data;
      }

      return body;
    } catch (e) {
      $log.error(e);
      return e;
    }
  }

  return $resource(ENV_VARS.API_URL + '/stuart/:action/:restaurantId', {
    restaurantId: '@restaurantId'
  }, {
    getStuartByRestaurantId: {
      method: RESOURCES_VARS.API_METHODS.GET,
      transformResponse: parseData
    },
    logout: {
      method: RESOURCES_VARS.API_METHODS.GET,
      transformResponse: parseData,
      params: {
        restaurantId: '@restaurantId',
        action: 'logout'
      }
    },
    login: {
      method: RESOURCES_VARS.API_METHODS.POST,
      transformResponse: parseData,
      params: {
        restaurantId: '@restaurantId',
        action: 'login'
      }
    },
    getQuote: {
      url: ENV_VARS.API_URL + '/orders/:orderId/stuart/quote',
      method: RESOURCES_VARS.API_METHODS.GET,
      transformResponse: parseData,
      params: {
        orderId: '@orderId'
      }
    },
    createJob: {
      url: ENV_VARS.API_URL + '/orders/:orderId/stuart/jobs',
      method: RESOURCES_VARS.API_METHODS.POST,
      transformResponse: parseData,
      params: {
        orderId: '@orderId'
      }
    },
    getJob: {
      url: ENV_VARS.API_URL + '/orders/:orderId/stuart/jobs/:jobId',
      method: RESOURCES_VARS.API_METHODS.GET,
      transformResponse: parseData,
      params: {
        orderId: '@orderId',
        jobId: '@jobId'
      }
    },
    cancelJob: {
      url: ENV_VARS.API_URL + '/orders/:orderId/stuart/jobs/:jobId',
      method: RESOURCES_VARS.API_METHODS.DELETE,
      transformResponse: parseData,
      params: {
        orderId: '@orderId',
        jobId: '@jobId'
      }
    },
    addConsumptionMode: {
      method: RESOURCES_VARS.API_METHODS.POST,
      url: ENV_VARS.API_URL + '/stuart/:stuartId/consumption_modes/:consumptionModeId',
      params: {
        stuartId: '@stuartId',
        consumptionModeId: '@consumptionModeId'
      },
      transformResponse: parseData
    },
    deleteConsumptionMode: {
      method: RESOURCES_VARS.API_METHODS.DELETE,
      url: ENV_VARS.API_URL + '/stuart/:stuartId/consumption_modes/:consumptionModeId',
      params: {
        stuartId: '@stuartId',
        consumptionModeId: '@consumptionModeId'
      },
      transformResponse: parseData
    }
  });
}
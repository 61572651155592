"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = _ngInjectExport;
_ngInjectExport.$inject = ["localStorageService"];

function _ngInjectExport(localStorageService) {
  'ngInject';

  var self = this;

  self.put = function (name, value) {
    localStorageService.cookie.set(name, value);
  };

  self.get = function (name) {
    return localStorageService.cookie.get(name);
  };
}
export default function (statePromise, StuartV2, $stateParams) {
    'ngInject';

    const vm = this;
    vm.validate = function () {
        StuartV2.cancelJob({
            orderId: $stateParams.orderId,
        })
            .$promise.then(function () {
                statePromise.resolve({ location: false }, 'stuartV2Cancel');
            })
            .catch(function (e) {
                statePromise.reject('stuartV2Cancel', { location: false }, e);
            });
    };
    vm.cancel = function () {
        statePromise.reject('stuartV2Cancel', { location: false });
    };
}

import availabilityResourceModule from '@innovorder/angularcore/core/resources/availability/availability.module';
import productResourceModule from '@innovorder/angularcore/core/resources/product/product.module';
import flagsModule from '@innovorder/angularcore/core/flags/flags.module';
import angular from 'angular';
import productController from './product.controller';
import productComponent from './product.component';

const dependencies = [availabilityResourceModule, productResourceModule, flagsModule];

angular
    .module('app.home.availability.product', dependencies)
    .controller('ProductController', productController)
    .component('product', productComponent);
export default angular.module('app.home.availability.product').name;

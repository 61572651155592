export default function() {
  'ngInject';

  return {
    require: 'ngModel',
    restrict: 'A',
    link: (scope, elm, attrs, ctrl) => {
      const patterns = {
        birtdate: /^(0[1-9]|[12][0-9]|3[01])[- \/.](0[1-9]|1[012])[- \/.](19|20)\d\d$/,
        phone: /^[\\+]?[0-9]{9,15}$/,
        password: /^.{12,128}$/,
        email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        alphanumerical: /^[a-zA-Z0-9]*$/,
        alphanumericalWithDiacritics: /^[A-zÀ-ÿ0-9 ]*$/,
        alphanumericalWithSpacesAndUnderscores: /^[A-zÀ-ÿ0-9_ ]*$/,
        number: /^[0-9]*$/,
        letters: /^[a-zA-Z]*$/,
        hostname: /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
        googleAnalyticId: /UA-([0-9]+)-([0-9]+)$/,
        googleTagManagerId: /GTM-([\w]+)$/,
        googleSiteTagId: /AW-([0-9]+)$/,
        multiEmail: /^(((([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})))(,|$))*$/,
        postpayment:/^0{1}$/,

      };
      if (patterns[attrs.fioPattern]) {
        ctrl.$validators[attrs.fioPattern] = modelValue =>
          (!attrs.required && ctrl.$isEmpty(modelValue)) ||
          patterns[attrs.fioPattern].test(modelValue);
      }
    },
  };
}

import tabsView from './tabs.view.html';
/**
 *  Sidenav directive
 *  ---------------------------------------------------
 */
export default function ($templateCache, $sce, $fioHelper) {
  'ngInject';

  function link(scope, element, attrs) {
    scope.markerPosition = {
      left: 0,
      right: 0,
    };
  }

  function controller($scope, $element, $timeout, $sce) {
    'ngInject';
    $scope.tabs = {};
    $scope.selectedTab = -1;

    $scope.slideMarker = function (elt) {
      var left =
        $fioHelper.offset(elt[0]).left - $fioHelper.offset($element[0]).left;

      $scope.transitionClass =
        left < $scope.markerPosition.left
          ? 'transition-left'
          : 'transition-right';

      $scope.markerPosition = {
        left: left,
        right:
          $fioHelper.outerWidth($element[0]) -
          (left + $fioHelper.outerWidth(elt[0])),
      };
    };

    $scope.selectTab = function (index) {
      $scope.selectedTab = index;
    };

    this.insertTab = function (label, content) {
      $scope.tabs[label] = $sce.trustAsHtml(content);
    };

    var watchSelectedTab = function () {
      return $scope.selectedTab;
    };
    $scope.$watch(watchSelectedTab, function (newValue) {
      if (newValue >= 0) {
        $scope.slideMarker(
          angular.element(
            $element[0].querySelectorAll('fio-tab-item')[newValue],
          ),
        );
      }
    });

    /* First loading : select the first tab 50 ms after everything is loaded to avoid render glitch */
    $scope.$watch(
      function () {
        return $element[0].querySelectorAll('fio-tab-item').length;
      },
      function (value) {
        if (value > 0 && value == Object.keys($scope.tabs).length) {
          $timeout(function () {
            $scope.selectedTab = 0;
          }, 50);
        }
      },
    );
  }

  return {
    scope: {},
    link: link,
    controller: controller,
    restrict: 'E',
    transclude: true,
    template: $templateCache.get(tabsView),
  };
}

// .directive('fioTabItem', function($templateCache) {

// 	function link(scope, element, attrs, fioTabsCtrl) {
// 	}

// 	return {
// 		link: link,
// 		restrict: 'E',
// 		require: '^fioTabs',
// 		scope: true
// 	};
// });

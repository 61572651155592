"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

require("core-js/modules/es.function.name");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = void 0;

var _filter_big_decimal = _interopRequireDefault(require("./../filter_big_decimal/filter_big_decimal.module"));

var _order_cart = _interopRequireDefault(require("./order_cart.component"));

var _order_product = _interopRequireDefault(require("./order_product.component"));

var _order_step = _interopRequireDefault(require("./order_step.component"));

var _order_step_product = _interopRequireDefault(require("./order_step_product.component"));

var _order_cart2 = _interopRequireDefault(require("./order_cart.controller"));

var _angular = _interopRequireDefault(require("angular"));

var dependencies = [_filter_big_decimal.default];

_angular.default.module('core.orderCart', dependencies).controller('OrderCartController', _order_cart2.default).component('orderCart', _order_cart.default).component('orderProduct', _order_product.default).component('orderStep', _order_step.default).component('orderStepProduct', _order_step_product.default);

var _default = _angular.default.module('core.orderCart').name;

exports.default = _default;
"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

require("core-js/modules/es.function.name");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = void 0;

var _moneylinev = _interopRequireDefault(require("./moneylinev2.service"));

var _moneylinev2 = _interopRequireDefault(require("./moneylinev2.constants"));

var _log = _interopRequireDefault(require("../log/log.module"));

var _angular = _interopRequireDefault(require("angular"));

var dependencies = [_log.default];

_angular.default.module('core.moneyline.v2', dependencies).service('moneylineServiceV2', _moneylinev.default).constant('MONEYLINE_V2', _moneylinev2.default);

var _default = _angular.default.module('core.moneyline.v2').name;

exports.default = _default;
"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = _ngInjectExport;

var _file_response_transformers = require("../../utils/file_response_transformers");

_ngInjectExport.$inject = ["$resource", "$log", "RESOURCES_VARS", "ENV_VARS"];

function _ngInjectExport($resource, $log, RESOURCES_VARS, ENV_VARS) {
  'ngInject';

  return $resource(ENV_VARS.API_URL + '/orders/summary/:exportType', {}, {
    getWebRecap: {
      method: RESOURCES_VARS.API_METHODS.GET,
      headers: {
        accept: 'application/pdf'
      },
      responseType: 'arraybuffer',
      cache: false,
      transformResponse: _file_response_transformers.transformPdfResponse
    }
  });
}
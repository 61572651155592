"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

require("core-js/modules/es.object.to-string");

require("core-js/modules/es.promise");

require("core-js/modules/es.promise.finally");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = _ngInjectExport;
_ngInjectExport.$inject = ["$q", "$interval", "moneylineServiceV1", "moneylineServiceV2", "MONEYLINE", "MONEYLINE_V1", "MONEYLINE_V2"];

function _ngInjectExport($q, $interval, moneylineServiceV1, moneylineServiceV2, MONEYLINE, MONEYLINE_V1, MONEYLINE_V2) {
  'ngInject'; // Initialization

  var logService = console;
  var moneylineService = this;
  moneylineService.isRunning = false;
  moneylineService.currentState = MONEYLINE.INIT_STATE;
  moneylineService.currentParameters = MONEYLINE.INIT_PARAMS;
  moneylineService.apiVersion = MONEYLINE.VERSION_1;

  moneylineService.isMoneyLineRunningV2 = function () {
    return moneylineService.isRunning && moneylineService.apiVersion === MONEYLINE.VERSION_2;
  }; // Private


  var _currentSupportedClass = moneylineServiceV1; // Start/Stop/Detect/UpdateState

  moneylineService.start = function () {
    var returnPayload = $q.defer();
    logService.info('[moneylineService] Starting moneyline service... ');
    moneylineService.detectVersion().then(function (supportedClass) {
      _currentSupportedClass = supportedClass;
      return moneylineService.updateState();
    }).then(function () {
      logService.info('[moneylineService] updateState OK - Service running ');
      moneylineService.isRunning = true;
      moneylineService.backgroundLoop = $interval(function () {
        moneylineService.updateState();
        logService.info('[moneylineService] backgroundLoop has run - currentState = ', moneylineService.currentState);
      }, MONEYLINE.LOOP_DELAY_MS);
      returnPayload.resolve(true);
    }).catch(function (err) {
      logService.info('[moneylineService] updateState KO - Service not running', err);
      moneylineService.isRunning = false;
      returnPayload.resolve(false);
    });
    return returnPayload.promise;
  };

  moneylineService.stop = function () {
    moneylineService.isRunning = false;
    $interval.cancel(moneylineService.backgroundLoop);
  };

  moneylineService.detectVersion = function () {
    var returnPayload = $q.defer();
    logService.info('[moneylineService] Detecting version... ');
    moneylineServiceV2.getConfiguration().then(function (data) {
      moneylineService.apiVersion = MONEYLINE.VERSION_2;
      moneylineService.currentParameters = data;
      logService.info('[moneylineService] VERSION 2 detected');
      logService.info('[moneylineService] moneylineService.currentParameters = ', moneylineService.currentParameters);
      logService.info('[moneylineService] moneylineService.currentState = ', moneylineService.currentState);
      returnPayload.resolve(moneylineServiceV2);
    }).catch(function (err) {
      moneylineService.apiVersion = MONEYLINE.VERSION_1;
      logService.info('[moneylineService] Could be VERSION 1 - need to check if getState is present');
      logService.info('[moneylineService] moneylineService.currentParameters = ', moneylineService.currentParameters);
      logService.info('[moneylineService] moneylineService.currentState = ', moneylineService.currentState);
      returnPayload.resolve(moneylineServiceV1); // always resolve here
    });
    return returnPayload.promise;
  }; // Template pattern for versioning


  moneylineService.updateState = function () {
    var returnPayload = $q.defer();
    logService.info('[moneylineService] updating status');

    _currentSupportedClass.getState().then(function (data) {
      moneylineService.currentState = data;
      returnPayload.resolve(data);
    }).catch(function (err) {
      returnPayload.reject(err);
    });

    return returnPayload.promise;
  };

  moneylineService.isPaymentTerminalEnabled = function () {
    return _currentSupportedClass.isPaymentTerminalEnabled(moneylineService.currentState, moneylineService.currentParameters);
  };

  moneylineService.isConecsEnabled = function (totalPrice) {
    return _currentSupportedClass.isConecsEnabled(totalPrice, moneylineService.currentState, moneylineService.currentParameters);
  };

  moneylineService.isPrinterEnabled = function () {
    return _currentSupportedClass.isPrinterEnabled(moneylineService.currentState, moneylineService.currentParameters);
  };

  moneylineService.printTicket = function (serializedTicketArray) {
    return _currentSupportedClass.printTicket(serializedTicketArray);
  };

  moneylineService.pay = function (orderId, amount, paymentMode) {
    if (moneylineService.apiVersion === MONEYLINE.VERSION_2) {
      return moneylineService.startTransaction().then(function () {
        return moneylineService.startPayment(orderId, amount, paymentMode);
      }).finally(function (paymentResult) {
        moneylineService.stopTransaction();
        return paymentResult;
      });
    }

    return moneylineService.startPayment(orderId, amount, paymentMode);
  };

  moneylineService.startPayment = function (orderId, amount, paymentMode) {
    return _currentSupportedClass.startPayment(orderId, amount, paymentMode);
  };

  moneylineService.startPaymentConecs = function (orderId, amount) {
    return _currentSupportedClass.startPaymentConecs(orderId, amount);
  };

  moneylineService.startTransaction = function () {
    return _currentSupportedClass.startTransaction();
  };

  moneylineService.stopTransaction = function () {
    return _currentSupportedClass.stopTransaction();
  };

  moneylineService.modeMaintenance = function () {
    return _currentSupportedClass.modeMaintenance();
  };

  moneylineService.startReadContactless = function () {
    return _currentSupportedClass.startReadContactless();
  };

  moneylineService.stopReadContactless = function () {
    return _currentSupportedClass.stopReadContactless();
  };
}
export default function (networkService) {
    'ngInject';

    const vm = this;

    vm.$onInit = function () {
        networkService.register(() => {
            vm.offline = !networkService.onLine;
        });
    };
    vm.reload = function () {
        if (networkService.onLine) {
            // eslint-disable-next-line no-undef, no-restricted-globals
            location.replace('/');
        }
    };
}

"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.PRINT_SOURCE = exports.PRINT_STATUS = void 0;
var PRINT_STATUS = {
  PRINTED: 'printed',
  PENDING: 'pending',
  ERROR: 'error'
};
exports.PRINT_STATUS = PRINT_STATUS;
var PRINT_SOURCE = {
  RECEPTION: 'reception',
  KIOSK: 'kiosk'
};
exports.PRINT_SOURCE = PRINT_SOURCE;
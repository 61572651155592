"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = _ngInjectExport;
_ngInjectExport.$inject = ["webBridge", "BRIDGE_MESSAGE"];

function _ngInjectExport(webBridge, BRIDGE_MESSAGE) {
  'ngInject';

  this.isLogEnabled = false;

  function info(message, payload) {
    if (!this.isLogEnabled) return;
    console.info(message, payload);
    webBridge.emitMessage(BRIDGE_MESSAGE.LOG, {
      message: message,
      payload: payload
    });
  }

  function space() {
    if (!this.isLogEnabled) return;
    webBridge.emitMessage(BRIDGE_MESSAGE.LOG, 'space');
  }

  function setIsLogEnabled(isLogEnabled) {
    this.isLogEnabled = isLogEnabled;
  }

  function init(isLogEnabled) {
    this.setIsLogEnabled(isLogEnabled);
  }

  return {
    info: info,
    space: space,
    init: init,
    setIsLogEnabled: setIsLogEnabled
  };
}
import searchBarView from './search_bar.view.html';
/**
 *  Searchbar directive
 *  ---------------------------------------------------
 */
export default function($templateCache, $fioHelper) {
  'ngInject';

  // var display_autocomplete = true;

  function link(scope, element, attrs, ctrl) {
    scope.placeholder = attrs.placeholder;

    scope.onChange = function() {
      ctrl.$setViewValue(scope.searchInput);
    };

    // scope.keySelection = -1;

    // if (!attrs.dictionary) {
    //     scope.forceNoAutocomplete = true;
    // }

    // Do not display autocomplete suggestion if search input not empty at first loading
    // scope.displayAutocomplete = (scope.searchInput == '');

    // scope.complete = function (value) {
    //     scope.searchInput = value;
    //     scope.displayAutocomplete = false;
    //     scope.keySelection = -1;
    // };

    // scope.update = function () {
    //     // if (!scope.displayAutocomplete) {
    //     //     scope.displayAutocomplete = true;
    //     // }
    //     console.log('triggerd');
    //     if (scope.onChange) scope.onChange(scope.searchInput)
    // };

    // scope.keyBinding = function (elt) {
    //     $fioHelper.eventListen(elt[0], 'keydown', function (e) {
    //         switch (e.keyCode) {
    //             case 38:
    //                 scope.$apply(function () {
    //                     if (scope.keySelection > 0) {
    //                         scope.keySelection--;
    //                     }
    //                 });
    //                 break;
    //
    //             case 40:
    //                 scope.$apply(function () {
    //                     if (scope.keySelection < scope.filtereddictionary.length - 1) {
    //                         scope.keySelection++;
    //                     }
    //                 });
    //                 break;
    //
    //             case 13:
    //                 scope.$apply(function () {
    //                     scope.complete(scope.filtereddictionary[scope.keySelection]);
    //                 });
    //                 break;
    //
    //             default:
    //                 return;
    //         }
    //         e.preventDefault();
    //     });
    // };
    //
    // scope.keyBinding(element);

    // scope.$watch(function () {
    //     return scope.searchInput;
    // }, function (newValue) {
    //     scope.keySelection = -1;
    // });
  }

  return {
    link: link,
    restrict: 'E',
    require: '^ngModel',
    scope: {
      dictionary: '=',
      placeholder: '@',
    },
    template: $templateCache.get(searchBarView),
  };
}

import dataInsightView from './data_insight.view.html';
export default function($templateCache, $interval) {
  function link(scope, element, attrs, $backdrop) {}

  return {
    link: link,
    restrict: 'E',
    transclude: true,
    scope: {
      icon: '@',
      value: '=',
      unit: '@',
      title: '@',
    },
    template: $templateCache.get(dataInsightView),
  };
}

export default function (
    Availability,
    Product,
    $log,
    $state,
    availabilityLoggerService,
    statePromise,
    availabilityService,
    FlagsManager,
    CONSUMPTION_MODE_FLAGS,
) {
    'ngInject';

    const vm = this;
    let newFlagManager;

    const MAX_PRODUCT_NAME_LENGTH = 85;

    const channelsAttributes = {
        1: 'kioskAvailability',
        2: 'webAvailability',
        9: 'uberEatsAvailability',
    };

    vm.$onInit = function () {
        newFlagManager = new FlagsManager(CONSUMPTION_MODE_FLAGS, vm.product.consumptionFlags).toObject();
        vm.productName = vm.product.name;
        if (vm.productName.length > MAX_PRODUCT_NAME_LENGTH) {
            vm.productName = `${vm.productName.slice(0, MAX_PRODUCT_NAME_LENGTH)}...`;
        }
    };

    vm.isDisplayed = function () {
        const { counterFilter, searchFilter, showOnlyDisabledProducts, selectedFilters } = availabilityService;

        // No filter : show the product
        if (!counterFilter && !showOnlyDisabledProducts && !searchFilter) {
            return true;
        }

        // Otherwise :
        // Eliminate all products that are available on both channels, if the filter is switched on.
        if (showOnlyDisabledProducts && vm.product.webAvailability && vm.product.kioskAvailability) {
            return false;
        }

        const isProductConsumptionModeMatching =
            Object.keys(selectedFilters).filter((key) => selectedFilters[key] && newFlagManager[key]).length > 0;

        const isProductMatchingFilter = () => {
            // Check for matching search filter (case-insensitive)
            if (searchFilter && !vm.productName.toLowerCase().includes(searchFilter.toLowerCase())) {
                return false;
            }
            // Check for matching consumption mode filters
            if (counterFilter && !isProductConsumptionModeMatching) {
                return false;
            }

            return true; // Matches all filters
        };

        return isProductMatchingFilter();
    };

    vm.onChange = function (channel) {
        $log.info('AVAILABILITY-PRODUCT-EVENT', {
            productId: vm.product.productId,
        });
        Availability.updateProduct({
            actionId: vm.product.productId,
            availability: vm.product[channelsAttributes[channel]],
            channel,
            menuId: vm.product.menuId,
        }).$promise.then(
            function (product) {
                availabilityLoggerService.log(
                    {
                        source: 'reception',
                        codeLocalizationLevel1: 'components/availability/product/product.controller.js',
                        codeLocalizationLevel2: 'vm.onChange(channel)',
                        codeLocalizationLevel3: 'Availability product reception event emitted',
                        channel,
                        productId: vm.product.productId,
                        menuId: vm.product.menuId,
                    },
                    vm.product.menuId,
                );
                vm.product.webAvailability = product.webAvailability;
                vm.product.kioskAvailability = product.kioskAvailability;
                vm.update(channel);
            },
            function (error) {
                availabilityLoggerService.log(
                    {
                        source: 'reception',
                        codeLocalizationLevel1: 'components/availability/product/product.controller.js',
                        codeLocalizationLevel2: 'vm.onChange(channel)',
                        codeLocalizationLevel3: 'Availability product reception event error',
                        error: JSON.stringify(error),
                        channel,
                        productId: vm.product.productId,
                        menuId: vm.product.menuId,
                    },
                    vm.product.menuId,
                );
                $state.go(
                    'home.availability',
                    {},
                    {
                        reload: 'home.availability',
                    },
                );
            },
        );
    };

    vm.select = function () {
        if (vm.selected === vm.product.signature) {
            vm.selected = null;
        } else {
            Product.get({
                menuId: vm.product.menuId,
                signature: vm.product.signature,
            }).$promise.then(
                function (product) {
                    vm.selectedStep = null;
                    vm.steps = product.productStep;
                    vm.selected = vm.product.signature;
                },
                // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
                function (error) {
                    $state.go(
                        'home.availability',
                        {},
                        {
                            reload: 'home.availability',
                        },
                    );
                },
            );
        }
    };

    vm.editQuantity = function () {
        statePromise
            .go(
                'home.availability.product_quantity',
                {
                    product: vm.product,
                },
                {
                    location: false,
                },
                'productQuantity',
            )
            .then(function (quantity) {
                // eslint-disable-next-line eqeqeq
                if (quantity || quantity == 0) {
                    Product.updateQuantity({
                        signature: vm.product.signature,
                        menuId: vm.product.menuId,
                        quantity,
                    }).$promise.then(function (data) {
                        vm.product.currentQuantity = data.currentQuantity;
                    });
                }
            });
    };
}

export default function (statePromise) {
    'ngInject';

    const vm = this;
    vm.close = function () {
        statePromise.reject('productQuantity');
    };
    vm.confirm = function () {
        statePromise.resolve(vm.quantity, 'productQuantity');
    };
}

"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

require("core-js/modules/es.array.join");

require("core-js/modules/es.date.to-string");

require("core-js/modules/es.function.name");

require("core-js/modules/es.number.constructor");

require("core-js/modules/es.object.to-string");

require("core-js/modules/es.regexp.exec");

require("core-js/modules/es.regexp.to-string");

require("core-js/modules/es.string.split");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.flattenProduct = flattenProduct;
exports.addSingleProduct = addSingleProduct;
exports.getProductTags = getProductTags;
exports.shouldPrintByTag = shouldPrintByTag;
exports.convertPricesToLines = convertPricesToLines;
exports.convertTpeTicketToLines = convertTpeTicketToLines;
exports.addProductsHeader = addProductsHeader;
exports.addVatDetailHeaders = addVatDetailHeaders;
exports.convertProductToLine = convertProductToLine;

var _slice = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/slice"));

var _indexOf = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/index-of"));

var _keys = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/keys"));

var _typeof2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/typeof"));

var _concat = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/concat"));

var _map = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/map"));

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/toConsumableArray"));

var _filter = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/filter"));

var _forEach = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/for-each"));

var _parseFloat2 = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/parse-float"));

var _intersection = _interopRequireDefault(require("lodash/intersection"));

var _isEmpty = _interopRequireDefault(require("lodash/isEmpty"));

var _some = _interopRequireDefault(require("lodash/some"));

var _constants = require("../constants");

var _helpers = require("../helpers");

function formatQuantity(n) {
  n = n.toString();

  if (n.length < 3) {
    n += Array(3 - n.length + 1).join(' ');
  }

  return (0, _helpers.alignText)("".concat(n), '', ' ', 6);
}

var formatPrice = function formatPrice(price) {
  return "".concat((price / 100).toLocaleString('fr-FR', {
    style: 'currency',
    currency: 'EUR'
  }), " \u20AC");
};

var formatVat = function formatVat(vat) {
  var newVat = (0, _parseFloat2["default"])(vat / 100);
  return "".concat(newVat.toLocaleString(undefined, {
    style: 'percent',
    minimumFractionDigits: 2
  }), " %");
};

function convertLineProduct(product, displayPrice, hasParent, depth, displayVat, vatMap) {
  var _context, _context2;

  displayPrice = displayPrice !== undefined ? displayPrice : true;
  hasParent = hasParent !== undefined;
  depth = depth !== undefined ? depth : 0;
  var output = addSingleProduct(product, [], displayPrice, hasParent, depth, undefined, displayVat, vatMap);
  (0, _forEach["default"])(_context = (0, _filter["default"])(_context2 = product.steps).call(_context2, function (step) {
    return !(0, _isEmpty["default"])(step.products);
  })).call(_context, function (step) {
    var _context3;

    (0, _forEach["default"])(_context3 = step.products).call(_context3, function (stepProduct) {
      output.push.apply(output, (0, _toConsumableArray2["default"])(convertLineProduct(stepProduct, displayPrice, true, depth + 1, displayVat, vatMap)));
    });
  });
  return output;
}

function flattenProduct(product, displayPrice, hasParent, depth, parentQuantity) {
  var _context4, _context5;

  displayPrice = displayPrice !== undefined ? displayPrice : true;
  hasParent = hasParent !== undefined;
  depth = depth !== undefined ? depth : 0;
  var output = [{
    product: product,
    displayPrice: displayPrice,
    hasParent: hasParent,
    depth: depth,
    parentQuantity: parentQuantity
  }];
  (0, _forEach["default"])(_context4 = (0, _filter["default"])(_context5 = product.steps).call(_context5, function (step) {
    return !(0, _isEmpty["default"])(step.products);
  })).call(_context4, function (step) {
    var _context6;

    (0, _forEach["default"])(_context6 = step.products).call(_context6, function (stepProduct) {
      output.push.apply(output, (0, _toConsumableArray2["default"])(flattenProduct(stepProduct, displayPrice, true, depth + 1, product.quantity)));
    });
  });
  return output;
}

function deepShouldPrintByTag(tagsFilter, flattenedProduct) {
  return (0, _some["default"])((0, _map["default"])(flattenedProduct).call(flattenedProduct, function (flatProduct) {
    return shouldPrintByTag(tagsFilter, flatProduct.product);
  }));
}

function convertFlattenProductWithTag(tagsFilter, flattenedProduct, displayVat, vatMap) {
  var _context7;

  var output = [];
  var addedProductToCartTicketCount = 0;
  (0, _forEach["default"])(_context7 = (0, _filter["default"])(flattenedProduct).call(flattenedProduct, function (_ref) {
    var product = _ref.product;
    return shouldPrintByTag(tagsFilter, product);
  })).call(_context7, function (_ref2, index) {
    var product = _ref2.product,
        displayPrice = _ref2.displayPrice,
        hasParent = _ref2.hasParent,
        depth = _ref2.depth,
        parentQuantity = _ref2.parentQuantity;

    if (hasParent && parentQuantity) {
      var newQuantity = parentQuantity * product.quantity;
      product.quantity = newQuantity;
    }

    addSingleProduct(product, output, displayPrice, hasParent, 1, ['left', '0', '0', '1'], displayVat, vatMap);
    addedProductToCartTicketCount += 1;
    var idx = index;
    var minDepth = depth + 1;

    while (flattenedProduct[idx + 1] !== undefined && minDepth <= flattenedProduct[idx + 1].depth) {
      if (shouldPrintByTag(tagsFilter, flattenedProduct[idx + 1].product) || getProductTags(flattenedProduct[idx + 1].product).length === 0) {
        var relativeDepth = flattenedProduct[idx + 1].depth - (minDepth - 1 - 1);
        addSingleProduct(flattenedProduct[idx + 1].product, output, flattenedProduct[idx + 1].displayPrice, flattenedProduct[idx + 1].hasParent, relativeDepth, undefined, displayVat, vatMap);
        addedProductToCartTicketCount += 1;
      }

      idx += 1;
    }
  });
  return {
    output: output,
    addedProductToCartTicketCount: addedProductToCartTicketCount
  };
}

function addProductByDepth(currentSchemaLine, flattenedProduct, usedProductIndex, numbering, numberingCurrentIndex, highlightQuantity, tmpOutput, lineDepth, lineStyle, addedProductToCartTicket, displayChildren, displayVat, vatMap) {
  var searchByDepth = Number(currentSchemaLine.attributes['by-depth']);

  for (var p = 0; p < flattenedProduct.length; p++) {
    if (Number(flattenedProduct[p].depth) === searchByDepth && usedProductIndex[p] !== true) {
      if (numbering) {
        var _context8;

        flattenedProduct[p].product.name = (0, _concat["default"])(_context8 = "".concat(numberingCurrentIndex, ". ")).call(_context8, flattenedProduct[p].product.name);
        numberingCurrentIndex += 1;
      }

      if (highlightQuantity) {
        flattenedProduct[p].product.highlightQuantity = true;
      }

      addSingleProduct(flattenedProduct[p].product, tmpOutput, flattenedProduct[p].displayPrice, flattenedProduct[p].hasParent, lineDepth, lineStyle, displayVat, vatMap);
      usedProductIndex[p] = true;
      addedProductToCartTicket += 1;

      if (displayChildren) {
        var idx = p;
        var minDepth = flattenedProduct[idx].depth + 1;

        while (flattenedProduct[idx + 1] !== undefined && minDepth <= flattenedProduct[idx + 1].depth) {
          if (usedProductIndex[idx + 1] !== true) {
            var relativeDepth = flattenedProduct[idx + 1].depth - (minDepth - 1 - lineDepth);
            addSingleProduct(flattenedProduct[idx + 1].product, tmpOutput, flattenedProduct[idx + 1].displayPrice, flattenedProduct[idx + 1].hasParent, relativeDepth, undefined, displayVat, vatMap);
            usedProductIndex[idx + 1] = true;
            addedProductToCartTicket += 1;
          }

          idx += 1;
        }
      }
    }
  }

  return {
    numberingCurrentIndex: numberingCurrentIndex,
    addedProductToCartTicket: addedProductToCartTicket
  };
}

function addProductByLabel(currentSchemaLine, flattenedProduct, usedProductIndex, numbering, numberingCurrentIndex, highlightQuantity, tmpOutput, lineDepth, lineStyle, addedProductToCartTicket, displayChildren, displayVat, vatMap) {
  var searchByLabel = String(currentSchemaLine.attributes['by-label']);

  for (var p = 0; p < flattenedProduct.length; p++) {
    if (shouldPrintByTag([searchByLabel], flattenedProduct[p].product) && usedProductIndex[p] !== true) {
      if (numbering) {
        var _context9;

        flattenedProduct[p].product.name = (0, _concat["default"])(_context9 = "".concat(numberingCurrentIndex, ". ")).call(_context9, flattenedProduct[p].product.name);
        numberingCurrentIndex += 1;
      }

      if (highlightQuantity) {
        flattenedProduct[p].product.highlightQuantity = true;
      }

      addSingleProduct(flattenedProduct[p].product, tmpOutput, flattenedProduct[p].displayPrice, flattenedProduct[p].hasParent, lineDepth, lineStyle, displayVat, vatMap);
      usedProductIndex[p] = true;
      addedProductToCartTicket += 1;

      if (displayChildren) {
        var idx = p;
        var minDepth = flattenedProduct[idx].depth + 1;

        while (flattenedProduct[idx + 1] !== undefined && minDepth <= flattenedProduct[idx + 1].depth) {
          if (usedProductIndex[idx + 1] !== true) {
            var relativeDepth = flattenedProduct[idx + 1].depth - (minDepth - 1 - lineDepth);
            addSingleProduct(flattenedProduct[idx + 1].product, tmpOutput, flattenedProduct[idx + 1].displayPrice, flattenedProduct[idx + 1].hasParent, relativeDepth, displayVat, vatMap);
            usedProductIndex[idx + 1] = true;
            addedProductToCartTicket += 1;
          }

          idx += 1;
        }
      }
    }
  }

  return {
    numberingCurrentIndex: numberingCurrentIndex,
    addedProductToCartTicket: addedProductToCartTicket
  };
}

function addProductPicker(loopInitCountAddedProductToCartTicket, addedProductToCartTicket, lineAfter, lineDepth, numbering, numberingCurrentIndex, displayChildren, highlightQuantity, currentSchemaLine, lineStyle, flattenedProduct, usedProductIndex, tmpOutput, displayVat, vatMap) {
  var lineAlignment = 'left';
  var lineEmphasis = '0';
  var lineUnderline = '0';
  var lineSize = '1';
  loopInitCountAddedProductToCartTicket = addedProductToCartTicket;
  lineAfter = 0;
  lineDepth = 0;
  numbering = false;
  numberingCurrentIndex = 1;
  displayChildren = true;
  highlightQuantity = false;

  if (currentSchemaLine.attributes.emphasis !== undefined) {
    lineEmphasis = String(currentSchemaLine.attributes.emphasis);
  }

  if (currentSchemaLine.attributes.underline !== undefined) {
    lineUnderline = String(currentSchemaLine.attributes.underline);
  }

  if (currentSchemaLine.attributes.alignment !== undefined) {
    lineAlignment = String(currentSchemaLine.attributes.alignment);
  }

  if (currentSchemaLine.attributes.size !== undefined) {
    lineSize = String(currentSchemaLine.attributes.size);
  }

  if (currentSchemaLine.attributes.depth !== undefined) {
    lineDepth = String(currentSchemaLine.attributes.depth);
  }

  if (currentSchemaLine.attributes['line-after'] !== undefined) {
    lineAfter = Number(currentSchemaLine.attributes['line-after']);
  }

  if (currentSchemaLine.attributes.numbering !== undefined) {
    numbering = true;
  }

  if (currentSchemaLine.attributes['highlight-quantity'] !== undefined) {
    highlightQuantity = true;
  }

  lineStyle = [lineAlignment, lineEmphasis, lineUnderline, lineSize]; // Compile Search query

  if (currentSchemaLine.attributes['display-children'] !== undefined && Number(currentSchemaLine.attributes['display-children']) === 0) {
    displayChildren = false;
  }

  if (currentSchemaLine.attributes['by-depth'] !== undefined) {
    var _addProductByDepth = addProductByDepth(currentSchemaLine, flattenedProduct, usedProductIndex, numbering, numberingCurrentIndex, highlightQuantity, tmpOutput, lineDepth, lineStyle, addedProductToCartTicket, displayChildren, displayVat, vatMap);

    numberingCurrentIndex = _addProductByDepth.numberingCurrentIndex;
    addedProductToCartTicket = _addProductByDepth.addedProductToCartTicket;
  } else if (currentSchemaLine.attributes['by-label'] !== undefined) {
    var _addProductByLabel = addProductByLabel(currentSchemaLine, flattenedProduct, usedProductIndex, numbering, numberingCurrentIndex, highlightQuantity, tmpOutput, lineDepth, lineStyle, addedProductToCartTicket, displayChildren, displayVat, vatMap);

    numberingCurrentIndex = _addProductByLabel.numberingCurrentIndex;
    addedProductToCartTicket = _addProductByLabel.addedProductToCartTicket;
  }

  return {
    loopInitCountAddedProductToCartTicket: loopInitCountAddedProductToCartTicket,
    addedProductToCartTicket: addedProductToCartTicket,
    lineAfter: lineAfter,
    lineDepth: lineDepth,
    numbering: numbering,
    numberingCurrentIndex: numberingCurrentIndex,
    displayChildren: displayChildren,
    highlightQuantity: highlightQuantity,
    lineStyle: lineStyle
  };
}

function getProductWithSchema(schemaLines, flattenedProduct, addedProductToCartTicket, tmpOutput, displayVat, vatMap) {
  var usedProductIndex = {};
  var loopInitCountAddedProductToCartTicket;
  var numbering;
  var numberingCurrentIndex;
  var lineDepth;
  var lineAfter;
  var highlightQuantity;
  var displayChildren;
  var lineStyle;
  (0, _forEach["default"])(schemaLines).call(schemaLines, function (currentSchemaLine) {
    if (currentSchemaLine.name === 'productPicker') {
      // Compile Style
      var _addProductPicker = addProductPicker(loopInitCountAddedProductToCartTicket, addedProductToCartTicket, lineAfter, lineDepth, numbering, numberingCurrentIndex, displayChildren, highlightQuantity, currentSchemaLine, lineStyle, flattenedProduct, usedProductIndex, tmpOutput, displayVat, vatMap);

      loopInitCountAddedProductToCartTicket = _addProductPicker.loopInitCountAddedProductToCartTicket;
      addedProductToCartTicket = _addProductPicker.addedProductToCartTicket;
      lineAfter = _addProductPicker.lineAfter;
      lineDepth = _addProductPicker.lineDepth;
      numbering = _addProductPicker.numbering;
      numberingCurrentIndex = _addProductPicker.numberingCurrentIndex;
      displayChildren = _addProductPicker.displayChildren;
      highlightQuantity = _addProductPicker.highlightQuantity;
      lineStyle = _addProductPicker.lineStyle;
    } else if (currentSchemaLine.name === 'leftProduct') {
      for (var p = 0; p < flattenedProduct.length; p++) {
        if (usedProductIndex[p] !== true) {
          if (numbering) {
            var _context10;

            flattenedProduct[p].product.name = (0, _concat["default"])(_context10 = "".concat(numberingCurrentIndex, ". ")).call(_context10, flattenedProduct[p].product.name);
            numberingCurrentIndex += 1;
          }

          if (highlightQuantity) {
            flattenedProduct[p].product.highlightQuantity = true;
          }

          addSingleProduct(flattenedProduct[p].product, tmpOutput, flattenedProduct[p].displayPrice, flattenedProduct[p].hasParent, lineDepth, lineStyle, displayVat, vatMap);
          usedProductIndex[p] = true;
          addedProductToCartTicket += 1;

          if (displayChildren) {
            var idx = p;
            var minDepth = flattenedProduct[idx].depth + 1;

            while (flattenedProduct[idx + 1] !== undefined && minDepth <= flattenedProduct[idx + 1].depth) {
              if (usedProductIndex[idx + 1] !== true) {
                var relativeDepth = flattenedProduct[idx + 1].depth - (minDepth - 1 - lineDepth);
                addSingleProduct(flattenedProduct[idx + 1].product, tmpOutput, flattenedProduct[idx + 1].displayPrice, flattenedProduct[idx + 1].hasParent, relativeDepth, undefined, displayVat, vatMap);
                usedProductIndex[idx + 1] = true;
                addedProductToCartTicket += 1;
              }

              idx += 1;
            }
          }
        }
      }
    } else if (_constants.PRINTER_SCHEMA[currentSchemaLine.name]) {
      tmpOutput.push((0, _helpers.convertBasicPrinterInstruction)(currentSchemaLine));
    }

    if (lineAfter > 0 && addedProductToCartTicket > loopInitCountAddedProductToCartTicket) {
      for (var _p = lineAfter - 1; _p >= 0; _p--) {
        (0, _helpers.addLineBreaks)(tmpOutput, 1);
      }
    }
  });
  return addedProductToCartTicket;
}

function addSingleProduct(product, output, displayPrice, hasParent, depth, customAttributes, displayVat, vatMap) {
  var leftString;
  var rightString;
  var padding = '';
  customAttributes = customAttributes !== undefined ? customAttributes : [_constants.PRINTER_ALIGNMENT.LEFT, _constants.PRINTER_EMPHASIS.NORMAL, _constants.PRINTER_UNDERLINE.NORMAL, _constants.PRINTER_FONT_SIZE.NORMAL];

  if (hasParent) {
    var quantityDisplay = product.quantity > 1 ? formatQuantity(product.quantity) : '';
    var priceDisplay = product.unitPrice > 0 && displayPrice ? "(+".concat(product.unitPriceFormatted, ")") : ''; // Only for sub products

    if (Number(depth) === 1) {
      padding = _constants.PRINTER_FIRST_PADDING[customAttributes[3]];
    } else if (Number(depth) > 1) {
      padding = _constants.PRINTER_FIRST_PADDING[customAttributes[3]] + Array(Number(depth)).join(_constants.PRINTER_SUB_PADDING[customAttributes[3]]);
    }

    leftString = padding + quantityDisplay + product.name;
    rightString = displayPrice ? priceDisplay : '';
  } else {
    leftString = formatQuantity(product.quantity) + product.name;
    rightString = displayPrice ? product.priceFormatted : '';
  }

  rightString += displayVat && vatMap && vatMap[product.vat] ? " (".concat(vatMap[product.vat], ")") || '' : '';
  var maxLeftStringLength = _constants.PRINTER_LINE_LENGTH - rightString.length - 1;

  if (leftString.length > maxLeftStringLength) {
    var leftStringArray = [];
    var toAddString = leftString;

    while (toAddString.length > 0) {
      leftStringArray.push(toAddString.substring(0, maxLeftStringLength));
      toAddString = toAddString.substring(maxLeftStringLength);

      if (toAddString.length > 0) {
        toAddString = padding + toAddString;
      }
    }

    output.push.apply(output, (0, _toConsumableArray2["default"])((0, _map["default"])(leftStringArray).call(leftStringArray, function (leftString_, index) {
      return {
        action: 'line',
        attributes: customAttributes,
        value: (0, _helpers.alignText)(leftString_, index === 0 ? rightString : '', ' ')
      };
    })));
  } else {
    output.push({
      action: 'line',
      attributes: customAttributes,
      value: (0, _helpers.alignText)(leftString, rightString, ' ')
    });
  }

  if (product.highlightQuantity && product.quantity > 1) {
    leftString = "/! Quantite = ".concat(product.quantity, " /!\\ ");
    rightString = '';
    output.push({
      action: 'line',
      attributes: customAttributes,
      value: (0, _helpers.alignText)(leftString, rightString, ' ')
    });
  }

  return output;
}

function getProductTags(product) {
  var _context12;

  if ((0, _typeof2["default"])(product.tags) === 'object') {
    var _context11;

    return (0, _map["default"])(_context11 = (0, _keys["default"])(product.tags)).call(_context11, function (tagId) {
      return product.tags[tagId].name.toLowerCase();
    });
  }

  return (0, _map["default"])(_context12 = product.tags).call(_context12, function (tag) {
    return tag.name.toLowerCase();
  });
}

function shouldPrintByTag(tagsFilter, product) {
  if ((0, _isEmpty["default"])(tagsFilter) || (0, _isEmpty["default"])(product.tags)) {
    return false;
  }

  var tagsFilterLowerCase = (0, _map["default"])(tagsFilter).call(tagsFilter, function (tag) {
    return String(tag).toLowerCase();
  });
  return (0, _intersection["default"])(getProductTags(product), tagsFilterLowerCase).length > 0;
}

function convertPricesToLines(orderData, tmpOutput, localeStringMapInstance, displayVat, vatMap) {
  var isPaid = Number(orderData.paymentStatus) === 1;

  if (orderData.promocode) {
    var _context13;

    (0, _helpers.addLine)(tmpOutput, (0, _helpers.alignText)((0, _concat["default"])(_context13 = "".concat(localeStringMapInstance.PROMOCODE, "(")).call(_context13, orderData.promocode.code, ")"), "- ".concat(orderData.ttcPromocodeReductionFormatted), ' '));
  }

  if (orderData.grants && orderData.grants.length) {
    var _context14;

    (0, _forEach["default"])(_context14 = orderData.grants).call(_context14, function (grant) {
      var _context15;

      (0, _helpers.addLine)(tmpOutput, (0, _helpers.alignText)((0, _concat["default"])(_context15 = "".concat(localeStringMapInstance.GRANT, " ")).call(_context15, grant.label), grant.amount, ' '));
    });
  }

  if (orderData.ttcTakeAwayReduction) {
    (0, _helpers.addLine)(tmpOutput, (0, _helpers.alignText)(localeStringMapInstance.TAKE_AWAY_REDUCTION, "- ".concat(orderData.ttcTakeAwayReductionFormatted), ' '));
  }

  if (orderData.ttcOnlinePaymentDiscount) {
    (0, _helpers.addLine)(tmpOutput, (0, _helpers.alignText)(localeStringMapInstance.ONLINE_PAYMENT_DISCOUNT, "- ".concat(orderData.ttcOnlinePaymentDiscountFormatted), ' '));
  }

  if (orderData.entranceFees && orderData.entranceFees.length) {
    var _context16;

    (0, _forEach["default"])(_context16 = orderData.entranceFees).call(_context16, function (entranceFee) {
      var _context17;

      (0, _helpers.addLine)(tmpOutput, (0, _helpers.alignText)((0, _concat["default"])(_context17 = "".concat(localeStringMapInstance.ENTRANCE_FEE, " ")).call(_context17, entranceFee.label), entranceFee.totalPrice.totalInclTax, ' '));
    });
  }

  if (orderData.ttcServiceFee) {
    (0, _helpers.addLine)(tmpOutput, (0, _helpers.alignText)(localeStringMapInstance.SERVICE_FEE, orderData.ttcServiceFeeFormatted, ' '));
  }

  if (orderData.ttcDeliveryFee) {
    (0, _helpers.addLine)(tmpOutput, (0, _helpers.alignText)(localeStringMapInstance.DELIVERY_FEE, orderData.ttcDeliveryFeeFormatted, ' '));
  }

  if (orderData.ttcRoyaltyReduction) {
    (0, _helpers.addLine)(tmpOutput, (0, _helpers.alignText)(localeStringMapInstance.ROYALTY_REDUCTION, "- ".concat(orderData.ttcRoyaltyReductionFormatted), ' '));
  }

  (0, _helpers.addLine)(tmpOutput, (0, _helpers.alignText)(localeStringMapInstance.TOTAL_TTC, orderData.ttcTotalPriceFormatted, ' ', Math.round(_constants.PRINTER_LINE_LENGTH / 2)), {
    emphasis: _constants.PRINTER_EMPHASIS.BOLD,
    size: _constants.PRINTER_FONT_SIZE.MEDIUM
  });
  var orderRecapRawData = JSON.parse(orderData.orderRecapRawData);

  if (displayVat && isPaid && orderRecapRawData) {
    var _context18;

    var _JSON$parse = JSON.parse(orderData.orderRecapRawData),
        total = _JSON$parse.totalPrice,
        taxRatesBreakdown = _JSON$parse.taxRatesBreakdown;

    (0, _helpers.addLine)(tmpOutput, "Montant HT : ".concat(formatPrice(total.totalExclTaxWithDiscount))); // VAT Total

    (0, _helpers.addLine)(tmpOutput, (0, _concat["default"])(_context18 = "".concat(localeStringMapInstance.VATS, " : ")).call(_context18, orderData.totalVatFormatted));
    (0, _helpers.addLine)(tmpOutput, (0, _helpers.alignText)('', '', ' '));
    addVatDetailHeaders(tmpOutput); // Construct and print the VAT table

    (0, _forEach["default"])(taxRatesBreakdown).call(taxRatesBreakdown, function (_ref3) {
      var _context19, _context20, _context21, _context22;

      var taxRate = _ref3.taxRate,
          totalPrice = _ref3.totalPrice;
      var content = (0, _concat["default"])(_context19 = (0, _concat["default"])(_context20 = (0, _concat["default"])(_context21 = (0, _concat["default"])(_context22 = "".concat(formatVat(taxRate), " | ")).call(_context22, formatPrice(totalPrice.totalExclTaxWithDiscount), " | ")).call(_context21, formatPrice(totalPrice.totalTaxWithDiscount), " | ")).call(_context20, formatPrice(totalPrice.totalInclTaxWithDiscount), " (")).call(_context19, vatMap[taxRate], ")");
      (0, _helpers.addLine)(tmpOutput, content);
    });
    (0, _helpers.addLine)(tmpOutput, (0, _helpers.alignText)('', '', ' '));
  }

  (0, _helpers.addLineBreaks)(tmpOutput, 1);

  if (isPaid) {
    (0, _helpers.addLine)(tmpOutput, localeStringMapInstance.PAID, {
      align: _constants.PRINTER_ALIGNMENT.CENTER,
      emphasis: _constants.PRINTER_EMPHASIS.BOLD,
      size: _constants.PRINTER_FONT_SIZE.LARGE
    });
  } else {
    (0, _helpers.addLine)(tmpOutput, localeStringMapInstance.UNPAID, {
      align: _constants.PRINTER_ALIGNMENT.CENTER,
      emphasis: _constants.PRINTER_EMPHASIS.BOLD,
      size: _constants.PRINTER_FONT_SIZE.LARGE
    });
  }

  if (orderData.createOrderFailed) {
    (0, _helpers.addLineBreaks)(tmpOutput, 1);
    (0, _helpers.addLine)(tmpOutput, (0, _helpers.alignText)('', '', '-'));
    (0, _helpers.addLineBreaks)(tmpOutput, 1);
    (0, _helpers.addLine)(tmpOutput, localeStringMapInstance.ORDER_ERROR.TITLE, {
      align: _constants.PRINTER_ALIGNMENT.CENTER,
      emphasis: _constants.PRINTER_EMPHASIS.BOLD,
      size: _constants.PRINTER_FONT_SIZE.MEDIUM
    });
    (0, _helpers.addLineBreaks)(tmpOutput, 1);
    (0, _helpers.addLine)(tmpOutput, localeStringMapInstance.ORDER_ERROR.LINE_1, {
      align: _constants.PRINTER_ALIGNMENT.CENTER,
      emphasis: _constants.PRINTER_EMPHASIS.BOLD,
      size: _constants.PRINTER_FONT_SIZE.NORMAL
    });
    (0, _helpers.addLine)(tmpOutput, localeStringMapInstance.ORDER_ERROR.LINE_2, {
      align: _constants.PRINTER_ALIGNMENT.CENTER,
      emphasis: _constants.PRINTER_EMPHASIS.BOLD,
      size: _constants.PRINTER_FONT_SIZE.NORMAL
    });
    (0, _helpers.addLine)(tmpOutput, localeStringMapInstance.ORDER_ERROR.LINE_3, {
      align: _constants.PRINTER_ALIGNMENT.CENTER,
      emphasis: _constants.PRINTER_EMPHASIS.BOLD,
      size: _constants.PRINTER_FONT_SIZE.NORMAL
    });
    (0, _helpers.addLineBreaks)(tmpOutput, 1);
  }

  if (orderData.tpeTicket) {
    convertTpeTicketToLines(tmpOutput, orderData.tpeTicket);
  }
}

function convertTpeTicketToLines(tmpOutput, tpeTicket) {
  if ((0, _indexOf["default"])(tpeTicket).call(tpeTicket, '\n') > -1) {
    var ticketLines = tpeTicket.split('\n');
    (0, _helpers.addLineBreaks)(tmpOutput, 1);
    (0, _helpers.addLine)(tmpOutput, (0, _helpers.alignText)('', '', '-'));
    (0, _forEach["default"])(ticketLines).call(ticketLines, function (ticketLine) {
      (0, _helpers.addLine)(tmpOutput, ticketLine);
    });
    (0, _helpers.addLine)(tmpOutput, (0, _helpers.alignText)('', '', '-'));
  } else {
    var ticketParts = tpeTicket.split(' ');
    var ticketPartsCount = ticketParts.length;

    if (ticketPartsCount >= 15) {
      var _context23, _context24, _context25, _context26, _context27, _context28;

      (0, _helpers.addLineBreaks)(tmpOutput, 1);
      (0, _helpers.addLine)(tmpOutput, (0, _helpers.alignText)('', '', '-', 72));
      var cityLine = (0, _slice["default"])(ticketParts).call(ticketParts, 11, ticketPartsCount - 4).join(' ');
      (0, _forEach["default"])(_context23 = [(0, _concat["default"])(_context24 = "".concat(ticketParts[0], " ")).call(_context24, ticketParts[1]), ticketParts[2], (0, _concat["default"])(_context25 = (0, _concat["default"])(_context26 = "".concat(ticketParts[3], " ")).call(_context26, ticketParts[4], " ")).call(_context25, ticketParts[5]), ticketParts[6], (0, _concat["default"])(_context27 = (0, _concat["default"])(_context28 = "".concat(ticketParts[7], " ")).call(_context28, ticketParts[8], " ")).call(_context27, ticketParts[9]), ticketParts[10], cityLine, ticketParts[ticketPartsCount - 4], ticketParts[ticketPartsCount - 3], ticketParts[ticketPartsCount - 2], ticketParts[ticketPartsCount - 1]]).call(_context23, function (ticketLine) {
        (0, _helpers.addLine)(tmpOutput, ticketLine);
      });
      (0, _helpers.addLine)(tmpOutput, (0, _helpers.alignText)('', '', '-', 72));
    }
  }
}

function addProductsHeader(tmpOutput) {
  (0, _helpers.addLine)(tmpOutput, "Qt\xE9   Produit / Sous-produit                    ", {
    emphasis: _constants.PRINTER_EMPHASIS.BOLD
  });
  (0, _helpers.addLine)(tmpOutput, (0, _helpers.alignText)('', '', '-'));
}

function addVatDetailHeaders(tmpOutput) {
  (0, _helpers.addLine)(tmpOutput, "    %TVA     HT       TVA     TTC", {
    emphasis: _constants.PRINTER_EMPHASIS.BOLD
  });
  (0, _helpers.addLine)(tmpOutput, (0, _helpers.alignText)('', '', '-'));
}

function convertProductToLine(product, displayPrice, tagsFilter, isSchemaActive, addedProductToCartTicket, child, tmpOutput, displayVat, vatMap) {
  var flattenedProduct = flattenProduct(product, displayPrice);
  var deepShouldPrintByTagValue = deepShouldPrintByTag(tagsFilter, flattenedProduct);

  if (!(tagsFilter.length === 0 || deepShouldPrintByTagValue)) {
    return addedProductToCartTicket;
  }

  if (isSchemaActive) {
    if ((0, _isEmpty["default"])(flattenedProduct)) {
      return addedProductToCartTicket;
    }

    addedProductToCartTicket = getProductWithSchema(child.children[0].children, flattenedProduct, addedProductToCartTicket, tmpOutput, displayVat, vatMap);
  } else if (deepShouldPrintByTagValue) {
    var cartAppendObj = convertFlattenProductWithTag(tagsFilter, flattenedProduct, displayVat, vatMap);
    tmpOutput.push.apply(tmpOutput, (0, _toConsumableArray2["default"])(cartAppendObj.output));
    (0, _helpers.addLineBreaks)(tmpOutput, 1);
    addedProductToCartTicket += cartAppendObj.addedProductToCartTicketCount;
  } else {
    var arrayActionCart = convertLineProduct(product, displayPrice, undefined, undefined, displayVat, vatMap);
    tmpOutput.push.apply(tmpOutput, (0, _toConsumableArray2["default"])(arrayActionCart));
    (0, _helpers.addLineBreaks)(tmpOutput, 1);
    addedProductToCartTicket += 1;
  }

  return addedProductToCartTicket;
}
import constantsResourceModule from '@innovorder/angularcore/core/resources/constants.module';
import angularUiRouter from 'angular-ui-router';
import angularAnimate from 'angular-animate';
import angularTranslate from 'angular-translate';
import angularFormState from 'angular-form-state';
import angularMoment from 'angular-moment';
import angularTranslateLoaderStaticFiles from 'angular-translate-loader-static-files';
import apiModule from '@innovorder/angularcore/core/api/api.module';
import apmModule from '@innovorder/angularcore/core/apm/apm.module';
import coreConstantsModule from '@innovorder/angularcore/core/constants/constants.module';
import localeModule from '@innovorder/angularcore/core/locale/locale.module';
import logModule from '@innovorder/angularcore/core/log/log.module';
import availabilityLoggerModule from '@innovorder/angularcore/core/availability_logger/availability_logger.module';
import apiResourceModule from '@innovorder/angularcore/core/resources/api/api.module';
import authModule from '@innovorder/angularcore/core/auth/auth.module';
import userResourceModule from '@innovorder/angularcore/core/resources/user/user.module';
import statePromiseModule from '@innovorder/angularcore/core/state_promise/state_promise.module';
import stuartResourceModule from '@innovorder/angularcore/core/resources/stuart/stuart.module';
import stuartV2ResourceModule from '@innovorder/angularcore/core/resources/stuart_v2/stuart_v2.module';
import networkModule from '@innovorder/angularcore/core/network/network.module';
import angularDynamicLocale from 'angular-dynamic-locale';
import printModule from '@innovorder/angularcore/core/print/print.module';
import ticketResourceModule from '@innovorder/angularcore/core/resources/ticket/ticket.module';
import languageStorageModule from '@innovorder/angularcore/core/language_storage/language_storage.module';
import webBridgeModule from '@innovorder/angularcore/core/web_bridge/web_bridge.module';
import areaModule from '@innovorder/angularcore/core/resources/area/area.module';
import launchdarklyModule from '@innovorder/angularcore/core/launchdarkly/launchdarkly.module';

import angular from 'angular';
import attachFastClick from 'fastclick';
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import inobounce from 'inobounce';
import firebase from 'firebase';
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars, import/extensions
import ctUiRouterExtrasCore from 'ui-router-extras/release/modular/ct-ui-router-extras.core.min.js';
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars, import/extensions
import ctUiRouterExtrasDsr from 'ui-router-extras/release/modular/ct-ui-router-extras.dsr.min.js';
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars, import/extensions
import ctUiRouterExtrasSticky from 'ui-router-extras/release/modular/ct-ui-router-extras.sticky.min.js';
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars, import/extensions
import ctUiRouterExtrasPrevious from 'ui-router-extras/release/modular/ct-ui-router-extras.previous.min.js';
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars, import/extensions
import ctUiRouterExtrasTransition from 'ui-router-extras/release/modular/ct-ui-router-extras.transition.min.js';
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars, import/extensions
import rzslider from 'angularjs-slider/dist/rzslider.min.js';
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import mnTouch from 'mn-touch';
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import isoCurrency from 'iso-currency';
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import angularLoadingBar from 'angular-loading-bar';
// eslint-disable-next-line import/no-unresolved
import frontkit from 'frontkit/src/js/app';
import ravenLoggerModule from '@innovorder/angularcore/core/raven_logger/raven_logger.module';
import orderTicketModule from '@innovorder/angularcore/core/order_ticket/order_ticket.module';
import offlineAlertModule from './components/offline_alert/offline_alert.module';
import driveModule from './components/drive/drive.module';
import appInterceptorConfig from './app_interceptor.config';
import appLocals from './app.locals';
import appRoutes from './app.routes';
import homeModule from './components/home/home.module';
import orderModule from './components/order/order.module';
import loginModule from './components/login/login.module';
import errorModule from './components/error/error.module';
// eslint-disable-next-line import/no-unresolved
import constantsModule from './constants.module';

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars, import/no-unresolved
import style from '../style.scss';

const dependencies = [
    constantsModule.name,
    constantsResourceModule,
    angularUiRouter,
    angularAnimate,
    angularTranslate,
    angularFormState,
    'mn',
    'angular-loading-bar',
    angularTranslateLoaderStaticFiles,
    loginModule,
    homeModule,
    apiModule,
    coreConstantsModule,
    localeModule,
    logModule,
    availabilityLoggerModule,
    apiResourceModule,
    authModule,
    driveModule,
    userResourceModule,
    statePromiseModule,
    stuartResourceModule,
    stuartV2ResourceModule,
    orderModule,
    offlineAlertModule,
    networkModule,
    angularDynamicLocale,
    printModule,
    ticketResourceModule,
    languageStorageModule,
    webBridgeModule,
    angularMoment,
    'ct.ui.router.extras.dsr',
    'ct.ui.router.extras.transition',
    'ct.ui.router.extras.previous',
    'ct.ui.router.extras.sticky',
    'rzModule',
    'isoCurrency',
    'isoCurrency.common',
    frontkit,
    ravenLoggerModule,
    areaModule,
    orderTicketModule,
    launchdarklyModule,
    errorModule,
    apmModule,
];

// eslint-disable-next-line angular/no-service-method
angular
    .module('app', dependencies)
    .config(appRoutes)
    .config(appLocals)
    .service('appInterceptorService', appInterceptorConfig)
    .config(appInterceptorConfig)
    .config([
        'tmhDynamicLocaleProvider',
        'ENV_VARS',
        function (tmhDynamicLocaleProvider, ENV_VARS) {
            tmhDynamicLocaleProvider.localeLocationPattern(
                `${ENV_VARS.STATIC_URL}/reception/angular-i18n/angular-locale_{{locale}}.js`,
            );
        },
    ])
    .config(function (localStorageServiceProvider) {
        'ngInject';

        localStorageServiceProvider.setPrefix('innovorder');
        localStorageServiceProvider.setStorageCookie(365, '/', false);
    })
    .config([
        '$compileProvider',
        function ($compileProvider) {
            $compileProvider.debugInfoEnabled(false);
        },
    ])
    .config([
        '$stickyStateProvider',
        function ($stickyStateProvider) {
            $stickyStateProvider.debugMode(false);
        },
    ])
    .config(function (ravenLoggerServiceProvider, ENV_VARS) {
        'ngInject';

        ravenLoggerServiceProvider.init(ENV_VARS.SENTRY_URL);
    })
    .run([
        'ENV_VARS',
        function (ENV_VARS) {
            // eslint-disable-next-line angular/no-run-logic
            const config = {
                apiKey: ENV_VARS.FB_API_KEY,
                databaseURL: ENV_VARS.FB_DB_URL,
            };
            firebase.initializeApp(config);
        },
    ])
    .run([
        'amMoment',
        '$rootScope',
        '$state',
        '$stateParams',
        '$exceptionHandler',
        'networkService',
        function (amMoment, $rootScope, $state, $stateParams, $exceptionHandler, networkService) {
            // eslint-disable-next-line no-new, angular/no-run-logic, new-cap, angular/document-service, no-undef
            new attachFastClick(document.body);
            amMoment.changeLocale('en');
            // eslint-disable-next-line angular/no-run-logic
            $rootScope.$state = $state;
            // eslint-disable-next-line angular/no-run-logic
            $rootScope.$stateParams = $stateParams;
            // eslint-disable-next-line angular/no-run-logic
            $rootScope.networkService = networkService;
        },
    ]);
export default angular.module('app').name;

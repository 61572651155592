import angular from 'angular';
import angularLocalStorage from 'angular-local-storage';
import angularMoment from 'angular-moment';
// eslint-disable-next-line import/extensions, no-unused-vars
import _indexedDB from '@innovorder/angularcore/libs/angular-indexed-db/angular-indexed-db.min.js';
import orderService from './order.service';

const dependencies = [angularLocalStorage, angularMoment, 'indexedDB'];

// eslint-disable-next-line angular/no-service-method
angular
    .module('core.reception.order', dependencies)
    .config(function ($indexedDBProvider) {
        'ngInject';

        $indexedDBProvider.connection('ioIndexedDb').upgradeDatabase(1, function (_event, db) {
            db.createObjectStore('printedOrders', { keyPath: 'orderId' });
        });
    })
    .service('orderService', orderService);

export default angular.module('core.reception.order').name;

import modalRefundView from 'app/components/orders_home/detail/modal_refund/modal_refund.view.html';
import modalNoPrinterView from 'app/components/orders_home/modal_no_printer/modal_no_printer.view.html';
import modalTicketPreviewView from 'app/components/orders_home/modal_ticket_preview/modal_ticket_preview.view.html';

export default function ($stateProvider, $urlRouterProvider, $locationProvider) {
    'ngInject';

    $locationProvider.html5Mode(true);
    $stateProvider
        .state('home.orders_home.placed.detail.modal_refund', states.modalRefund())
        .state('home.orders_home.validated.detail.modal_refund', states.modalRefund())
        .state('home.orders_home.in_progress.detail.modal_refund', states.modalRefund())
        .state('home.orders_home.done.detail.modal_refund', states.modalRefund())
        .state('home.orders_home.placed.detail.modal_no_printer', states.modalNoPrinter())
        .state('home.orders_home.validated.detail.modal_no_printer', states.modalNoPrinter())
        .state('home.orders_home.in_progress.detail.modal_no_printer', states.modalNoPrinter())
        .state('home.orders_home.done.detail.modal_no_printer', states.modalNoPrinter())
        .state('home.orders_home.placed.detail.modal_preview', states.modalTicketPreview())
        .state('home.orders_home.validated.detail.modal_preview', states.modalTicketPreview())
        .state('home.orders_home.in_progress.detail.modal_preview', states.modalTicketPreview())
        .state('home.orders_home.done.detail.modal_preview', states.modalTicketPreview());
}

// eslint-disable-next-line vars-on-top, no-var
var states = {
    modalRefund() {
        return {
            abstract: true,
            params: {
                orderToCancel: null,
            },
            views: {
                'modal@': {
                    templateUrl: modalRefundView,
                    controller: 'ModalRefundController',
                    controllerAs: 'modalRefundVm',
                },
            },
        };
    },
    modalNoPrinter() {
        return {
            views: {
                'modal@': {
                    templateUrl: modalNoPrinterView,
                    controller: 'ModalNoPrinterController',
                    controllerAs: 'modalNoPrinterVm',
                },
            },
        };
    },
    modalTicketPreview() {
        return {
            views: {
                'modal@': {
                    templateUrl: modalTicketPreviewView,
                    controller: 'ModalTicketPreviewController',
                    controllerAs: 'modalTicketPreviewVm',
                },
            },
            resolve: {
                orderTickets: [
                    '$state',
                    'apiService',
                    function ($state, apiService) {
                        'ngInject';

                        return apiService.orderTicket.get({
                            orderId: $state.params.order.orderId,
                        }).$promise;
                    },
                ],
            },
        };
    },
};

"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = _ngInjectExport;

var _stringify = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/json/stringify"));

var _concat = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/concat"));

var _indexOf = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/index-of"));

var _utils = require("./utils");

_ngInjectExport.$inject = ["User", "localStorageService", "simpleLogService", "$q", "$state"];

function _ngInjectExport(User, localStorageService, simpleLogService, $q, $state) {
  'ngInject';

  var authService = this;
  var CONSTANTS = {
    GRANT_TYPE: 'password',
    DEFAULT_PATH_ACCESS_TOKEN: 'token',
    DEFAULT_HAS_LOGGED_IN_ONCE_PATH: 'innovorderKioskHasLoggedInOnce',
    GUEST_PATH_ACCESS_TOKEN: 'token-guest',
    GUEST_BRAND_PATH_ACCESS_TOKEN: 'token-guest-brand',
    A_YEAR: 365,
    A_DAY: 1
  };
  var authenticated = $q.defer();
  var unauthenticated = $q.defer();
  authService.user = null;
  authService.isLogged = false;
  authService.isLoggedAsGuest = false;
  authService.isLoggedAsGuestBrand = false;
  authService.shouldLogoutOnError = true;
  authService.pathAccessToken = CONSTANTS.DEFAULT_PATH_ACCESS_TOKEN;
  authService.pathGuestAccessToken = CONSTANTS.GUEST_PATH_ACCESS_TOKEN;
  authService.pathGuestBrandAccessToken = CONSTANTS.GUEST_BRAND_PATH_ACCESS_TOKEN;
  authService.pathHasLoggedInOnce = CONSTANTS.DEFAULT_HAS_LOGGED_IN_ONCE_PATH;
  authService.brandGroupUser = {
    mainUserEmail: null,
    brandGroupId: null
  };

  authService.init = function (pathAccessToken) {
    var shouldAuthenticateUser = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
    var shouldLogoutOnError = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
    authService.pathAccessToken = pathAccessToken;
    authService.pathGuestAccessToken = "".concat(pathAccessToken, "-guest");
    authService.pathGuestBrandAccessToken = "".concat(pathAccessToken, "-guest-brand");
    authService.shouldLogoutOnError = shouldLogoutOnError;
    simpleLogService.info('[AuthService][init] pathAccessToken cookie : ', localStorageService.cookie.get(authService.pathAccessToken));
    simpleLogService.info('[AuthService][init] pathGuestAccessToken cookie : ', localStorageService.cookie.get(authService.pathGuestAccessToken));
    return shouldAuthenticateUser ? authenticateUser() : null;
  };

  authService.requireUnauth = function () {
    return unauthenticated.promise;
  };

  authService.requireAuth = function () {
    return authenticated.promise;
  };

  authService.autoLogin = function (autoLoginJWT) {
    return authService.logout().then(function () {
      authenticated = $q.defer();
      unauthenticated = $q.defer();
      var promise = User.login({
        autoLoginJWT: autoLoginJWT
      });
      return enrichLoginResult(promise, true);
    });
  };

  authService.login = function (emailOrStudentNumber, password, rememberMe, brandId, role, strategy) {
    authenticated = $q.defer();
    unauthenticated = $q.defer();
    simpleLogService.info('[AuthService][login] triggered');
    var isEmail = ~(0, _indexOf.default)(emailOrStudentNumber).call(emailOrStudentNumber, '@');
    var loginInfos = {
      password: password,
      rememberMe: rememberMe,
      grant_type: CONSTANTS.GRANT_TYPE
    };

    if (brandId) {
      loginInfos.brandId = brandId;
    }

    if (role) {
      loginInfos.role = role;
    }

    var promise;

    if (!isEmail || strategy === 'studentNumber') {
      loginInfos.studentNumber = emailOrStudentNumber;
      promise = User.loginStudentNumber(loginInfos);
    } else {
      loginInfos.username = emailOrStudentNumber;
      promise = User.login(loginInfos);
    }

    return enrichLoginResult(promise, rememberMe);
  };

  authService.requestUserAuthToken = function (userId) {
    return User.requestToken({
      userId: userId
    }).$promise.then(function (oauthResponse) {
      return oauthResponse[0];
    });
  };

  var authenticateWithJwtToken = function authenticateWithJwtToken(token) {
    return authService.verifyToken(token).then(function (user) {
      authenticated = $q.defer();
      unauthenticated = $q.defer();
      authService.user = user;
      authService.isLogged = true;
      authenticated.resolve(user);
      unauthenticated.reject();
      return user;
    }).catch(function (err) {
      authService.isLogged = false;
      authService.isLoggedAsGuestBrand = false;
      authenticated.reject(err);
      unauthenticated.resolve();
      return err;
    });
  };

  authService.loginAsGuest = function (token) {
    rememberGuest(token);
    return authenticateWithJwtToken(token).then(function (user) {
      authService.isLoggedAsGuest = true;
      return user;
    });
  };

  authService.loginAsGuestBrand = function (_ref) {
    var accessToken = _ref.accessToken,
        brandGroupId = _ref.brandGroupId,
        mainUserEmail = _ref.mainUserEmail;
    rememberGuestBrand({
      accessToken: accessToken,
      brandGroupId: brandGroupId,
      mainUserEmail: mainUserEmail
    });
    authService.brandGroupUser.mainUserEmail = mainUserEmail;
    authService.brandGroupUser.brandGroupId = brandGroupId;
    return authenticateWithJwtToken(accessToken).then(function (user) {
      authService.isLoggedAsGuestBrand = true;
      return user;
    });
  };

  authService.loginWithJwtToken = function (token) {
    rememberTheUser({
      accessToken: token
    }, CONSTANTS.A_YEAR);
    return authenticateWithJwtToken(token);
  };

  authService.logout = function () {
    var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
      shouldDeleteMainToken: true
    },
        shouldDeleteMainToken = _ref2.shouldDeleteMainToken;

    authenticated = $q.defer();
    unauthenticated = $q.defer();
    simpleLogService.info('[AuthService][logout] logout called with shouldDeleteMainToken : ', shouldDeleteMainToken);
    var deferred = User.logout().$promise;
    deferred.then(function () {
      if (shouldDeleteMainToken) {
        localStorageService.cookie.remove(authService.pathAccessToken);
      }

      localStorageService.cookie.remove(authService.pathGuestAccessToken);
      localStorageService.cookie.remove(authService.pathHasLoggedInOnce);
      localStorageService.cookie.remove(authService.pathGuestBrandAccessToken);
      simpleLogService.info('[AuthService][logout] User.logout().$promise.then : ', shouldDeleteMainToken);
      authService.user = null;
      authService.isLogged = false;
      authService.isLoggedAsGuestBrand = false;
      authenticated.reject('logout');
      unauthenticated.resolve();
    }).catch(function (err) {
      if (shouldDeleteMainToken) {
        localStorageService.cookie.remove(authService.pathAccessToken);
      }

      localStorageService.cookie.remove(authService.pathGuestAccessToken);
      localStorageService.cookie.remove(authService.pathHasLoggedInOnce);
      localStorageService.cookie.remove(authService.pathGuestBrandAccessToken);
      simpleLogService.info('[AuthService][logout] User.logout().$promise.catch : ', shouldDeleteMainToken);
      authService.user = null;
      authService.isLogged = false;
      authService.isLoggedAsGuestBrand = false;
      authenticated.reject(err);
      unauthenticated.resolve();
    });
    return deferred;
  };

  authService.accessToken = null;

  authService.catchLogout = function (nextStateIfLogout, params) {
    return function (response) {
      if (response.status == 401 && (response.data.code == 'token_expired' || response.data.code == 'permission_denied')) {
        simpleLogService.info('[AuthService][catchLogout] response is 401 token_expired + $state.go : ', nextStateIfLogout);
        authService.logout();
        $state.go(nextStateIfLogout, params);
      } else {
        $state.go('init.home');
      }
    };
  };

  authService.verifyToken = function (token) {
    authService.accessToken = token;
    return User.me().$promise.then(function (user) {
      authService.user = user;
      return user;
    }).catch(function (error) {
      var _context;

      simpleLogService.info('[AuthService][verifyToken][catch] error on verifyToken + shouldLogoutOnError', (0, _concat.default)(_context = "".concat((0, _stringify.default)(error), "   -   ")).call(_context, (0, _stringify.default)(authService.shouldLogoutOnError)));
      var tokenError = (0, _utils.isTokenError)(error);

      if (authService.shouldLogoutOnError || tokenError) {
        localStorageService.cookie.remove(authService.pathAccessToken);
        localStorageService.cookie.remove(authService.pathGuestAccessToken);
        localStorageService.cookie.remove(authService.pathGuestBrandAccessToken);
        localStorageService.cookie.remove(authService.pathHasLoggedInOnce);
        simpleLogService.info('[AuthService][verifyToken][catch] removed cookies');
      }

      throw tokenError ? 'permission_denied' : 'unkown_error';
    });
  };

  var authenticateUser = function authenticateUser() {
    var deferred = $q.defer();
    checkIfLoggedByCookie().then(function (userFromCookie) {
      authService.user = userFromCookie;
      authService.isLogged = true;
      authenticated.resolve(authService.user);
      unauthenticated.reject();
      deferred.resolve();
    }).catch(function (error) {
      simpleLogService.info('[AuthService][authenticateUser][checkIfLoggedByCookie][catch] checkIfLoggedByCookie failed');
      deferred.reject(error);
      authenticated.reject(error);
      unauthenticated.resolve();
      deferred.resolve();
    });
    return deferred.promise;
  };

  var checkIfLoggedByCookie = function checkIfLoggedByCookie() {
    var isLogged = $q.defer();
    var cookie = localStorageService.cookie.get(authService.pathAccessToken);
    simpleLogService.info('[AuthService][checkIfLoggedByCookie] cookies on trigger : ', cookie);

    if ((!cookie || !cookie.accessToken) && authService.pathAccessToken != CONSTANTS.DEFAULT_PATH_ACCESS_TOKEN) {
      cookie = localStorageService.cookie.get(CONSTANTS.DEFAULT_PATH_ACCESS_TOKEN);

      if (cookie && cookie.accessToken) {
        rememberTheUser(cookie, CONSTANTS.A_YEAR);
      } else {
        simpleLogService.info('[AuthService][checkIfLoggedByCookie] no cookies found at either locations');
      }
    }

    if (cookie && cookie.accessToken) {
      authService.verifyToken(cookie.accessToken).then(function (user) {
        authService.user = user;
        isLogged.resolve(user);

        if (localStorageService.cookie.get(authService.pathGuestAccessToken)) {
          authService.isLoggedAsGuest = true;
        }

        if (localStorageService.cookie.get(authService.pathGuestBrandAccessToken)) {
          var brandGroupCookieValue = localStorageService.cookie.get(authService.pathGuestBrandAccessToken);
          authService.isLoggedAsGuestBrand = true;
          authService.brandGroupUser.mainUserEmail = brandGroupCookieValue.mainUserEmail;
          authService.brandGroupUser.brandGroupId = brandGroupCookieValue.brandGroupId;
        }
      }).catch(function (error) {
        simpleLogService.info('[AuthService][checkIfLoggedByCookie] verifyToken catch : ', error);
        isLogged.reject(error);
      });
    } else {
      simpleLogService.info('[AuthService][checkIfLoggedByCookie] no_token catch');
      isLogged.reject('no_token');
    }

    return isLogged.promise;
  };

  var rememberTheUser = function rememberTheUser(_ref3, dayNumber) {
    var accessToken = _ref3.accessToken;
    sessionStorage.setItem(authService.pathAccessToken, accessToken);
    simpleLogService.info('[AuthService][rememberTheUser] sets accessToken in cookies');
    localStorageService.cookie.set(authService.pathAccessToken, {
      accessToken: accessToken
    }, dayNumber);
    authService.accessToken = accessToken;
  }; // This method is used to login as guest: if pathGuestAccessToken is filled a banner will appear on the BO.


  var rememberGuest = function rememberGuest(accessToken) {
    simpleLogService.info('[AuthService][rememberGuest] kiosk user is a guest');
    localStorageService.cookie.set(authService.pathGuestAccessToken, {
      accessToken: accessToken
    }, CONSTANTS.A_DAY);
    localStorageService.cookie.get(authService.pathGuestAccessToken);
    authService.accessToken = accessToken;
  };

  var rememberGuestBrand = function rememberGuestBrand(cookieValue) {
    simpleLogService.info('[AuthService][rememberGuestBrand] user is a guest brand');
    localStorageService.cookie.set(authService.pathGuestBrandAccessToken, cookieValue, CONSTANTS.A_DAY);
    localStorageService.cookie.get(authService.pathGuestBrandAccessToken);
    authService.accessToken = cookieValue.accessToken;
  };

  var enrichLoginResult = function enrichLoginResult(promise, rememberMe) {
    return promise.$promise.then(function (loginResult) {
      simpleLogService.info('[AuthService][login] login succeeded');
      authService.user = loginResult[0].user ? loginResult[0].user : loginResult[0].customer;
      authService.isLogged = true;
      rememberTheUser(loginResult[0], rememberMe && localStorageService.cookie.isSupported ? CONSTANTS.A_YEAR : CONSTANTS.A_DAY);
      simpleLogService.info('[AuthService][login] cookies set for : ', rememberMe && localStorageService.cookie.isSupported ? CONSTANTS.A_YEAR : CONSTANTS.A_DAY);
      localStorageService.cookie.set(authService.pathHasLoggedInOnce, true, CONSTANTS.A_YEAR);
      authenticated.resolve(authService.user);
      unauthenticated.reject();
      return $q.resolve(authService.user);
    }, function (err) {
      simpleLogService.info('[AuthService][login] login failed');
      authService.isLogged = false;
      authService.isLoggedAsGuestBrand = false;
      localStorageService.cookie.remove(authService.pathHasLoggedInOnce);
      authenticated.reject(err);
      unauthenticated.resolve();
      return $q.reject(err);
    });
  };
}
export default function($q) {
  'ngInject';

  var service = this;
  service.cellFont = null;
  service.padding = null;

  function initializeGrid(grid) {
    grid.registerColumnBuilder(this.colAutoFitColumnBuilder);
    grid.registerColumnsProcessor(this.columnsProcessor, 60);
  }

  function colAutoFitColumnBuilder(colDef, col, gridOptions) {
    let promises = [];

    if (colDef.enableColumnAutoFit === undefined) {
      if (isResizable(colDef)) {
        colDef.enableColumnAutoFit = gridOptions.enableColumnAutoFit;
      } else {
        colDef.enableColumnAutoFit = false;
      }
    }

    return $q.all(promises);
  }

  function columnsProcessor(renderedColumnsToProcess, rows) {
    if (!rows.length) {
      return renderedColumnsToProcess;
    }

    // TODO: respect existing colDef options
    // if (col.colDef.enableColumnAutoFitting === false) return;
    let optimalWidths = {};

    renderedColumnsToProcess.forEach(column => {
      if (column.colDef.enableColumnAutoFit) {
        let columnKey = column.field || column.name;
        optimalWidths[columnKey] =
          measureRoundedTextWidth(column.displayName) + 25;

        rows.forEach(row => {
          let cellText = getCellText(row.entity, columnKey);
          let currentCellWidth = measureRoundedTextWidth(cellText);
          let optimalCellWidth = currentCellWidth; // > 350 ? 350 : currentCellWidth;

          if (optimalCellWidth > optimalWidths[columnKey]) {
            optimalWidths[columnKey] = optimalCellWidth;
          }
        });

        column.colDef.width = optimalWidths[columnKey] + 20;
        column.updateColumnDef(column.colDef, false);
      }
    });
    return renderedColumnsToProcess;
  }

  service.initializeGrid = initializeGrid;
  service.colAutoFitColumnBuilder = colAutoFitColumnBuilder;
  service.columnsProcessor = columnsProcessor;

  // ----- UTILITY FUNCTION -----

  function isResizable(colDef) {
    return !colDef.hasOwnProperty('width');
  }

  function getCellText(row, column) {
    return row[column];
  }

  function measureTextWidth(text) {
    let canvas = document.createElement('canvas');
    let context = canvas.getContext('2d');
    context.font = getCellFont();
    let metrics = context.measureText(text);
    return metrics.width;
  }

  function measureRoundedTextWidth(text, font) {
    let width = measureTextWidth(text, font);
    return Math.floor(width) + 1;
  }

  function getCellFont() {
    if (service.cellFont != null) {
      return service.cellFont;
    }

    let cell = document.querySelector('.ui-grid-cell > .ui-grid-cell-contents');

    if (!cell) {
      let element = document.createElement('div');
      element.className = 'ui-grid-cell-contents';
      element.style.cssFloat = 'left';
      angular.element(document.body).append(element);
      let cellStyle = getComputedStyle(element);
      service.cellFont = getFontStringFrom(cellStyle);
      angular.element(element).remove();
      return service.cellFont;
    }

    let cellStyle = getComputedStyle(cell);
    service.cellFont = getFontStringFrom(cellStyle);
    return service.cellFont;
  }

  function getFontStringFrom(cellStyle) {
    return (
      cellStyle.fontStyle +
      ' ' +
      cellStyle.fontVariant +
      ' ' +
      cellStyle.fontWeight +
      ' ' +
      cellStyle.fontSize +
      ' ' +
      cellStyle.fontFamily
    );
  }
}

export default function (orderService, printService) {
    'ngInject';

    const vm = this;
    vm.printService = printService;
    vm.tickets = angular.copy(printService.tickets);
    vm.tickets.unshift({
        name: 'Par défaut (sans ticket)',
        value: null,
    });

    vm.printRecap = function () {
        orderService.printRecap();
    };
}

"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = _ngInjectExport;

var _concat = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/concat"));

var _keys = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/keys"));

var _forEach = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/for-each"));

_ngInjectExport.$inject = ["$injector"];

function _ngInjectExport($injector) {
  'ngInject';

  var provider = this;
  var service = {};

  var _authService;

  service.captureException = function (level, error, data) {
    if (!_authService) {
      _authService = $injector.get('authService');
    }

    if (_authService.user) {
      provider.Raven.setUser({
        email: _authService.user.email,
        id: _authService.user.userId,
        userName: _authService.user.firstName + ' ' + _authService.user.lastName
      });
      provider.Raven.setContext('user', {
        email: _authService.user.email,
        id: _authService.user.userId,
        brandId: _authService.user.brandId,
        role: _authService.user.role,
        fullName: _authService.user.firstName + ' ' + _authService.user.lastName
      });
    }

    provider.Raven.withScope(function (scope) {
      scope.setLevel(level);
    });

    if (error instanceof Error) {
      provider.Raven.captureException(error, data);
    } else {
      provider.Raven.captureMessage(error, data);
    }
  };
  /**
   * @param tags \{ [key: string]: unknown; \}
   * @param prefix string | undefined
   * @return void
   */


  service.addTags = function (tags) {
    var _context;

    var prefix = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
    (0, _forEach.default)(_context = (0, _keys.default)(tags)).call(_context, function (key) {
      var _context2;

      return provider.Raven.setTag((0, _concat.default)(_context2 = "".concat(prefix)).call(_context2, key), tags[key]);
    });
  };

  service.error = logSentry('error');
  service.info = logSentry('info');
  service.warn = logSentry('warn');

  service.silly = function (silly, context) {
    console.log(silly, context);
  };

  function logSentry(type) {
    return function (message, context) {
      if (provider.isInit) {
        var ctx = {
          extra: context,
          tags: context && context.tags ? context.tags : undefined
        };
        service.captureException(type, message, ctx);
      }

      console[type](message, ctx);
    };
  }

  return service;
}
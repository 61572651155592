/**
 *  Loading Screen service
 *  ---------------------------------------------------
 */
export default function($timeout, $compile, $rootScope) {
  'ngInject';
  var isInjected = false;

  function trigger(timeout) {
    if (!isInjected) {
      var newElement = $compile('<fio-loading-screen></fio-loading-screen>')(
        $rootScope.$new(),
      );
      angular
        .element(document)
        .find('body')
        .append(newElement);
      isInjected = true;

      if (typeof timeout === 'number' && timeout % 1 == 0) {
        $timeout(function() {
          destroy();
        }, timeout);
      }
    }
  }

  function destroy() {
    if (isInjected) {
      angular
        .element(document)
        .find('fio-loading-screen')
        .remove();
      isInjected = false;
    }
  }

  return {
    trigger: trigger,
    destroy: destroy,
  };
}

export default function(uiGridAutoFitColumnsService) {
  'ngInject';

  return {
    replace: true,
    priority: 0,
    require: '^uiGrid',
    scope: false,
    compile: function() {
      return {
        pre: function($scope, $elm, $attrs, uiGridCtrl) {
          uiGridAutoFitColumnsService.initializeGrid(uiGridCtrl.grid);
        },
      };
    },
  };
}

import modalHeaderView from './modal_header.view.html';
export default function($templateCache) {
  'ngInject';

  return {
    link: function(scope, element, attrs) {
      scope.displayClose = attrs.close != undefined;
    },
    restrict: 'E',
    require: '^fioModal',
    scope: {
      color: '@',
    },
    transclude: true,
    controller: controller,
    bindToController: {
      close: '=',
    },
    controllerAs: 'modalHeaderVm',
    template: $templateCache.get(modalHeaderView),
  };

  function controller() {
    'ngInject';
    var vm = this;
  }
}

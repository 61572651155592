"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = _ngInjectExport;
_ngInjectExport.$inject = ["$httpProvider"];

function _ngInjectExport($httpProvider) {
  'ngInject';

  $httpProvider.interceptors.push('localeInterceptor');
}
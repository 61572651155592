import clonedeep from 'lodash.clonedeep';
import { OrderDeliveryStatus } from '../../../constants/order_delivery';

export default function (
    orderDetail,
    $scope,
    orderService,
    $interval,
    stuartActive,
    stuartV2Active,
    statePromise,
    $state,
    ENV_VARS,
    ORDERS_VARS,
    displayedOrders,
    printService,
    Stuart,
    StuartV2,
    $translate,
    CHANNELS,
    $filter,
) {
    'ngInject';

    const vm = this;
    vm.grants = [];
    vm.entranceFees = [];
    vm.isLoading = false;
    vm.reloadInterval = null;

    const ngTranslate = $filter('translate');

    function getPaymentProviderLabel(paymentProvider) {
        if (!paymentProvider) {
            return '';
        }
        return `
        ${ngTranslate('ORDER_DETAIL.BY')}
         ${ngTranslate(`ORDER_DETAIL.${paymentProvider}`)}`;
    }

    function setParsedStatus() {
        if (vm.order?.deliveryStatus !== null) {
            $translate(`ORDER_DETAIL.${ENV_VARS.ORDER_DELIVERY_STATUS[vm.order.deliveryStatus]}`).then(function (
                translation,
            ) {
                vm.order.parsedStatus = translation;
            });
        }
    }

    function onOrderDetailUpdate() {
        vm.isLoading = false;
        if (vm.reloadInterval && vm.orderDetail.order.mainStatus !== 1) {
            $interval.cancel(vm.reloadInterval);
            vm.reloadInterval = null;
        }
        setParsedStatus();
    }

    vm.$onInit = function () {
        vm.cart = JSON.parse(orderDetail.order.cartRawData);
        vm.paymentProviderLabel = getPaymentProviderLabel(orderDetail.order.paymentProvider);
        if (orderDetail.order.orderRecapRawData) {
            const orderRecap = JSON.parse(orderDetail.order.orderRecapRawData);
            vm.grants = orderRecap.discounts.filter((discount) => discount.strategy.type === 'GRANT');
            vm.entranceFees = orderRecap.fees.filter((fee) =>
                ['ENTRANCE_FEE', 'AdmissionFee', 'RoyaltyFee'].includes(fee.type),
            );
        } else {
            if (orderDetail.order.grantRawData) {
                const grant = JSON.parse(orderDetail.order.grantRawData);
                vm.grants = [{ label: grant.labelTicket, amount: grant.amount }];
            }
            if (orderDetail.order.entranceFeeRawData) {
                const entranceFee = JSON.parse(orderDetail.order.entranceFeeRawData);
                vm.entranceFees = [
                    {
                        label: entranceFee.labelTicket,
                        totalPrice: {
                            totalInclTaxWithDiscount: entranceFee.amount,
                        },
                    },
                ];
            }
        }
        vm.stuartActive = stuartActive;
        vm.stuartV2Active = stuartV2Active;
        vm.order = orderDetail.order;
        setParsedStatus();
        orderDetail.onUpdate = onOrderDetailUpdate;
        vm.orderDetail = orderDetail;
        vm.CHANNELS = CHANNELS;

        const isStuartV2Job =
            vm.order.consommationMode === ENV_VARS.CONSOMMATION_MODES.DELIVERY && vm.order.deliveryType === 'STUART_V2';

        vm.jobCreationError = null;

        if (isStuartV2Job) {
            StuartV2.getLatestJobCreationError({ orderId: vm.order.orderId }).$promise.then(({ jobCreationError }) => {
                vm.jobCreationError = jobCreationError;
                if (jobCreationError) {
                    /*
                     * jobCreationError.message may be a JSON like
                     * {"job.pickup_at":["can not schedule a pickup at this time","is not valid"]}
                     *
                     * If that's the case, JSON.parse successfully parses it without raising a SyntaxError
                     * Then I compute the translation key by prepending the JSON key and its values to our translation prefix.
                     * A translation key should look like '${ID_PREFIX}JOB.PICKUP_AT.IS NOT VALID'.
                     *
                     * I reduce the keys instead of mapping them in order to flatten the resulting array using .concat();
                     */
                    try {
                        const message = JSON.parse(jobCreationError.message);
                        const ID_PREFIX = 'ORDER_DETAIL.STUART_V2_JOB_CREATION_ERROR.ERRORS.';
                        const messagesIds = Object.keys(message).reduce((acc, errorKey) => {
                            return acc.concat(
                                message[errorKey].map(
                                    (validationError) =>
                                        `${ID_PREFIX}${errorKey.toUpperCase()}.${validationError.toUpperCase()}`,
                                ),
                            );
                        }, []);
                        $translate(messagesIds).then((translations) => {
                            vm.jobCreationError.parsedMessages = messagesIds
                                .map((id) => (id !== translations[id] ? translations[id] : ''))
                                // Filter out untranslated messages
                                .filter(Boolean);
                        });
                    } catch (error) {
                        if (error instanceof SyntaxError) {
                            /*
                             * The error message must not be a valid JSON, meaning it is most probably a string.
                             */

                            if (jobCreationError.message.includes('PAYMENT_DECLINED')) {
                                $translate('STUART_V2_PAYMENT_DECLINED').then(function (message) {
                                    vm.jobCreationError.parsedMessages = [message];
                                });
                            } else {
                                vm.jobCreationError.parsedMessages = [jobCreationError.message];
                            }
                        }
                    }
                }
            });
        }
    };
    vm.callStuart = function () {
        statePromise
            .go(
                'home.stuart.quote',
                {
                    statePromiseName: 'stuartJob',
                    stuartOrder: orderDetail.order,
                },
                {
                    location: false,
                },
                'stuartJob',
            )
            .then(function () {
                orderDetail.update().then(function () {
                    $scope.$apply();
                });
            });
    };
    // eslint-disable-next-line consistent-return
    vm.updateDeliveryStatus = function () {
        if (vm.order.deliveryStatus) {
            return StuartV2.getJob({
                orderId: vm.order.orderId,
            }).$promise.finally(function () {
                return orderDetail.update();
            });
        }
    };
    const interval = $interval(vm.updateDeliveryStatus, 20000);

    vm.callStuartV2 = function () {
        statePromise
            .go(
                'home.orders_home.stuart_v2.quote_v2',
                {
                    stuartV2Order: vm.order,
                },
                {
                    location: false,
                },
                'stuartV2',
            )
            .finally(function () {
                vm.updateDeliveryStatus();
                orderDetail.update();
            });
    };

    vm.printTicket = function () {
        if (vm.isLoading) {
            return;
        }
        if (!printService.canPrint()) {
            statePromise
                .go(
                    '.modal_no_printer',
                    {},
                    {
                        location: false,
                    },
                    'noPrinter',
                )
                .then(function () {
                    $state.go('home.parameters.print');
                });
            return;
        }
        vm.isLoading = true;
        orderService.printOrder(clonedeep(orderDetail.order));
        // Create a new interval to reload data while printing is pending
        if (orderDetail.order.mainStatus === 1) {
            vm.reloadInterval = $interval(orderDetail.update, 500);
        } else {
            vm.isLoading = false;
        }
    };

    vm.displayPreview = function () {
        statePromise.go(
            '.modal_preview',
            {
                printService,
            },
            {
                location: false,
            },
            'ticketPreview',
        );
    };
    vm.detailStuart = function () {
        if (
            orderDetail.order.stuartJob &&
            orderDetail.order.stuartJob.length > 0 &&
            [
                OrderDeliveryStatus.DELIVERY_SCHEDULED,
                OrderDeliveryStatus.DELIVERY_SEARCH,
                OrderDeliveryStatus.DELIVERY_PICKING,
                OrderDeliveryStatus.DELIVERY_DELIVERING,
                OrderDeliveryStatus.DELIVERY_DELIVERED,
            ].includes(orderDetail.order.deliveryStatus)
        ) {
            statePromise
                .go(
                    'home.stuart.infos',
                    {
                        statePromiseName: 'stuartInfos',
                        job: orderDetail.order.stuartJob[0],
                        stuartOrder: orderDetail.order,
                    },
                    {
                        location: false,
                    },
                    'stuartInfos',
                )
                .then(function () {
                    orderDetail.update();
                });
        }
    };

    vm.detailStuartV2 = function () {
        if (orderDetail.order.deliveryStatus && vm.stuartV2Active) {
            statePromise
                .go(
                    'home.orders_home.stuart_v2.infos_v2',
                    {
                        stuartV2Order: vm.order,
                    },
                    {
                        location: false,
                    },
                    'stuartV2',
                )
                .finally(function () {
                    vm.updateDeliveryStatus();
                    orderDetail.update();
                });
        }
    };

    vm.cancelOrder = function () {
        statePromise
            .go(
                '.modal_refund.digicode',
                {
                    orderToCancel: vm.order,
                },
                {
                    location: false,
                },
                'refundOrder',
            )
            .then(function () {
                orderService.loop.forceUpdateAll();
                orderDetail.update();
                $state.go('^');
            });
    };

    vm.refuseOrder = function () {
        vm.isLoading = true;
        displayedOrders.previous(orderDetail.order.orderId).then(function () {
            vm.isLoading = false;
            $state.go('^');
        });
    };

    vm.nextStatusGroup = function () {
        vm.isLoading = true;
        displayedOrders.next(orderDetail.order.orderId).then(function () {
            orderDetail.update().then(function () {
                vm.isLoading = false;
                $state.go('^');
            });
        });
    };

    vm.setToPrepared = function () {
        vm.isLoading = true;
        displayedOrders.prepared(orderDetail.order.orderId).then(function () {
            vm.isLoading = false;
            $state.go('^');
        });
    };

    vm.shouldDisplayStuartV2DetailsButton = function () {
        return (
            // I would have used +vm.order.consommationMode to cast to number
            // But my teammates preferred a more readable way during the code review
            Number(vm.order.consommationMode) === ENV_VARS.CONSOMMATION_MODES.DELIVERY &&
            vm.stuartV2Active &&
            typeof vm.order.deliveryStatus === 'number' &&
            vm.order.deliveryStatus !== OrderDeliveryStatus.DELIVERY_QUOTE_OPEN
        );
    };

    $scope.$on('$destroy', function () {
        orderDetail.stop();
        if (interval) {
            $interval.cancel(interval);
        }
        if (vm.reloadInterval) {
            $interval.cancel(vm.reloadInterval);
        }
    });
}

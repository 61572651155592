import digicodeView from 'app/components/orders_home/detail/modal_refund/digicode/digicode.view.html';
import reasonView from 'app/components/orders_home/detail/modal_refund/reason/reason.view.html';
import pendingView from 'app/components/orders_home/detail/modal_refund/pending/pending.view.html';
import confirmView from 'app/components/orders_home/detail/modal_refund/confirm/confirm.view.html';

export default function ($stateProvider, $urlRouterProvider, $locationProvider) {
    'ngInject';

    $locationProvider.html5Mode(true);
    $stateProvider
        .state('home.orders_home.placed.detail.modal_refund.digicode', states.digicode())
        .state('home.orders_home.placed.detail.modal_refund.reason', states.reason())
        .state('home.orders_home.placed.detail.modal_refund.pending', states.pending())
        .state('home.orders_home.placed.detail.modal_refund.confirm', states.confirm())
        .state('home.orders_home.validated.detail.modal_refund.digicode', states.digicode())
        .state('home.orders_home.validated.detail.modal_refund.reason', states.reason())
        .state('home.orders_home.validated.detail.modal_refund.pending', states.pending())
        .state('home.orders_home.validated.detail.modal_refund.confirm', states.confirm())
        .state('home.orders_home.in_progress.detail.modal_refund.digicode', states.digicode())
        .state('home.orders_home.in_progress.detail.modal_refund.reason', states.reason())
        .state('home.orders_home.in_progress.detail.modal_refund.pending', states.pending())
        .state('home.orders_home.in_progress.detail.modal_refund.confirm', states.confirm())
        .state('home.orders_home.done.detail.modal_refund.digicode', states.digicode())
        .state('home.orders_home.done.detail.modal_refund.reason', states.reason())
        .state('home.orders_home.done.detail.modal_refund.pending', states.pending())
        .state('home.orders_home.done.detail.modal_refund.confirm', states.confirm());
}

// eslint-disable-next-line vars-on-top, no-var
var states = {
    digicode() {
        return {
            params: {
                message: null,
            },
            templateUrl: digicodeView,
            controller: 'DigicodeRefundController',
            controllerAs: 'digicodeRefundVm',
        };
    },
    reason() {
        return {
            params: {
                code: null,
            },
            templateUrl: reasonView,
            controller: 'reasonController',
            controllerAs: 'reasonVm',
        };
    },
    pending() {
        return {
            params: {
                code: null,
                cancellationReason: null,
                cancellationReasonDescription: null,
            },
            templateUrl: pendingView,
            controller: 'PendingRefundController',
            controllerAs: 'pendingRefundVm',
            resolve: {
                cancellation: [
                    'Order',
                    '$stateParams',
                    'ENV_VARS',
                    function (Order, $stateParams, ENV_VARS) {
                        if ($stateParams.orderToCancel.isCancellable) {
                            return Order.cancel({
                                digicode: $stateParams.code,
                                orderId: parseInt($stateParams.orderToCancel.orderId, 10),
                                source: ENV_VARS.SOURCE,
                                cancellationReason: $stateParams.cancellationReason,
                                cancellationReasonDescription: $stateParams.cancellationReasonDescription,
                            });
                        }
                        return Order.refund({
                            digicode: $stateParams.code,
                            orderId: parseInt($stateParams.orderToCancel.orderId, 10),
                            source: ENV_VARS.SOURCE,
                            cancellationReason: $stateParams.cancellationReason,
                            cancellationReasonDescription: $stateParams.cancellationReasonDescription,
                        });
                    },
                ],
                isCancel: [
                    '$stateParams',
                    function ($stateParams) {
                        return $stateParams.orderToCancel.isCancellable;
                    },
                ],
            },
        };
    },
    confirm() {
        return {
            params: {
                isCancel: null,
            },
            templateUrl: confirmView,
            controller: 'ConfirmRefundController',
            controllerAs: 'confirmRefundVm',
        };
    },
};

export default function ($state) {
    'ngInject';

    const homeVm = this;
    homeVm.loadOrders = function () {
        if (!$state.includes('home.orders_home')) {
            $state.go('home.orders_home.placed', {}, { reload: 'home.orders_home' });
        }
    };
    homeVm.loadAvailability = function () {
        if (!$state.includes('home.availability')) {
            $state.go('home.availability');
        }
    };
    homeVm.loadParameters = function () {
        if (!$state.includes('home.parameters')) {
            $state.go('home.parameters.rush');
        }
    };
}

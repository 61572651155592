"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

require("core-js/modules/es.function.name");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = void 0;

var _angular = _interopRequireDefault(require("angular"));

var _api = _interopRequireDefault(require("../api/api.module"));

var _order_ticket = _interopRequireDefault(require("./order_ticket.service"));

var dependencies = [_api.default];

_angular.default.module('core.orderTicket', dependencies).service('orderTicketService', _order_ticket.default).run(["orderTicketService", function (orderTicketService) {
  'ngInject';

  orderTicketService.eventListener();
}]);

var _default = _angular.default.module('core.orderTicket').name;

exports.default = _default;
"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = void 0;
var _default = {
  template: '<div class="order-item-options">' + '<div class="order-item-option">' + '<span ng-if="orderStepProductVm.product.quantity > 1">{{orderStepProductVm.product.quantity}}x</span>' + '{{orderStepProductVm.product.name}}' + '<span ng-if="orderStepProductVm.product.unitPrice > 0"> (+{{::orderStepProductVm.product.unitPrice | bigDecimal:orderStepProductVm.currency.fraction | isoCurrency:orderStepProductVm.currency.code}})</span>' + '<span ng-if="orderStepProductVm.product.unitPrice === 0 && orderStepProductVm.product.price > 0"> (+{{::orderStepProductVm.product.price | bigDecimal:orderStepProductVm.currency.fraction | isoCurrency:orderStepProductVm.currency.code}})</span>' + '</div>' + '<div layout="row">' + '<div flex="10"></div>' + '<div flex="90" layout="column">' + '<order-step ng-repeat="step in orderStepProductVm.product.steps" step="step" currency="orderStepProductVm.currency">' + '</order-step>' + '</div>' + '</div>' + '</div>',
  controller: function controller() {
    'ngInject';
  },
  controllerAs: 'orderStepProductVm',
  bindings: {
    product: '=',
    currency: '<'
  }
};
exports.default = _default;
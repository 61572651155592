import formMessagesView from './form_messages.view.html';
export default function($templateCache) {
  'ngInject';

  function link(scope, element, attrs, form) {
    scope.form = form;
  }

  return {
    link: link,
    require: '^form',
    scope: true,
    restrict: 'E',
    template: $templateCache.get(formMessagesView),
  };
}

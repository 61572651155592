export default function (statePromise) {
    'ngInject';

    const vm = this;
    vm.validate = function () {
        statePromise.resolve({}, 'stuartCancel');
    };
    vm.cancel = function () {
        statePromise.reject('stuartCancel');
    };
}

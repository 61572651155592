import inputMessageView from './input_message.view.html';
export default {
  templateUrl: inputMessageView,
  require: {
    form: '^form',
  },
  controller: function() {
    'ngInject';

    var vm = this;
  },
  bindings: {
    field: '=',
    isInvalid: '=',
    validators: '=',
  },
  controllerAs: 'validatorVm',
  replace: true,
};

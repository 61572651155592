"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = _ngInjectExport;

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/objectWithoutProperties"));

_ngInjectExport.$inject = ["$resource", "$q", "$log", "ENV_VARS", "$translate"];

function _ngInjectExport($resource, $q, $log, ENV_VARS, $translate) {
  'ngInject';

  var apiService = this; // controller constants
  // =============================================================================

  var CONSTANTS = {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    DELETE: 'DELETE',
    API_URL: ENV_VARS.API_URL
  }; // Public methods & properties
  // =============================================================================

  apiService.requireApi = function () {
    return apiLoaded.promise;
  };

  apiService.welcome = $resource(ENV_VARS.API_URL, {}, {
    get: {
      method: CONSTANTS.GET,
      transformResponse: parseData
    }
  });
  apiService.menu = $resource(CONSTANTS.API_URL + '/menus/:menuId', {
    menuId: '@id'
  }, {
    get: {
      method: CONSTANTS.GET,
      transformResponse: parseData
    }
  });
  apiService.copyProduct = $resource(CONSTANTS.API_URL + '/menus/copy_product', {
    productIds: '@productIds',
    restaurantIds: '@restaurantIds'
  });
  apiService.restaurant = $resource(CONSTANTS.API_URL + '/restaurants/:restaurantId/:action', {
    restaurantId: '@restaurantId'
  }, {
    get: {
      method: CONSTANTS.GET,
      params: {
        restaurantId: '@restaurantId'
      },
      transformResponse: parseData
    },
    update: {
      method: CONSTANTS.PUT,
      transformResponse: parseData
    },
    updateV2: {
      url: CONSTANTS.API_URL + '/brand/:brandId/restaurants/:restaurantId',
      method: CONSTANTS.PUT,
      params: {
        brandId: '@brandId',
        restaurantId: '@restaurantId'
      },
      transformResponse: parseData
    },
    query: {
      method: CONSTANTS.GET,
      isArray: true,
      transformResponse: parseData
    },
    getAreas: {
      method: CONSTANTS.GET,
      isArray: true,
      params: {
        action: 'areas',
        restaurantId: '@id'
      },
      transformResponse: parseData
    },
    save: {
      method: CONSTANTS.POST,
      transformResponse: parseData
    },
    saveV2: {
      url: CONSTANTS.API_URL + '/brand/:brandId/restaurants',
      method: CONSTANTS.POST,
      params: {
        brandId: '@brandId'
      },
      transformResponse: parseData
    },
    getKitchenDisplay: {
      method: CONSTANTS.GET,
      params: {
        action: 'kitchen_display',
        restaurantId: '@restaurantId'
      },
      transformResponse: parseData
    },
    activateKitchenDisplay: {
      method: CONSTANTS.POST,
      params: {
        action: 'kitchen_display',
        restaurantId: '@restaurantId'
      },
      transformResponse: parseData
    },
    disactivateKitchenDisplay: {
      method: CONSTANTS.DELETE,
      params: {
        action: 'kitchen_display',
        restaurantId: '@restaurantId'
      },
      transformResponse: parseData
    },
    saveKitchenDisplay: {
      method: CONSTANTS.PUT,
      params: {
        action: 'kitchen_display',
        restaurantId: '@restaurantId'
      },
      transformResponse: parseData
    },
    saveOptions: {
      method: CONSTANTS.PUT,
      transformResponse: parseData,
      params: {
        action: 'options'
      }
    },
    saveLastShiftVerificationHour: {
      method: CONSTANTS.PUT,
      params: {
        action: 'shifts_closure_hour',
        restaurantId: '@restaurantId'
      },
      transformResponse: parseData,
      transformRequest: function transformRequest(data) {
        var restaurantId = data.restaurantId,
            rest = (0, _objectWithoutProperties2.default)(data, ["restaurantId"]);
        return angular.toJson(rest);
      }
    },
    getRestaurantPosShiftStatus: {
      url: CONSTANTS.API_URL + '/v1/restaurants/:restaurantId/pos_shifts/:date',
      method: CONSTANTS.GET,
      params: {
        restaurantId: '@restaurantId',
        date: '@date'
      },
      transformResponse: parseData
    }
  });
  apiService.areas = $resource(CONSTANTS.API_URL + '/restaurants/:restaurantId/areas', {
    restaurantId: '@id'
  }, {
    query: {
      method: CONSTANTS.GET,
      isArray: true,
      params: {
        restaurantId: '@restaurantId'
      },
      transformResponse: parseData
    },
    save: {
      method: CONSTANTS.POST,
      isArray: true,
      params: {
        restaurantId: '@restaurantId'
      },
      transformResponse: parseData
    }
  });
  apiService.brand = $resource(CONSTANTS.API_URL + '/brands/:brandId/:action', {
    brandId: '@brandId'
  }, {
    get: {
      method: CONSTANTS.GET,
      params: {
        brandId: '@brandId'
      },
      transformResponse: parseData
    },
    update: {
      method: CONSTANTS.PUT,
      transformResponse: parseData
    },
    query: {
      method: CONSTANTS.GET,
      isArray: true,
      transformResponse: parseData
    },
    closestRestaurant: {
      method: CONSTANTS.GET,
      params: {
        brandId: '@brandId',
        action: 'closestRestaurant'
      },
      transformResponse: parseData
    },
    deliveryAddress: {
      method: CONSTANTS.GET,
      params: {
        brandId: '@brandId',
        action: 'findRestaurantByDeliveryAddress'
      },
      transformResponse: parseData
    },
    getRestaurants: {
      method: CONSTANTS.GET,
      isArray: true,
      params: {
        brandId: '@brandId',
        action: 'restaurants'
      },
      transformResponse: parseData
    },
    getAreas: {
      method: CONSTANTS.GET,
      isArray: true,
      params: {
        action: 'areas',
        brandId: '@id'
      },
      transformResponse: parseData
    },
    getEntranceFees: {
      method: CONSTANTS.GET,
      isArray: true,
      params: {
        action: 'entrance_fees',
        brandId: '@id'
      },
      transformResponse: parseData
    },
    getGrants: {
      method: CONSTANTS.GET,
      isArray: true,
      params: {
        action: 'grants',
        brandId: '@id'
      },
      transformResponse: parseData
    },
    getFamilies: {
      url: CONSTANTS.API_URL + '/brand/:brandId/brand_restaurant_families',
      method: CONSTANTS.GET,
      isArray: true,
      params: {
        brandId: '@id'
      },
      transformResponse: parseData
    },
    save: {
      method: CONSTANTS.POST,
      transformResponse: parseData
    },
    saveOptions: {
      method: CONSTANTS.PUT,
      transformResponse: parseData,
      params: {
        action: 'options'
      }
    },
    getOptions: {
      method: CONSTANTS.GET,
      transformResponse: parseData,
      params: {
        action: 'options'
      }
    },
    importUsers: {
      url: CONSTANTS.API_URL + '/brands/:brandId/customers/import',
      params: {
        brandId: '@brandId'
      },
      method: CONSTANTS.POST,
      transformResponse: parseData
    }
  });
  apiService.brandChannels = $resource(CONSTANTS.API_URL + '/brands/:brandId/channels/:channelId/:action', {
    brandId: '@brandId',
    channelId: '@channelId'
  }, {
    get: {
      method: CONSTANTS.GET,
      params: {
        brandId: '@brandId'
      },
      transformResponse: parseData
    },
    query: {
      method: CONSTANTS.GET,
      isArray: true,
      transformResponse: parseData
    },
    enableChannel: {
      method: CONSTANTS.PUT,
      params: {
        brandId: '@brandId',
        channelId: '@channelId'
      },
      transformResponse: parseData
    },
    disableChannel: {
      method: CONSTANTS.DELETE,
      params: {
        brandId: '@brandId',
        channelId: '@channelId'
      },
      transformResponse: parseData
    },
    forceWebsiteUpdate: {
      method: CONSTANTS.POST,
      params: {
        brandId: '@brandId',
        channelId: 'web_ordering',
        action: 'force_update'
      },
      transformResponse: parseData
    }
  });
  apiService.brandModules = $resource(CONSTANTS.API_URL + '/brands/:brandId/modules', {
    brandId: '@brandId'
  }, {
    get: {
      method: CONSTANTS.GET,
      params: {
        brandId: '@brandId'
      },
      transformResponse: parseData
    },
    post: {
      method: CONSTANTS.POST,
      params: {
        brandId: '@brandId'
      },
      transformResponse: parseData
    },
    delete: {
      method: CONSTANTS.DELETE,
      params: {
        brandId: '@brandId'
      },
      transformResponse: parseData
    },
    query: {
      method: CONSTANTS.GET,
      isArray: true,
      transformResponse: parseData
    }
  });
  apiService.restaurantChannels = $resource(CONSTANTS.API_URL + '/restaurants/:restaurantId/channels/:channelId', {
    restaurantId: '@restaurantId',
    channelId: '@channelId'
  }, {
    get: {
      method: CONSTANTS.GET,
      params: {
        restaurantId: '@restaurantId'
      },
      transformResponse: parseData
    },
    query: {
      method: CONSTANTS.GET,
      isArray: true,
      transformResponse: parseData
    },
    enableChannel: {
      method: CONSTANTS.PUT,
      params: {
        restaurantId: '@restaurantId',
        channelId: '@channelId'
      },
      transformResponse: parseData
    },
    disableChannel: {
      method: CONSTANTS.DELETE,
      params: {
        restaurantId: '@restaurantId',
        channelId: '@channelId'
      },
      transformResponse: parseData
    }
  });
  apiService.restaurantModules = $resource(CONSTANTS.API_URL + '/restaurants/:restaurantId/modules', {
    restaurantId: '@restaurantId'
  }, {
    get: {
      method: CONSTANTS.GET,
      params: {
        restaurantId: '@restaurantId'
      },
      transformResponse: parseData
    },
    post: {
      method: CONSTANTS.POST,
      params: {
        restaurantId: '@restaurantId'
      },
      transformResponse: parseData
    },
    delete: {
      method: CONSTANTS.DELETE,
      params: {
        restaurantId: '@restaurantId'
      },
      transformResponse: parseData
    },
    query: {
      method: CONSTANTS.GET,
      isArray: true,
      transformResponse: parseData
    }
  });
  apiService.schedule = $resource(CONSTANTS.API_URL + '/restaurants/:restaurantId/schedules/', {
    restaurantId: '@restaurantId'
  }, {
    post: {
      method: CONSTANTS.POST,
      params: {
        restaurantId: '@restaurantId'
      },
      isArray: true,
      transformResponse: parseData
    },
    query: {
      method: CONSTANTS.GET,
      params: {
        restaurantId: '@restaurantId'
      },
      isArray: true,
      transformResponse: parseData
    }
  });
  apiService.forgottenPassword = $resource(CONSTANTS.API_URL + '/users/forgottenPassword/:token/:userId/:brandId', {
    token: '@token',
    userId: '@userId',
    brandId: '@brandiId'
  }, {
    get: {
      method: CONSTANTS.GET,
      transformResponse: parseData
    },
    post: {
      method: CONSTANTS.POST,
      transformResponse: parseData
    },
    put: {
      method: CONSTANTS.PUT,
      transformResponse: parseData
    }
  });
  apiService.forgottenPasswordCustomer = $resource(CONSTANTS.API_URL + '/customers/forgottenPassword/:token/:customerId/:brandId', {
    token: '@token',
    customerId: '@customerId',
    brandId: '@brandiId'
  }, {
    get: {
      method: CONSTANTS.GET,
      transformResponse: parseData
    },
    post: {
      method: CONSTANTS.POST,
      transformResponse: parseData
    },
    put: {
      method: CONSTANTS.PUT,
      transformResponse: parseData
    }
  });
  apiService.forgottenPasswordCustomerPost = $resource(CONSTANTS.API_URL + '/customers/forgottenPassword', {}, {
    post: {
      method: CONSTANTS.POST,
      transformResponse: parseData
    }
  });
  apiService.forgottenPasswordCustomerGet = $resource(CONSTANTS.API_URL + '/customers/forgottenPassword/:token/:customerId/:brandId', {
    token: '@token',
    customerId: '@customerId',
    brandId: '@brandId'
  }, {
    get: {
      method: CONSTANTS.GET,
      transformResponse: parseData
    }
  });
  apiService.forgottenPasswordCustomerPut = $resource(CONSTANTS.API_URL + '/customers/forgottenPassword/:token/:customerId', {
    token: '@token',
    customerId: '@customerId'
  }, {
    put: {
      method: CONSTANTS.PUT,
      transformResponse: parseData
    }
  });
  apiService.userAddresses = $resource(CONSTANTS.API_URL + '/users/:userId/addresses/:addressId', {
    userId: '@userId',
    addressId: '@addressId'
  }, {
    get: {
      method: CONSTANTS.GET,
      params: {
        userId: '@userId'
      },
      transformResponse: parseData
    },
    update: {
      method: CONSTANTS.PUT,
      params: {
        userId: '@userId',
        addressId: '@addressId'
      },
      transformResponse: parseData
    }
  });
  apiService.orderTicket = $resource(CONSTANTS.API_URL + '/order_ticket', {}, {
    get: {
      method: CONSTANTS.GET,
      isArray: true,
      params: {
        orderId: '@orderId'
      },
      transformResponse: parseData
    },
    post: {
      method: CONSTANTS.POST,
      transformResponse: parseData
    },
    put: {
      method: CONSTANTS.PUT,
      transformResponse: parseData
    }
  });
  apiService.currentMinimumUrssaf = $resource(CONSTANTS.API_URL + '/minimum_urssaf/current', {}, {
    get: {
      method: CONSTANTS.GET,
      transformResponse: parseData
    }
  });
  apiService.minimumUrssaf = $resource(CONSTANTS.API_URL + '/minimum_urssaf', {}, {
    post: {
      method: CONSTANTS.POST,
      transformResponse: parseData
    }
  });
  apiService.allMinimumUrssaf = $resource(CONSTANTS.API_URL + '/minimum_urssaf/', {}, {
    get: {
      method: CONSTANTS.GET,
      isArray: true,
      transformResponse: parseData
    }
  });
  apiService.updateMinimumUrssaf = $resource(CONSTANTS.API_URL + '/minimum_urssaf/:minimumUrssafConfigId', {
    minimumUrssafConfigId: '@minimumUrssafConfigId'
  }, {
    put: {
      method: CONSTANTS.PUT,
      transformResponse: parseData
    }
  });
  apiService.deleteMinimumUrssaf = $resource(CONSTANTS.API_URL + '/minimum_urssaf/:minimumUrssafConfigId', {
    minimumUrssafConfigId: '@minimumUrssafConfigId'
  }, {
    delete: {
      method: CONSTANTS.DELETE,
      transformResponse: parseData
    }
  });
  apiService.resetTransactions = $resource(CONSTANTS.API_URL + '/brands/:brandId/reset_transactions', {
    brandId: '@brandId'
  }, {
    put: {
      method: CONSTANTS.PUT,
      transformResponse: parseData
    }
  });
  apiService.lastRaz = $resource(CONSTANTS.API_URL + '/brands/:brandId/last_raz', {
    brandId: '@brandId'
  }, {
    get: {
      method: CONSTANTS.GET,
      isArray: false,
      transformResponse: parseData
    }
  });
  apiService.cancelLastRaz = $resource(CONSTANTS.API_URL + '/brands/:brandId/rollback_last_raz', {
    brandId: '@brandId'
  }, {
    delete: {
      method: CONSTANTS.DELETE,
      isArray: false,
      transformResponse: parseData
    }
  });
  apiService.autoraz = $resource(CONSTANTS.API_URL + '/brands/:brandId/auto_raz', {
    brandId: '@brandId'
  }, {
    post: {
      method: CONSTANTS.POST,
      isArray: false,
      transformResponse: parseData
    },
    get: {
      method: CONSTANTS.GET,
      isArray: true,
      transformResponse: parseData
    },
    delete: {
      method: CONSTANTS.DELETE,
      isArray: false,
      transformResponse: parseData
    }
  });
  apiService.razContactEmail = $resource(CONSTANTS.API_URL + '/brands/:brandId/raz_contact_email', {
    brandId: '@brandId'
  }, {
    post: {
      method: CONSTANTS.POST,
      isArray: false,
      transformResponse: parseData
    },
    get: {
      method: CONSTANTS.GET,
      isArray: true,
      transformResponse: parseData
    }
  });
  apiService.reloadKiosk = $resource(CONSTANTS.API_URL + '/restaurants/kiosk/reload?restaurantId=:restaurantId', {
    restaurantId: '@restaurantId'
  }, {
    post: {
      params: {
        restaurantId: '@restaurantId'
      },
      method: CONSTANTS.POST,
      isArray: false,
      transformResponse: parseData
    }
  }); // Private methods & properties
  // =============================================================================

  var apiLoaded = $q.defer();

  function parseData(response) {
    if (response) {
      try {
        var body = angular.fromJson(response);

        if (body && body.data) {
          return body.data;
        }

        return body;
      } catch (e) {
        $log.error(e);
        return e;
      }
    } else {
      return null;
    }
  }

  var initialize = function initialize() {
    var welcome = new apiService.welcome();
    welcome.$get().then(function () {
      apiLoaded.resolve();
    }).catch(function (error) {
      $log.error(error);
      apiLoaded.reject();
    });
  }; // Initialization
  // =============================================================================


  initialize();
}
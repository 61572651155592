import angular from 'angular';
import closeUbereatsController from './close_ubereats.controller';
import closeTabsModule from '../close/close_tabs/close_tabs.module';

const dependencies = [closeTabsModule];

angular
    .module('app.home.parameters.close_ubereats', dependencies)
    .controller('CloseUbereatsController', closeUbereatsController);
export default angular.module('app.home.parameters.close_ubereats').name;

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
export default function (StuartV2, $interval, $state, statePromise, $stateParams, $timeout) {
    'ngInject';

    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    const vm = this;
    StuartV2.createJob({
        orderId: $stateParams.orderId,
        transportType: $stateParams.transport,
    })
        .$promise.then(function (data) {
            statePromise.resolve({ data }, 'stuartV2Search');
        })
        // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
        .catch(function (e) {
            statePromise.reject('stuartV2Search');
        });
}

"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = _ngInjectExport;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/defineProperty"));

var _apmRum = require("@elastic/apm-rum");

var _uuid = require("uuid");

_ngInjectExport.$inject = ["ENV_VARS"];

function _ngInjectExport(ENV_VARS) {
  'ngInject';

  console.debug('APM init...');
  var apmService = this;
  apmService.enabled = false;
  apmService.isHttpInterceptorEnabled = false;

  if (ENV_VARS.ELASTIC_APM_ACTIVE) {
    console.debug('APM is enabled');
    apmService.enabled = true;
    apmService.isHttpInterceptorEnabled = true;
    var apm = (0, _apmRum.init)({
      environment: ENV_VARS.ELASTIC_APM_ENVIRONMENT,
      serviceName: ENV_VARS.ELASTIC_APM_SERVICE_NAME,
      serverUrl: ENV_VARS.ELASTIC_APM_SERVER_URL,
      serviceVersion: ENV_VARS.APP_VERSION,
      distributedTracing: true,
      distributedTracingOrigins: [ENV_VARS.API_URL]
    });
    apmService.apm = apm;
  }

  apmService.setSessionId = function () {
    if (!apmService.enabled) return;
    apmService.apm.addLabels({
      sessionId: (0, _uuid.v4)()
    });
  };

  apmService.setUserContext = function (_ref) {
    var id = _ref.id,
        username = _ref.username,
        email = _ref.email;
    if (!apmService.enabled) return;
    apmService.apm.setUserContext({
      id: id,
      username: username,
      email: email
    });
  };

  apmService.addLabel = function (key, value) {
    if (!apmService.enabled) return;
    apmService.apm.addLabels((0, _defineProperty2.default)({}, key, value));
  };

  apmService.enableHttpInterceptor = function (value) {
    apmService.isHttpInterceptorEnabled = value;
  };

  apmService.startTransaction = function (name) {
    if (!apmService.enabled) return {
      transaction: null,
      span: null
    };
    var apm = apmService.apm;
    var transaction = apm.startTransaction(name, 'custom', {
      managed: true
    });
    var span = transaction.startSpan(name, 'custom', {
      blocking: true
    });
    apmService.isHttpInterceptorEnabled = false;
    return {
      transaction: transaction,
      span: span
    };
  };

  apmService.endTransaction = function (transaction) {
    if (transaction) {
      transaction.end();
    }

    apmService.isHttpInterceptorEnabled = true;
  };
}
"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = _ngInjectExport;

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/objectWithoutProperties"));

_ngInjectExport.$inject = ["$resource", "$log", "$q", "RESOURCES_VARS", "ENV_VARS"];

function _ngInjectExport($resource, $log, $q, RESOURCES_VARS, ENV_VARS) {
  'ngInject';

  function parseData(response) {
    try {
      var body = angular.fromJson(response);

      if (body && body.data) {
        return body.data;
      }

      return body;
    } catch (e) {
      $log.error(e);
      return e;
    }
  }

  function extractParamsFromRequestBody(data) {
    try {
      var transactionId = data.transactionId,
          brandId = data.brandId,
          rest = (0, _objectWithoutProperties2.default)(data, ["transactionId", "brandId"]);
      return angular.toJson(rest);
    } catch (e) {
      $log.error(e);
      return e;
    }
  }

  return $resource(ENV_VARS.API_URL + '/ewallet/:action', {
    brandId: '@brandId',
    transactionId: '@transactionId'
  }, {
    setBalance: {
      method: RESOURCES_VARS.API_METHODS.PUT,
      transformResponse: parseData,
      params: {
        action: 'balance'
      }
    },
    creditCustomerWithAdyen: {
      url: ENV_VARS.API_URL + '/ewallet/:brandId/customers/credit/adyen',
      method: RESOURCES_VARS.API_METHODS.POST,
      transformResponse: parseData,
      transformRequest: extractParamsFromRequestBody
    },
    resumeCreditCustomerWithAdyen: {
      url: ENV_VARS.API_URL + '/ewallet/:brandId/customers/credit/adyen/:transactionId/resume',
      method: RESOURCES_VARS.API_METHODS.POST,
      transformResponse: parseData,
      transformRequest: extractParamsFromRequestBody
    },
    createTransactionDraft: {
      url: ENV_VARS.API_URL + '/transaction_drafts',
      method: RESOURCES_VARS.API_METHODS.POST,
      transformResponse: parseData
    },
    validateTransactionDraft: {
      url: ENV_VARS.API_URL + '/transaction_drafts/:transactionDraftId/validate',
      method: RESOURCES_VARS.API_METHODS.POST,
      transformResponse: parseData,
      transformRequest: extractParamsFromRequestBody
    },
    cancelTransactionDraft: {
      url: ENV_VARS.API_URL + '/transaction_drafts/:transactionDraftId/cancel',
      method: RESOURCES_VARS.API_METHODS.POST,
      transformResponse: parseData,
      transformRequest: extractParamsFromRequestBody
    }
  });
}
export default function() {
  return {
    priority: 0,
    require: '^uiGrid',
    compile: function() {
      return {
        pre: function(scope, $element, attrs, uiGridCtrl) {
          uiGridCtrl.grid.options.enableHovering = true;
        },
        post: function() {},
      };
    },
  };
}

/**
 *  Helper factory
 *  ---------------------------------------------------
 */
export default function() {
  'ngInject';

  function offset(elt) {
    var rect = elt.getBoundingClientRect(),
      bodyElt = document.body;
    return {
      top: rect.top + bodyElt.scrollTop,
      left: rect.left + bodyElt.scrollLeft,
    };
  }

  function outerHeight(el) {
    var height = el.offsetHeight;
    var style = getComputedStyle(el);

    height += parseInt(style.marginTop) + parseInt(style.marginBottom);
    return height;
  }

  function outerWidth(el) {
    var width = el.offsetWidth;
    var style = getComputedStyle(el);

    width += parseInt(style.marginLeft) + parseInt(style.marginRight);
    return width;
  }

  function eventListen(element, eventName, callback) {
    if (element.addEventListener) {
      element.addEventListener(eventName, callback, false);
    } else if (element.attachEvent) {
      element.attachEvent('on' + eventName, callback);
    }
  }

  return {
    offset: offset,
    outerHeight: outerHeight,
    outerWidth: outerWidth,
    eventListen: eventListen,
  };
}

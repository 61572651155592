"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

require("core-js/modules/es.function.name");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = _ngInjectExport;
_ngInjectExport.$inject = ["$state", "$previousState", "$q"];

function _ngInjectExport($state, $previousState, $q) {
  'ngInject';

  var self = this;
  var transition = null;
  var promises = {};

  self.know = function (name) {
    return promises[name] ? true : false;
  };

  self.go = function (toState, params, options, name, force) {
    if (!$state.is(toState) || force) {
      name = name || toState;

      if (options && options.override) {
        var previousState = $previousState.get(options.override);

        if (previousState && !$state.is(previousState.state.name)) {
          transition = $state.go(toState, params, options);
          $previousState.set(name, previousState.state.name, previousState.params);
          promises[name] = promises[options.override];
          self.forget(options.override);
          transition.then(function () {
            transition = null;
          });
        }
      } else {
        transition = $state.go(toState, params, options);
        $previousState.memo(name);
        promises[name] = $q.defer();
        transition.then(function () {
          transition = null;
        });
      }

      return promises[name].promise;
    } else {
      return $q.reject('already loaded');
    }
  };

  self.resolve = function (value, name, options, params) {
    if (!transition) {
      name = name || $state.$current.name;
      var previousState = $previousState.get(name);

      if (previousState && !$state.is(previousState.state.name)) {
        if (options && options.reload && options.reload === '^') {
          options.reload = previousState.state.name;
        }

        if (params) {
          transition = $state.go($previousState.get(name).state.name, params, options);
        } else {
          transition = $previousState.go(name, options);
        }

        return transition.then(function () {
          if (promises[name]) {
            promises[name].resolve(value);
            self.forget(name);
          }

          transition = null;
        });
      }
    } else {
      return $q.reject('already loaded');
    }
  };

  self.reject = function (name, options, error) {
    if (!transition) {
      name = name || $state.$current.name;
      var previousState = $previousState.get(name);

      if (previousState && !$state.is(previousState.state.name)) {
        transition = $state.go(previousState.state.name, previousState.params, options);
        return transition.then(function () {
          if (promises[name]) {
            promises[name].reject(error);
            self.forget(name);
          }

          transition = null;
        });
      }
    } else {
      return $q.reject('already loaded');
    }
  };

  self.forget = function (name) {
    $previousState.forget(name);
    promises[name] = null;
  };
}
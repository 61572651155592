export default function (
    Order,
    $interval,
    ENV_VARS,
    ORDERS_VARS,
    printService,
    moment,
    localStorageService,
    $q,
    Stuart,
    $rootScope,
    webBridge,
    CONSUMPTION_MODES,
    constantService,
    $indexedDB,
) {
    'ngInject';

    const BRIDGE_MESSAGE = {
        PRINT_TICKET_SUCCEEDED: 'PRINT_TICKET_SUCCEEDED',
        RING: 'RING',
        RING_DRIVE: 'RING_DRIVE',
    };
    const orderService = this;
    let source;
    let userId;

    const audioNotifier = {
        sound: {
            // eslint-disable-next-line
            newOrder: new Audio(ENV_VARS.STATIC_URL + ENV_VARS.NOTIFY_SOUND_URL),
            // eslint-disable-next-line
            driveOrder: new Audio(ENV_VARS.STATIC_URL + ENV_VARS.DRIVE_SOUND_URL),
        },
        interval: null,
        startNotification: () => {
            if (!audioNotifier.isNotifying()) {
                audioNotifier.interval = $interval(() => {
                    audioNotifier.singleNotification();
                }, 500);
            }
        },
        stopNotification: () => {
            if (audioNotifier.isNotifying()) {
                $interval.cancel(audioNotifier.interval);
                audioNotifier.interval = null;
            }
        },
        isNotifying: () => {
            return audioNotifier.interval !== null;
        },
        singleNotification: () => {
            if (orderService.configReception && orderService.configReception.ringAlert) {
                $rootScope.$broadcast(BRIDGE_MESSAGE.RING, {});
                audioNotifier.sound.newOrder.play();
            }
        },
        driveNotification: () => {
            $rootScope.$broadcast(BRIDGE_MESSAGE.RING_DRIVE, {});
            audioNotifier.sound.driveOrder.play();
        },
    };

    const getOrders = (statusGroup) => {
        const options = {
            statusGroup,
            channels: [],
        };
        if (userId) {
            options.user_id = userId;
        }
        if (orderService.configReception && orderService.configReception.restaurantId) {
            options.restaurant_id = orderService.configReception.restaurantId;
        }
        if (orderService.configReception.webChannelActivated) {
            options.channels.push(2);
        }
        if (orderService.configReception.kioskChannelActivated) {
            options.channels.push(1);
        }
        Order.getStatusGroup(options).$promise.then((orders) => {
            orderService.orders[statusGroup].onUpdate(orders);
            orderService.driveOrders.add(orders);
            orderService.updateCA();
            orderService.orders[statusGroup].onLoop();
        });
    };
    const onStatusGroupUpdate = (direction) => (orderId) => {
        const deferred = $q.defer();
        Order.updateStatusGroup({
            orderId,
            direction,
            source,
        })
            .$promise.then(() => {
                orderService.loop.forceUpdateAll();
                deferred.resolve(true);
            })
            .catch((err) => {
                deferred.reject(err);
            });
        return deferred.promise;
    };
    orderService.init = (configReception, initSource, requestUserId) => {
        source = initSource;
        userId = requestUserId;
        orderService.totalProvisionalCA = 0;
        orderService.configReception = configReception;
        orderService.loopPrint.printedOrders.recover().then(() => {
            orderService.loop.startAll();
        });

        $rootScope.$on(BRIDGE_MESSAGE.PRINT_TICKET_SUCCEEDED, (_event, orderId) => {
            orderService.loopPrint.printedOrders.list.push(orderId);
            orderService.loopPrint.printedOrders.save(orderId);
        });
        return orderService.orders;
    };

    orderService.driveOrders = {
        list: {},
        add: (orders) => {
            let lastAdded = null;
            for (let i = orders.length - 1; i >= 0; i--) {
                if (orders[i] && orders[i].driveStatus) {
                    if (
                        orders[i].driveStatus === 'CUSTOMER_ARRIVED' &&
                        orders[i].mainStatus !== 5 &&
                        orders[i].mainStatus !== 6
                    ) {
                        if (!orderService.driveOrders.list[orders[i].orderId]) {
                            lastAdded = orders[i];
                        }
                        orderService.driveOrders.list[orders[i].orderId] = orders[i];
                    } else {
                        orderService.driveOrders.remove(orders[i].orderId);
                    }
                }
            }
            if (lastAdded) {
                audioNotifier.driveNotification();
                if (orderService.driveOrders.onAddedCallback) {
                    orderService.driveOrders.onAddedCallback(lastAdded);
                }
            }
        },
        onAdded: (f) => {
            orderService.driveOrders.onAddedCallback = f;
        },
        onAddedCallback: null,
        reset: () => {
            orderService.driveOrders.list = {};
        },
        remove: (orderId) => {
            if (orderService.driveOrders.list[orderId]) {
                delete orderService.driveOrders.list[orderId];
                orderService.loop.forceUpdateAll();
            }
        },
    };

    orderService.receivedOrders = {
        list: [],
        update: (newList) => {
            let shouldRing = false;
            for (let i = 0; i < newList.length; i++) {
                if (!orderService.receivedOrders.exist(newList[i].orderId)) {
                    orderService.receivedOrders.list.push(newList[i].orderId);
                    shouldRing = true;
                }
            }
            return shouldRing;
        },
        exist: (id) => orderService.receivedOrders.list.indexOf(id) > -1,
    };

    orderService.configReception = {};

    orderService.loop = {
        startAll: () => {
            // eslint-disable-next-line no-restricted-syntax, guard-for-in
            for (const service in ENV_VARS.ORDERS_SERVICES) {
                orderService.loop.start(ENV_VARS.ORDERS_SERVICES[service]);
            }
        },
        forceUpdateAll: () => {
            // eslint-disable-next-line no-restricted-syntax, guard-for-in
            for (const service in ENV_VARS.ORDERS_SERVICES) {
                orderService.orders[ENV_VARS.ORDERS_SERVICES[service].NAME].forceUpdate();
            }
        },
        stopAll: () => {
            // eslint-disable-next-line no-restricted-syntax, guard-for-in
            for (const service in ENV_VARS.ORDERS_SERVICES) {
                orderService.loop.stop(ENV_VARS.ORDERS_SERVICES[service]);
            }
        },
        start: (service) => {
            getOrders(service.NAME);
            orderService.orders[service.NAME].interval = $interval(() => {
                getOrders(service.NAME);
            }, service.INTERVAL);
        },
        stop: (service) => {
            orderService.driveOrders.reset();
            if (orderService.orders[service.NAME].interval) {
                $interval.cancel(orderService.orders[service.NAME].interval);
                orderService.orders[service.NAME].interval = null;
                orderService.orders[service.NAME].reset();
            }
        },
    };

    orderService.printOrder = (order) => {
        if (order.mainStatus === 1) {
            // Update order main status before reload to avoid double next if printing stack
            order.mainStatus = 2;
            onStatusGroupUpdate('next')(order.orderId);
        }
        printService.print(order, { isEcoFriendlyTicket: false });
    };

    orderService.orders = {
        PLACED: {
            interval: null,
            count: 0,
            list: [],
            next: onStatusGroupUpdate('next'),
            prepared: onStatusGroupUpdate('prepared'),
            previous: onStatusGroupUpdate('previous'),
            onUpdate: (orders) => {
                orderService.orders.PLACED.count = orders.length;
                orderService.orders.PLACED.list = orders;
            },
            onLoop: () => {
                if (!orderService.configReception.ringAlert || orderService.orders.PLACED.list.length === 0) {
                    audioNotifier.stopNotification();
                } else {
                    audioNotifier.startNotification();
                }
                if (
                    orderService.receivedOrders.update(orderService.orders.PLACED.list) &&
                    !audioNotifier.isNotifying()
                ) {
                    audioNotifier.singleNotification();
                }
            },
            forceUpdate: () => {
                getOrders('PLACED');
            },
            reset: () => {
                orderService.orders.PLACED.count = 0;
                orderService.orders.PLACED.list = [];
            },
        },
        VALIDATED: {
            interval: null,
            count: 0,
            list: [],
            next: onStatusGroupUpdate('next'),
            prepared: onStatusGroupUpdate('prepared'),
            previous: onStatusGroupUpdate('previous'),
            listeners: {},
            onUpdate: (orders) => {
                orderService.orders.VALIDATED.count = orders.length;
                orderService.orders.VALIDATED.list = orders;
                orderService.loopPrint.add(orderService.orders.VALIDATED.list);
            },
            onLoop: () => {
                orderService.loopPrint.add(orderService.orders.VALIDATED.list);
                if (
                    orderService.receivedOrders.update(orderService.orders.VALIDATED.list) &&
                    !audioNotifier.isNotifying()
                ) {
                    audioNotifier.singleNotification();
                }
            },
            forceUpdate: () => {
                getOrders('VALIDATED');
            },
            reset: () => {
                orderService.orders.VALIDATED.count = 0;
                orderService.orders.VALIDATED.list = [];
            },
        },
        IN_PROGRESS: {
            interval: null,
            count: 0,
            list: [],
            next: onStatusGroupUpdate('next'),
            prepared: onStatusGroupUpdate('prepared'),
            previous: onStatusGroupUpdate('previous'),
            onUpdate: (orders) => {
                orderService.orders.IN_PROGRESS.count = orders.length;
                orderService.orders.IN_PROGRESS.list = orders;
                orderService.loopPrint.add(orderService.orders.IN_PROGRESS.list);
            },
            onLoop: () => {
                orderService.loopPrint.add(orderService.orders.IN_PROGRESS.list);
                if (
                    orderService.receivedOrders.update(orderService.orders.IN_PROGRESS.list) &&
                    !audioNotifier.isNotifying()
                ) {
                    audioNotifier.singleNotification();
                }
            },
            forceUpdate: () => {
                getOrders('IN_PROGRESS');
            },
            reset: () => {
                orderService.orders.IN_PROGRESS.count = 0;
                orderService.orders.IN_PROGRESS.list = [];
            },
        },
        DONE: {
            interval: null,
            count: 0,
            list: [],
            next: onStatusGroupUpdate('next'),
            prepared: onStatusGroupUpdate('prepared'),
            previous: onStatusGroupUpdate('previous'),
            onUpdate: (orders) => {
                orderService.orders.DONE.count = orders.length;
                orderService.orders.DONE.list = orders;
            },
            // Nothing to do in onLoop for completed orders
            // eslint-disable-next-line prettier/prettier
            onLoop: () => null,
            forceUpdate: () => {
                getOrders('DONE');
            },
            reset: () => {
                orderService.orders.DONE.count = 0;
                orderService.orders.DONE.list = [];
            },
        },
    };

    orderService.Detail = function (order) {
        const self = this;
        self.order = order;

        self.loop = {
            start: (interval) => {
                self.interval = $interval(() => {
                    self.update();
                }, interval || ORDERS_VARS.DETAIL_INTERVAL);
            },
            stop: () => {
                $interval.cancel(self.interval);
                self.interval = null;
            },
        };
        self.update = () => {
            if (self.order) {
                if (self.order.stuartJob && self.order.stuartJob.length > 0) {
                    const deliveryJobStatus = self.order.stuartJob[0].status;
                    if ([3, 4].indexOf(deliveryJobStatus) === -1) {
                        Stuart.getJob({
                            jobId: self.order.stuartJob[0].stuartJobId,
                            orderId: self.order.orderId,
                        }).$promise.then(() => {
                            orderService.loop.forceUpdateAll();
                            self.loop.stop();
                            if (deliveryJobStatus === 5) {
                                self.loop.start();
                            } else {
                                self.loop.start(ORDERS_VARS.DETAIL_WITH_DELIVERY_ONGOING_INTERVAL);
                            }
                            return self.order.$refresh().then(() => {
                                if (self.onUpdate) {
                                    self.onUpdate();
                                }
                            });
                        });
                    }
                }
                return self.order.$refresh().then(() => {
                    if (self.onUpdate) {
                        self.onUpdate();
                    }
                });
            }
            return null;
        };
        self.stop = () => {
            self.loop.stop();
            self.order = null;
            self.onUpdate = null;
        };

        self.loop.start();
        self.update();
        return self;
    };

    orderService.printRecap = () => {
        const typeReference = {
            products: {
                subtype: 'steps',
                id: 'productId',
            },
            steps: {
                subtype: 'products',
                id: 'stepId',
            },
        };
        const rawData = {
            price: 0,
            products: [],
        };
        const idReference = {};

        let orderList = [];

        if (printService.statusToPrint.placed) {
            orderList = orderList.concat(orderService.orders.VALIDATED.list);
        }
        if (printService.statusToPrint.preparing) {
            orderList = orderList.concat(orderService.orders.IN_PROGRESS.list);
        }

        if (printService.statusToPrint.done) {
            orderList = orderList.concat(orderService.orders.DONE.list);
        }

        orderList = orderList.filter(
            ({ mainStatus, paymentStatus }) => paymentStatus !== 3 && mainStatus !== 5 && paymentStatus !== 2,
        );

        function areEqual(p1, p2, type) {
            const { subtype, id: idRef } = typeReference[type];

            // Verify if they dont have the same id
            if (p1[idRef] !== p2[idRef]) {
                return false;
            }

            // Verify if one has subtypes and the other does not (steps, products) and if they dont have the same length
            if (
                (p1[subtype] && !p2[subtype]) ||
                (p2[subtype] && !p1[subtype]) ||
                (p1[subtype] && p2[subtype] && p1[subtype].length !== p2[subtype].length)
            ) {
                return false;
            }

            // Check their subtype
            if (p1[subtype]) {
                for (let j = 0; j < p1[subtype].length; j++) {
                    if (!areEqual(p1[subtype][j], p2[subtype][j], subtype)) {
                        return false;
                    }
                }
            }
            return true;
        }

        function findExistingProduct(product) {
            if (idReference[product.productId]) {
                for (let i = 0; i < idReference[product.productId].length; i++) {
                    const productReference = idReference[product.productId][i];
                    if (areEqual(product, rawData.products[productReference], 'products')) {
                        return productReference;
                    }
                }
            }
            return -1;
        }

        orderList.forEach(({ cartRawData }) => {
            const orderRawData = JSON.parse(cartRawData);
            orderRawData.products.forEach((product) => {
                const existingProductIndex = findExistingProduct(product);
                if (existingProductIndex > -1) {
                    rawData.products[existingProductIndex].quantity += product.quantity;
                    rawData.products[existingProductIndex].price += product.price;
                } else {
                    const newLength = rawData.products.push(angular.copy(product));
                    if (!idReference[product.productId]) {
                        idReference[product.productId] = [];
                    }
                    idReference[product.productId].push(newLength - 1);
                }
                rawData.price += product.price;
            });
        });
        const orderToPrint = angular.copy(orderList[0]);
        if (orderToPrint !== undefined) {
            orderToPrint.cartRawData = JSON.stringify(rawData);
            printService.printRecap(orderToPrint);
        }
    };

    orderService.updateCA = () => {
        orderService.totalProvisionalCA = 0;
        const startDay = moment().hours(0).minutes(0).seconds(1);
        const endDay = moment().add(1, 'd').hours(0).minutes(0).seconds(1);
        const totalOrders = orderService.orders.VALIDATED.list
            .concat(orderService.orders.IN_PROGRESS.list)
            .concat(orderService.orders.DONE.list);

        totalOrders.forEach((order) => {
            if (
                moment(order.expectedDate).isAfter(startDay) &&
                moment(order.expectedDate).isBefore(endDay) &&
                [1, 2, 3, 4].includes(order.mainStatus) &&
                order.paymentStatus !== 3
            ) {
                orderService.totalProvisionalCA += order.ttcTotalPrice;
            }
        });
    };

    orderService.loopPrint = {
        start: () => {
            orderService.loopPrint.status = 'RUNNING';
            while (orderService.loopPrint.toPrintOrders.length > 0) {
                const orderToPrint = orderService.loopPrint.toPrintOrders.pop();
                const isWebBridgeReady = webBridge.ready;
                const isOrderNotAlreadyPrinted =
                    orderService.loopPrint.printedOrders.list.indexOf(orderToPrint.orderId) === -1;
                const isOrderCurrentlyPrinting = !orderService.loopPrint.printingOrders.list[orderToPrint.orderId];

                if (isWebBridgeReady && isOrderNotAlreadyPrinted && isOrderCurrentlyPrinting) {
                    let delay = 0;
                    const consumptionModeName = constantService.getNameByValue(
                        CONSUMPTION_MODES,
                        orderToPrint.consommationMode,
                    );
                    delay = printService.printDelay[consumptionModeName];
                    const nowDate = moment();
                    const orderExpectedDate = moment(orderToPrint.expectedDate);
                    const isDelayPassed =
                        delay === 0 || moment(orderExpectedDate).subtract(delay, 'minutes').isSameOrBefore(nowDate);
                    const isExpectedDateSameAsToday = moment(orderExpectedDate).format('DDD') === nowDate.format('DDD');
                    const isExpectedDateWithinNextSevenHours = moment(orderExpectedDate)
                        .add(7, 'hour')
                        .isSameOrAfter(nowDate);

                    if (isDelayPassed && (isExpectedDateWithinNextSevenHours || isExpectedDateSameAsToday)) {
                        orderService.loopPrint.printingOrders.list[orderToPrint.orderId] = true;
                        orderService.printOrder(orderToPrint);
                    }
                }
            }
            orderService.loopPrint.status = 'STOPPED';
        },
        status: 'STOPPED',
        add: (orders) => {
            if (printService.autoPrint && Object.keys(printService.printers).length > 0) {
                const toPrintOrders = orderService.loopPrint.toPrintOrders.concat(orders);
                const toPrintUniqueOrders = toPrintOrders.filter(
                    ({ orderId }, index, self) => self.findIndex(({ orderId: oId }) => oId === orderId) === index,
                );
                orderService.loopPrint.toPrintOrders = toPrintUniqueOrders;
                if (orderService.loopPrint.status !== 'RUNNING') {
                    orderService.loopPrint.start();
                }
            }
        },
        toPrintOrders: [],
        printedOrders: {
            list: [],
            save: (orderId) => {
                if (!orderId) {
                    $indexedDB.openStore('printedOrders', (store) => {
                        store.clear().then(() => {
                            store.upsert(
                                orderService.loopPrint.printedOrders.list.map((oId) => ({
                                    orderId: oId,
                                })),
                            );
                        });
                    });
                } else {
                    let itemsToDelete = [];
                    if (orderService.loopPrint.printedOrders.list.length > ENV_VARS.QUEUE_LENGTH_PRINTED_ORDERS) {
                        itemsToDelete = orderService.loopPrint.printedOrders.list.splice(
                            0,
                            orderService.loopPrint.printedOrders.list.length - ENV_VARS.QUEUE_LENGTH_PRINTED_ORDERS,
                        );
                    }
                    $indexedDB.openStore('printedOrders', (store) => {
                        $q.all(itemsToDelete.map((i) => store.delete(i))).then(() => {
                            store.upsert({ orderId });
                        });
                    });
                }
            },
            recover: () => {
                const deferred = $q.defer();
                orderService.loopPrint.printedOrders.migrate().then(() => {
                    $indexedDB.openStore('printedOrders', (store) => {
                        store.getAllKeys().then((printedOrders) => {
                            if (printedOrders.length > 0) {
                                orderService.loopPrint.printedOrders.list = printedOrders;
                            }
                            deferred.resolve();
                        });
                    });
                });
                return deferred.promise;
            },
            migrate: () => {
                const deferred = $q.defer();
                let dataToRecover = localStorageService.cookie.get('printedOrders');
                if (!dataToRecover) {
                    return $q.resolve();
                }
                if (dataToRecover && !Array.isArray(dataToRecover)) {
                    dataToRecover = Object.keys(dataToRecover);
                }
                $indexedDB.openStore('printedOrders', (store) => {
                    const ordersToStore = dataToRecover.map(({ orderId }) => ({ orderId }));
                    store.upsert(ordersToStore).then(() => {
                        localStorageService.remove('printedOrders');
                        deferred.resolve();
                    });
                });
                return deferred.promise;
            },
        },
        printingOrders: {
            list: {},
        },
    };
}

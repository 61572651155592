"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty2 = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

require("core-js/modules/es.function.name");

_Object$defineProperty2(exports, "__esModule", {
  value: true
});

exports.default = _ngInjectExport;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/define-property"));

var _defineProperties = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/define-properties"));

var _getOwnPropertyDescriptors = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors"));

var _forEach = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/for-each"));

var _getOwnPropertyDescriptor = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor"));

var _filter = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/filter"));

var _getOwnPropertySymbols = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols"));

var _keys = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/keys"));

var _map = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/map"));

var _defineProperty3 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/defineProperty"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/objectWithoutProperties"));

var _reduce = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/reduce"));

var _isArray = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/array/is-array"));

var _fromPairs = _interopRequireDefault(require("lodash/fromPairs"));

function ownKeys(object, enumerableOnly) { var keys = (0, _keys.default)(object); if (_getOwnPropertySymbols.default) { var symbols = (0, _getOwnPropertySymbols.default)(object); if (enumerableOnly) symbols = (0, _filter.default)(symbols).call(symbols, function (sym) { return (0, _getOwnPropertyDescriptor.default)(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { var _context3; (0, _forEach.default)(_context3 = ownKeys(Object(source), true)).call(_context3, function (key) { (0, _defineProperty3.default)(target, key, source[key]); }); } else if (_getOwnPropertyDescriptors.default) { (0, _defineProperties.default)(target, (0, _getOwnPropertyDescriptors.default)(source)); } else { var _context4; (0, _forEach.default)(_context4 = ownKeys(Object(source))).call(_context4, function (key) { (0, _defineProperty2.default)(target, key, (0, _getOwnPropertyDescriptor.default)(source, key)); }); } } return target; }

_ngInjectExport.$inject = ["$q", "MONEYLINE_V2", "$http", "$interval", "ENV_VARS"];

function _ngInjectExport($q, MONEYLINE_V2, $http, $interval, ENV_VARS) {
  'ngInject'; // Class variables

  var logService = console;
  var moneylineServiceV2 = this; // Class methods

  moneylineServiceV2.getState = function () {
    return moneylineServiceV2.getStatus().then(parseGetStatus);
  };

  moneylineServiceV2.isPaymentTerminalEnabled = function (currentState, currentParameters) {
    return ENV_VARS.ENV === 'development' || currentState[MONEYLINE_V2.PAYMENT_TERMINAL] === true && currentParameters[MONEYLINE_V2.PAYMENT_TERMINAL] === true;
  };

  moneylineServiceV2.isConecsEnabled = function (totalPrice, currentState, currentParameters) {
    return moneylineServiceV2.isPaymentTerminalEnabled(currentState, currentParameters) && currentParameters.ConecsMaxAmount && currentParameters.ConecsMaxAmount > 0 && currentParameters[MONEYLINE_V2.CONECS] && totalPrice <= currentParameters.ConecsMaxAmount;
  };

  moneylineServiceV2.isPrinterEnabled = function (currentState, currentParameters) {
    return currentState[MONEYLINE_V2.PRINTER_RECEIPT] === true && currentParameters[MONEYLINE_V2.PRINTER_RECEIPT] === true;
  }; // API Services


  moneylineServiceV2.getConfiguration = function () {
    return getMoneylineHttpWrapper('http://localhost:8081/api/v2.2/Management/GetConfiguration').then(parseGetConfiguration).catch(function (err) {
      console.error(err);
      throw err;
    });
  };

  moneylineServiceV2.getStatus = function () {
    return getMoneylineHttpWrapper('http://localhost:8081/api/v2.2/Management/GetStatus');
  };

  moneylineServiceV2.printTicket = function (serializedTicketArray) {
    return postMoneylineHttpWrapper('http://localhost:8081/api/v2.2/Print/PrintModel', {
      PrintingModel: 'Ticket',
      Content: serializedTicketArray || []
    });
  };

  moneylineServiceV2.startPayment = function (orderId, amount) {
    return postMoneylineHttpWrapper('http://localhost:8081/api/v2.2/Payment/StartPaymentCB', {
      ID: orderId,
      Amount: amount
    });
  };

  moneylineServiceV2.startPaymentConecs = function (orderId, amount) {
    return postMoneylineHttpWrapper('http://localhost:8081/api/v2.2/Payment/StartPaymentConecs', {
      ID: orderId,
      Amount: amount
    });
  };

  moneylineServiceV2.startTransaction = function () {
    console.info('[moneylineServiceV2] start transaction');
    return postMoneylineHttpWrapper('http://localhost:8081/api/v2.2/Transaction/StartTransaction', {});
  };

  moneylineServiceV2.stopTransaction = function () {
    console.info('[moneylineServiceV2] stop transaction');
    return postMoneylineHttpWrapper('http://localhost:8081/api/v2.2/Transaction/StopTransaction', {});
  };

  moneylineServiceV2.startReadContactless = function () {
    console.info('[moneylineServiceV2] start read contactless');
    return postMoneylineHttpWrapper('http://localhost:8081/api/v2.2/Transaction/ReadContactless', {});
  };

  moneylineServiceV2.stopReadContactless = function () {
    console.info('[moneylineServiceV2] stop read contactless');
    return postMoneylineHttpWrapper('http://localhost:8081/api/v2.2/Transaction/StopReadContactless', {});
  };

  moneylineServiceV2.modeMaintenance = function () {
    return $q.reject(false);
  }; // Private helpers


  function parseGetStatus(data) {
    var _context;

    if (!(0, _isArray.default)(data.Services)) {
      return MONEYLINE_V2.INIT_STATE;
    }

    return (0, _reduce.default)(_context = data.Services).call(_context, function (_ref, _ref2) {
      var _objectSpread3;

      var Name = _ref2.Name,
          Message = _ref2.Message,
          Status = _ref2.Status;
      var errors = _ref.errors,
          state = (0, _objectWithoutProperties2.default)(_ref, ["errors"]);
      var isOk = Status === 'OK';
      return _objectSpread({}, state, (_objectSpread3 = {}, (0, _defineProperty3.default)(_objectSpread3, Name, isOk), (0, _defineProperty3.default)(_objectSpread3, "errors", _objectSpread({}, errors, (0, _defineProperty3.default)({}, Name, isOk ? '' : Message))), _objectSpread3));
    }, MONEYLINE_V2.INIT_STATE);
  }

  function parseGetConfiguration(data) {
    var _context2;

    if (!(0, _isArray.default)(data.Parameters)) {
      return MONEYLINE_V2.INIT_PARAMS;
    }

    return _objectSpread({}, MONEYLINE_V2.INIT_PARAMS, {}, (0, _fromPairs.default)((0, _map.default)(_context2 = data.Parameters).call(_context2, function (parameter) {
      var name = parameter.Name || parameter.name;
      var value = parameter.Value || parameter.value;

      if (value === 'True') {
        return [name, true];
      }

      if (value === 'False') {
        return [name, false];
      }

      return [name, value];
    })));
  }

  function getMoneylineHttpWrapper(url) {
    return $http.get(url, {
      headers: {
        Authorization: 'Basic dXNlcmFwaTptZHBhcGk='
      }
    }).then(function (_ref3) {
      var data = _ref3.data;
      return data;
    });
  }

  function postMoneylineHttpWrapper(url, data) {
    var postData = data || null;
    var returnPayload = $q.defer();
    $http.post(url, postData, {
      headers: {
        Authorization: 'Basic dXNlcmFwaTptZHBhcGk='
      }
    }).then(function (response) {
      if (response.data && response.data.Result && response.data.Result == MONEYLINE_V2.API_OK) {
        logService.info('[moneylineServiceV2][V2] post success');
        returnPayload.resolve(response.data);
      } else {
        logService.info('[moneylineServiceV2][V2] post failed');
        returnPayload.reject(response);
      }
    }, function (response) {
      logService.info('[moneylineServiceV2] post failed');
      returnPayload.reject(response);
    });
    return returnPayload.promise;
  }
}
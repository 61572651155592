import driveDetailView from 'app/components/drive/detail/drive_detail.view.html';

export default {
    templateUrl: driveDetailView,
    controller: 'DriveDetailController',
    controllerAs: 'driveDetailVm',
    bindings: {
        order: '<',
        close: '<',
        validate: '<',
        seeDetail: '<',
    },
};

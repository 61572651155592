export default function (statePromise, ENV_VARS) {
    'ngInject';

    const vm = this;
    vm.ioPicture = ENV_VARS.STATIC_URL + ENV_VARS.CONTACT_PICTURE;
    vm.ioName = ENV_VARS.CONTACT_NAME;
    vm.ioPhone = ENV_VARS.CONTACT_PHONE;
    vm.close = function () {
        statePromise.reject('aboutContact');
    };
}

"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = void 0;
var _default = {
  ERROR_BRIDGE_MESSAGE_NOT_FOUND: 'ERROR_BRIDGE_MESSAGE_NOT_FOUND',
  ERROR_CATCH: 'ERROR_CATCH',
  PING: 'PING',
  PONG: 'PONG',
  BRIDGE_READY: 'BRIDGE_READY',
  PRINTER_LIST_UPDATED: 'PRINTER_LIST_UPDATED',
  PRINTER_START_DISCOVER: 'PRINTER_START_DISCOVER',
  GET_PRINTERS_STATUS: 'GET_PRINTERS_STATUS',
  SET_PRINTERS_STATUS: 'SET_PRINTERS_STATUS',
  PRINT_TICKET: 'PRINT_TICKET',
  PRINT_INGENICO_TICKET: 'PRINT_INGENICO_TICKET',
  PRINT_TICKET_SUCCEEDED: 'PRINT_TICKET_SUCCEEDED',
  RING: 'RING',
  RING_DRIVE: 'RING_DRIVE',
  RFID_INITIATE_CARD_READER: 'RFID_INITIATE_CARD_READER',
  RFID_START_CARD_DISCOVERY: 'RFID_START_CARD_DISCOVERY',
  RFID_STOP_CARD_DISCOVERY: 'RFID_STOP_CARD_DISCOVERY',
  RFID_CARD_READ: 'RFID_CARD_READ',
  INGENICO_START: 'INGENICO_START',
  INGENICO_ACTION: 'INGENICO_ACTION',
  INGENICO_ACTION_SUCCESS: 'INGENICO_ACTION_SUCCESS',
  INGENICO_ACTION_FAIL: 'INGENICO_ACTION_FAIL',
  INGENICO_TICKET_TO_PRINT: 'INGENICO_TICKET_TO_PRINT',
  ORDER_PRINT_SUCCEEDED: 'ORDER_PRINT_SUCCEEDED',
  ORDER_PRINT_RETRY: 'ORDER_PRINT_RETRY',
  ORDER_PRINT_ERROR: 'ORDER_PRINT_ERROR',
  ORDER_PRINT_STARTING: 'ORDER_PRINT_STARTING',
  LOG: 'LOG',
  SHUTDOWN: 'SHUTDOWN',
  GET_SERIAL_NUMBER: 'GET_SERIAL_NUMBER',
  GET_SERIAL_NUMBER_RESPONSE: 'GET_SERIAL_NUMBER_RESPONSE',
  NEPTING_PINPAD_INITIALIZE: 'NEPTING_PINPAD_INITIALIZE',
  NEPTING_PINPAD_LOGIN: 'NEPTING_PINPAD_LOGIN',
  NEPTING_PINPAD_START_TRANSACTION: 'NEPTING_PINPAD_START_TRANSACTION',
  NEPTING_PINPAD_ABORT_TRANSACTION: 'NEPTING_PINPAD_ABORT_TRANSACTION',
  NEPTING_PINPAD_GET_TERMINAL_INFORMATION: 'NEPTING_PINPAD_GET_TERMINAL_INFORMATION',
  NEPTING_PINPAD_RESPONSE: 'NEPTING_PINPAD_RESPONSE',
  IOPAY_IS_ENABLED: 'IOPAY_IS_ENABLED',
  IOPAY_IS_ENABLED_RESPONSE: 'IOPAY_IS_ENABLED_RESPONSE',
  IOPAY_START_PAYMENT: 'IOPAY_START_PAYMENT',
  IOPAY_PAYMENT_RESPONSE: 'IOPAY_PAYMENT_RESPONSE',
  SAVE_IOPAY_TERMINAL_CONFIG: 'SAVE_IOPAY_TERMINAL_CONFIG',
  REQUIRE_IOPAY_TERMINAL_CONFIG: 'REQUIRE_IOPAY_TERMINAL_CONFIG',
  REQUIRE_IOPAY_TERMINAL_CONFIG_RESPONSE: 'REQUIRE_IOPAY_TERMINAL_CONFIG_RESPONSE',
  IOPAY_SAVE_SECRET_KEY: 'IOPAY_SAVE_SECRET_KEY',
  KIOSK_PRINTERS_STATUSES_UPDATED: 'KIOSK_PRINTERS_STATUSES_UPDATED',
  // IO Pay by Yavin
  YAVIN_CHECK_STATUS: 'YAVIN_CHECK_STATUS',
  YAVIN_STATUS_RESPONSE: 'YAVIN_STATUS_RESPONSE',
  YAVIN_START_TRANSACTION: 'YAVIN_START_TRANSACTION',
  YAVIN_TRANSACTION_RESPONSE: 'YAVIN_TRANSACTION_RESPONSE'
};
exports.default = _default;
import { EXTERNAL_CHANNELS_SETTINGS } from '../../constants/external_channels_settings';

export default function (
    $state,
    apiService,
    restaurant,
    $timeout,
    statePromise,
    availabilityService,
    menus,
    shouldUseUberEatsAvailability,
    shouldUseDeliverooAvailability,
    localStorageService,
) {
    'ngInject';

    const vm = this;
    vm.processExternalChannelOptionsDisplay = function () {
        let fullSettings = localStorageService.cookie.get(EXTERNAL_CHANNELS_SETTINGS);
        if (!fullSettings) {
            fullSettings = { ubereats: true, deliveroo: true };
            localStorageService.cookie.set(EXTERNAL_CHANNELS_SETTINGS, fullSettings);
        }
        if (!shouldUseDeliverooAvailability) delete fullSettings.deliveroo;
        if (!shouldUseUberEatsAvailability) delete fullSettings.ubereats;
        return Object.entries(fullSettings);
    };

    vm.externalChannelsOptions = vm.processExternalChannelOptionsDisplay();
    vm.consumptionModes = availabilityService;
    vm.menus = menus;
    vm.isExternalChannelsOpen = false;
    vm.shouldDisplayExternalChannelsDropdown = shouldUseUberEatsAvailability || shouldUseDeliverooAvailability;
    if ($state.is('home.availability')) {
        // eslint-disable-next-line no-inner-declarations
        $state.go('home.availability.menu', {
            menus: vm.menus,
            menuId: vm.menus[0].menuId,
        });
    }

    vm.searchValue = '';

    vm.filterBySearch = function () {
        vm.consumptionModes.searchFilter = vm.searchValue;
    };

    vm.toggleExternalChannelDropdown = function () {
        vm.isExternalChannelsOpen = !vm.isExternalChannelsOpen;
    };

    vm.toggleExternalChannel = function (channelLabel) {
        const currentSettings = localStorageService.cookie.get(EXTERNAL_CHANNELS_SETTINGS);
        const updatedSettings = {
            ...currentSettings,
            [channelLabel]: !currentSettings[channelLabel],
        };
        localStorageService.cookie.set(EXTERNAL_CHANNELS_SETTINGS, updatedSettings);

        vm.externalChannelsOptions = vm.processExternalChannelOptionsDisplay();
        if (vm.externalChannelsOptions.length === 1) {
            vm.toggleExternalChannelDropdown();
        }
        $state.reload('home.availability.menu');
    };

    vm.filterByConsumptionModes = function () {
        statePromise.go(
            'home.availability.modal_filters',
            {},
            {
                location: false,
            },
            'dataFromModal',
        );
    };

    vm.reloadKiosks = function () {
        apiService.reloadKiosk
            .post({
                restaurantId: restaurant.restaurantId,
            })
            .$promise.then(function () {
                statePromise.go(
                    'home.availability.modal_reload_kiosks',
                    {},
                    {
                        location: false,
                    },
                    'reloadKiosks',
                );
            });
    };
}

"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = void 0;
var _default = {
  template: '<div class="order-step">' + '<order-step-product ng-repeat="product in orderStepVm.step.products" product="product" currency="orderStepVm.currency">' + '</order-step-product>' + '</div>',
  controller: function controller() {
    'ngInject';
  },
  controllerAs: 'orderStepVm',
  bindings: {
    step: '=',
    currency: '<'
  }
};
exports.default = _default;
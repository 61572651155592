import buttonSubmitView from './button_submit.view.html';
export default function($templateCache, $timeout) {
  'ngInject';

  function link(scope, element, attrs, ctrls) {
    scope.form = ctrls[0];
    var dbSubmit = ctrls[1];

    scope.$watch(
      function() {
        return dbSubmit.pending;
      },
      function(pending) {
        if (pending) {
          scope.pending = true;
          var cancelSucceedWatch = scope.$watch(
            function() {
              return dbSubmit.succeeded;
            },
            function(succeeded) {
              if (scope.pending && succeeded) {
                scope.pending = false;
                scope.succeeded = true;
                $timeout(function() {
                  scope.succeeded = false;
                  scope.form.$setPristine();
                }, 1000);
                cancelSucceedWatch();
                cancelFailedWatch();
              }
            },
          );
          var cancelFailedWatch = scope.$watch(
            function() {
              return dbSubmit.failed;
            },
            function(failed) {
              if (scope.pending && failed) {
                scope.pending = false;
                cancelSucceedWatch();
                cancelFailedWatch();
              }
            },
          );
        }
      },
    );
  }

  return {
    link: link,
    require: ['^form', '^bdSubmit'],
    restrict: 'E',
    transclude: true,
    replace: true,
    scope: {
      color: '@',
      isPending: '=',
      acceptPristine: '=',
      isDisabled: '=?',
    },
    template: $templateCache.get(buttonSubmitView),
  };
}

import loginView from 'app/components/login/login.view.html';
import homeView from 'app/components/home/home.view.html';
import driveView from 'app/components/drive/drive.view.html';
import errorView from 'app/components/error/error.view.html';
import offlineAlertView from 'app/components/offline_alert/offline_alert.view.html';
import { PRINT_SOURCE } from '@innovorder/angularcore/core/order_ticket/order_ticket.constants';

export default function ($stateProvider, $urlRouterProvider, $locationProvider) {
    'ngInject';

    $locationProvider.html5Mode(true);
    $urlRouterProvider.otherwise(function ($injector) {
        const $state = $injector.get('$state');
        $state.go('login');
    });

    $stateProvider.state('login', states.login()).state('home', states.home()).state('error', states.error());
}

// eslint-disable-next-line vars-on-top, no-var
var states = {
    login() {
        return {
            url: '/login',
            views: {
                alert: {
                    templateProvider($templateCache) {
                        'ngInject';

                        return $templateCache.get(offlineAlertView);
                    },
                    controller: 'OfflineAlertController',
                    controllerAs: 'offlineAlertVm',
                },
                '@': {
                    templateProvider: [
                        '$templateCache',
                        function ($templateCache) {
                            return $templateCache.get(loginView);
                        },
                    ],
                    controller: 'LoginController',
                    controllerAs: 'loginVm',
                },
            },
            resolve: {
                init(authService) {
                    'ngInject';

                    authService.init('innovorderReception', true, false).catch();
                },
                isNotAuthenticated: [
                    'authService',
                    'User',
                    '$state',
                    '$q',
                    function (authService, User, $state, $q) {
                        const deferred = $q.defer();
                        authService
                            .requireAuth()
                            .then(function (data) {
                                if (data.userId) {
                                    deferred.reject();
                                    $state.go('home.orders_home.placed');
                                } else {
                                    deferred.resolve({});
                                }
                            })
                            .catch(function (error) {
                                if (['permission_denied', 'no_token', 'logout'].includes(error)) {
                                    deferred.resolve({});
                                } else {
                                    $state.go('error');
                                    deferred.reject();
                                }
                            });
                        return deferred.promise;
                    },
                ],
                // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
                stopLoops(orderService) {
                    'ngInject';

                    if (orderService.loop) {
                        orderService.loop.stopAll();
                    }
                },
            },
            onEnter: [
                '$stickyState',
                function ($stickyState) {
                    $stickyState.reset('home');
                },
            ],
        };
    },
    error() {
        return {
            url: '/error',
            views: {
                '@': {
                    templateUrl: errorView,
                    controller: 'ErrorController',
                    controllerAs: 'errorVm',
                },
                alert: {
                    templateProvider($templateCache) {
                        'ngInject';

                        return $templateCache.get(offlineAlertView);
                    },
                    controller: 'OfflineAlertController',
                    controllerAs: 'offlineAlertVm',
                },
            },
        };
    },
    home() {
        return {
            url: '/home',
            views: {
                '@': {
                    templateProvider($templateCache) {
                        'ngInject';

                        return $templateCache.get(homeView);
                    },
                    controller: 'HomeController',
                    controllerAs: 'homeVm',
                },
                alert: {
                    templateProvider($templateCache) {
                        'ngInject';

                        return $templateCache.get(offlineAlertView);
                    },
                    controller: 'OfflineAlertController',
                    controllerAs: 'offlineAlertVm',
                },
                'drive@home': {
                    templateProvider($templateCache) {
                        'ngInject';

                        return $templateCache.get(driveView);
                    },
                    controller: 'DriveController',
                    controllerAs: 'driveVm',
                },
            },
            sticky: true,
            resolve: {
                init(authService) {
                    'ngInject';

                    authService.init('innovorderReception', true, false).catch();
                },
                user(authService, $state, $q) {
                    'ngInject';

                    const deferred = $q.defer();
                    authService
                        .requireAuth()
                        .then(function (user) {
                            deferred.resolve(user);
                        })
                        .catch(function (error) {
                            if (['permission_denied', 'no_token'].includes(error)) {
                                $state.go('login');
                            } else if (error !== 'logout') {
                                $state.go('error');
                            }
                            deferred.reject();
                        });
                    return deferred.promise;
                },
                initializeLaunchDarkly(launchdarklyService, user, restaurant, brand, ENV_VARS, $q) {
                    'ngInject';

                    const deferred = $q.defer();

                    const { firstName, lastName, email, userId, brandId, role } = user;
                    const ldUser = {
                        key: userId.toString(),
                        firstName,
                        lastName,
                        email,
                        custom: {
                            role,
                            restaurantId: restaurant.restaurantId,
                            ...(brandId !== null ? { brandId } : {}),
                            ...(brand && brand.brandType ? { brandType: brand.brandType } : {}),
                        },
                    };

                    launchdarklyService.initialize(ENV_VARS.LAUNCH_DARKLY_ENV_ID, ldUser).then(function () {
                        deferred.resolve();
                    });
                    return deferred;
                },
                restaurant(user, User, $q) {
                    'ngInject';

                    const deferred = $q.defer();
                    User.getRestaurants({
                        userId: user.userId,
                    })
                        .$promise.then(function (restaurants) {
                            deferred.resolve(restaurants[0]);
                        })
                        .catch(function (error) {
                            deferred.reject(error);
                        });
                    return deferred.promise;
                },
                brand(apiService, user) {
                    'ngInject';

                    return apiService.brand.get({
                        brandId: user.brandId,
                    }).$promise;
                },
                logs(logService, restaurant) {
                    'ngInject';

                    logService.init(restaurant.brandId, 4);
                },
                availabilityLogger(availabilityLoggerService) {
                    'ngInject';

                    availabilityLoggerService.init();
                },
                currency(restaurant, iso4217) {
                    'ngInject';

                    const currency = iso4217.getCurrencyByCode(restaurant.currency);
                    currency.code = restaurant.currency;
                    return currency;
                },
                locale(tmhDynamicLocale, restaurant, $translate, $q) {
                    'ngInject';

                    tmhDynamicLocale.set($translate.use());
                    $translate.useFallbackLanguage(restaurant.locale);
                    return $q.resolve();
                },
                tickets(restaurant, printService, Ticket, $q) {
                    'ngInject';

                    const deferred = $q.defer();
                    const model = {
                        model: 'restaurants',
                        modelId: restaurant.restaurantId,
                    };
                    Ticket.query(model)
                        .$promise.then(function (tickets) {
                            printService.setTickets(tickets);
                            deferred.resolve();
                        })
                        // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
                        .catch(function (e) {
                            deferred.reject();
                        });
                    return deferred.promise;
                },
                configReception($q, RestaurantModule, restaurant) {
                    'ngInject';

                    const deferred = $q.defer();
                    const moduleOptions = {
                        restaurantId: restaurant.restaurantId,
                        moduleId: 12,
                        channelId: 4,
                    };
                    RestaurantModule.get(moduleOptions)
                        .$promise.then(function (module) {
                            deferred.resolve(module);
                        })
                        .catch(function (err) {
                            deferred.reject(err);
                        });
                    return deferred.promise;
                },
                webBridgeInitialized(webBridge) {
                    'ngInject';

                    return webBridge.init();
                },
                // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
                printServiceInitialize(tickets, user, printService, webBridgeInitialized, CHANNELS) {
                    'ngInject';

                    return printService.init({ channel: CHANNELS.CHANNEL_RECEPTION.VALUE });
                },
                // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
                printServiceRecovery(user, printService, printServiceInitialize) {
                    'ngInject';

                    printService.recover();
                },
                orders(configReception, user, orderService, ENV_VARS) {
                    'ngInject';

                    return orderService.init(configReception, ENV_VARS.SOURCE, user.userId);
                },

                initializeTicketService(orderTicketService, restaurant, user) {
                    'ngInject';

                    const { userId, brandId } = user;

                    orderTicketService.init({
                        restaurantId: restaurant.restaurantId,
                        source: PRINT_SOURCE.KIOSK,
                        userId,
                        brandId,
                    });
                },
                processMarketplaceAvailability(user, restaurant, launchdarklyService, initializeLaunchDarkly) {
                    'ngInject';

                    return function (MarketplaceRessource, deferred, featureFlag) {
                        initializeLaunchDarkly.promise.then(function () {
                            const isGA = !featureFlag;
                            const featureFlagValue =
                                isGA ||
                                (launchdarklyService.allFlags ? launchdarklyService.allFlags[featureFlag] : false);

                            if (!featureFlagValue) {
                                deferred.resolve(false);
                            } else {
                                MarketplaceRessource.getConfiguration({
                                    brandId: user.brandId,
                                    restaurantId: restaurant.restaurantId,
                                }).$promise.then(
                                    function (marketplaceIntegration) {
                                        const marketplaceModuleData = marketplaceIntegration[0];
                                        const hasMarketplaceIntegration = !!marketplaceModuleData;
                                        const isMarketplaceIntegrationEnabled =
                                            marketplaceModuleData?.isEnabled &&
                                            marketplaceModuleData?.channels[0]?.isEnabled;

                                        const shouldDisplayMarketplaceColumn =
                                            hasMarketplaceIntegration && isMarketplaceIntegrationEnabled;

                                        deferred.resolve(shouldDisplayMarketplaceColumn);
                                    },
                                    function () {
                                        deferred.resolve(false);
                                    },
                                );
                            }
                        });
                    };
                },
                shouldUseUberEatsAvailability(Ubereats, $q, processMarketplaceAvailability) {
                    'ngInject';

                    const deferred = $q.defer();
                    processMarketplaceAvailability(Ubereats, deferred);
                    return deferred.promise;
                },
                shouldUseDeliverooAvailability(Deliveroo, $q, processMarketplaceAvailability) {
                    'ngInject';

                    const deferred = $q.defer();
                    processMarketplaceAvailability(Deliveroo, deferred, 'use-deliveroo-availability');
                    return deferred.promise;
                },
            },
        };
    },
};

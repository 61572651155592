export default function() {
  'ngInject';

  return {
    require: 'ngModel',
    restrict: 'A',
    link: function(scope, element, attributes, ngModel) {
      ngModel.$validators.max = function(modelValue, viewValue) {
        return parseFloat(viewValue) <= parseFloat(attributes.fioMax);
      };
    },
  };
}

import angular from 'angular';
import errorModule from './error/error.module';
import infosModule from './infos/infos.module';
import quoteModule from './quote/quote.module';
import searchModule from './search/search.module';
import stuartRoutes from './stuart.routes';
import stuartController from './stuart.controller';

const dependencies = [errorModule, infosModule, quoteModule, searchModule];

angular.module('app.home.stuart', dependencies).config(stuartRoutes).controller('StuartController', stuartController);
export default angular.module('app.home.stuart').name;

export default function() {
  return {
    priority: -200,
    compile: function($element) {
      var rowRepeatDiv = angular.element($element.children().children()[0]);

      var existingNgClass = rowRepeatDiv.attr('ng-class');
      var newNgClass = '';

      if (existingNgClass) {
        newNgClass =
          existingNgClass.slice(0, -1) +
          ",'ui-grid-row-hovered': row.isHovered}";
      } else {
        newNgClass = "{'ui-grid-row-hovered': row.isHovered}";
      }

      rowRepeatDiv.attr('ng-class', newNgClass);

      return {
        pre: function() {},
        post: function() {},
      };
    },
  };
}

"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

require("core-js/modules/es.function.name");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = void 0;

var _firebase_initializer = require("../utils/firebase_initializer");

var _log = _interopRequireDefault(require("./../resources/log/log.module"));

var _log2 = _interopRequireDefault(require("./log.service"));

var _log_interceptor = _interopRequireDefault(require("./log_interceptor.service"));

var _angularMoment = _interopRequireDefault(require("angular-moment"));

var _angularLocalStorage = _interopRequireDefault(require("angular-local-storage"));

var _angular = _interopRequireDefault(require("angular"));

var dependencies = [_angularMoment.default, _log.default, _angularLocalStorage.default];

_angular.default.module('core.log', dependencies).service('logService', _log2.default).service('logInterceptorService', _log_interceptor.default).run(['ENV_VARS', _firebase_initializer.initializeFirebaseIfNotAlreadyDone]);

var _default = _angular.default.module('core.log').name;

exports.default = _default;
import loadingScreenTemplateView from './loading_screen_template.view.html';

export default function($templateCache) {
  'ngInject';

  function link(scope, element, attrs) {
    var sentences = ['COOKING', 'LOADING', 'PREPARING'];

    scope.choosenOne = sentences[Math.floor(Math.random() * sentences.length)];
  }

  return {
    link: link,
    restrict: 'E',
    scope: {},
    template: $templateCache.get(loadingScreenTemplateView),
  };
}

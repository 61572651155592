"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

require("core-js/modules/es.function.name");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = void 0;

var _user = _interopRequireDefault(require("./../resources/user/user.module"));

var _customer = _interopRequireDefault(require("./../resources/customer/customer.module"));

var _ewallet = _interopRequireDefault(require("./../resources/ewallet/ewallet.module"));

var _api = _interopRequireDefault(require("./../resources/api/api.module"));

var _angularLocalStorage = _interopRequireDefault(require("angular-local-storage"));

var _angularUiRouter = _interopRequireDefault(require("angular-ui-router"));

var _auth = _interopRequireDefault(require("./auth.service"));

var _auth_interceptor = _interopRequireDefault(require("./auth_interceptor.provider"));

var _network = _interopRequireDefault(require("../network/network.module"));

var _simpleLog = _interopRequireDefault(require("./../simpleLog/simpleLog.module"));

var _angular = _interopRequireDefault(require("angular"));

var dependencies = [_network.default, _user.default, _ewallet.default, _api.default, _angularLocalStorage.default, _angularUiRouter.default, _simpleLog.default];

_angular.default.module('core.auth', dependencies).service('authService', _auth.default).provider('authInterceptorService', _auth_interceptor.default);

var _default = _angular.default.module('core.auth').name;

exports.default = _default;
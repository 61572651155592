"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = void 0;
var _default = {
  template: '<div class="order-item">' + '<div layout="row" class="order-item-head" layout-align="space-between center">' + '<div layout="row"><div class="quantity">{{orderProductVm.product.quantity}}x</div>' + '<div class="name">{{orderProductVm.product.name}}</div></div>' + '<div class="price">{{orderProductVm.product.price | bigDecimal:orderProductVm.currency.fraction | isoCurrency:orderProductVm.currency.code}}</div>' + '</div>' + '<div>' + '<order-step ng-repeat="step in orderProductVm.product.steps" step="step" currency="orderProductVm.currency"></order-step>' + '</div>' + '</div>',
  controller: function controller() {
    'ngInject';
  },
  controllerAs: 'orderProductVm',
  bindings: {
    product: '=',
    currency: '<'
  }
};
exports.default = _default;
/**
 *  Order directive
 *  ---------------------------------------------------
 */
export default function($swipe, $compile, $parse, $timeout) {
  'ngInject';

  function controller($element, $attrs) {
    'ngInject';
    var vm = this;
    var initPosition,
      initTranslateValue = 0,
      transXRegex = /\.*translateX\((.*)px\)/i,
      messageElt,
      swipeCancel,
      valid,
      startDate;
    var eltWidth = function() {
      return $element[0].clientWidth;
    };
    vm.$onInit = function() {
      messageElt = $element
        .parent()[0]
        .querySelectorAll('[order-swipe-message]')[0];
      swipeCancel = $element
        .parent()[0]
        .querySelectorAll('[order-swipe-cancel]')[0];
      $element.parent().bind('mouseleave', function() {
        resetPosition();
        valid = false;
      });
      $swipe.bind($element, {
        start: function(pos, e) {
          initPosition = pos;
          if ($element.css('transform') != '') {
            initTranslateValue = parseInt(
              transXRegex.exec($element.css('transform'))[1],
            );
          }
          valid = true;
          startDate = new Date().getTime();
        },
        move: function(pos, e) {
          $element.removeClass('smooth');

          var transltX = initTranslateValue + (pos.x - initPosition.x);
          if (transltX < eltWidth() && transltX > -1 * eltWidth()) {
            $element.css('transform', 'translateX(' + transltX + 'px)');
            $element.css('opacity', 1 - transltX / eltWidth());
          }

          if (transltX < 0) {
            angular
              .element(messageElt)
              .css('opacity', Math.abs(transltX / eltWidth()));
            angular
              .element(messageElt)
              .css(
                'transform',
                'scale(' +
                  Math.min(Math.max(Math.abs(transltX / eltWidth()), 0.6), 1) +
                  ')',
              );
          } else {
            angular.element(messageElt).css('opacity', 0);
          }
        },
        cancel: function() {
          valid = false;
          resetPosition();
        },
        end: function(coords, event) {
          $element.addClass('smooth');
          if (validSwipe(coords, -1)) {
            angular.element(messageElt).css('opacity', 1);
            angular.element(messageElt).css('transform', 'scale(1)');
            $element.css('transform', 'translateX(' + -1 * eltWidth() + 'px)');
          } else if (validSwipe(coords, 1)) {
            if (vm.swipeRightCb) {
              vm.swipeRightCb(vm.swipeRightContent)
                .then(function(result) {
                  $element.css('transform', 'translateX(' + eltWidth() + 'px)');
                  $element.css('opacity', 0);
                })
                .catch(function(err) {
                  resetPosition();
                  if (vm.onError) {
                    vm.onError(err);
                  }
                });
            }
          } else {
            resetPosition();
          }
        },
      });
    };

    // Add cancel to swipe
    function resetPosition() {
      $element.css('transform', 'translateX(0px)');
      $element.css('opacity', 1);
      angular.element(messageElt).css('opacity', 0);
    }

    angular.element(swipeCancel).on('click', function() {
      resetPosition();
    });

    // Swipe detection configuration
    var MAX_VERTICAL_DISTANCE = 150;
    var MAX_VERTICAL_RATIO = 0.45;
    var MIN_HORIZONTAL_DISTANCE = 30;
    var MAX_DELAY = 300;

    // -1 is a left swipe, 1 is right
    function validSwipe(coords, direction) {
      if (!initPosition) return false;

      var deltaY = Math.abs(coords.y - initPosition.y);
      var deltaX = (coords.x - initPosition.x) * direction;
      return (
        valid && // Short circuit for already-invalidated swipes.
        deltaY < MAX_VERTICAL_DISTANCE &&
        deltaX > 0 &&
        deltaX > MIN_HORIZONTAL_DISTANCE &&
        deltaY / deltaX < MAX_VERTICAL_RATIO &&
        new Date().getTime() - startDate < MAX_DELAY
      );
    }
  }

  return {
    controller: controller,
    restrict: 'A',
    controllerAs: 'orderSwipeVm',
    bindToController: {
      swipeRightCb: '=',
      swipeRightContent: '=',
      onError: '=',
    },
  };
}

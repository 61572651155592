"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = _ngInjectExport;

var _firebase = _interopRequireDefault(require("firebase"));

_ngInjectExport.$inject = ["moment", "ENV_VARS", "$injector", "$q", "uuid"];

function _ngInjectExport(moment, ENV_VARS, $injector, $q, uuid) {
  'ngInject';

  var uuid = uuid.v4();
  var service = this;
  var ref;
  var authService;

  service.init = function () {
    ref = ENV_VARS.FB_DB_USER ? _firebase.default.database().ref(ENV_VARS.FB_DB_USER).child('feature_logs').child('availability') : _firebase.default.database().ref().child('feature_logs').child('availability');
  };

  service.log = function (logData, menuId) {
    if (!$q) {
      $q = $injector.get('$q');
    }

    logData.uuid = uuid;
    logData.date = moment().format();
    var deferred = $q.defer();

    if (!ref) {
      deferred.reject('Firebase service not init');
    } else {
      ref.child(menuId).push(logData);
    }

    return deferred.promise;
  };
}
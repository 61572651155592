import angular from 'angular';
import uberEatsResourceModule from '@innovorder/angularcore/core/resources/ubereats/ubereats.module';
import deliverooResourceModule from '@innovorder/angularcore/core/resources/deliveroo/deliveroo.module';
import menuModule from './menu/menu.module';
import productQuantityModule from './product_quantity/product_quantity.module';
import modalConsumptionModesModule from './modal_filters/modal_filters.module';
import modalReloadKiosksModule from './modal_reload_kiosks/modal_reload_kiosks.module';
import modalSkuWarningModule from './modal_sku_warning/modal_sku_warning.module';
import availabilityRoutes from './availability.routes';
import availabilityController from './availability.controller';
import availabilityService from './availability.service';

const dependencies = [
    menuModule,
    productQuantityModule,
    modalConsumptionModesModule,
    modalReloadKiosksModule,
    modalSkuWarningModule,
    uberEatsResourceModule,
    deliverooResourceModule,
];

// eslint-disable-next-line angular/no-service-method
angular
    .module('app.home.availability', dependencies)
    .config(availabilityRoutes)
    .service('availabilityService', availabilityService)
    .controller('AvailabilityController', availabilityController);
export default angular.module('app.home.availability').name;

import categoryResourceModule from '@innovorder/angularcore/core/resources/category/category.module';
import angular from 'angular';
import categoryModule from '../category/category.module';
import productModule from '../product/product.module';
import stepModule from '../step/step.module';
import menuRoutes from './menu.routes';
import menuController from './menu.controller';

const dependencies = [categoryResourceModule, categoryModule, productModule, stepModule];

angular
    .module('app.home.availability.menu', dependencies)
    .config(menuRoutes)
    .controller('MenuController', menuController);
export default angular.module('app.home.availability.menu').name;

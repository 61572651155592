import angular from 'angular';
import modalSkuWarningController from './modal_sku_warning.controller';

const dependencies = [];

// eslint-disable-next-line angular/controller-name
angular
    .module('app.home.availability.modal_sku_warning.controller', dependencies)
    .controller('modalSkuWarningController', modalSkuWarningController);
export default angular.module('app.home.availability.modal_sku_warning.controller').name;

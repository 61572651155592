"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = void 0;
var _default = {
  MAX_TERMINAL_RETRY: 2,
  LOOP_DELAY_MS: 300000,
  PAYMENT_METHOD: {
    CB: 'CB',
    ES: 'ES'
  },
  PAYMENT_TERMINAL: 'CB',
  CONECS: 'CONECS',
  PRINTER_RECEIPT: 'PRINTER_RECEIPT',
  PRINTER: 'TicketPrinterAdapter',
  COOKIE_PATH: 'MONEYLINE_CONFIG',
  INIT_STATE: {
    CB: false,
    TicketPrinterAdapter: false,
    NetworkAdapter: false,
    errors: {
      CB: '',
      TicketPrinterAdapter: '',
      NetworkAdapter: ''
    }
  },
  INIT_PARAMS: {},
  VERSION_2: 'v2',
  VERSION_1: 'v1',
  API_OK: 'OK'
};
exports.default = _default;
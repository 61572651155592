"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = _ngInjectExport;
_ngInjectExport.$inject = ["$resource", "$log", "$q", "RESOURCES_VARS", "ENV_VARS"];

function _ngInjectExport($resource, $log, $q, RESOURCES_VARS, ENV_VARS) {
  'ngInject';

  var authUrl = ENV_VARS.API_URL + '/oauth/:action';
  var transactionUrl = ENV_VARS.API_URL + '/users/transactions';

  function parseData(response) {
    try {
      var body = angular.fromJson(response);

      if (body && body.data) {
        return body.data;
      } else if (body && body.message) {
        return body;
      }
    } catch (e) {
      $log.error(e);
      return e;
    }
  }

  function parseOauth(response) {
    try {
      var body = angular.fromJson(response);

      if (body && body.data) {
        return [body.data, body.data.user];
      } else {
        return body;
      }
    } catch (e) {
      $log.error(e);
      return e;
    }
  }

  var User = $resource(ENV_VARS.API_URL + '/users/:userId/:action/:actionId', {
    userId: '@userId'
  }, {
    get: {
      method: RESOURCES_VARS.API_METHODS.GET,
      params: {
        userId: '@userId'
      },
      transformResponse: parseData
    },
    reactivate: {
      method: RESOURCES_VARS.API_METHODS.POST,
      params: {
        action: 'reactivate',
        userId: '@userId'
      },
      transformResponse: parseData
    },
    saveGuest: {
      method: RESOURCES_VARS.API_METHODS.POST,
      params: {
        action: 'customer'
      },
      transformResponse: parseData
    },
    createCustomer: {
      method: RESOURCES_VARS.API_METHODS.POST,
      url: ENV_VARS.API_URL + '/brands/:brandId/customers',
      params: {
        brandId: '@brandId'
      },
      transformResponse: parseData
    },
    saveManager: {
      method: RESOURCES_VARS.API_METHODS.POST,
      params: {
        action: 'manager'
      },
      transformResponse: parseData
    },
    saveServiceAccount: {
      method: RESOURCES_VARS.API_METHODS.POST,
      params: {
        action: 'service'
      },
      transformResponse: parseData
    },
    getServiceAccount: {
      method: RESOURCES_VARS.API_METHODS.GET,
      params: {
        action: 'service'
      },
      transformResponse: parseData
    },
    delete: {
      method: RESOURCES_VARS.API_METHODS.DELETE,
      params: {
        userId: '@userId'
      },
      transformResponse: parseData
    },
    update: {
      method: RESOURCES_VARS.API_METHODS.PUT,
      transformResponse: parseData
    },
    updatePassword: {
      method: RESOURCES_VARS.API_METHODS.PUT,
      params: {
        action: 'password'
      },
      transformResponse: parseData
    },
    query: {
      method: RESOURCES_VARS.API_METHODS.GET,
      isArray: true,
      transformResponse: parseData
    },
    saveDashboard: {
      method: RESOURCES_VARS.API_METHODS.POST,
      params: {
        userId: 'dashboard'
      },
      transformResponse: parseData
    },
    login: {
      url: authUrl,
      method: RESOURCES_VARS.API_METHODS.POST,
      isArray: true,
      params: {
        action: 'login'
      },
      transformResponse: parseOauth
    },
    requestToken: {
      url: authUrl,
      isArray: true,
      method: RESOURCES_VARS.API_METHODS.GET,
      params: {
        action: 'admin'
      },
      transformResponse: parseOauth
    },
    loginStudentNumber: {
      url: authUrl,
      method: RESOURCES_VARS.API_METHODS.POST,
      isArray: true,
      params: {
        action: 'student_number'
      },
      transformResponse: parseOauth
    },
    nfclogin: {
      url: authUrl,
      method: RESOURCES_VARS.API_METHODS.POST,
      isArray: true,
      params: {
        action: 'badge_auth'
      },
      transformResponse: parseOauth
    },
    refresh: {
      url: authUrl,
      method: RESOURCES_VARS.API_METHODS.POST,
      params: {
        action: 'login'
      },
      transformResponse: parseData
    },
    me: {
      url: authUrl,
      method: RESOURCES_VARS.API_METHODS.GET,
      params: {
        action: 'me'
      },
      transformResponse: parseData
    },
    logout: {
      url: authUrl,
      method: RESOURCES_VARS.API_METHODS.GET,
      params: {
        action: 'logout'
      },
      transformResponse: parseData
    },
    getRestaurants: {
      method: RESOURCES_VARS.API_METHODS.GET,
      params: {
        action: 'restaurants'
      },
      isArray: true,
      transformResponse: parseData
    },
    getEmailAvailability: {
      method: RESOURCES_VARS.API_METHODS.GET,
      params: {
        action: 'emailavailable'
      },
      transformResponse: parseData
    },
    createTransaction: {
      url: transactionUrl,
      method: RESOURCES_VARS.API_METHODS.POST,
      transformResponse: parseData
    }
  });

  User.prototype.getRoleModel = function () {
    var deferred = $q.defer();

    if (this.role === 'admin') {
      deferred.resolve({
        name: 'admin',
        route: 'brands'
      });
    } else if (this.role === 'brand') {
      deferred.resolve({
        name: 'brand',
        id: this.brandId,
        route: 'brands'
      });
    } else if (this.role === 'restaurant') {
      deferred.resolve({
        name: 'restaurant',
        id: this.brandId,
        route: 'restaurants'
      });
    }

    return deferred.promise;
  };

  return User;
}
export default function () {
  'ngInject';
  function link(scope, element, attrs, fioRouterTabsCtrl) {}

  return {
    link: link,
    require: '^fioRouterTabs',
    restrict: 'E',
    replace: true,
    transclude: true,
    template: '<a ng-transclude class="fio-tab-link">placeholder</a>',
  };
}

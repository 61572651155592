export default function($animate, $backDrop) {
  'ngInject';

  function link(scope, element, attrs, fioDashboardSidenavCtrl) {
    scope.displayed = false;
    scope.backdrop = null;
    scope.link = angular.element(angular.element(element).children()[1]);
    scope.popover = angular.element(angular.element(element).children()[0]);

    scope.popover.addClass('hide');

    angular.element(element[0]).on('click', function() {
      scope.toggle();
    });

    scope.toggle = function() {
      scope.$apply(function() {
        if (scope.displayed) {
          $animate.addClass(scope.popover, 'hide');
          $backDrop.destroy(scope.backdrop);
        } else {
          $animate.removeClass(scope.popover, 'hide');

          scope.backdrop = $backDrop.inject(
            scope,
            function() {
              scope.toggle();
            },
            element,
          );
        }

        scope.link.toggleClass('highlight');
      });

      scope.displayed = !scope.displayed;
    };
  }

  return {
    link: link,
    restrict: 'E',
    require: '^fioDashboardSidenav',
    scope: true,
  };
}

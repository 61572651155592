// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
export default function (statePromise, StuartV2, $stateParams, job, $sce, $scope) {
    'ngInject';

    const vm = this;
    vm.loading = false;
    vm.errorMessage = null;
    vm.trustSrc = function (src) {
        return $sce.trustAsResourceUrl(src);
    };

    vm.stuartJob = job.job;
    vm.stuartV2Order = $stateParams.stuartV2Order;
    vm.cancel = function () {
        if (!vm.loading) {
            statePromise
                .go(
                    '^.cancel',
                    {
                        orderId: $stateParams.stuartV2Order.orderId,
                    },
                    {
                        location: false,
                    },
                    'stuartV2Cancel',
                )
                // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
                .then(function (data) {
                    statePromise.resolve({ hasCancelledJob: true }, 'stuartV2');
                })
                .catch(function (e) {
                    if (e && e.data) {
                        vm.errorMessage = e.data.message;
                        vm.loading = false;
                    }
                });
        }
    };
}

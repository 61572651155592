"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

require("core-js/modules/es.function.name");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = void 0;

var _launchdarkly = _interopRequireDefault(require("./launchdarkly.service"));

var _angular = _interopRequireDefault(require("angular"));

var dependencies = [];

_angular.default.module('core.launchdarkly', dependencies).service('launchdarklyService', _launchdarkly.default);

var _default = _angular.default.module('core.launchdarkly').name;

exports.default = _default;
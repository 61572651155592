"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

require("core-js/modules/es.array.join");

require("core-js/modules/es.function.name");

require("core-js/modules/es.number.constructor");

require("core-js/modules/es.regexp.exec");

require("core-js/modules/es.string.split");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.convertCartToLines = convertCartToLines;

_Object$defineProperty(exports, "convertTpeTicketToLines", {
  enumerable: true,
  get: function get() {
    return _helpers.convertTpeTicketToLines;
  }
});

var _concat = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/concat"));

var _filter = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/filter"));

var _sort = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/sort"));

var _forEach = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/for-each"));

var _map = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/map"));

var _find = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/find"));

var _reduce = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/reduce"));

var _map2 = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/map"));

var _moment = _interopRequireDefault(require("moment"));

var _helpers = require("./helpers");

var _helpers2 = require("../helpers");

var _constants = require("../constants");

function convertCartToLines(cartCount, child, localeStringMapInstance, orderData, doNotPrintCount, output) {
  var _context2;

  var tagsFilter = [];
  var tmpOutput = [];
  var isRecap = child.attributes.recap && Number(child.attributes.recap) === 1;
  var displayVat = child.attributes.vat && Number(child.attributes.vat) === 1;
  var displayPrice = !isRecap;
  var isPaid = Number(orderData.paymentStatus) === 1;
  cartCount += 1; // Attributes parsing

  if (child.attributes.tags) {
    var parsedTags = child.attributes.tags.split('|');
    tagsFilter = (0, _map2["default"])(parsedTags).call(parsedTags, function (tag) {
      return String(tag).toLowerCase();
    });
  } // Cart header


  if (displayPrice) {
    (0, _helpers2.addLine)(tmpOutput, (0, _helpers2.alignText)(localeStringMapInstance.TITLE_LEFT_LIST_PRODUCT, localeStringMapInstance.TITLE_RIGHT_LIST_PRODUCT, ' '));
  } else {
    (0, _helpers2.addLine)(tmpOutput, (0, _moment["default"])().format('DD/MM/YYYY HH:mm'), {
      align: _constants.PRINTER_ALIGNMENT.CENTER,
      emphasis: _constants.PRINTER_EMPHASIS.BOLD
    });
  }

  (0, _helpers2.addLine)(tmpOutput, (0, _helpers2.alignText)('', '', '-')); // Cart content

  var cart = JSON.parse(orderData.cartRawData);
  var vatMap = {};
  var recapData = JSON.parse(orderData.orderRecapRawData);

  if (recapData) {
    var _context;

    var _recapData$products$r = (0, _reduce["default"])(_context = recapData.products).call(_context, function (_ref, product) {
      var _vatMap = _ref._vatMap,
          index = _ref.index;

      if (_vatMap[product.taxRate]) {
        return {
          _vatMap: _vatMap,
          index: index
        };
      }

      _vatMap[product.taxRate] = String.fromCharCode(65 + index);
      return {
        _vatMap: _vatMap,
        index: index + 1
      };
    }, {
      _vatMap: {},
      index: 0
    }),
        map = _recapData$products$r._vatMap;

    vatMap = map;
  }

  var addedProductToCartTicket = 0;
  var isSchemaActive = child.children.length === 1 && child.children[0].name === 'productSchema' && child.children[0].children.length > 0;
  var categoriesNamesAreUsed = (0, _find["default"])(_context2 = cart.products).call(_context2, function (product) {
    return product.categoriesNames;
  });

  if (isRecap && categoriesNamesAreUsed) {
    var _context3;

    var productsByCategoriesGroup = new _map["default"]();
    (0, _helpers.addProductsHeader)(tmpOutput);
    (0, _forEach["default"])(_context3 = cart.products).call(_context3, function (product) {
      var _context4;

      var categoriesNames = product.categoriesNames ? (0, _sort["default"])(_context4 = product.categoriesNames).call(_context4).join(', ') : 'N/A';

      if (!productsByCategoriesGroup.has(categoriesNames)) {
        productsByCategoriesGroup.set(categoriesNames, [product]);
      } else {
        var productGroup = productsByCategoriesGroup.get(categoriesNames);
        productGroup.push(product);
      }
    });
    (0, _forEach["default"])(productsByCategoriesGroup).call(productsByCategoriesGroup, function (products, categories) {
      if ((0, _filter["default"])(products).call(products, function (_ref2) {
        var name = _ref2.name;
        return name;
      }).length) {
        (0, _helpers2.addLine)(tmpOutput, categories, {
          emphasis: _constants.PRINTER_EMPHASIS.BOLD,
          underline: _constants.PRINTER_UNDERLINE.UNDERLINED
        });
        (0, _helpers2.addLineBreaks)(tmpOutput, 1);
        (0, _forEach["default"])(products).call(products, function (product) {
          addedProductToCartTicket = (0, _helpers.convertProductToLine)(product, displayPrice, tagsFilter, isSchemaActive, addedProductToCartTicket, child, tmpOutput, displayVat, vatMap);
        });
      }
    });
  } else {
    var _context5;

    (0, _helpers.addProductsHeader)(tmpOutput);
    (0, _forEach["default"])(_context5 = cart.products).call(_context5, function (product) {
      addedProductToCartTicket = (0, _helpers.convertProductToLine)(product, displayPrice, tagsFilter, isSchemaActive, addedProductToCartTicket, child, tmpOutput, displayVat, vatMap);
    });
  } // Cart Footer


  (0, _helpers2.addLine)(tmpOutput, (0, _helpers2.alignText)('', '', '-'));

  if (displayPrice) {
    (0, _helpers.convertPricesToLines)(orderData, tmpOutput, localeStringMapInstance, displayVat, vatMap);
  }

  if (displayVat && isPaid) {
    (0, _helpers2.addLine)(tmpOutput, (0, _helpers2.alignText)('', '', ' '));
    (0, _helpers2.addLine)(tmpOutput, orderData.restaurant.name);
    (0, _helpers2.addLine)(tmpOutput, orderData.restaurant.address.name);
    (0, _helpers2.addLine)(tmpOutput, "T\xE9l\xE9phone. ".concat(orderData.restaurant.contactPhone));
    (0, _helpers2.addLine)(tmpOutput, (0, _helpers2.alignText)('', '', '  '));
    (0, _helpers2.addLine)(tmpOutput, (0, _helpers2.alignText)('', '', ' '));
  }

  if (addedProductToCartTicket === 0) {
    doNotPrintCount += 1;
  } else {
    output = (0, _concat["default"])(output).call(output, tmpOutput);
  }

  return {
    cartCount: cartCount,
    doNotPrintCount: doNotPrintCount,
    output: output
  };
}
import contactView from 'app/components/parameters/about/contact/contact.view.html';

export default function ($stateProvider, $urlRouterProvider, $locationProvider) {
    'ngInject';

    $locationProvider.html5Mode(true);
    $stateProvider.state('home.parameters.about.contact', states.contact());
}

// eslint-disable-next-line vars-on-top, no-var
var states = {
    contact() {
        return {
            views: {
                'modal@': {
                    templateUrl: contactView,
                    controller: 'ParametersAboutContactController',
                    controllerAs: 'parametersAboutContactVm',
                },
            },
        };
    },
};

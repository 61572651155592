"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.initializeFirebaseIfNotAlreadyDone = void 0;

var _firebase = _interopRequireDefault(require("firebase"));

var initializeFirebaseIfNotAlreadyDone = function initializeFirebaseIfNotAlreadyDone(ENV_VARS) {
  if (!_firebase.default.apps.length) {
    var config = {
      apiKey: ENV_VARS.FB_API_KEY,
      databaseURL: ENV_VARS.FB_DB_URL
    };

    _firebase.default.initializeApp(config);
  }
};

exports.initializeFirebaseIfNotAlreadyDone = initializeFirebaseIfNotAlreadyDone;
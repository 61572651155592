"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = _ngInjectExport;

var _isArray = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/array/is-array"));

var _assert = require("assert");

_ngInjectExport.$inject = ["$resource", "$log", "$q", "RESOURCES_VARS", "ENV_VARS"];

function _ngInjectExport($resource, $log, $q, RESOURCES_VARS, ENV_VARS) {
  'ngInject';

  var customerUrl = ENV_VARS.API_URL + '/customers/:customerId/:action/:actionId';

  function parseData(response) {
    try {
      var body = angular.fromJson(response);

      if (body && body.status === 400) {
        return body;
      }

      if (body && body.data) {
        return body.data;
      } else if (body && body.message) {
        return body;
      }
    } catch (e) {
      $log.error(e);
      return e;
    }
  }

  function parseCustomers(response) {
    return (0, _isArray.default)(response) ? {
      customers: response
    } : response;
  }

  var Customer = $resource(customerUrl, {
    customerId: '@customerId'
  }, {
    getAll: {
      method: RESOURCES_VARS.API_METHODS.GET,
      params: {
        brandId: '@brandId'
      },
      transformResponse: function transformResponse(response) {
        return parseCustomers(parseData(response));
      }
    },
    getAddresses: {
      isArray: true,
      method: RESOURCES_VARS.API_METHODS.GET,
      params: {
        action: 'addresses'
      },
      transformResponse: parseData
    },
    deleteAddress: {
      method: RESOURCES_VARS.API_METHODS.DELETE,
      params: {
        action: 'addresses'
      },
      transformResponse: parseData
    },
    createAddress: {
      method: RESOURCES_VARS.API_METHODS.POST,
      params: {
        action: 'addresses'
      },
      transformResponse: parseData
    },
    createAddressV2: {
      url: ENV_VARS.API_URL + '/customers/:customerId/address',
      method: RESOURCES_VARS.API_METHODS.POST,
      transformResponse: parseData
    },
    updateAddress: {
      method: RESOURCES_VARS.API_METHODS.PUT,
      params: {
        action: 'addresses',
        actionId: '@addressId'
      },
      transformResponse: parseData
    },
    updateAddressV2: {
      url: ENV_VARS.API_URL + '/customers/:customerId/address/:addressId',
      method: RESOURCES_VARS.API_METHODS.PUT,
      params: {
        addressId: '@addressId'
      },
      transformResponse: parseData
    },
    getRoyaltyBalance: {
      method: RESOURCES_VARS.API_METHODS.GET,
      params: {
        action: 'royalty_balance'
      },
      transformResponse: parseData
    },
    getOne: {
      url: customerUrl,
      method: RESOURCES_VARS.API_METHODS.GET,
      transformResponse: parseData
    },
    getBalance: {
      url: customerUrl,
      method: RESOURCES_VARS.API_METHODS.GET,
      params: {
        action: 'balance'
      },
      transformResponse: parseData
    },
    getBalanceHistory: {
      url: customerUrl,
      method: RESOURCES_VARS.API_METHODS.GET,
      params: {
        action: 'balance_history'
      },
      transformResponse: parseData,
      isArray: true
    },
    getBalanceHistoryCount: {
      url: customerUrl,
      method: RESOURCES_VARS.API_METHODS.GET,
      params: {
        action: 'balance_history',
        actionId: 'count'
      },
      transformResponse: parseData
    },
    update: {
      url: customerUrl,
      method: RESOURCES_VARS.API_METHODS.PUT,
      transformResponse: parseData
    },
    delete: {
      url: customerUrl,
      method: RESOURCES_VARS.API_METHODS.DELETE,
      transformResponse: parseData
    },
    reactivate: {
      url: customerUrl,
      method: RESOURCES_VARS.API_METHODS.POST,
      params: {
        action: 'reactivate'
      },
      transformResponse: parseData
    },
    getEmailAvailability: {
      method: RESOURCES_VARS.API_METHODS.GET,
      params: {
        action: 'emailavailable'
      },
      transformResponse: parseData
    },
    create: {
      method: RESOURCES_VARS.API_METHODS.POST,
      transformResponse: parseData
    },
    canHaveEntranceFee: {
      method: RESOURCES_VARS.API_METHODS.GET,
      params: {
        action: 'can_have_entrance_fee'
      },
      transformResponse: parseData
    },
    canHaveGrant: {
      method: RESOURCES_VARS.API_METHODS.GET,
      params: {
        action: 'can_have_grant'
      },
      transformResponse: parseData
    },
    archive: {
      method: RESOURCES_VARS.API_METHODS.POST,
      url: ENV_VARS.API_URL + '/customers/archive',
      transformResponse: parseData
    },
    creditEwallet: {
      method: RESOURCES_VARS.API_METHODS.POST,
      params: {
        action: 'credit_ewallet'
      },
      transformResponse: parseData
    },
    creditEwalletV2: {
      url: ENV_VARS.API_URL + '/customers/:customerId/credit_ewallet/v2',
      method: RESOURCES_VARS.API_METHODS.POST,
      transformResponse: parseData
    },
    ewalletNotNull: {
      url: ENV_VARS.API_URL + '/customers/ewallet_not_null',
      method: RESOURCES_VARS.API_METHODS.GET,
      params: {
        brandId: '@brandId'
      },
      transformResponse: parseData
    },
    resetPassword: {
      method: RESOURCES_VARS.API_METHODS.PUT,
      params: {
        action: 'reset_password'
      },
      transformResponse: parseData
    },
    checkIfCustomerHasAccessToChannel: {
      url: ENV_VARS.API_URL + '/customers/:customerId/channel/:channelId',
      method: RESOURCES_VARS.API_METHODS.GET,
      params: {
        channelId: '@channelId'
      },
      transformResponse: parseData
    },
    getEwalletAutomaticReloadConfig: {
      url: ENV_VARS.API_URL + '/customers/:customerId/ewallet_automatic_reload',
      method: RESOURCES_VARS.API_METHODS.GET,
      transformResponse: parseData
    }
  });
  return Customer;
}
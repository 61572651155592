"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

require("core-js/modules/es.function.name");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = void 0;

var _language_storage = _interopRequireDefault(require("./language_storage.service"));

var _angular = _interopRequireDefault(require("angular"));

var dependencies = [];

_angular.default.module('core.languageStorage', dependencies).service('languageStorage', _language_storage.default);

var _default = _angular.default.module('core.languageStorage').name;

exports.default = _default;
"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = _ngInjectExport;
_ngInjectExport.$inject = ["$q", "$window", "$interval", "Api", "$timeout"];

function _ngInjectExport($q, $window, $interval, Api, $timeout) {
  'ngInject';

  var self = this;
  self.timeOut = 10000;
  self.onLine = navigator.onLine;
  self.interval = null;
  self.callback = null;
  self.isTestingNetwork = false;

  self.register = function (cb) {
    self.callback = cb;
  };

  self.unregister = function () {
    self.callback = null;
  };

  self.testNetwork = function () {
    if (!self.isTestingNetwork) {
      self.isTestingNetwork = true;
      return Api.ping().$promise.then(function () {
        self.onLine = true;

        if (self.callback) {
          self.callback();
        }

        if (self.interval) {
          $interval.cancel(self.interval);
          self.interval = null;
        }

        self.isTestingNetwork = false;
        return true;
      }).catch(function () {
        self.onLine = false;

        if (self.callback) {
          self.callback();
        }

        self.interval = waitForReconnection();
        $timeout(function () {
          self.isTestingNetwork = false;
          self.testNetwork();
        }, self.timeOut);
        return false;
      });
    }
  };

  $window.addEventListener('offline', function () {
    self.interval = waitForReconnection();
  }, false);
  $window.addEventListener('online', function () {
    self.onLine = true;

    if (self.callback) {
      self.callback();
    }

    if (self.interval) {
      $interval.cancel(self.interval);
      self.interval = null;
    }
  }, false);

  var waitForReconnection = function waitForReconnection() {
    return $interval(function () {
      self.onLine = false;

      if (self.callback) {
        self.callback();
      }

      self.interval = null;
    }, self.timeOut, 1);
  };
}
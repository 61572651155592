export default function (authService, $state, statePromise, restaurant) {
    'ngInject';

    const vm = this;
    vm.restaurant = restaurant;
    vm.contact = function () {
        statePromise.go(
            'home.parameters.about.contact',
            {},
            {
                location: false,
            },
            'aboutContact',
        );
    };
    vm.logout = function () {
        authService.logout().then(function () {
            $state.go('login');
        });
    };
}

import tableView from './table.view.html';

// templates
import imageTemplate from './cellTemplates/image.template.html';
import priceTemplate from './cellTemplates/price.template.html';
import categoriesTemplate from './cellTemplates/categories.template.html';
import percentageTemplate from './cellTemplates/percentage.template.html';
import reportItemTypeTemplate from './cellTemplates/reportItemType.template.html';
import dateTemplate from './cellTemplates/date.template.html';
import hourTemplate from './cellTemplates/hour.template.html';
import checkboxTemplate from './cellTemplates/checkbox.template.html';
import dateMediumTemplate from './cellTemplates/date-medium.template.html';
import clickRowTemplate from './clickRow.template.view.html';

const DEFAULT_TABLE_PAGE_SIZE = 10;

export default function($templateCache, $timeout) {
  'ngInject';

  function controller($scope, $element, $attrs) {
    'ngInject';

    const vm = this;
    vm.allowAllSelect = true;
    vm.uiGridSetting = {};
    if (!vm.triggers) {
      vm.triggers = {};
    }
    if (!vm.scope) {
      vm.scope = {};
    }
    vm.$onInit = function() {
      vm.selectCount = 0;
      vm.pagesToDisplay = [];
      vm.pageSize = vm.pageSize || DEFAULT_TABLE_PAGE_SIZE;
      setGridSettings();
      setColumnDefs();
      if (vm.data.identifier) {
        vm.scope.identifier = vm.data.identifier;
        vm.scope.lockedItems = vm.data.lockedItems;
        vm.scope.fakeSelectedItems = vm.data.fakeSelectedItems;
      }
      vm.uiGridSetting.onRegisterApi = function(gridApi) {
        vm.gridApi = gridApi;
        if (vm.paginate) {
          if (vm.triggers.onPaginationChanged) {
            gridApi.pagination.on.paginationChanged($scope, function(
              newPage,
              pageSize,
            ) {
              vm.triggers
                .onPaginationChanged(newPage, pageSize)
                .then(function() {
                  $timeout(function() {
                    vm.uiGridSetting.data = vm.data.items;
                  });
                });
            });
            if (vm.triggers.onSortChanged) {
              gridApi.core.on.sortChanged($scope, function(grid, sortColumns) {
                vm.triggers.onSortChanged(grid, sortColumns);
                vm.forceUpdate();
              });
            }
          }
        }
        if ($attrs.searchModel && !vm.triggers.onPaginationChanged) {
          gridApi.grid.registerRowsProcessor(vm.singleFilter, 200);
        }
        gridApi.selection.on.rowSelectionChanged($scope, setSelectionVariables);
        gridApi.selection.on.rowSelectionChangedBatch(
          $scope,
          setSelectionVariables,
        );
      };
    };

    $timeout(() => {
      if (vm.selectedRows) {
        vm.selectedRows.map(row => vm.gridApi.selection.selectRow(row));
      }
      vm.scope.toggleRow = function(row) {
        if (!row.isSelected) {
          vm.gridApi.selection.selectRow(row.entity);
        } else {
          vm.gridApi.selection.unSelectRow(row.entity);
        }
      };
      updatePagesToDisplay();
    });

    function setColumnDefs() {
      if (!vm.columnDefs) return;
      vm.uiGridSetting.columnDefs = vm.columnDefs.map(function(colDef) {
        return setColumn(colDef);
      });
      if (vm.selectable) {
        vm.uiGridSetting.columnDefs.unshift({
          width: '80',
          name: 'selected',
          displayName: '',
          cellTemplate: checkboxTemplate,
        });
      }
    }

    function setGridSettings() {
      vm.uiGridSetting = {
        enableColumnMenus: false,
        rowHeight: 52,
        columnFooterHeight: 50,
        virtualizationThreshold: 100,
        columnVirtualizationThreshold: 100,
        enablePaginationControls: false,
        enableRowHeaderSelection: false,
        enableRowSelection: !(vm.data.lockedItems || vm.rowClick),
        showColumnFooter: !!vm.footer,
        totalItems: vm.data.count || vm.data.items.length,
        paginationPageSize: vm.paginate
          ? vm.pageSize
          : vm.data
          ? vm.data.items.length
          : vm.pageSize,
        data: vm.data.items,
        appScopeProvider: vm.scope,
        enableColumnAutoFit: !!vm.autoFit,
      };

      if (vm.triggers.onPaginationChanged) {
        vm.uiGridSetting.useExternalPagination = true;
      }
      if (vm.triggers.onSortChanged) {
        vm.uiGridSetting.useExternalSorting = true;
      }

      if (vm.rowClick) {
        vm.scope.rowClick = function(row, col) {
          if (!col.colDef.preventRowClick) {
            vm.rowClick(row);
          }
        };
        vm.uiGridSetting.rowTemplate = clickRowTemplate;
      }
    }

    function setColumn(column) {
      column.enableColumnMenu = false;
      column.aggregationHideLabel = true;

      if (column.template === 'image') {
        column.cellTemplate = imageTemplate;
        column.width = '80';
      }
      if (column.template === 'price') {
        column.cellTemplate = priceTemplate;
      }
      if (column.template === 'categories') {
        column.cellTemplate = categoriesTemplate;
      }
      if (column.template === 'vat') {
        column.cellTemplate = percentageTemplate;
      }
      if (column.template === 'reportItemType') {
        column.cellTemplate = reportItemTypeTemplate;
      }
      if (column.template === 'date') {
        column.cellTemplate = dateTemplate;
      }
      if (column.template === 'date-medium') {
        column.cellTemplate = dateMediumTemplate;
      }
      if (column.template === 'hour') {
        column.cellTemplate = hourTemplate;
      }
      return column;
    }

    function setSelectionVariables() {
      vm.selectedRows = vm.gridApi.selection.getSelectedRows();
      vm.selectCount = vm.gridApi.selection.getSelectedCount();
    }

    function updatePagesToDisplay() {
      if (!vm.gridApi) {
        return;
      }
      vm.pagesToDisplay = [];
      const startingPage = Math.max(vm.gridApi.pagination.getPage() - 5, 1);
      const endingPage = Math.min(
        startingPage + 10,
        vm.gridApi.pagination.getTotalPages(),
      );

      for (let i = startingPage; i <= endingPage; ++i) {
        vm.pagesToDisplay.push(i);
      }
    }

    vm.singleFilter = function(renderableRows) {
      if (vm.searchModel === undefined || vm.searchModel.length < 2)
        return renderableRows;

      const matcher = new RegExp(
        vm.searchModel.toLowerCase().replace(/\s/g, ''),
      );
      const columns = [];

      angular.forEach(vm.columnDefs, function(val) {
        columns.push(val.field);
      });

      renderableRows.forEach(function(row) {
        let match = false;
        columns.forEach(function(field) {
          if (
            row.entity[field] != null &&
            row.entity[field]
              .toString()
              .toLowerCase()
              .replace(/\s/g, '')
              .match(matcher)
          ) {
            match = true;
          }
        });
        if (!match) {
          row.visible = false;
        }
      });
      return renderableRows;
    };

    vm.applyAction = function(fn, promise, callback) {
      const selectedItems = vm.gridApi.selection.getSelectedRows();
      if (promise) {
        promise().then(function(data) {
          // if promise success
          selectedItems.forEach(function(object, index) {
            const promise = fn(object, index, data);
            if (
              promise &&
              index === selectedItems.length - 1 &&
              callback &&
              typeof callback === 'function'
            ) {
              promise.then(callback);
            }
          });
        });
      } else {
        selectedItems.forEach(function(object, index) {
          const promise = fn(object, index);
          if (
            promise &&
            index === selectedItems.length - 1 &&
            callback &&
            typeof callback === 'function'
          ) {
            promise.then(callback);
          }
        });
      }
    };

    vm.changePage = function(index) {
      if (vm.gridApi) {
        vm.gridApi.pagination.seek(parseInt(index));
        updatePagesToDisplay();
      }
    };

    vm.forceUpdate = function() {
      setColumnDefs();
      if (vm.gridApi.pagination.getPage() != 1) {
        vm.changePage(1);
      } else if (vm.triggers.onPaginationChanged) {
        vm.triggers
          .onPaginationChanged(1, vm.uiGridSetting.paginationPageSize)
          .then(function() {
            $timeout(function() {
              vm.uiGridSetting.data = vm.data.items;
              vm.uiGridSetting.totalItems = vm.data.count || vm.data.items.length;
              vm.uiGridSetting.paginationPageSize = vm.paginate
                ? vm.pageSize
                : vm.data
                ? vm.data.items.length
                : vm.pageSize;
            });
            vm.gridApi.core.refresh().then(function() {
              updatePagesToDisplay();
            });
          });
      } else {
        $timeout(function() {
          vm.uiGridSetting.data = vm.data.items;
          vm.uiGridSetting.totalItems =
            vm.data.items.count || vm.data.items.length;
          vm.uiGridSetting.paginationPageSize = vm.paginate
            ? vm.pageSize
            : vm.data
            ? vm.data.items.length
            : vm.pageSize;
          });
        vm.gridApi.core.refresh().then(function() {
          updatePagesToDisplay();
        });
      }
    };
    vm.resetFunction = function(data) {
      vm.data = data;
      vm.changePage(1);
      vm.uiGridSetting.data = data.items;
      vm.uiGridSetting.totalItems = data.items.count || data.items.length;
      vm.selectCount = 0;
      vm.pagesToDisplay = [];

      setGridSettings();
      setColumnDefs();
      if (vm.paginate) {
        updatePagesToDisplay();
        if (vm.triggers.onPaginationChanged) {
          gridApi.pagination.on.paginationChanged($scope, function(
            newPage,
            pageSize,
          ) {
            vm.triggers.onPaginationChanged(newPage, pageSize).then(function() {
              vm.uiGridSetting.data = vm.data.items;
            });
          });
          if (vm.triggers.onSortChanged) {
            gridApi.core.on.sortChanged($scope, function(grid, sortColumns) {
              vm.triggers.onSortChanged(grid, sortColumns);
              vm.forceUpdate();
            });
          }
        }
      }
    };
    vm.refreshHeight = function() {
      vm.gridApi.grid.refresh();
    };

    $scope.$watch(
      function() {
        return vm.searchModel;
      },
      function() {
        if (vm.gridApi) {
          if (vm.gridApi.selection) {
            vm.gridApi.selection.clearSelectedRows();
          }
          if (vm.triggers.onPaginationChanged) {
            vm.forceUpdate();
          } else {
            vm.gridApi.core.refreshRows().then(function() {
              if (!vm.triggers.disableRefreshRow) {
                updatePagesToDisplay();
              }
            });
          }
        }
      },
    );
  }

  return {
    restrict: 'A',
    bindToController: {
      actions: '=?',
      scope: '=?',
      data: '=?',
      columnDefs: '=?',
      searchModel: '=?',
      rowClick: '=?',
      selectedRows: '=?',
      gridApi: '=?',
      paginate: '<',
      pageSize: '<?',
      footer: '<',
      selectable: '<',
      autoFit: '<',
      triggers: '=?',
      forceUpdate: '=?',
      resetFunction: '=?',
      refreshHeight: '=?',
      rowTemplate: '=?',
    },
    controller,
    controllerAs: 'tableVm',
    scope: {},
    template: $templateCache.get(tableView),
  };
}

export default function (statePromise, Stuart, order, job, $stateParams, $sce) {
    'ngInject';

    const vm = this;
    vm.loading = false;
    vm.trustSrc = function (src) {
        return $sce.trustAsResourceUrl(src);
    };

    vm.cancel = function () {
        if (!vm.loading) {
            vm.error = null;
            statePromise.go('home.stuart.infos.cancel', {}, { location: false }, 'stuartCancel').then(function () {
                vm.loading = true;
                Stuart.cancelJob({ orderId: order.orderId, jobId: job.stuartJobId })
                    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
                    .$promise.then(function (data) {
                        statePromise.resolve({}, $stateParams.statePromiseName);
                    })
                    .catch(function (e) {
                        vm.loading = false;
                        vm.error = e.data.message;
                    });
            });
        }
    };
}

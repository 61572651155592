import dataExcerptView from './data_excerpt.view.html';
export default function($templateCache, $interval, $fioHelper) {
  'ngInject';

  function link(scope, element, attrs, $backdrop) {
    scope.numercialDisplayedValue = 0;
    var counter;
    var time = 1000; // total animation time in ms
    var hasBeenTriggered = false;

    // interval multiplier
    var multiplier = 9;
    if (scope.value < 100) {
      multiplier = 4;
    } else if (scope.value < 200) {
      multiplier = 6;
    } else if (scope.value > 1000) {
      multiplier = 13;
    } else if (scope.value > 10000) {
      multiplier = 17;
    }

    var interval = (time / scope.value) * multiplier;

    scope.countUp = function() {
      hasBeenTriggered = true;

      if (counter != undefined) {
        $interval.cancel(counter);
        counter = undefined;
      }

      scope.numercialDisplayedValue = 0;

      counter = $interval(function() {
        scope.numercialDisplayedValue += multiplier;

        //scope.toCompensate = scope.value.toString().length - scope.displayedValue.toString().length;

        scope.displayedValue = (
          '000000000000000000' + scope.numercialDisplayedValue
        ).slice(-1 * scope.value.toString().length);

        if (scope.displayedValue >= scope.value) {
          if (scope.displayedValue != scope.value) {
            scope.displayedValue = scope.value;
          }
          $interval.cancel(counter);
          counter = undefined;
        }
      }, interval);
    };

    var viewportHeight = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight || 0,
    );
    window.onresize = function(event) {
      viewportHeight = Math.max(
        document.documentElement.clientHeight,
        window.innerHeight || 0,
      );
    };

    var isInViewport = function() {
      return $fioHelper.offset(element[0]).top - viewportHeight < 0;
    };

    var closestContent = element[0];
    while (closestContent.tagName !== 'FIO-CONTENT') {
      closestContent = closestContent.parentNode;
    }

    angular.element(closestContent).on('scroll', function() {
      if (isInViewport() && !hasBeenTriggered) {
        scope.countUp();
      }
    });
  }

  return {
    link: link,
    restrict: 'E',
    transclude: true,
    scope: {
      icon: '@',
      color: '@',
      value: '=',
      unit: '@',
      legend: '@',
    },
    template: $templateCache.get(dataExcerptView),
  };
}

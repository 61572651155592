import angular from 'angular';
// eslint-disable-next-line import/extensions
import pendingController from './pending.controller.js';

const dependencies = [];

angular
    .module('app.home.orders_home.detail.modal_refund.pending', dependencies)
    .controller('PendingRefundController', pendingController);
export default angular.module('app.home.orders_home.detail.modal_refund.pending').name;

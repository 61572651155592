import angular from 'angular';
import searchModule from './search/search.module';
import validateModule from './validate/validate.module';
import addPrinterRoutes from './add_printer.routes';
import addPrinterController from './add_printer.controller';

const dependencies = [searchModule, validateModule];

angular
    .module('app.home.parameters.print.add', dependencies)
    .config(addPrinterRoutes)
    .controller('ParametersPrinterAddController', addPrinterController);
export default angular.module('app.home.parameters.print.add').name;

"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = _ngInjectExport;
_ngInjectExport.$inject = ["$resource", "$log", "RESOURCES_VARS", "ENV_VARS"];

function _ngInjectExport($resource, $log, RESOURCES_VARS, ENV_VARS) {
  'ngInject'; // resource rules
  // =============================================================================

  function parseData(response) {
    try {
      var body = angular.fromJson(response);

      if (body && body.data) {
        return body.data;
      }

      return body;
    } catch (e) {
      $log.error(e);
      return e;
    }
  }

  return $resource(ENV_VARS.API_URL + '/availability/:menuId/:channel/:action/:actionId', {
    menuId: '@menuId',
    channel: '@channel',
    action: '@action',
    actionId: '@actionId'
  }, {
    updateProduct: {
      method: RESOURCES_VARS.API_METHODS.PUT,
      transformResponse: parseData,
      params: {
        action: 'product'
      }
    },
    updateCategory: {
      method: RESOURCES_VARS.API_METHODS.PUT,
      transformResponse: parseData,
      params: {
        action: 'category'
      }
    },
    updateStep: {
      method: RESOURCES_VARS.API_METHODS.PUT,
      transformResponse: parseData,
      params: {
        action: 'step'
      }
    }
  });
}
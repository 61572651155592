"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

require("core-js/modules/es.function.name");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = void 0;

var _concat = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/concat"));

var _angularResource = _interopRequireDefault(require("angular-resource"));

var _api = _interopRequireDefault(require("./api.service"));

var _apm = _interopRequireDefault(require("../apm/apm.interceptor"));

var _angular = _interopRequireDefault(require("angular"));

var _angularTranslate = _interopRequireDefault(require("angular-translate"));

var _angularTranslateLoaderStaticFiles = _interopRequireDefault(require("angular-translate-loader-static-files"));

var dependencies = [_angularResource.default, _angularTranslate.default, _angularTranslateLoaderStaticFiles.default];

_angular.default.module('core.api', dependencies).config(['$httpProvider', 'ENV_VARS', function ($httpProvider, ENV_VARS) {
  $httpProvider.defaults.useXDomain = true;

  if (ENV_VARS.APP_NAME && ENV_VARS.APP_VERSION) {
    var _context;

    $httpProvider.defaults.headers.common['io-application'] = (0, _concat.default)(_context = "".concat(ENV_VARS.APP_NAME, "/")).call(_context, ENV_VARS.APP_VERSION);
  }

  $httpProvider.interceptors.push(_apm.default);
}]).service('apiService', _api.default);

var _default = _angular.default.module('core.api').name;

exports.default = _default;
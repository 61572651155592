import validateView from 'app/components/parameters/print/add_printer/validate/validate.view.html';
import searchView from 'app/components/parameters/print/add_printer/search/search.view.html';

export default function ($stateProvider, $urlRouterProvider, $locationProvider) {
    'ngInject';

    $locationProvider.html5Mode(true);
    $stateProvider
        .state('home.parameters.print.add.search', states.search())
        .state('home.parameters.print.add.validate', states.validate());
}

// eslint-disable-next-line vars-on-top, no-var
var states = {
    validate() {
        return {
            params: {
                printer: null,
                edit: false,
            },
            templateProvider: [
                '$templateCache',
                function ($templateCache) {
                    return $templateCache.get(validateView);
                },
            ],
            controller: 'ParametersPrinterAddValidateController',
            controllerAs: 'parametersPrinterAddValidateVm',
        };
    },
    search() {
        return {
            templateProvider: [
                '$templateCache',
                function ($templateCache) {
                    return $templateCache.get(searchView);
                },
            ],
            controller: 'ParametersPrinterAddSearchController',
            controllerAs: 'parametersPrinterAddSearchVm',
        };
    },
};

import filterBigDecimalModule from '@innovorder/angularcore/core/filter_big_decimal/filter_big_decimal.module';
import angular from 'angular';
import ordersModule from './orders/orders.module';
import emptyModule from './empty/empty.module';
import orderModule from './order/order.module';
import modalNoPrinterModule from './modal_no_printer/modal_no_printer.module';
import modalTicketPreviewModule from './modal_ticket_preview/modal_ticket_preview.module';
import notificationModule from './notification/notification.module';
import detailModule from './detail/detail.module';
import ordersHomeRoutes from './orders_home.routes';
import ordersHomeConstants from './orders_home.constants';
import ordersHomeController from './orders_home.controller';
import StuartV2Module from './stuart_v2/stuart_v2.module';

const dependencies = [
    ordersModule,
    emptyModule,
    orderModule,
    modalNoPrinterModule,
    modalTicketPreviewModule,
    notificationModule,
    detailModule,
    filterBigDecimalModule,
    StuartV2Module,
];

angular
    .module('app.home.orders_home', dependencies)
    .config(ordersHomeRoutes)
    .constant('ORDERS_VARS', ordersHomeConstants)
    .controller('OrdersHomeController', ordersHomeController);
export default angular.module('app.home.orders_home').name;

import angular from 'angular';
import driveDetailComponent from './drive_detail.component';
import driveDetailController from './drive_detail.controller';

const dependencies = [];

angular
    .module('app.home.drive.detail', dependencies)
    .component('driveDetail', driveDetailComponent)
    .controller('DriveDetailController', driveDetailController);
export default angular.module('app.home.drive.detail').name;

"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

require("core-js/modules/es.regexp.exec");

require("core-js/modules/es.string.replace");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.createUrlWithPlaceholders = void 0;

var createUrlWithPlaceholders = function createUrlWithPlaceholders(url) {
  // To replace https://api-dev.innovorder.fr/brands/webordering-configuration/19df250927455d with https://api-dev.innovorder.fr/brands/webordering-configuration/:hash
  var weborderingConfigurationRegex = /\/webordering-configuration\/([0-9]|[a-z])+/;
  var urlWithPlaceholders = url.replace(weborderingConfigurationRegex, '/webordering-configuration/:hash'); // To delete params
  // E.g: replace i18n/fr.json?date=1677167202851 with i18n/fr.json
  // E.g: replace https://api.innovorder.fr/location/autocomplete?searchText=Centre affaires altar&sessiontoken=5078f1e7-9cd7-42d7-b0f5-2e8d61c3fb74 with https://api.innovorder.fr/location/autocomplete

  var paramsRegex = /\?.*/;
  urlWithPlaceholders = urlWithPlaceholders.replace(paramsRegex, ''); // To replace https://api-dev.innovorder.fr/customers/458954/royalty_balance with https://api-dev.innovorder.fr/customers/:id/royalty_balance

  var numberRegex = /\/[0-9]+/g;
  urlWithPlaceholders = urlWithPlaceholders.replace(numberRegex, '/:id');
  return urlWithPlaceholders;
};

exports.createUrlWithPlaceholders = createUrlWithPlaceholders;
"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.transformZipResponse = exports.transformPdfResponse = void 0;

var getFileResponseTransformer = function getFileResponseTransformer(mimeType) {
  return function (data) {
    var file;

    if (data) {
      file = new Blob([data], {
        type: "application/".concat(mimeType)
      });
    }

    return {
      response: file
    };
  };
};

var transformPdfResponse = getFileResponseTransformer('pdf');
exports.transformPdfResponse = transformPdfResponse;
var transformZipResponse = getFileResponseTransformer('zip');
exports.transformZipResponse = transformZipResponse;
"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.PAYMENT_METHOD_CODE = void 0;
var PAYMENT_METHOD_CODE = {
  AlloResto: 'AlloResto',
  AmericanExpress: 'AmericanExpress',
  Cash: 'Cash',
  Change: 'Change',
  Check: 'Check',
  CreditCard: 'CreditCard',
  CreditNote: 'CreditNote',
  CreditNoteIssued: 'CreditNoteIssued',
  Deliveroo: 'Deliveroo',
  ELuncheonVoucher: 'ELuncheonVoucher',
  EWallet: 'EWallet',
  ExternalPayment: 'ExternalPayment',
  Foodora: 'Foodora',
  Ingenico: 'Ingenico',
  LuncheonVoucher: 'LuncheonVoucher',
  Lydia: 'Lydia',
  Nepting: 'Nepting',
  Yavin: 'Yavin',
  Paygreen: 'Paygreen',
  RestoFlash: 'RestoFlash',
  Stripe: 'Stripe',
  Transfer: 'Transfer',
  UberEats: 'UberEats',
  Unknown: 'Unknown',
  Unknown2: 'Unknown2'
};
exports.PAYMENT_METHOD_CODE = PAYMENT_METHOD_CODE;
"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = _default;

var _map = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/map"));

var _auth_interceptor = _interopRequireDefault(require("./auth_interceptor.service"));

function _default() {
  var provider = this;
  provider.caseResponseError = [];

  provider.setCaseResponseError = function (statusCase, statusCallback, statusCode) {
    provider.caseResponseError = addOrUpdateCase(provider.caseResponseError, statusCase, statusCallback, statusCode);
  };

  function addOrUpdateCase(caseRequests, statusCase, statusCallback, statusCode) {
    if (typeof statusCase === 'number' && typeof statusCallback === 'function') {
      var createNewStatusCase = true;
      caseRequests = (0, _map.default)(caseRequests).call(caseRequests, function (caseRequest) {
        if (caseRequest.status === statusCode) {
          createNewStatusCase = false;

          if (typeof statusCode === 'string') {
            caseRequest.callbacks.push({
              code: statusCode,
              fn: statusCallback
            });
          } else {
            caseRequest.default = statusCallback;
          }
        }

        return caseRequest;
      });

      if (createNewStatusCase) {
        var res = {
          status: statusCase,
          callbacks: []
        };

        if (typeof statusCode === 'string') {
          res.callbacks.push({
            code: statusCode,
            fn: statusCallback
          });
        } else {
          res.default = statusCallback;
        }

        caseRequests.push(res);
      }
    }

    return caseRequests;
  }

  provider.$get = _auth_interceptor.default;
  return provider;
}
export default function ($state, $stateParams) {
    'ngInject';

    const digicodeVm = this;

    digicodeVm.message = $stateParams.message;

    digicodeVm.keyboard = {
        code: [],
        keyDown(i) {
            if (digicodeVm.keyboard.code.length < 4) {
                digicodeVm.keyboard.code.push(i);
            }

            if (digicodeVm.keyboard.code.length > 3) {
                $state.go(
                    '^.reason',
                    {
                        code: digicodeVm.keyboard.code.join(''),
                    },
                    {
                        reload: false,
                        location: false,
                    },
                );
            }
        },
        flush() {
            digicodeVm.keyboard.code = [];
        },
    };
}

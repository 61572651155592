export default function ($state, $stateParams, statePromise) {
    'ngInject';

    const reasonVm = this;
    reasonVm.code = $stateParams.code;
    reasonVm.cancellationReasonSelected = null;
    reasonVm.cancellationReasons = [
        {
            name: 'CANNOT_FULFILL_ORDER',
            value: 'CANNOT_FULFILL_ORDER',
            selected: false,
        },
        {
            name: 'ORDERING_MISTAKE',
            value: 'ORDERING_MISTAKE',
            selected: false,
        },
        {
            name: 'UNSATISFIED_CUSTOMER',
            value: 'UNSATISFIED_CUSTOMER',
            selected: false,
        },
        {
            name: 'CUSTOMER_NO_SHOW',
            value: 'CUSTOMER_NO_SHOW',
            selected: false,
        },
        {
            name: 'CUSTOMER_LEAVES',
            value: 'CUSTOMER_LEAVES',
            selected: false,
        },
        {
            name: 'LEARNING_MODE',
            value: 'LEARNING_MODE',
            selected: false,
        },
        {
            name: 'TECHNICAL',
            value: 'TECHNICAL',
            selected: false,
        },
        {
            name: 'OTHER',
            value: 'OTHER',
            selected: false,
        },
    ];
    reasonVm.submit = function () {
        statePromise.go('^.pending', {
            code: reasonVm.code,
            cancellationReason: reasonVm.cancellationReasonSelected.value,
            cancellationReasonDescription: reasonVm.cancellationReasonDescription,
        });
    };
    reasonVm.handleChange = function (reason) {
        reasonVm.cancellationReasonDescription = null;
        reasonVm.cancellationReasons = reasonVm.cancellationReasons.map(function (element) {
            if (element.value !== reason.value) {
                element.selected = false;
            }
            return element;
        });
        if (reason.selected) {
            reasonVm.cancellationReasonSelected = reason;
        } else {
            reasonVm.cancellationReasonSelected = null;
        }
    };
}

"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = _ngInjectExport;

var _indexOf = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/index-of"));

_ngInjectExport.$inject = ["$q", "$injector"];

function _ngInjectExport($q, $injector) {
  'ngInject';

  var interceptor = this;
  var blacklist = ['init/home/configuration', 'hash_map', 'orders/verify', 'log']; // Public methods & properties
  // =============================================================================

  var logRequest = function logRequest(config) {
    var _context, _context2;

    if ((0, _indexOf.default)(_context = config.url).call(_context, 'api') > -1 && (0, _indexOf.default)(_context2 = config.url).call(_context2, 'log') == -1) {}

    return config;
  };

  var logResponse = function logResponse(res) {
    var shouldLog = true;

    for (var i = 0; i < blacklist.length; i++) {
      var _context3;

      if ((0, _indexOf.default)(_context3 = res.config.url).call(_context3, blacklist[i]) == -1) {
        shouldLog = false;
      }
    }

    return res;
  };

  var logErrorResponse = function logErrorResponse(res) {
    var shouldLog = true;

    for (var i = 0; i < blacklist.length; i++) {
      var _context4;

      if (res && res.config && (0, _indexOf.default)(_context4 = res.config.url).call(_context4, blacklist[i]) == -1) {
        shouldLog = false;
      }
    }

    return $q.reject(res);
  };

  interceptor.request = logRequest;
  interceptor.requestError = logRequest;
  interceptor.response = logResponse;
  interceptor.responseError = logErrorResponse;
}
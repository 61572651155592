import selectView from './select.view.html';

export default function($templateCache, $backDrop) {
  'ngInject';

  function link(scope, element, attrs, ngModel) {
    scope.placeholder = attrs.placeholder;

    scope.options = {};
    scope.isOpen = false;

    let backdrop;

    scope.toggle = function() {
      scope.isOpen = !scope.isOpen;

      if (scope.isOpen) {
        backdrop = $backDrop.inject(
          scope,
          function() {
            scope.$apply(function() {
              scope.isOpen = !scope.isOpen;
            });
          },
          element,
        );
      } else {
        $backDrop.destroy(backdrop);
      }
    };

    scope.ngModel = ngModel;
  }

  function controller($scope, $element, $attrs) {
    'ngInject';

    const self = this;

    this.addOption = function(name, value) {
      if (!$scope.options) {
        $scope.options = {};
      }
      $scope.options[value] = name;
    };

    this.selectElement = function(name, value) {
      if (!$scope.$$phase) {
        $scope.$apply(function() {
          $scope.ngModel.$setViewValue(value);
          if ($scope.onSelect) {
            $scope.onSelectArgument
              ? $scope.onSelect($scope.onSelectArgument)
              : $scope.onSelect();
          }
          $scope.toggle();
        });
      }
    };
  }

  return {
    link,
    controller,
    require: 'ngModel',
    scope: {
      onSelect: '<',
      onSelectArgument: '<',
    },
    restrict: 'E',
    transclude: true,
    template: $templateCache.get(selectView),
  };
}

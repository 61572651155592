export default function (job, Stuart, $interval, order, $state, statePromise, $stateParams, $timeout) {
    'ngInject';

    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    const vm = this;
    /* var interval = $interval(function(){
			Stuart.getJob({jobId : job.stuartJobId, orderId : order.orderId}).$promise
				.then(function(data){
					if(data.status != 0){
						$interval.cancel(interval);
						statePromise.resolve({}, $stateParams.statePromiseName);
					}
				})
				.catch(function(e){
					$state.go('home.stuart.error', {error : e.data.message, transport : $stateParams.transport});
				});
		}
	,1000); */
    $timeout(function () {
        statePromise.resolve({}, $stateParams.statePromiseName);
    }, 1000);
}

import ordersView from 'app/components/orders_home/orders/orders.view.html';

export default {
    templateUrl: ordersView,
    controller: 'OrdersController',
    controllerAs: 'ordersVm',
    bindings: {
        status: '@',
        orders: '<',
        currency: '<',
        colors: '<',
    },
};

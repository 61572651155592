"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

require("core-js/modules/es.function.name");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports["default"] = serializeTicket;

var _forEach = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/for-each"));

var _constants = require("../constants");

var _helpers = require("../helpers");

var _cart = require("../cart");

var _LOCALE_STRING_MAP = require("../LOCALE_STRING_MAP");

function serializeTicket(xmlTicket, orderData) {
  var isEcoFriendlyTicket = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  var output = [];
  var doNotPrintCount = 0;
  var cartCount = 0;

  if (xmlTicket !== '' && orderData !== '') {
    var _context;

    var localeStringMapInstance = _LOCALE_STRING_MAP.LOCALE_STRING_MAP[orderData.restaurant.locale] ? _LOCALE_STRING_MAP.LOCALE_STRING_MAP[orderData.restaurant.locale] : _LOCALE_STRING_MAP.LOCALE_STRING_MAP.fr;
    var ticketParsedXml = (0, _helpers.getXMLTicket)(orderData, localeStringMapInstance, xmlTicket); // Convert ticket with cart to basic printer instructions

    (0, _forEach["default"])(_context = ticketParsedXml.children).call(_context, function (child) {
      // Convert cart to line instructions
      if (child.name === 'cart' && !isEcoFriendlyTicket) {
        var _convertCartToLines = (0, _cart.convertCartToLines)(cartCount, child, localeStringMapInstance, orderData, doNotPrintCount, output);

        cartCount = _convertCartToLines.cartCount;
        doNotPrintCount = _convertCartToLines.doNotPrintCount;
        output = _convertCartToLines.output;
      } else if (_constants.PRINTER_SCHEMA[child.name]) {
        output.push((0, _helpers.convertBasicPrinterInstruction)(child));
      } else if (child.name === 'tpe-ticket' && orderData.tpeTicket) {
        (0, _cart.convertTpeTicketToLines)(output, orderData.tpeTicket);
      }
    });
  }

  if (xmlTicket === '' && orderData && orderData.tpeTicket) {
    (0, _cart.convertTpeTicketToLines)(output, orderData.tpeTicket);
  }

  if (doNotPrintCount > 0 && doNotPrintCount === cartCount) {
    return [];
  }

  return output;
}
import optionMultipleView from './option_multiple.view.html';
export default function($templateCache, $compile) {
  'ngInject';

  function link(scope, element, attrs, selectCtrl) {
    if (attrs.default != undefined) {
      setTimeout(function() {
        selectCtrl.selectElement(scope.value);
      }, 0);
    }

    if (selectCtrl.addOption(scope.name, scope.value)) {
      scope.isSelected = true;
    }

    angular.element(element).on('click', function() {
      if (!scope.isSelected) {
        selectCtrl.selectElement(scope.value);
      }
    });

    scope.$watch(
      function() {
        return (
          selectCtrl.options &&
          selectCtrl.options[scope.value] &&
          selectCtrl.options[scope.value].isSelected
        );
      },
      function(value) {
        scope.isSelected = value;
      },
    );

    scope.$watch(
      function() {
        return selectCtrl.options[scope.value].toDisplay;
      },
      function(value) {
        scope.toDisplay = value;
      },
    );
  }

  return {
    link: link,
    restrict: 'E',
    require: '^fioSelectMultiple',
    scope: {
      value: '=',
      name: '=',
    },
    template: $templateCache.get(optionMultipleView),
  };
}

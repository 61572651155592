import angular from 'angular';
import modalReloadKiosksController from './modal_reload_kiosks.controller';

const dependencies = [];

// eslint-disable-next-line angular/controller-name
angular
    .module('app.home.availability.modal_reload_kiosks', dependencies)
    .controller('modalReloadKiosksController', modalReloadKiosksController);
export default angular.module('app.home.availability.modal_reload_kiosks').name;

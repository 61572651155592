export default function() {
  return {
    priority: -200,
    restrict: 'A',
    require: '?^uiGrid',
    link: function(scope, $element) {
      if (scope.grid.options.enableHovering) {
        $element.on('mouseover', function() {
          scope.row.grid.api.core.getVisibleRows().forEach(function(row) {
            row.isHovered = false;
          });

          scope.row.isHovered = true;
          scope.$apply();
        });

        $element.on('mouseout', function() {
          scope.row.isHovered = false;
          scope.$apply();
        });
      }
    },
  };
}

"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

require("core-js/modules/es.array.join");

require("core-js/modules/es.regexp.exec");

require("core-js/modules/es.string.split");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.formatCartData = void 0;

var _assign = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/assign"));

var _filter = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/filter"));

var _map = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/map"));

var _order_cart = require("../order_cart/order_cart.controller");

function generateCurrencyBigDecimalPriceFormatter($filter, iso4217) {
  return function (price, currency) {
    return $filter('isoCurrency')($filter('bigDecimal')(price, iso4217.getCurrencyByCode(currency).fraction), currency);
  };
}

function recursiveProductCurrencyBigDecimalPriceFormatter(product, currency, currencyBigDecimalPriceFormatter) {
  product.unitPriceFormatted = currencyBigDecimalPriceFormatter(product.unitPrice, currency);
  product.priceFormatted = currencyBigDecimalPriceFormatter(product.price, currency);

  if (product.steps.length > 0) {
    for (var i = 0; i < product.steps.length; i++) {
      if (product.steps[i].products.length > 0) {
        for (var j = 0; j < product.steps[i].products.length; j++) {
          product.steps[i].products[j] = recursiveProductCurrencyBigDecimalPriceFormatter(product.steps[i].products[j], currency, currencyBigDecimalPriceFormatter);
        }
      }
    }
  }

  return product;
}

var formatCartData = function formatCartData(orderData, $filter, iso4217) {
  var currencyBigDecimalPriceFormatter = generateCurrencyBigDecimalPriceFormatter($filter, iso4217);
  orderData.ttcPromocodeReductionFormatted = currencyBigDecimalPriceFormatter(orderData.ttcPromocodeReduction, orderData.currency);
  orderData.ewalletBalanceAfterOrder = currencyBigDecimalPriceFormatter(orderData.ewalletBalanceAfterOrder, orderData.currency);
  orderData.ewalletBalanceBeforeOrder = currencyBigDecimalPriceFormatter(orderData.ewalletBalanceBeforeOrder, orderData.currency);
  orderData.ttcRoyaltyReductionFormatted = currencyBigDecimalPriceFormatter(orderData.ttcRoyaltyReduction, orderData.currency);
  orderData.ttcTakeAwayReductionFormatted = currencyBigDecimalPriceFormatter(orderData.ttcTakeAwayReduction, orderData.currency);
  orderData.ttcOnlinePaymentDiscountFormatted = currencyBigDecimalPriceFormatter(orderData.ttcOnlinePaymentDiscount, orderData.currency);
  orderData.ttcDeliveryFeeFormatted = currencyBigDecimalPriceFormatter(orderData.ttcDeliveryFee, orderData.currency);
  orderData.ttcServiceFeeFormatted = currencyBigDecimalPriceFormatter(orderData.ttcServiceFee, orderData.currency);
  orderData.ttcTotalPriceFormatted = currencyBigDecimalPriceFormatter(orderData.ttcTotalPrice, orderData.currency);

  if (orderData.ttcEntranceFee) {
    orderData.ttcEntranceFeeFormatted = currencyBigDecimalPriceFormatter(orderData.ttcEntranceFee, orderData.currency);
  }

  if (orderData.ttcGrantReduction) {
    orderData.ttcGrantReductionFormatted = '- ' + currencyBigDecimalPriceFormatter(orderData.ttcGrantReduction, orderData.currency);
  }

  if (orderData.paymentByEwallet) {
    orderData.paymentMean = 'EWALLET';
  } else if (orderData.paymentProvider) {
    orderData.paymentMean = orderData.paymentProvider;
  } else if (orderData.paymentMean) {
    orderData.paymentMean = orderData.paymentMean.split('###').join(', ');
  } else if (orderData.ttcRoyaltyReduction) {
    orderData.paymentMean = 'CAGNOTTE';
  } else {
    orderData.paymentMean = '';
  } //previous printing bridge compliance.


  if (orderData.customer) {
    orderData.user = orderData.customer;
  }

  try {
    if (orderData.orderRecapRawData) {
      var _context, _context2, _context3, _context4;

      // Handle orders with orderRecapRawData (new version)
      var orderRecap = JSON.parse(orderData.orderRecapRawData);
      orderData.grants = (0, _map.default)(_context = (0, _filter.default)(_context2 = orderRecap.discounts).call(_context2, function (discount) {
        return discount.strategy.type === 'GRANT';
      })).call(_context, function (grant) {
        return (0, _assign.default)({}, grant, {
          amount: currencyBigDecimalPriceFormatter(grant.amount, orderData.currency)
        });
      });
      orderData.entranceFees = (0, _map.default)(_context3 = (0, _filter.default)(_context4 = orderRecap.fees).call(_context4, function (fee) {
        return fee.type === 'ENTRANCE_FEE';
      })).call(_context3, function (fee) {
        return (0, _assign.default)({}, fee, {
          totalPrice: {
            totalInclTaxWithDiscount: currencyBigDecimalPriceFormatter(fee.totalPrice.totalInclTaxWithDiscount, orderData.currency),
            totalInclTax: currencyBigDecimalPriceFormatter(fee.totalPrice.totalInclTax, orderData.currency)
          }
        });
      });
      orderData.totalVatFormatted = currencyBigDecimalPriceFormatter(orderRecap.totalPrice.totalTax, orderData.currency);
    } else {
      // Handle orders without orderRecapRawData (old version)
      if (orderData.grantRawData) {
        var grant = JSON.parse(orderData.grantRawData);
        orderData.grants = [{
          label: grant.labelTicket,
          amount: currencyBigDecimalPriceFormatter(grant.amount, orderData.currency)
        }];
      }

      if (orderData.entranceFeeRawData) {
        var entranceFee = JSON.parse(orderData.entranceFeeRawData);
        orderData.entranceFees = [{
          label: entranceFee.labelTicket,
          totalPrice: {
            totalInclTaxWithDiscount: currencyBigDecimalPriceFormatter(entranceFee.amount, orderData.currency)
          }
        }];
      }
    }
  } catch (err) {
    console.log(err);
  }

  var cartRawData;

  try {
    cartRawData = JSON.parse(orderData.cartRawData);
  } catch (err) {
    console.log(err);
  }

  var products;

  switch (cartRawData.version) {
    case _order_cart.CartVersion.V1:
      products = cartRawData.enrichedCart;
      break;

    default:
      products = cartRawData.products;
  }

  return {
    products: (0, _map.default)(products).call(products, function (product) {
      return recursiveProductCurrencyBigDecimalPriceFormatter(product, orderData.currency, currencyBigDecimalPriceFormatter);
    })
  };
};

exports.formatCartData = formatCartData;
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
export default function (statePromise, $state) {
    'ngInject';

    const modalNoPrinterVm = this;

    modalNoPrinterVm.close = function () {
        statePromise.reject('noPrinter');
    };
    modalNoPrinterVm.goToPrinters = function () {
        statePromise.resolve({}, 'noPrinter');
    };
}

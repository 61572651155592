"use strict";

var _interopRequireWildcard = require("@babel/runtime-corejs3/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = _ngInjectExport;

var _angular = _interopRequireDefault(require("angular"));

var Sentry = _interopRequireWildcard(require("@sentry/browser"));

var _raven_logger = _interopRequireDefault(require("./raven_logger.service"));

_ngInjectExport.$inject = ["ENV_VARS"];

function _ngInjectExport(ENV_VARS) {
  'ngInject';

  var provider = this;
  provider.isInit = false;

  provider.init = function (urlConfig) {
    provider.Raven = Sentry;
    provider.Raven.init({
      dsn: urlConfig,
      release: ENV_VARS.GIT_HASH,
      environment: ENV_VARS.ENV
    });
    provider.isInit = true;
  };

  provider.$get = _raven_logger.default;
}
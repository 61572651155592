"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

require("core-js/modules/es.function.name");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = void 0;

var _angular = _interopRequireDefault(require("angular"));

var modules = [];

_angular.default.module('core.resources.constants', modules).constant('RESOURCES_VARS', {
  API_METHODS: {
    GET: 'GET',
    PUT: 'PUT',
    POST: 'POST',
    DELETE: 'DELETE',
    PATCH: 'PATCH'
  },
  MODELS_PATHS: {
    LIBRARY: 'library',
    POS_DEVICE: 'pos_devices',
    UPLOAD_FONTS: 'style_upload/font',
    UPLOAD_IMAGES: 'style_upload/image'
  }
});

var _default = _angular.default.module('core.resources.constants').name;

exports.default = _default;
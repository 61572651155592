export default function ($stateParams, statePromise, printService) {
    'ngInject';

    const vm = this;
    vm.printer = {
        driverType: 0,
    };
    vm.isManual = true;

    vm.tickets = printService.tickets;
    vm.isEdit = $stateParams.edit;
    if ($stateParams.printer) {
        vm.printer = $stateParams.printer;
        vm.isManual = false;
    }

    vm.driverTypes = [
        {
            name: 'EPSON',
            value: 0,
        },
        {
            name: 'STAR',
            value: 1,
        },
    ];

    vm.validate = function () {
        if (vm.isManual) {
            vm.printer.type = 'PRINTER';
            vm.printer.mac = `CUSTOM-${guid()}`;
            vm.printer.target = `TCP:${vm.printer.ip}`;
        } else if (!vm.printer.mac) {
            vm.printer.mac = `CUSTOM-${guid()}`;
        }
        statePromise.resolve(vm.printer, 'addPrinter');
    };

    function guid() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
    }
}

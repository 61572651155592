export const OrderDeliveryStatus = {
    DELIVERY_SCHEDULED: -1,
    DELIVERY_QUOTE_OPEN: 0,
    DELIVERY_QUOTE_EXPIRED: 1,
    DELIVERY_CANCELLED: 2,
    DELIVERY_SEARCH: 3,
    DELIVERY_PICKING: 4,
    DELIVERY_DELIVERING: 5,
    DELIVERY_DELIVERED: 6,
};

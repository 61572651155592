import availabilityResourceModule from '@innovorder/angularcore/core/resources/availability/availability.module';
import stepResourceModule from '@innovorder/angularcore/core/resources/step/step.module';
import angular from 'angular';
import stepController from './step.controller';
import stepComponent from './step.component';

const dependencies = [availabilityResourceModule, stepResourceModule];

angular
    .module('app.home.availability.step', dependencies)
    .controller('StepController', stepController)
    .component('step', stepComponent);
export default angular.module('app.home.availability.step').name;

// eslint-disable-next-line import/no-extraneous-dependencies
import download from 'downloadjs';
// eslint-disable-next-line import/no-extraneous-dependencies
import range from 'lodash/range';

const ALL_AREAS_ID = -1;
const TRANSLATION_PREFIX = 'PARAMETERS_RECAP_WEB';
const translationsToQuery = [
    `${TRANSLATION_PREFIX}.ALL_AREAS`,
    `${TRANSLATION_PREFIX}.PRODUCTION_REPORT`,
    `${TRANSLATION_PREFIX}.SITIN_REPORT`,
    `${TRANSLATION_PREFIX}.CLICK_AND_COLLECT_REPORT`,
    `${TRANSLATION_PREFIX}.DELIVERY_REPORT`,
];
export default function (orderService, printService, Recap, moment, $translate) {
    'ngInject';

    const vm = this;
    vm.$onInit = () => {
        $translate(translationsToQuery).then((translate) => {
            vm.deliveryAreas = [
                { name: translate[`${TRANSLATION_PREFIX}.ALL_AREAS`], areaId: ALL_AREAS_ID },
                ...(vm.$resolve.deliveryAreas || []),
            ];

            vm.exports = [
                {
                    name: translate[`${TRANSLATION_PREFIX}.PRODUCTION_REPORT`],
                    value: 'production',
                },
                {
                    name: translate[`${TRANSLATION_PREFIX}.CLICK_AND_COLLECT_REPORT`],
                    value: 'clickAndCollect',
                },
                {
                    name: translate[`${TRANSLATION_PREFIX}.SITIN_REPORT`],
                    value: 'sitIn',
                },
                {
                    name: translate[`${TRANSLATION_PREFIX}.DELIVERY_REPORT`],
                    value: 'delivery',
                },
            ];
        });
        vm.minDate = new Date(2017, 7);
        vm.allDay = false;
        vm.date = null;
        vm.startDateHours = 0;
        vm.startDateMinutes = 1;
        vm.endDateHours = 23;
        vm.endDateMinutes = 59;
        vm.deliveryAreas = [];
        vm.exports = [];
        vm.timeSlots = range(24);
    };
    function convertToMomentObject(originalDate, hours, minutes) {
        const date = new Date(originalDate.getTime());
        date.setHours(hours);
        date.setMinutes(minutes);
        return moment(date).format();
    }
    vm.isAreasSelectorVisible = () => {
        return vm.selectedExport === 'delivery';
    };

    vm.getExport = function () {
        if (!vm.date || !vm.selectedExport) {
            vm.showErrorMessage = true;
            return;
        }
        vm.showErrorMessage = false;
        const startDate = convertToMomentObject(
            vm.date,
            vm.allDay ? 0 : vm.startDateHours,
            vm.allDay ? 1 : vm.startDateMinutes,
        );
        const endDate = convertToMomentObject(
            vm.date,
            vm.allDay ? 23 : vm.endDateHours,
            vm.allDay ? 59 : vm.endDateMinutes,
        );
        Recap.getWebRecap({
            startDate,
            endDate,
            exportType: vm.selectedExport,
            deliveryAreaID: vm.selectedDeliveryArea,
        }).$promise.then(function (data) {
            vm.pdfReport = data.response;
            vm.pdfReportName = `rapport.pdf`;
            vm.status = 'INIT';
            download(vm.pdfReport, vm.pdfReportName, 'application/pdf');
        });
    };
}

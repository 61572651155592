/**
 *  Modal directive
 *  ---------------------------------------------------
 */
export default function($templateCache, $timeout, $window) {
  'ngInject';

  function controller($element, $backDrop, $scope) {
    'ngInject';
    var vm = this;
    var backdrop = $backDrop.injectOpaque($scope, vm.close);

    // function killOnEscape(event) {
    //     console.log(event);
    //
    // }

    $scope.$on('$destroy', function() {
      $backDrop.destroy(backdrop);
      angular.element(document).off('keyup');
    });

    angular.element(document).on('keyup', function(event) {
      if (event.which === 27) {
        vm.close();
      }
    });

    // $timeout(function() {
    //     if ($element[0].offsetHeight > Math.max(document.documentElement.clientHeight, window.innerHeight || 0)) {
    //         angular.element($element).addClass('movable');
    //     }
    // }, 0);

    // angular.element($window).bind('resize', function(){
    //     if ($element[0].offsetHeight > Math.max(document.documentElement.clientHeight, window.innerHeight || 0)) {
    //         angular.element($element).addClass('movable');
    //     }
    //     else {
    //         angular.element($element).removeClass('movable');
    //     }
    // });

    angular.element($element).attr('layout', 'column');
  }

  return {
    restrict: 'E',
    controller: controller,
    bindToController: {
      close: '=',
    },
    controllerAs: 'modalVm',
  };
}

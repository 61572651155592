"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

require("core-js/modules/es.function.name");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = void 0;

var _angularUiRouter = _interopRequireDefault(require("angular-ui-router"));

var _state_promise_service = _interopRequireDefault(require("./state_promise_service.js"));

var _angular = _interopRequireDefault(require("angular"));

var _ctUiRouterExtrasCoreMin = _interopRequireDefault(require("ui-router-extras/release/modular/ct-ui-router-extras.core.min.js"));

var _ctUiRouterExtrasDsrMin = _interopRequireDefault(require("ui-router-extras/release/modular/ct-ui-router-extras.dsr.min.js"));

var _ctUiRouterExtrasStickyMin = _interopRequireDefault(require("ui-router-extras/release/modular/ct-ui-router-extras.sticky.min.js"));

var _ctUiRouterExtrasPreviousMin = _interopRequireDefault(require("ui-router-extras/release/modular/ct-ui-router-extras.previous.min.js"));

var _ctUiRouterExtrasTransitionMin = _interopRequireDefault(require("ui-router-extras/release/modular/ct-ui-router-extras.transition.min.js"));

var dependencies = [_angularUiRouter.default, 'ct.ui.router.extras.dsr', 'ct.ui.router.extras.transition', 'ct.ui.router.extras.previous', 'ct.ui.router.extras.sticky'];

_angular.default.module('core.statePromise', dependencies).service('statePromise', _state_promise_service.default);

var _default = _angular.default.module('core.statePromise').name;

exports.default = _default;
import angularUiRouter from 'angular-ui-router';
import angular from 'angular';
import orderComponent from './order.component';
import orderController from './order.controller';

const dependencies = [angularUiRouter];

angular
    .module('app.home.orders_home.order', dependencies)
    .component('order', orderComponent)
    .controller('OrderController', orderController);
export default angular.module('app.home.orders_home.order').name;

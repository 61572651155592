"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

require("core-js/modules/es.function.name");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = void 0;

var _moneyline = _interopRequireDefault(require("./moneyline.service"));

var _moneyline2 = _interopRequireDefault(require("./moneyline.constants"));

var _moneylinev = _interopRequireDefault(require("./../moneylinev1/moneylinev1.module"));

var _moneylinev2 = _interopRequireDefault(require("./../moneylinev2/moneylinev2.module"));

var _angular = _interopRequireDefault(require("angular"));

var dependencies = [_moneylinev.default, _moneylinev2.default];

_angular.default.module('core.moneyline', dependencies).service('moneylineService', _moneyline.default).constant('MONEYLINE', _moneyline2.default);

var _default = _angular.default.module('core.moneyline').name;

exports.default = _default;
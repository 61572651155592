"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.parseJSONData = parseJSONData;
exports.redirectJSONResponse = redirectJSONResponse;

function parseJSONData($log) {
  return function (response) {
    try {
      var body = angular.fromJson(response);

      if (body && body.data) {
        return body.data;
      }

      return body;
    } catch (e) {
      $log.error(e);
      return e;
    }
  };
}

function redirectJSONResponse($log) {
  return function (response) {
    try {
      var _angular$fromJson = angular.fromJson(response),
          redirectUrl = _angular$fromJson.redirectUrl;

      if (typeof redirectUrl !== 'string') {
        throw new Error('Invalid redirect URL');
      }

      window.location.href = redirectUrl;
    } catch (e) {
      $log.error(e);
      return e;
    }
  };
}
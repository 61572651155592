export default function ($stateParams, order, Stuart, ENV_VARS, statePromise) {
    'ngInject';

    const vm = this;

    vm.stuartPhoneNumber = ENV_VARS.STUART_CONTACT;
    vm.loading = false;
    vm.error = null;

    if ($stateParams.error && $stateParams.error.data && $stateParams.error.data.message) {
        vm.error = $stateParams.error.data.message;
    }

    vm.close = function () {
        statePromise.reject($stateParams.statePromiseName);
    };
}

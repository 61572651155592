"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = _ngInjectExport;

var _reduce = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/reduce"));

var _keys = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/keys"));

var _slice = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/slice"));

var _typeof2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/typeof"));

var _localStorageConverter = require("../localStorageConverter");

var _firebase = _interopRequireDefault(require("firebase"));

var _uuid = _interopRequireDefault(require("uuid"));

_ngInjectExport.$inject = ["moment", "ENV_VARS", "$window", "$injector", "localStorageService"];

function _ngInjectExport(moment, ENV_VARS, $window, $injector, localStorageService) {
  'ngInject';

  var service = this; //TODO : When all reception are >= 2.0.3 replace by localStorageService.cookie.get()

  var logUniqueId = (0, _localStorageConverter.get)(localStorageService, 'logUniqueId');
  var appInitializedForTheFirstTime = false;

  if (!logUniqueId) {
    logUniqueId = _uuid.default.v4();
    localStorageService.cookie.set('logUniqueId', logUniqueId);
    appInitializedForTheFirstTime = true;
  }

  var ref;
  var $q;
  var authService;
  var Log;

  service.init = function (brandId, channelId) {
    var logTarget = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'firebase';
    var uniqueDeviceId = arguments.length > 3 ? arguments[3] : undefined;

    if (logTarget == 'firebase') {
      ref = ENV_VARS.FB_DB_USER ? _firebase.default.database().ref(ENV_VARS.FB_DB_USER).child('public_logs').child(brandId).child(channelId).child(moment().format('YYYY/MM/DD')).child(logUniqueId) : _firebase.default.database().ref().child('public_logs').child(brandId).child(channelId).child(moment().format('YYYY/MM/DD')).child(logUniqueId);

      if (!authService) {
        try {
          authService = $injector.get('authService');
        } catch (e) {}
      }

      service.send = function (log) {
        if (!$q) {
          $q = $injector.get('$q');
        }

        var deferred = $q.defer();

        if (!ref) {
          deferred.reject('Firebase service not init');
        } else {
          ref.push(log).then(deferred.resolve).catch(deferred.reject);
        }

        return deferred.promise;
      };
    } else {
      if (!Log) {
        Log = $injector.get('Log');
      }

      service.send = function (log) {
        log.channelId = channelId;
        log.brandId = brandId;
        return Log.save(log).$promise;
      };

      service.info('Device initialized', {
        deviceStatus: 'Initialized',
        uniqueDeviceId: uniqueDeviceId,
        appInitializedForTheFirstTime: appInitializedForTheFirstTime,
        lsContent: getLocalStorageContent()
      });
    }
  };

  service.minType = ENV_VARS.LOG_MIN_TYPE || 'info';
  service.maxStack = ENV_VARS.LOG_MAX_STACK || 1;
  service.types = {
    error: 0,
    warn: 1,
    info: 2,
    log: 3
  };

  service.error = function () {
    self.type = 'error';
    log.apply(self, arguments);
  };

  service.warn = function () {
    self.type = 'warn';
    log.apply(self, arguments);
  };

  service.info = function () {
    self.type = 'info';
    log.apply(self, arguments);
  };

  service.log = function () {
    self.type = 'log';
    log.apply(self, arguments);
  };

  service.enabled = false;
  service.logs = [];

  var log = function log() {
    var args = {
      userId: authService && authService.user && authService.user.userId ? authService.user.userId : null
    };
    args.uid = logUniqueId;

    if ((typeof arguments === "undefined" ? "undefined" : (0, _typeof2.default)(arguments)) === 'object') {
      for (var index in arguments) {
        if (arguments.hasOwnProperty(index)) {
          var arg = arguments[index];
          var exception = {};

          if ((0, _typeof2.default)(arg) === 'object' && !args.message) {
            args.message = arg.message;
            args.stack = arg.stack;
          } else {
            if (typeof arg === 'string' && !args.message) {
              args.message = arg;
            } else {
              if (!args.extra) {
                args.extra = arg;
              } else {
                args.data = [];
                args.data.push(arg);
              }
            }
          }
        }
      }
    }

    args.time = moment().format();
    args.type = type;

    if (service.types[type] <= service.types[service.minType]) {
      service.logs.push(args);
    }

    if (service.logs.length >= service.maxStack && service.send) {
      service.pushLog();
    }
  };

  service.pushLog = function () {
    var _context;

    if (!$q) {
      $q = $injector.get('$q');
    }

    var tmpLogs = (0, _slice.default)(_context = service.logs).call(_context);
    service.logs = [];
    var promises = [];

    for (var index in tmpLogs) {
      if (tmpLogs[index]) {
        var logToSend = tmpLogs[index];

        for (var key in logToSend) {
          if (logToSend[key] !== 0 && !logToSend[key]) {
            logToSend[key] = null;
          }
        }

        var dataToPush = {
          uid: logUniqueId,
          log: logToSend,
          time: moment().format('DD/MM/YYYY HH:MM')
        };
        promises.push(service.send(dataToPush));
      }
    }

    $q.all(promises).catch(function (error) {
      service.logs = tmpLogs; //Prevent infinite loop on log error;

      console.log(error);
    });
  };

  function getLocalStorageContent() {
    var lsKeys = (0, _keys.default)(localStorageService).call(localStorageService);
    var lsContent = (0, _reduce.default)(lsKeys).call(lsKeys, function (acc, current) {
      acc[current] = localStorageService.get(current);
      return acc;
    }, {});
    return lsContent;
  }

  $window.onbeforeunload = service.pushLog;
}
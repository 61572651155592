"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

require("core-js/modules/es.number.to-fixed");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = _default;

var _numeral = _interopRequireDefault(require("numeral"));

function _default() {
  'ngInject';

  return function (input, fractionDigits) {
    var shouldOutputNumber = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    input = input || 0;
    var valueToReturn = (0, _numeral.default)(input).divide(Math.pow(10, fractionDigits)).value();
    return shouldOutputNumber ? valueToReturn : valueToReturn.toFixed(fractionDigits);
  };
}
export default function (orderService, RestaurantModule, restaurant) {
    'ngInject';

    const vm = this;

    vm.webChannelActivated = orderService.configReception.webChannelActivated;
    vm.kioskChannelActivated = orderService.configReception.kioskChannelActivated;
    vm.save = save;

    function save() {
        RestaurantModule.post({
            restaurantId: restaurant.restaurantId,
            moduleId: 12,
            channelId: 4,
            module: {
                webChannelActivated: vm.webChannelActivated,
                kioskChannelActivated: vm.kioskChannelActivated,
            },
        })
            .$promise.then(function (data) {
                vm.webChannelActivated = data.webChannelActivated;
                vm.kioskChannelActivated = data.kioskChannelActivated;
                orderService.configReception = data;
            })
            // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars, no-empty-function
            .catch(function (error) {});
    }
}

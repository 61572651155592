import filterView from './filter.view.html';
export default function($templateCache, $backDrop) {
  'ngInject';

  function link(scope, element, attrs, ngModel, $backdrop) {
    scope.choicesOpen = false;
    var backdrop;

    // make the model accessible in the view
    scope.ngModel = ngModel;

    /* select first element in array at first loading if model undefined */
    if (scope.model === undefined) {
      ngModel.$setViewValue(Object.keys(scope.values)[0]);
    }

    /*  */
    if (!(attrs.actionMessage === undefined)) {
      scope.displayAction = true;
      scope.actionMessage = attrs.actionMessage;
    }

    scope.selectValue = function(value) {
      ngModel.$setViewValue(value);
      scope.choicesOpen = false;

      $backDrop.destroy(backdrop);
    };

    scope.toggleChoices = function() {
      scope.centerChoices(element);
      scope.choicesOpen = !scope.choicesOpen;

      backdrop = $backDrop.inject(
        scope,
        function() {
          scope.choicesOpen = !scope.choicesOpen;
        },
        element,
      );
    };

    scope.centerChoices = function(element) {
      //scope.leftOffset = (outerWidth(element[0]) - outerWidth(angular.element(element[0].querySelector('.dropdown'))[0]) / 2) - element.css('marginLeft').replace('px', '');
      scope.leftOffset = 0;
    };

    var actionHandler = scope.actionFunction();

    scope.callAction = function() {
      actionHandler();
    };
  }

  return {
    link: link,
    restrict: 'E',
    transclude: true,
    require: 'ngModel',
    scope: {
      values: '=',
      icon: '@',
      actionFunction: '&action',
      model: '=ngModel',
    },
    controllerAs: 'filterVm',
    template: $templateCache.get(filterView),
  };
}

import statePromiseModule from '@innovorder/angularcore/core/state_promise/state_promise.module';
import orderCartModule from '@innovorder/angularcore/core/order_cart/order_cart.module';
import filterBigDecimalModule from '@innovorder/angularcore/core/filter_big_decimal/filter_big_decimal.module';
import angular from 'angular';
import modalRefundModule from './modal_refund/modal_refund.module';
import detailRoutes from './detail.routes';
import detailController from './detail.controller';

const dependencies = [statePromiseModule, modalRefundModule, orderCartModule, filterBigDecimalModule];

angular
    .module('app.home.orders_home.detail', dependencies)
    .config(detailRoutes)
    .controller('DetailController', detailController);
export default angular.module('app.home.orders_home.detail').name;

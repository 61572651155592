import tinyActionButtonView from './tiny_action_button.view.html';
/**
 *  tiny Action Button directive
 *  ---------------------------------------------------
 */
export default function($templateCache) {
  'ngInject';

  function link(scope, element, attrs) {}

  return {
    link: link,
    restrict: 'E',
    scope: {
      color: '@',
      icon: '@',
      tooltip: '@',
    },
    template: $templateCache.get(tinyActionButtonView),
  };
}

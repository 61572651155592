import accordionView from './accordion.view.html';
import accordionController from './accordion.controller';

angular
  .module('app.accordion', [])
  .controller('AccordionController', accordionController)
  .directive('accordion', function() {
    return {
      restrict: 'E',
      transclude: true,
      controller: 'AccordionController',
      controllerAs: 'accordionVm',
      scope: {
        title: '@',
        backgroundColor: '@',
        subtitle: '@',
        openOnInit: '<',
      },
      templateUrl: accordionView,
    };
  });

export default angular.module('app.accordion').name;

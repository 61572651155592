"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

require("core-js/modules/es.function.name");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = void 0;

var _angularUuid = _interopRequireDefault(require("angular-uuid"));

var _firebase_initializer = require("../utils/firebase_initializer");

var _availability_logger = _interopRequireDefault(require("./availability_logger.service"));

var _angular = _interopRequireDefault(require("angular"));

var dependencies = ['angular-uuid'];

_angular.default.module('core.availability_logger', dependencies).service('availabilityLoggerService', _availability_logger.default).run(['ENV_VARS', _firebase_initializer.initializeFirebaseIfNotAlreadyDone]);

var _default = _angular.default.module('core.availability_logger').name;

exports.default = _default;
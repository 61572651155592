import activatorView from './activator.view.html';
export default function() {
  'ngInject';

  return {
    restrict: 'E',
    link: function($scope, $element, $attrs) {
      $scope.create = $attrs.create;
      $scope.edit = $attrs.edit;
      $scope.delete = $attrs.delete;
    },
    controller: function($scope, $filter) {
      'ngInject';
      var vm = this;
      vm.toggle = function() {
        if (vm.target && $scope.edit) {
          $scope.$eval($scope.edit);
        } else if (!vm.target && $scope.create) {
          $scope.$eval($scope.create);
        }
      };

      vm.disable = function() {
        if (vm.target && $scope.delete) {
          $scope.$eval($scope.delete);
        }
      };
      vm.text = {
        add: $filter('translate')('ACTIVATOR.ACTIVATE'),
        delete: $filter('translate')('ACTIVATOR.DEACTIVATE'),
        edit: $filter('translate')('ACTIVATOR.EDIT'),
      };
      if (vm.actionText) {
        if (vm.actionText.add) {
          vm.text.add = vm.actionText.add;
        }
        if (vm.actionText.delete) {
          vm.text.delete = vm.actionText.delete;
        }
        if (vm.actionText.edit) {
          vm.text.edit = vm.actionText.edit;
        }
      }
    },
    controllerAs: 'activatorVm',
    bindToController: {
      title: '@',
      icon: '@',
      target: '<',
      create: '@',
      edit: '@',
      delete: '@',
      actionText: '<',
    },
    scope: true,
    templateUrl: activatorView,
  };
}

import selectMultipleView from './select_multiple.view.html';

export default function($templateCache, $timeout, $parse, $backDrop) {
  'ngInject';

  function link(scope, element, attrs, ngModel) {
    scope.placeholder = attrs.placeholder;

    scope.options = {};
    scope.isOpen = false;
    scope.backdrop;

    scope.toggle = function() {
      if (
        scope.ngModel.$viewValue != undefined &&
        scope.ngModel.$viewValue.length == Object.keys(scope.options).length
      ) {
        return;
      }

      scope.isOpen = !scope.isOpen;

      $timeout(function() {
        scope.computeOffset();
      }, 0);

      if (scope.isOpen) {
        scope.backdrop = $backDrop.inject(
          scope,
          function() {
            scope.$apply(function() {
              scope.isOpen = false;
            });
          },
          element,
        );
      }
    };

    scope.empty = function() {
      angular.forEach(scope.options, function(value, key) {
        value.isSelected = false;
      });
      scope.ngModel.$setViewValue(undefined);
    };

    scope.$watch(
      function() {
        return scope.searchText;
      },
      function(value) {
        angular.forEach(scope.options, function(value, key) {
          value.toDisplay =
            value.name.toLowerCase().indexOf(scope.searchText.toLowerCase()) !==
            -1;
        });
      },
    );

    scope.ngModel = ngModel;
  }

  function controller($scope, $element, $attrs) {
    'ngInject';

    var self = this;
    self.options = $scope.options;
    $scope.fixed = false;

    this.addOption = function(name, value) {
      $scope.options[value] = {
        name: name,
        isSelected:
          $scope.ngModel.$viewValue &&
          $scope.ngModel.$viewValue.indexOf(value) > -1,
        toDisplay: true,
      };
      self.options = $scope.options;

      return (
        $scope.ngModel.$viewValue &&
        $scope.ngModel.$viewValue.indexOf(value) > -1
      );
    };

    this.selectElement = function(value) {
      if ($scope.ngModel.$viewValue == undefined) {
        $scope.ngModel.$setViewValue(Array());
      } else {
        // if already in model, gtfo
        if ($scope.ngModel.$viewValue.indexOf(value) > -1) {
          return;
        }
      }

      var temp = $scope.ngModel.$viewValue;
      temp.push(value);

      $timeout(function() {
        $scope.ngModel.$setViewValue(temp.slice());
      });

      $scope.options[value].isSelected = true;
      $scope.isOpen = false; // on ferme

      $backDrop.destroy($scope.backdrop);

      $scope.searchText = '';

      self.options = $scope.options;
    };

    this.unselectElement = function(value) {
      $scope.$eval($attrs.ngChange);

      if ($scope.ngModel.$viewValue != undefined) {
        var temp = $scope.ngModel.$viewValue;
        temp.splice(temp.indexOf(value), 1);

        $timeout(function() {
          if ($scope.ngModel.$viewValue.length == 0) {
            $scope.ngModel.$setViewValue(undefined);
          } else {
            $scope.ngModel.$setViewValue(temp.slice());
          }
        });
      }

      $scope.options[value].isSelected = false;
      //value.isSelected = false;
      self.options = $scope.options;
    };

    $scope.remove = this.unselectElement;

    $scope.$watch(
      function() {
        return $scope.ngModel.$viewValue;
      },
      function(value) {
        angular.forEach($scope.options, function(value, key) {
          $scope.options[key].isSelected =
            $scope.ngModel.$viewValue &&
            $scope.ngModel.$viewValue.indexOf(key) > -1;
        });
      },
    );

    $scope.computeOffset = function() {
      $scope.fixed = false;
      var optList = $element[0].querySelector('input');
      var viewportHeight = Math.max(
        document.documentElement.clientHeight,
        window.innerHeight || 0,
      );
      var optListHeight =
        viewportHeight - (optList.getBoundingClientRect().top + 40);
      $scope.fixed =
        optListHeight < $element[0].querySelector('.options').clientHeight
          ? true
          : false;
    };
  }

  return {
    link: link,
    controller: controller,
    require: 'ngModel',
    restrict: 'E',
    transclude: true,
    scope: {},
    templateUrl: selectMultipleView,
  };
}

/**
 *  Highlight filter
 *  apply span with specific class on matching part of result
 *  ---------------------------------------------------
 */

export default function($sce) {
  'ngInject';
  return function(text, phrase) {
    if (phrase)
      text = text.replace(
        new RegExp('(' + phrase + ')', 'gi'),
        '<span class="hl">$1</span>',
      );

    return $sce.trustAsHtml(text);
  };
}

import cancelView from 'app/components/stuart/infos/cancel/cancel.view.html';

export default function ($stateProvider, $urlRouterProvider, $locationProvider) {
    'ngInject';

    $locationProvider.html5Mode(true);

    $stateProvider.state('home.stuart.infos.cancel', states.quote());
}

// eslint-disable-next-line vars-on-top, no-var
var states = {
    quote() {
        return {
            views: {
                'modal@': {
                    templateUrl: cancelView,
                    controller: 'StuartInfosCancelController',
                    controllerAs: 'stuartInfosCancelVm',
                },
            },
        };
    },
};

import sidenavLinkView from './sidenav_link.view.html';
export default function($templateCache) {
  'ngInject';

  var parentController;

  function link(scope, element, attrs, fioDashboardSidenavCtrl) {
    scope.icon = attrs.icon;
    scope.subMenu = attrs['subMenu'] != undefined ? true : false;
    parentController = fioDashboardSidenavCtrl;
  }

  function controller($scope, $element, $attrs) {
    'ngInject';

    var displayed;

    angular.element($element).on('click', function(e) {
      setTimeout(function() {
        displayed = $element[0].className.indexOf('display-sub') > -1;
        parentController.hideSubs();

        if (!displayed && $scope.subMenu) {
          parentController.toggleSub();
          angular.element($element).toggleClass('display-sub');
        }
      }, 0);
    });
  }

  return {
    link: link,
    controller: controller,
    restrict: 'E',
    require: '^fioDashboardSidenav',
    transclude: true,
    scope: true,
    template: $templateCache.get(sidenavLinkView),
  };
}

import imagePreviewView from './image_preview.view.html';

export default function($templateCache) {
  'ngInject';

  return {
    restrict: 'E',
    template: $templateCache.get(imagePreviewView),
    scope: {
      src: '=',
    },
  };
}

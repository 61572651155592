export default function() {
  'ngInject';

  return {
    restrict: 'A',
    require: 'ngModel',
    link: function(scope, element, attrs, ngModelController) {
      ngModelController.$parsers.push(function(data) {
        return ('' + data).replace(',', '.');
      });
    },
  };
}

import buttonView from './button.view.html';
export default function($templateCache) {
  'ngInject';

  function link(scope, element, attrs) {
    scope.isEnabled = false;
    scope.isDone = false;
    var falseColor = scope.color;

    /* enable state */
    scope.enable = function(bool) {
      scope.isEnabled = bool;
      if (bool == true) {
        angular.element(element[0]).removeClass('disabled');
      } else {
        angular.element(element[0]).addClass('disabled');
      }
    };

    scope.$watch(
      function() {
        return scope.disabled;
      },
      function(value) {
        scope.enable(value);
      },
    );
  }

  return {
    link: link,
    restrict: 'E',
    transclude: true,
    replace: true,
    scope: {
      disabled: '=',
      color: '@',
    },
    template: $templateCache.get(buttonView),
  };
}

export default function (statePromise, $stateParams) {
    'ngInject';

    const vm = this;

    vm.skuWarningData = $stateParams.skuWarningData;
    vm.shouldUseUberEatsAvailability = $stateParams.shouldUseUberEatsAvailability;
    vm.shouldUseDeliverooAvailability = $stateParams.shouldUseDeliverooAvailability;

    vm.marketplacesTabs = [];
    vm.selectedMarketplaceTab = null;
    vm.allWarnings = [];

    const setDisplayedWarnings = function (marketplaceTab) {
        const selectedMarketplaceWarnings = vm.skuWarningData[marketplaceTab.errorsAttribute];
        vm.allWarnings = [...selectedMarketplaceWarnings.noSku, ...selectedMarketplaceWarnings.noMatchingSku];
    };

    vm.$onInit = function () {
        const totalUbereatsErrors =
            vm.skuWarningData.invalidUbereatsProducts?.noSku.length +
            vm.skuWarningData.invalidUbereatsProducts?.noMatchingSku.length;
        const totalDeliverooErrors =
            vm.skuWarningData?.invalidDeliverooProducts?.noSku.length +
            vm.skuWarningData?.invalidDeliverooProducts?.noMatchingSku.length;
        if (vm.shouldUseUberEatsAvailability && totalUbereatsErrors > 0) {
            this.marketplacesTabs.push({ id: 9, name: 'Ubereats', errorsAttribute: 'invalidUbereatsProducts' });
        }
        if (vm.shouldUseDeliverooAvailability && totalDeliverooErrors > 0) {
            this.marketplacesTabs.push({ id: 10, name: 'Deliveroo', errorsAttribute: 'invalidDeliverooProducts' });
        }

        const [initialMarketplace] = vm.marketplacesTabs;
        vm.selectedMarketplaceTab = initialMarketplace;
        setDisplayedWarnings(vm.selectedMarketplaceTab);
    };

    vm.onTabChange = function (marketplaceTab) {
        vm.selectedMarketplaceTab = marketplaceTab;
        setDisplayedWarnings(marketplaceTab);
    };

    vm.close = function () {
        statePromise.reject('skuWarning');
    };
}

import emptyView from 'app/components/orders_home/empty/empty.view.html';
import detailView from 'app/components/orders_home/detail/detail.view.html';
import notificationView from 'app/components/orders_home/notification/notification.view.html';
import stuartV2View from './stuart_v2/stuart_v2.view.html';

export default function ($stateProvider, $urlRouterProvider, $locationProvider) {
    'ngInject';

    $locationProvider.html5Mode(true);
    $stateProvider
        .state('home.orders_home.placed', states.placed())
        .state('home.orders_home.placed.detail', states.detail())
        .state('home.orders_home.in_progress', states.in_progress())
        .state('home.orders_home.in_progress.detail', states.detail())
        .state('home.orders_home.done', states.done())
        .state('home.orders_home.done.detail', states.detail())
        .state('home.orders_home.notification', states.notification())
        .state('home.orders_home.stuart_v2', states.stuart_v2());
}

// eslint-disable-next-line vars-on-top, no-var
var states = {
    placed() {
        return {
            url: '/placed',
            views: {
                detail: {
                    templateUrl: emptyView,
                    controller: 'EmptyController',
                    controllerAs: 'emptyVm',
                },
            },
            resolve: {
                displayedOrders: [
                    'orders',
                    'ENV_VARS',
                    function (orders, ENV_VARS) {
                        return orders[ENV_VARS.ORDERS_SERVICES.VALIDATED.NAME];
                    },
                ],
                importantOrders: [
                    'orders',
                    'ENV_VARS',
                    function (orders, ENV_VARS) {
                        return orders[ENV_VARS.ORDERS_SERVICES.PLACED.NAME];
                    },
                ],
            },
        };
    },
    in_progress() {
        return {
            url: '/in_progress',
            views: {
                detail: {
                    templateUrl: emptyView,
                    controller: 'EmptyController',
                    controllerAs: 'emptyVm',
                },
            },
            resolve: {
                displayedOrders: [
                    'orders',
                    'ENV_VARS',
                    function (orders, ENV_VARS) {
                        return orders[ENV_VARS.ORDERS_SERVICES.IN_PROGRESS.NAME];
                    },
                ],
                importantOrders: [
                    function () {
                        return null;
                    },
                ],
            },
        };
    },
    done() {
        return {
            url: '/done',
            views: {
                detail: {
                    templateUrl: emptyView,
                    controller: 'EmptyController',
                    controllerAs: 'emptyVm',
                },
            },
            resolve: {
                displayedOrders: [
                    'orders',
                    'ENV_VARS',
                    function (orders, ENV_VARS) {
                        return orders[ENV_VARS.ORDERS_SERVICES.DONE.NAME];
                    },
                ],
                importantOrders: [
                    function () {
                        return null;
                    },
                ],
            },
        };
    },
    detail() {
        return {
            params: {
                order: null,
            },
            views: {
                'detail@home.orders_home': {
                    templateUrl: detailView,
                    controller: 'DetailController',
                    controllerAs: 'detailVm',
                },
            },
            resolve: {
                orderDetail: [
                    '$stateParams',
                    'orderService',
                    '$q',
                    function ($stateParams, orderService) {
                        return new orderService.Detail($stateParams.order);
                    },
                ],
            },
        };
    },
    notification() {
        return {
            params: {
                message: null,
            },
            views: {
                'modal@': {
                    templateUrl: notificationView,
                    controller: 'NotificationController',
                    controllerAs: 'notificationVm',
                },
            },
        };
    },
    stuart_v2() {
        return {
            views: {
                'stuart_v2@': {
                    templateUrl: stuartV2View,
                    controller: 'StuartV2Controller',
                    controllerAs: 'stuartV2Vm',
                },
            },
        };
    },
};

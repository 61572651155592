import { parsePhoneNumberFromString } from 'libphonenumber-js';

export default function() {
  'ngInject';

  return {
    require: 'ngModel',
    restrict: 'A',
    link: function(scope, element, attributes, ngModel) {
      ngModel.$validators.phone = function(modelValue, viewValue) {
        if (!viewValue) {
          return true;
        }
        const number = parsePhoneNumberFromString(viewValue, 'FR');
        return !!number && number.isValid();
      };
    },
  };
}

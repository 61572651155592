"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = _default;

var _locale_interceptor = _interopRequireDefault(require("./locale_interceptor.service"));

function _default() {
  'ngInject';

  var provider = this;
  provider.$get = _locale_interceptor.default;
  return provider;
}
import angularAnimate from 'angular-animate';
import angularUiGrid from 'angular-ui-grid';
import angularMessages from 'angular-messages';
import angular from 'angular';
import datepickerDirective from './directives/dashboard/datepicker/datepicker.directive';
import helperFactory from './factories/helper.factory';
import loadingScreenFactory from './factories/loading_screen.factory';
import backdropFactory from './factories/backdrop.factory';
import buttonDirective from './directives/dashboard/button/button.directive';
import checkboxDirective from './directives/dashboard/checkbox/checkbox.directive';
import dataExcerptDirective from './directives/dashboard/data_excerpt/data_excerpt.directive';
import dataInsightDirective from './directives/dashboard/data_insight/data_insight.directive';
import filterDirective from './directives/dashboard/filter/filter.directive';
import loadingScreenDirective from './directives/dashboard/loading_screen/loading_screen.directive';
import logoPickerDirective from './directives/dashboard/logo_picker/logo_picker.directive';
import switchDirective from './directives/dashboard/switch/switch.directive';
import tinyActionButtonDirective from './directives/dashboard/tiny_action_button/tiny_action_button.directive';
import fioDatepickerDirective from './directives/dashboard/datepicker/fio_datepicker.directive';
import formMessagesDirective from './directives/dashboard/form/form_messages.directive';
import buttonSubmitDirective from './directives/dashboard/form/button_submit.directive';
import inputComponent from './directives/dashboard/input/input.component';
import inputMessageComponent from './directives/dashboard/input/input_message.component';
import inputCompareDirective from './directives/dashboard/input/validators/input_compare.directive';
import inputPatternDirective from './directives/dashboard/input/validators/input_pattern.directive';
import inputMaxDirective from './directives/dashboard/input/validators/input_max.directive';
import inputMinDirective from './directives/dashboard/input/validators/input_min.directive';
import inputMaxLengthDirective from './directives/dashboard/input/validators/input_max_length.directive';
import inputMinLengthDirective from './directives/dashboard/input/validators/input_min_length.directive';
import inputValidPhoneDirective from './directives/dashboard/input/validators/input_validate_phone.directive';
import commaFormatterDirective from './directives/dashboard/input/comma_formatter.directive';
import colorPickerDirective from './directives/dashboard/color_picker/color_picker.directive';
import fioColorPickerDirective from './directives/dashboard/color_picker/fio_color_picker.directive';
import modalDirective from './directives/dashboard/modal/modal.directive';
import fioModalHeaderDirective from './directives/dashboard/modal/fio_modal_header.directive';
import modalStepsDirective from './directives/dashboard/modal/modal_steps.directive';
import modalStepDirective from './directives/dashboard/modal/modal_step.directive';
import routerTabsDirective from './directives/dashboard/router_tabs/router_tabs.directive';
import fioTabLinkDirective from './directives/dashboard/router_tabs/fio_tab_link.directive';
import searchBarDirective from './directives/dashboard/search_bar/search_bar.directive';
import highlightFilter from './directives/dashboard/search_bar/highlight.filter';
import selectDirective from './directives/dashboard/select/select.directive';
import optionDirective from './directives/dashboard/select/option.directive';
import selectMultipleDirective from './directives/dashboard/select_multiple/select_multiple.directive';
import optionMultipleDirective from './directives/dashboard/select_multiple/option_multiple.directive';
import sidenavDirective from './directives/dashboard/sidenav/sidenav.directive';
import sideItemDirective from './directives/dashboard/sidenav/side_item.directive';
import popOverDirective from './directives/dashboard/sidenav/pop_over.directive';
import oldTableDirective from './directives/dashboard/table/fio_table.directive';
import newTableDirective from './directives/dashboard/table/table.directive';
import tableHeadDirective from './directives/dashboard/table/table_head.directive';
import sortableObjectDirective from './directives/dashboard/table/sortable_object.directive';
import sortableDirective from './directives/dashboard/table/sortable.directive';
import sortByDirective from './directives/dashboard/table/sort_by.directive';
import tablePaginationDirective from './directives/dashboard/table/table_pagination.directive';
import paginationFilter from './directives/dashboard/table/pagination.filter';
import tabsDirective from './directives/dashboard/tabs/tabs.directive';
import tabDirective from './directives/dashboard/tabs/tab.directive';
import imagePreviewDirective from './directives/dashboard/image_preview/image_preview.directive';
import orderDirective from './directives/reception/order/order.directive';
import activatorDirective from './directives/dashboard/activator/activator.directive';
import selectableList from './directives/dashboard/selectable_list/selectable_list.directive';
import selectableOption from './directives/dashboard/selectable_list/selectable_option.directive';
import mapboxAutoComplete from './directives/dashboard/mapbox_auto_complete/mapbox_auto_complete.directive';

import uiGridCell from './directives/dashboard/table/hovering/ui_grid_cell.directive';
import uiGridHovering from './directives/dashboard/table/hovering/ui_grid_hovering.directive';
import uiGridViewport from './directives/dashboard/table/hovering/ui_grid_viewport.directive';
import uiGridAutoFitColumns from './directives/dashboard/table/autoFitColumns/autoFitColumns.directive';
import uiGridAutoFitColumnsService from './directives/dashboard/table/autoFitColumns/autoFitColumns.service';

// Import components
import accordionModule from '../components/accordion/accordion.module';

// import variables from './../core/modules/_variables.scss'
// import mixins from './../core/modules/mixins.scss'
// import layout from './../core/partials/layout.scss'

// import etc from './../core/helpers/etc.scss'
// import grid from './../core/helpers/grid.scss'
// import navigation from './../core/helpers/navigation.scss'
// import typograghy from './../core/helpers/typograghy.scss'

// import animations from './../core/partials/animations.scss'
// import backdrop from './../core/partials/backdrop.scss'
// import content from './../core/partials/content.scss'
// import form from './../core/partials/form.scss'
// import groundzero from './../core/partials/groundzero.scss'
// import modal from './../core/partials/modal.scss'
// import sidenav from './../core/partials/sidenav.scss'

const dependencies = [
  angularAnimate,
  angularMessages,
  datepickerDirective,
  angularUiGrid,
  accordionModule,
  'ui.grid.selection',
  'ui.grid.cellNav',
  'ui.grid.pagination',
  'ui.grid.pinning',
  'ui.grid.autoResize',
  'pascalprecht.translate',
];

angular
  .module('frontkit-io', dependencies)

  /** ***********
   * FACTORIES *
   ************ */
  .factory('$fioHelper', helperFactory)
  .factory('$loadingScreen', loadingScreenFactory)
  .factory('$backDrop', backdropFactory)

  /** ***********
   * DIRECTIVES *
   ************ */
  .directive('fioButton', buttonDirective)
  .directive('fioCheckbox', checkboxDirective)
  .directive('fioDataExcerpt', dataExcerptDirective)
  .directive('fioDataInsight', dataInsightDirective)
  .directive('fioFilter', filterDirective)
  .directive('fioLoadingScreen', loadingScreenDirective)
  .directive('fioLogoPicker', logoPickerDirective)
  .directive('fioSwitch', switchDirective)
  .directive('fioTinyAction', tinyActionButtonDirective)
  .directive('fioDatepicker', fioDatepickerDirective)

  // input directives
  .directive('formMessages', formMessagesDirective)
  .directive('fioButtonSubmit', buttonSubmitDirective)
  .component('fioInput', inputComponent)
  .component('inputMessage', inputMessageComponent)
  .directive('compareTo', inputCompareDirective)
  .directive('fioPattern', inputPatternDirective)
  .directive('fioMax', inputMaxDirective)
  .directive('fioMin', inputMinDirective)
  .directive('fioMaxlength', inputMaxLengthDirective)
  .directive('fioMinlength', inputMinLengthDirective)
  .directive('fioValidPhone', inputValidPhoneDirective)
  .directive('commaFormatter', commaFormatterDirective)
  .directive('mapboxAutoComplete', mapboxAutoComplete)

  // colorPicker
  .directive('colorPicker', colorPickerDirective)
  .directive('fioColorPicker', fioColorPickerDirective)

  // Modal
  .directive('fioModal', modalDirective)
  .directive('fioModalHeader', fioModalHeaderDirective)
  .directive('fioModalSteps', modalStepsDirective)
  .directive('fioModalStep', modalStepDirective)

  // Router tabs
  .directive('fioRouterTabs', routerTabsDirective)
  .directive('fioTabLink', fioTabLinkDirective)

  .directive('activator', activatorDirective)

  // Search Bar
  .directive('fioDashboardSearchBar', searchBarDirective)
  .filter('highlight', highlightFilter)

  // Select
  .directive('fioSelect', selectDirective)
  .directive('fioOption', optionDirective)

  .directive('fioSelectMultiple', selectMultipleDirective)
  .directive('fioOptionMultiple', optionMultipleDirective)

  // SideNav
  .directive('fioDashboardSidenav', sidenavDirective)
  .directive('fioSidenavItem', sideItemDirective)
  .directive('fioSidenavPopover', popOverDirective)

  // Table
  .directive('table', newTableDirective)
  .directive('fioTable', oldTableDirective)
  .directive('fioTableHead', tableHeadDirective)
  .directive('object', sortableObjectDirective)
  .directive('fioSortable', sortableDirective)
  .directive('sortBy', sortByDirective)
  .directive('fioTablePagination', tablePaginationDirective)
  .filter('siPagination', paginationFilter)

  .directive('uiGridCell', uiGridCell)
  .directive('uiGridHovering', uiGridHovering)
  .directive('uiGridAutoFitColumns', uiGridAutoFitColumns)
  .directive('uiGridViewport', uiGridViewport)
  .service('uiGridAutoFitColumnsService', uiGridAutoFitColumnsService)

  // Tabs
  .directive('fioTabs', tabsDirective)
  .directive('fioTab', tabDirective)

  // Selectable list
  .directive('fioSelectableList', selectableList)
  .directive('fioSelectableOption', selectableOption)

  // Image previewer
  .directive('fioImagePreview', imagePreviewDirective)

  /** ***********
   * RECEPTION *
   ************ */
  .directive('fioOrder', orderDirective);
export default angular.module('frontkit-io').name;

import selectMultipleView from 'js/directives/dashboard/selectable_list/selectable_list.view.html';

export default function ($templateCache, $backDrop) {
  'ngInject';

  function link(scope, element, attrs, ngModel) {
    scope.ngModel = ngModel;
    scope.placeholder = attrs.placeholder;
    scope.closable = false;

    scope.getInputValue = () => {
      return !scope.labels ? '' : scope.labels.join(', ');
    };

    if (attrs.closable !== undefined) {
      scope.isOpen = false;
      scope.closable = true;

      let backdrop;
      scope.toggle = () => {
        scope.isOpen = !scope.isOpen;

        if (!scope.isOpen) {
          $backDrop.destroy(backdrop);
        } else {
          backdrop = $backDrop.inject(
            scope,
            () => {
              scope.$apply(() => {
                scope.isOpen = !scope.isOpen
              });
            },
            element,
          );
        }
      };
    }
  }

  function controller($scope, $attrs) {
    'ngInject';

    const self = this;
    const isThereASelectAllOption = $attrs.firstOptionIsSelectAllOption;
    const SELECT_ALL_OPTION_ID = $attrs.selectAllOptionId;
    $scope.inputValue = '';
    self.scope = $scope;
    $scope.labels = [];

    this.addLabel = (label) => {

      const currentLabels = Array.isArray($scope.labels) ? $scope.labels : []
      $scope.labels = Array.from(new Set([...currentLabels, label]));
    };

    this.removeLabel = (label) => {
      $scope.labels = $scope.labels.filter((l) => l !== label);
    };

    this.addOption = (value) => {
      const currentSelectedOptions = Array.isArray($scope.ngModel.$viewValue)
        ? $scope.ngModel.$viewValue
        : [];
      $scope.ngModel.$setViewValue([...currentSelectedOptions, value]);

    };

    this.removeOption = (value) => {
      $scope.$eval($attrs.ngChange);
      const newSelectedOptions = $scope.ngModel.$viewValue.filter((id) => {
        const itIsNotAllOption = isThereASelectAllOption
          ? id !== SELECT_ALL_OPTION_ID
          : true;
        return id !== value && itIsNotAllOption;
      });
      $scope.ngModel.$setViewValue(newSelectedOptions);
    };
  }

  return {
    link,
    controller,
    require: 'ngModel',
    restrict: 'E',
    transclude: true,
    scope: {},
    template: $templateCache.get(selectMultipleView),
  };
}

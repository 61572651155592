"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

require("core-js/modules/es.function.name");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = void 0;

var _consumption_modesConstant = _interopRequireDefault(require("./consumption_modes.constant.json"));

var _statusConstant = _interopRequireDefault(require("./status.constant.json"));

var _payment_statusConstant = _interopRequireDefault(require("./payment_status.constant.json"));

var _login_strategy_flagConstant = _interopRequireDefault(require("./login_strategy_flag.constant.json"));

var _channelsConstant = _interopRequireDefault(require("./channels.constant.json"));

var _access_flagsConstant = _interopRequireDefault(require("./access_flags.constant.json"));

var _option_typeConstant = _interopRequireDefault(require("./option_type.constant.json"));

var _payment_method_code = require("./payment_method_code.constant");

var _constant_helperService = _interopRequireDefault(require("./constant_helper.service.js"));

var _angular = _interopRequireDefault(require("angular"));

var dependencies = [];

_angular.default.module('core.constants', dependencies).constant('CONSUMPTION_MODES', _consumption_modesConstant.default).constant('LOGIN_STRATEGY_FLAG', _login_strategy_flagConstant.default).constant('STATUS', _statusConstant.default).constant('PAYMENT_STATUS', _payment_statusConstant.default).constant('CHANNELS', _channelsConstant.default).constant('OPTION_TYPES', _option_typeConstant.default).constant('ACCESS_FLAGS', _access_flagsConstant.default).constant('PAYMENT_METHOD_CODE', _payment_method_code.PAYMENT_METHOD_CODE).service('constantService', _constant_helperService.default);

var _default = _angular.default.module('core.constants').name;

exports.default = _default;
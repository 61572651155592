"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = _ngInjectExport;
_ngInjectExport.$inject = ["$resource", "$log", "RESOURCES_VARS", "ENV_VARS"];

function _ngInjectExport($resource, $log, RESOURCES_VARS, ENV_VARS) {
  'ngInject'; // resource rules
  // =============================================================================

  function parseData(response) {
    try {
      var body = angular.fromJson(response);

      if (body && body.data) {
        return body.data;
      }

      return body;
    } catch (e) {
      $log.error(e);
      return e;
    }
  }

  return $resource(ENV_VARS.API_URL + '/areas/:target/:targetId', {
    target: '@target',
    targetId: '@targetId'
  }, {
    getAllByRestaurantId: {
      isArray: true,
      params: {
        target: 'restaurant'
      },
      method: RESOURCES_VARS.API_METHODS.GET,
      transformResponse: parseData
    },
    getAllAreas: {
      isArray: true,
      params: {
        target: 'brand'
      },
      method: RESOURCES_VARS.API_METHODS.GET,
      transformResponse: parseData
    },
    updateByRestaurantId: {
      isArray: true,
      params: {
        target: 'restaurant'
      },
      method: RESOURCES_VARS.API_METHODS.POST,
      transformResponse: parseData
    },
    addConsumptionMode: {
      method: RESOURCES_VARS.API_METHODS.POST,
      url: ENV_VARS.API_URL + '/areas/:areaId/consumption_modes/:consumptionModeId',
      params: {
        consumptionModeId: '@consumptionModeId',
        areaId: '@areaId'
      },
      transformResponse: parseData
    },
    deleteConsumptionMode: {
      method: RESOURCES_VARS.API_METHODS.DELETE,
      url: ENV_VARS.API_URL + '/areas/:areaId/consumption_modes/:consumptionModeId',
      params: {
        areaId: '@areaId',
        consumptionModeId: '@consumptionModeId'
      },
      transformResponse: parseData
    },
    findAreaSchedulesByUuid: {
      isArray: true,
      method: RESOURCES_VARS.API_METHODS.GET,
      url: ENV_VARS.API_URL + '/areas/:areaUuid/area_schedules',
      params: {
        areaUuid: '@areaUuid'
      },
      transformResponse: parseData
    }
  });
}
import closeTabsView from 'app/components/parameters/close/close_tabs/close_tabs.view.html';

export default {
    templateUrl: closeTabsView,
    controller: 'CloseTabsController',
    controllerAs: 'closeTabsVm',
    bindings: {
        shouldUseUbereats: '<',
        shouldUseDeliveroo: '<',
    },
};

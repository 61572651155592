"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = _ngInjectExport;

var _isArray = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/array/is-array"));

_ngInjectExport.$inject = ["$q", "MONEYLINE_V1", "$http", "$interval", "ENV_VARS"];

function _ngInjectExport($q, MONEYLINE_V1, $http, $interval, ENV_VARS) {
  'ngInject'; // Class variables

  var logService = console;
  var moneylineServiceV1 = this; // Class methods

  moneylineServiceV1.getState = function () {
    var returnPayload = $q.defer();
    moneylineServiceV1.getStatus().then(function (data) {
      returnPayload.resolve(parseGetStatus(data));
    }).catch(function (err) {
      returnPayload.reject(err);
    });
    return returnPayload.promise;
  };

  moneylineServiceV1.isPaymentTerminalEnabled = function (currentState) {
    return ENV_VARS.ENV === 'development' || currentState[MONEYLINE_V1.PAYMENT_TERMINAL] === true;
  };

  moneylineServiceV1.isConecsEnabled = function () {
    return false;
  };

  moneylineServiceV1.isPrinterEnabled = function (currentState) {
    return currentState[MONEYLINE_V1.PRINTER] === true;
  }; // API Services


  moneylineServiceV1.getConfiguration = function () {
    var returnPayload = $q.defer();
    returnPayload.reject(false);
    return returnPayload.promise;
  };

  moneylineServiceV1.getStatus = function () {
    return postMoneylineHttpWrapper('http://localhost:8081/api/Management/GetStatus');
  };

  moneylineServiceV1.printTicket = function (serializedTicketArray) {
    return postMoneylineHttpWrapper('http://localhost:8081/api/Payment/PrintTicket', serializedTicketArray || []);
  };

  moneylineServiceV1.startPayment = function (orderId, amount, paymentMode) {
    if (paymentMode !== MONEYLINE_V1.PAYMENT_METHOD.CB && paymentMode !== MONEYLINE_V1.PAYMENT_METHOD.ES) {
      throw new Error('paymentMode must be equal to CB or ES');
    }

    return postMoneylineHttpWrapper('http://localhost:8081/api/Payment/StartPayment', {
      Id: orderId,
      Amount: amount,
      PaymentMode: paymentMode
    });
  };

  moneylineServiceV1.startPaymentConecs = function () {
    throw new Error('Conecs is only available in Moneyline V2');
  };

  moneylineServiceV1.startTransaction = function () {
    return false;
  };

  moneylineServiceV1.stopTransaction = function () {
    return false;
  };

  moneylineServiceV1.modeMaintenance = function () {
    return postMoneylineHttpWrapper('http://localhost:8081/api/Management/ModeMaintenance');
  }; // Private helpers


  function parseGetStatus(data) {
    var returnPayload = MONEYLINE_V1.INIT_STATE;

    if (data.DevicesStatus !== undefined && (0, _isArray.default)(data.DevicesStatus) && data.DevicesStatus.length > 0) {
      for (var i = 0; i < data.DevicesStatus.length; i++) {
        var element = data.DevicesStatus[i];

        if (element.Status === 'OK') {
          returnPayload[element.Name] = true;
          returnPayload.errors[element.Name] = '';
        } else {
          returnPayload[element.Name] = false;
          returnPayload.errors[element.Name] = element.Message;
        }
      }
    }

    return returnPayload;
  }

  function postMoneylineHttpWrapper(url, data) {
    var postData = data || null;
    var returnPayload = $q.defer();
    $http.post(url, postData, {
      headers: {
        Authorization: 'Basic dXNlcmFwaTptZHBhcGk='
      }
    }).then(function (response) {
      logService.info('[moneylineServiceV1][V1] post success');
      returnPayload.resolve(response.data);
    }, function (response) {
      logService.info('[moneylineServiceV1] post failed');
      returnPayload.reject(response);
    });
    return returnPayload.promise;
  }

  moneylineServiceV1.startReadContactless = function () {
    throw new Error('ReadContactless is only available in Moneyline V2');
  };

  moneylineServiceV1.stopReadContactless = function () {
    throw new Error('ReadContactless is only available in Moneyline V2');
  };
}
import switchView from './switch.view.html';

export default function($templateCache) {
  'ngInject';
  var true_value = true;
  var false_value = false;
  function link(scope, element, attrs, ngModel) {
    if (scope.ngDisabled) {
      angular.element(element[0]).addClass('disabled');
    }
    angular.element(element[0]).on('click', function() {
      scope.toogleSwitch();
    });
    scope.toogleSwitch = function() {
      scope.$apply(function() {
        if (!scope.ngDisabled) {
          switch (ngModel.$viewValue) {
            case true_value:
              ngModel.$setViewValue(false_value);
              angular.element(element[0]).removeClass('checked');
              break;

            case false_value:
              ngModel.$setViewValue(true_value);
              angular.element(element[0]).addClass('checked');
              break;

            default:
              ngModel.$setViewValue(true_value);
              break;
          }
        }
      });
    };
    scope.$watch(
      function() {
        return ngModel.$viewValue;
      },
      function(newVal, oldVal) {
        if (newVal == true_value) {
          angular.element(element[0]).addClass('checked');
        } else {
          angular.element(element[0]).removeClass('checked');
        }
      },
    );

    scope.$watch('ngDisabled', function(disabled) {
      if (disabled) {
        angular.element(element[0]).addClass('disabled');
      } else {
        angular.element(element[0]).removeClass('disabled');
      }
    });

    scope.isChecked = function() {
      return ngModel.$viewValue == true_value;
    };
  }

  return {
    link: link,
    restrict: 'E',
    transclude: true,
    require: 'ngModel',
    scope: {
      ngDisabled: '=?',
    },
    template: $templateCache.get(switchView),
  };
}

"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = _ngInjectExport;

var _concat = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/concat"));

var _urlPlaceholders = require("./utils/urlPlaceholders");

_ngInjectExport.$inject = ["$q", "apmService", "ENV_VARS"];

function _ngInjectExport($q, apmService, ENV_VARS) {
  'ngInject';

  if (!ENV_VARS.ELASTIC_APM_ACTIVE) return {}; // Don't get isHttpInterceptorEnabled here or it will be always the same value

  var apm = apmService.apm;
  return {
    request: function request(config) {
      var _context;

      if (!apmService.isHttpInterceptorEnabled) return config;
      var urlWithPlaceholders = (0, _urlPlaceholders.createUrlWithPlaceholders)(config.url);
      var name = (0, _concat.default)(_context = "".concat(config.method, " ")).call(_context, urlWithPlaceholders);
      var transaction = apm.startTransaction(name, 'custom', {
        managed: true
      });
      var span = transaction === null || transaction === void 0 ? void 0 : transaction.startSpan(name, 'custom', {
        blocking: true
      });
      config.transaction = transaction;
      config.span = span;
      return config;
    },
    response: function response(_response) {
      if (!apmService.isHttpInterceptorEnabled) return _response;
      var _response$config = _response.config,
          transaction = _response$config.transaction,
          span = _response$config.span;
      span === null || span === void 0 ? void 0 : span.end();
      transaction === null || transaction === void 0 ? void 0 : transaction.end();
      return _response;
    },
    responseError: function responseError(rejection) {
      if (!apmService.isHttpInterceptorEnabled) return $q.reject(rejection);
      var _rejection$config = rejection.config,
          transaction = _rejection$config.transaction,
          span = _rejection$config.span;
      apm.captureError(rejection.data);
      span === null || span === void 0 ? void 0 : span.end();
      transaction === null || transaction === void 0 ? void 0 : transaction.end();
      return $q.reject(rejection);
    }
  };
}
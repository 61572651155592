"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

require("core-js/modules/es.object.to-string");

require("core-js/modules/es.regexp.to-string");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = _ngInjectExport;

var _map = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/map"));

var _includes = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/includes"));

_ngInjectExport.$inject = ["localStorageService", "$q", "$injector", "launchdarklyService"];

function _ngInjectExport(localStorageService, $q, $injector, launchdarklyService) {
  'ngInject';

  var provider = this;
  var interceptor = {};
  var authService; // controller constants
  // =============================================================================

  var CONSTANTS = {
    BEARER: 'Bearer',
    SPACE: ' '
  }; // Public methods & properties
  // =============================================================================

  interceptor.request = defaultRequest;
  interceptor.responseError = defaultResponseError; // Default methods if no setting on provider
  // =============================================================================

  var getTokenFromStorage = function getTokenFromStorage(_ref) {
    var pathAccessToken = _ref.pathAccessToken,
        pathGuestAccessToken = _ref.pathGuestAccessToken,
        pathGuestBrandAccessToken = _ref.pathGuestBrandAccessToken,
        accessToken = _ref.accessToken;
    var guestToken = localStorageService.cookie.get(pathGuestAccessToken);

    if (guestToken) {
      return guestToken.accessToken;
    }

    var guestBrandToken = localStorageService.cookie.get(pathGuestBrandAccessToken);

    if (guestBrandToken) {
      return guestBrandToken.accessToken;
    }

    var cookie = localStorageService.cookie.get(pathAccessToken);

    if (cookie) {
      return cookie.accessToken;
    }

    return accessToken || null;
  };

  var getVersionFromStorage = function getVersionFromStorage() {
    return localStorageService.cookie.get('app-version');
  };

  function defaultRequest(config) {
    var _context;

    if ((0, _includes.default)(_context = config.url).call(_context, 'http')) {
      if (!authService) {
        authService = $injector.get('authService');
      }

      var versionFromStorage = getVersionFromStorage();
      config.headers = config.headers || {};
      config.headers['App-version'] = versionFromStorage ? versionFromStorage.toString() : undefined;
      var token = getTokenFromStorage(authService);

      if (!config.headers.Authorization && token) {
        config.headers.Authorization = CONSTANTS.BEARER + CONSTANTS.SPACE + token;
      }
    }

    return config;
  }

  function defaultResponseError(response) {
    var _context2;

    (0, _map.default)(_context2 = provider.caseResponseError).call(_context2, function (caseRequest) {
      if (caseRequest.status === response.status) {
        var _context3;

        var $state = $injector.get('$state');
        var Api = $injector.get('Api');
        var authService = $injector.get('authService');
        var netWorkService = $injector.get('networkService');
        var useDefault = true;
        (0, _map.default)(_context3 = caseRequest.callbacks).call(_context3, function (callback) {
          if (response.data && response.data.code === callback.code) {
            useDefault = false;
            callback.fn($state, Api, authService);
          }
        });

        if (useDefault && caseRequest.hasOwnProperty('default') && typeof caseRequest.default === 'function') {
          caseRequest.default($state, Api, authService, netWorkService);
        }
      }
    });
    return $q.reject(response);
  } // Service return
  // =============================================================================


  return interceptor;
}
export default function ($state, statePromise, printService, $filter, CONSUMPTION_MODES) {
    'ngInject';

    const vm = this;
    const drivers = {
        0: 'EPSON',
        1: 'STAR',
    };

    vm.autoPrint = printService.autoPrint;
    vm.printDelay = printService.printDelay;
    vm.consumptionModes = CONSUMPTION_MODES;

    vm.printDriveNotificationTicket = printService.printDriveNotificationTicket;
    vm.driveNotificationTicketPrinter = printService.driveNotificationTicketPrinter;
    vm.driveNotificationTicketId = printService.driveNotificationTicketId;
    vm.tickets = printService.tickets;

    vm.webBridgeEnabled = true;

    vm.recoverUsbPrinter = printService.recoverUsbPrinter;
    vm.save = function () {
        printService.autoPrint = vm.autoPrint;
        printService.printDelay = vm.printDelay;
        printService.printDriveNotificationTicket = vm.printDriveNotificationTicket;
        printService.driveNotificationTicketPrinter = vm.driveNotificationTicketPrinter;
        printService.driveNotificationTicketId = vm.driveNotificationTicketId;
        printService.save();
    };

    vm.openModal = function () {
        statePromise
            .go(
                '.add.search',
                {},
                {
                    location: false,
                },
                'addPrinter',
            )
            .then(function (printer) {
                printService.setPrinter(printer);
                setPrinterToView(printer);
            });
    };

    vm.printers = [];

    vm.delays = [
        {
            name: $filter('translate')('PARAMETERS_PRINT.WHEN_RECEIVED'),
            value: 0,
        },
        {
            name: `5 ${$filter('translate')('PARAMETERS_PRINT.MINUTES')}`,
            value: 5,
        },
        {
            name: `10 ${$filter('translate')('PARAMETERS_PRINT.MINUTES')}`,
            value: 10,
        },
        {
            name: `15 ${$filter('translate')('PARAMETERS_PRINT.MINUTES')}`,
            value: 15,
        },
        {
            name: `20 ${$filter('translate')('PARAMETERS_PRINT.MINUTES')}`,
            value: 20,
        },
        {
            name: `25 ${$filter('translate')('PARAMETERS_PRINT.MINUTES')}`,
            value: 25,
        },
        {
            name: `30 ${$filter('translate')('PARAMETERS_PRINT.MINUTES')}`,
            value: 30,
        },
        {
            name: `35 ${$filter('translate')('PARAMETERS_PRINT.MINUTES')}`,
            value: 35,
        },
        {
            name: `40 ${$filter('translate')('PARAMETERS_PRINT.MINUTES')}`,
            value: 40,
        },
        {
            name: `45 ${$filter('translate')('PARAMETERS_PRINT.MINUTES')}`,
            value: 45,
        },
        {
            name: `50 ${$filter('translate')('PARAMETERS_PRINT.MINUTES')}`,
            value: 50,
        },
        {
            name: `55 ${$filter('translate')('PARAMETERS_PRINT.MINUTES')}`,
            value: 55,
        },
        {
            name: `60 ${$filter('translate')('PARAMETERS_PRINT.MINUTES')}`,
            value: 60,
        },
    ];

    function setPrinterToView(printer, index) {
        const ticketsToDisplay = [];
        if (printer.tickets) {
            for (let i = 0; i < printer.tickets.length; i++) {
                const ticket = printService.getTicket(printer.tickets[i]);
                if (ticket) {
                    ticketsToDisplay.push(ticket.name);
                }
            }
        }
        const printerToSet = {
            name: printer.name,
            ip: printer.ip,
            driver: drivers[printer.driverType],
            tickets: ticketsToDisplay.join(','),
            mac: printer.mac,
        };
        // eslint-disable-next-line eqeqeq
        if (index == null || index == undefined) {
            vm.printers.push(printerToSet);
        } else {
            vm.printers[index] = printerToSet;
        }
    }

    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const printer in printService.printers) {
        setPrinterToView(printService.printers[printer]);
    }

    vm.removePrinter = function (index) {
        statePromise
            .go(
                '.confirm_delete_printer',
                {},
                {
                    location: false,
                },
                'confirmDeletePrinter',
            )
            .then(function () {
                printService.removePrinter(vm.printers[index].mac);
                vm.printers.splice(index, 1);
            });
    };

    vm.editPrinter = function (index) {
        statePromise
            .go(
                '.add.validate',
                {
                    edit: true,
                    printer: printService.printers[vm.printers[index].mac],
                },
                {
                    location: false,
                },
                'addPrinter',
            )
            .then(function (printer) {
                printService.setPrinter(printer);
                setPrinterToView(printer, index);
            });
    };

    vm.testPrint = function (mac) {
        printService.testPrint(mac);
    };
}

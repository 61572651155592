import orderResourceModule from '@innovorder/angularcore/core/resources/order/order.module';
import menuResourceModule from '@innovorder/angularcore/core/resources/menu/menu.module';
import stuartResourceModule from '@innovorder/angularcore/core/resources/stuart/stuart.module';
import stuartV2ResourceModule from '@innovorder/angularcore/core/resources/stuart_v2/stuart_v2.module';
import angular from 'angular';
import parametersModule from '../parameters/parameters.module';
import availabilityModule from '../availability/availability.module';
import ordersHomeModule from '../orders_home/orders_home.module';
import stuartModule from '../stuart/stuart.module';
import homeRoutes from './home.routes';
import homeController from './home.controller';

const dependencies = [
    orderResourceModule,
    parametersModule,
    availabilityModule,
    ordersHomeModule,
    stuartModule,
    menuResourceModule,
    stuartResourceModule,
    stuartV2ResourceModule,
];

angular.module('app.home', dependencies).config(homeRoutes).controller('HomeController', homeController);
export default angular.module('app.home').name;

import sortByTemplateView from './sort_by_template.view.html';
/**
 *  Sort By directive
 *  ---------------------------------------------------
 *
 * Add sorting interface to TH elements which have the `sortBy` attribute on
 * them. The directive accepts the parameter `sortBy`, which should correspond
 * to the key on the table items on which to sort.
 *
 * Suppose the items look like this:
 *
 *     {
 *       name: 'Mikael',
 *       age: 25
 *     }
 *
 * then use the `sortBy attribute like the following, in order to make the name-
 * column sortable:
 *
 *     <th sortBy="name">Name</th>
 *
 * The directive is required to be on a child of an siTable, as it communicates
 * with the siTable controller.
 *
 *
 * Credits : si-table by simplicitylabs
 *           https://github.com/simplicitylabs/si-table
 *
 */
export default function($rootScope) {
  'ngInject';

  function link(scope, element, attrs, controller) {
    // Copy the sorting parameters from the siTable controller. Since
    // we're copying a reference, the parameters will stay in sync.
    var params = controller.sortingParams;
    var paginationParams = controller.paginationParams;

    // Observe the value of the `sortBy` attribute and update the
    // internal model
    attrs.$observe('sortBy', function(sortBy) {
      scope.sortBy = sortBy;
    });

    // If the sortInit attribute is set, then initialize sorting on this
    // header
    if (attrs.sortInit === 'desc') {
      params.sortArray.push('-' + attrs.sortBy);
      scope.state = 'desc';
    } else if (attrs.sortInit) {
      params.sortArray.push(attrs.sortBy);
      scope.state = 'asc';
    }

    scope.sort = function() {
      var sortBy = attrs.sortBy;
      if (!sortBy || !params) {
        return;
      }

      if (params.single) {
        $rootScope.$broadcast('resetSorting');
      }

      // Tri-state: ascending -> descending -> neutral, represented by
      // an array as per Angular's orderBy specification.

      // ascending -> descending
      if (params.sortArray.indexOf(sortBy) !== -1) {
        if (params.single) {
          params.sortArray = ['-' + sortBy];
          params.sortModel = attrs.sortModel;
        } else {
          params.sortArray[params.sortArray.indexOf(sortBy)] = '-' + sortBy;
        }
        scope.state = 'desc';

        // descending -> neutral
      } else if (params.sortArray.indexOf('-' + sortBy) !== -1) {
        if (params.single) {
          params.sortArray = [];
          params.sortModel = null;
        } else {
          params.sortArray.splice(params.sortArray.indexOf('-' + sortBy), 1);
        }
        scope.state = '';

        // neutral -> ascending
      } else {
        if (params.single) {
          params.sortArray = [sortBy];
          params.sortModel = attrs.sortModel;
        } else {
          params.sortArray.push(sortBy);
        }
        scope.state = 'asc';
      }
      // Added by @DORIAN to reset page location when using sortable tools
      paginationParams.offset = 0;
    };

    scope.$on('resetSorting', function(event) {
      scope.state = '';
    });
  }

  return {
    restrict: 'A',
    transclude: true,
    replace: true,
    scope: true,
    require: '^fioTable',
    link: link,
    templateUrl: sortByTemplateView,
  };
}

export default function (printService, $timeout, ENV_VARS, $state) {
    'ngInject';

    const vm = this;
    vm.hasPrinters = false;
    vm.timeoutExceeded = false;
    vm.selectedPrinter = null;
    vm.printService = printService;

    $timeout(function () {
        if (!vm.hasPrinters) {
            vm.timeoutExceeded = true;
        }
    }, ENV_VARS.PRINTER_SEARCH_TIMEOUT);

    vm.selectPrinter = function (printer) {
        if (!vm.selectedPrinter || vm.selectedPrinter.mac !== printer.mac) {
            vm.selectedPrinter = printer;
        } else {
            vm.selectedPrinter = null;
        }
    };

    vm.addPrinter = function () {
        if (vm.selectedPrinter) {
            $state.go('^.validate', { printer: vm.selectedPrinter });
        }
    };
}

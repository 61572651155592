import modalStepView from './modal_step.view.html';
export default function($templateCache) {
  'ngInject';
  function link(scope, element, attrs, ctrller) {
    ctrller.addStep(element[0]);

    scope.$watch(
      function() {
        return element.attr('class');
      },
      function(value) {
        if (value !== undefined && value.indexOf('active') > -1) {
          ctrller.computeClasses(element[0]);
        }
      },
    );
  }

  return {
    link: link,
    restrict: 'E',
    require: '^fioModalSteps',
    transclude: true,
    template: $templateCache.get(modalStepView),
  };
}

import tableHeadView from './table_head.view.html';

export default function($templateCache, $compile) {
  'ngInject';

  function link(scope, element, attrs, controller) {
    // if change comes from elsewhere
    scope.$watch(
      function() {
        return controller.getSelectedItemsCount();
      },
      function(value) {
        scope.selectedCount = value;

        if (controller.getNumberItemsInPage() == value) {
          scope.selectedCount = true;
        }
      },
    );

    // if select all option is allowed, we append an checkbox to do so
    if (attrs.allowSelectAll != undefined) {
      var newElement = $compile(
        '<th><fio-checkbox ng-model="selectedCount"></fio-checkbox></th>',
      )(scope);
      angular.element(element.children()[0]).prepend(newElement);

      angular.element(newElement).on('click', function() {
        // controller.selectAll = scope.selectedCount;

        if (scope.selectedCount === true) {
          controller.selectAll();
        } else if (scope.selectedCount === false) {
          controller.unselectAll();
        }
      });
    }
  }

  function controller($scope) {
    'ngInject';
  }

  return {
    link: link,
    restrict: 'A',
    require: '^fioTable',
    controller: controller,
    transclude: true,
    replace: true,
    template: $templateCache.get(tableHeadView),
  };
}

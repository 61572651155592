"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = _ngInjectExport;
_ngInjectExport.$inject = ["$resource", "$log", "RESOURCES_VARS", "ENV_VARS"];

function _ngInjectExport($resource, $log, RESOURCES_VARS, ENV_VARS) {
  'ngInject'; // resource rules
  // =============================================================================

  function parseData(response) {
    try {
      var body = angular.fromJson(response);

      if (body && body.data) {
        return body.data;
      }

      return body;
    } catch (e) {
      $log.error(e);
      return e;
    }
  }

  return $resource(ENV_VARS.API_URL + '/menus/:menuId/products/:signature/:action', {
    menuId: '@menuId',
    signature: '@signature'
  }, {
    query: {
      isArray: true,
      method: RESOURCES_VARS.API_METHODS.GET,
      transformResponse: parseData,
      cancellable: true
    },
    count: {
      method: RESOURCES_VARS.API_METHODS.GET,
      params: {
        action: 'count'
      },
      transformResponse: parseData
    },
    get: {
      method: RESOURCES_VARS.API_METHODS.GET,
      transformResponse: parseData
    },
    save: {
      method: RESOURCES_VARS.API_METHODS.POST,
      transformResponse: parseData
    },
    duplicate: {
      method: RESOURCES_VARS.API_METHODS.POST,
      params: {
        action: 'duplicate'
      },
      transformResponse: parseData
    },
    update: {
      method: RESOURCES_VARS.API_METHODS.PUT,
      transformResponse: parseData
    },
    updateTags: {
      method: RESOURCES_VARS.API_METHODS.PUT,
      params: {
        action: 'tags'
      },
      transformResponse: parseData
    },
    updateQuantity: {
      method: RESOURCES_VARS.API_METHODS.PUT,
      params: {
        action: 'quantity'
      },
      transformResponse: parseData
    },
    delete: {
      method: RESOURCES_VARS.API_METHODS.DELETE,
      transformResponse: parseData
    }
  });
}
'use strict';

import angular from 'angular';

let env = angular.module("app.constants", [])

.constant("ENV_VARS", {
	"API_URL": "https://api-preprod.innovorder.fr",
	"API_METHODS": {
		"GET": "GET",
		"PUT": "PUT",
		"POST": "POST",
		"DELETE": "DELETE"
	},
	"APP_NAME": "reception",
	"APP_VERSION": "dev",
	"FB_API_KEY": "AIzaSyBeF2xdhhmYBKj51MVDLyLTANBsBs_WK_A",
	"FB_DB_URL": "https://preprod-fring-events.firebaseio.com/",
	"STATIC_URL": "https://static-preprod.innovorder.fr",
	"DEBUG": true,
	"GOOGLE_MAPS_API_KEY": "AIzaSyA6VGwffN4pxPROUDbEfFhFCGKP1PnykKQ",
	"GOOGLE_RECAPTCHA_API_KEY": "6LcLcSUTAAAAABv1XCKkyqNHl0OcSEH_hz6mvvvU",
	"LOG_MIN_TYPE": "info",
	"LOG_MAX_STACK": 3,
	"QUEUE_LENGTH_PRINTED_ORDERS": 600,
	"USE_FIXED_PRINTS": [],
	"QUEUE_LENGTH_RECEIVED_ORDERS": 600,
	"ORDERS_SERVICES": {
		"PLACED": {
			"INTERVAL": 10000,
			"NAME": "PLACED"
		},
		"VALIDATED": {
			"INTERVAL": 30000,
			"NAME": "VALIDATED"
		},
		"IN_PROGRESS": {
			"INTERVAL": 60000,
			"NAME": "IN_PROGRESS"
		},
		"DONE": {
			"INTERVAL": 300000,
			"NAME": "DONE"
		}
	},
	"SOURCE": "4",
	"NOTIFY_SOUND_URL": "/reception/sounds/druluring.mp3",
	"DRIVE_SOUND_URL": "/reception/sounds/drive.mp3",
	"PRINTER_SEARCH_TIMEOUT": 45000,
	"CONTACT_PICTURE": "/reception/images/clemence.jpg",
	"CONTACT_NAME": "Clémence",
	"CONTACT_PHONE": "+33 6 77 38 30 13",
	"ORDER_DELIVERY_STATUS": {
		"0": "ORDER_DELIVERY_QUOTE_OPEN",
		"1": "ORDER_DELIVERY_QUOTE_EXPIRED",
		"2": "ORDER_DELIVERY_CANCELLED",
		"3": "ORDER_DELIVERY_SEARCH",
		"4": "ORDER_DELIVERY_PICKING",
		"5": "ORDER_DELIVERY_DELIVERING",
		"6": "ORDER_DELIVERY_DELIVERED",
		"-1": "ORDER_DELIVERY_SCHEDULED"
	},
	"STUART_TRANSPORTS": {
		"bike": 2,
		"motorbike": 3,
		"car": 4,
		"cargobike": 5
	},
	"STUART_CONTACT": "09 08 76 14 13",
	"PRINT_DELAY": {
		"DEFAULT": {
			"TAKE_AWAY": 0,
			"DELIVERY": 0,
			"SIT_IN": 0,
			"DRIVE": 0
		}
	},
	"CONSOMMATION_MODES": {
		"DELIVERY": 1,
		"TAKE_AWAY": 2,
		"SIT_IN": 3
	},
	"ENV": "preprod",
	"SENTRY_URL": "https://095a45b938544ad4b70bde451526bab6@o66040.ingest.sentry.io/1741906",
	"LAUNCH_DARKLY_ENV_ID": "62a1c3a6449acb12afd627f6"
})

;

export default env;

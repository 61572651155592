export default function () {
  'ngInject';

  return {
    require: 'ngModel',
    restrict: 'A',
    link: (scope, element, attributes, ngModel) => {
      ngModel.$validators.min = function (modelValue, viewValue) {
        if (!attributes.required && !viewValue) {
          return true;
        }
        return parseFloat(viewValue) >= parseFloat(attributes.fioMin);
      };
    },
  };
}
